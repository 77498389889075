import { Alert, Button, Card, Checkbox, Col, DatePicker, Divider, Input, Row, Select, Space, Switch, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ceil from 'lodash/ceil';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeSMSAccount, changeSMSAccountLoading } from '../../../store/actions/messages';
import Network from '../../../utils/network';
import { BCActiveLessons, BCActiveLessonsClient, BCLanguage, CodeDiscount, Company, CompanyProduct, Country, FullSMSAccount, Medal, RouterProps, SMSSend } from '../../../utils/types/generalTypes';
import { LoadClientBodyRequest, MailBodyRequest, SMSBodyRequest, SMSTranslationBodyRequest } from '../../../utils/types/networkTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification, shrinkDate, ShrinkedText, shrinkTime } from '../../../utils/utils';
import DateTimePicker, { DateTimePickerType, DateTimePickerVersion } from '../../common/fields/dateTimePicker';
import { tableColumnTextFilterConfig } from '../../courseManagement/tableSearch';
import MailPreview from './mailPreview';

import { FormattedMessage, injectIntl } from 'react-intl';
import deUrl from '../../../../src/images/lang/de.webp';
import enUrl from '../../../../src/images/lang/en.webp';
import frUrl from '../../../../src/images/lang/fr.webp';
import itUrl from '../../../../src/images/lang/it.webp';
import getFormat from '../../../utils/Lang';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';

enum DataType { From, To, CodesDiscount, Range, MedalId, ProductIds, ActivityIds, LanguageIds, CountryIds, LevelId, PrgId, MeetingId }

type ReduxProps = ConnectedProps<typeof connector>;


interface IProps {
    currentStep: number;
    create: boolean;
    alerts: { key: string, title: string, description: string, type: 'info' | 'warning' | 'error'; }[];
    checkCanSend: (smsAccount: FullSMSAccount, nrCreditUsed?: number) => boolean;
    setNextClients?: (value: boolean) => void;
    setNextMail?: (value: boolean) => void;
    finishCreatePromotion?: () => void;
    cancelCreate?: () => void;

    companyCodeDiscounts?: CodeDiscount[];
    loadingCompanyCodeDiscounts: boolean;
    companyProducts: CompanyProduct[];
    loadingCompanyProducts: boolean;
    medals: Medal[];
    countries: Country[];
    languages?: BCLanguage[];
    companyDetails?: Company;
    dispatch: StoreDispatch;
    name?: string;
    segment: 'mail' | 'sms';
    setSegment: (segment: 'mail' | 'sms') => void;
}

interface State {
    lessons?: BCActiveLessons[];
    filteredLessons?: BCActiveLessons[];
    selectedClients?: BCActiveLessons[];
    selectedCodeDiscount?: CodeDiscount;
    selectedLevelsIds?: string[];
    selectedPrgIds?: string[];
    selectedActivitiesIds?: string[];
    selectedMeetingIds?: string[];

    selectedProducts?: CompanyProduct[];
    loadLessonsBodyRequest: LoadClientBodyRequest;
    loadingLessons: boolean;
    isFilterModified: boolean;
    isLessonParameterModified: boolean;

    language: string,
    titleMailFr: string;
    contentMailFr: string;
    enableLanguageFr: boolean;
    titleMailEn: string;
    contentMailEn: string;
    enableLanguageEn: boolean;
    titleMailIt: string;
    contentMailIt: string;
    enableLanguageIt: boolean;
    titleMailDe: string;
    contentMailDe: string;
    enableLanguageDe: boolean;
    newSms: SMSSend;
    newSmsTranslations: SMSTranslationBodyRequest[];
    // linkValue: string;
    delayChecked: boolean;
}

type Props = IProps & RouterProps & ReduxProps & IntlProps;
/**
 * Component for the promotions tab in the CRM page
 */
class CreateMessage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            delayChecked: false,
            loadLessonsBodyRequest: {
                From: moment().startOf('day').hours(9),
                To: moment().add(1, 'day').endOf('day').hours(17).minutes(0)
            },
            // linkValue: '',
            loadingLessons: false,
            isFilterModified: false,
            isLessonParameterModified: true,
            language: "en",
            titleMailFr: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Informations importantes` : "Titre par défaut",
            contentMailFr: "<p>Veuillez modifier le message.</p>",
            enableLanguageFr: false,
            titleMailEn: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Important information` : "Default title",
            contentMailEn: "<p>Please change the message.</p>",
            enableLanguageEn: true,
            titleMailIt: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Informazioni importanti` : "Titolo predefinito",
            contentMailIt: "<p>Si prega di cambiare il messaggio.</p>",
            enableLanguageIt: false,
            titleMailDe: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Wichtige Informationen` : "Standardtitel",
            contentMailDe: "<p>Bitte ändern Sie die Nachricht.</p>",
            enableLanguageDe: false,
            newSms: { message: "", to: [], type: "alert", links: [] },
            newSmsTranslations: []
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {

        if (prevProps.create === false && this.props.create === true) {
            if (this.props.segment === 'mail') {
                this.createEmail();
            } else {
                this.createSMS();
            }
        }

        if ((this.state.lessons && this.state.lessons.length > 0) && (!isEqual(this.state.lessons, prevState.lessons) || !this.state.loadLessonsBodyRequest.From.isSame(prevState.loadLessonsBodyRequest.From) || !this.state.loadLessonsBodyRequest.To.isSame(prevState.loadLessonsBodyRequest.To))) {
            this.filterLessons();
        }
    }
    changeLang = (language: string) => {
        if (this.state.language !== language) {
            this.setState({ language });
        }
    };
    createSMS = () => {

        //setSMSSending(true)
        const { lessons, newSms, selectedClients, newSmsTranslations } = this.state;
        const { intl } = this.props;
        console.log('pfff 0', selectedClients, newSmsTranslations);

        const sms: SMSBodyRequest = {
            type: 'sms',
            text: '',
            to: [],
            delay: newSms.delay,
            links: [],
            simulate: newSms.simulate || false,
            translations: []
        };
        if (this.state.enableLanguageFr) {
            const tr = newSmsTranslations.find(t => t.lang === 'fr');
            if (tr !== undefined) sms.translations.push(tr);
        }
        if (this.state.enableLanguageEn) {
            const tr = newSmsTranslations.find(t => t.lang === 'en');
            if (tr !== undefined) sms.translations.push(tr);
        }
        if (this.state.enableLanguageIt) {
            const tr = newSmsTranslations.find(t => t.lang === 'it');
            if (tr !== undefined) sms.translations.push(tr);
        }
        if (this.state.enableLanguageDe) {
            const tr = newSmsTranslations.find(t => t.lang === 'de');
            if (tr !== undefined) sms.translations.push(tr);
        }
        sms.text = sms.translations.length > 0 ? sms.translations[0].message : '';


        selectedClients && lessons && this.uniqueBCActiveLessons(selectedClients).forEach((value) => {
            if (value) {
                sms.to.push({
                    language: value.language,
                    mail: value.email,
                    mobile: value.mobile,
                    cid: value.id,
                    firstName: value.firstName,
                    lastName: value.lastName
                });
            }


        });

        if (!sms.translations || sms.translations.length === 0) {
            console.log('pfff', sms.translations);
            showNotification(intl.formatMessage({ defaultMessage: 'You must add a message' }), "warning");
            return;
        }
        if (sms.to.length === 0) {
            showNotification(intl.formatMessage({ defaultMessage: 'Contact field is required' }), "warning");
            return;
        }
        //sms.simulate=true;

        Network.createClientMessages(sms).then(
            response => {
                const smsAccount = cloneDeep(this.props.smsAccount);
                if (smsAccount) {
                    smsAccount.sent = response;
                    this.props.changeSMSAccount(smsAccount);
                }

                showNotification(intl.formatMessage({ defaultMessage: 'SMS successfully sent' }), "success");
                this.props.finishCreatePromotion && this.props.finishCreatePromotion();
                this.props.cancelCreate && this.props.cancelCreate();
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while sending the SMS' }), "error");
            }
        );
    };
    createEmail = () => {
        const { lessons, selectedClients } = this.state;
        const { intl } = this.props;
        const mail: MailBodyRequest = {
            type: 'mail',
            text: '',
            translations: [],
            to: [],
        };

        if (this.props.name?.toLowerCase() === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'The school name is missing' }), "error");
            return;
        }
        if (this.state.enableLanguageFr) {
            mail.translations.push({
                lang: "fr",
                title: this.state.titleMailFr,
                description: this.state.contentMailFr,
                schoolName: this.props.name.toLowerCase()
            });
        }
        if (this.state.enableLanguageEn) {
            mail.translations.push({
                lang: "en",
                title: this.state.titleMailEn,
                description: this.state.contentMailEn,
                schoolName: this.props.name.toLowerCase()
            });
        }
        if (this.state.enableLanguageIt) {
            mail.translations.push({
                lang: "it",
                title: this.state.titleMailIt,
                description: this.state.contentMailIt,
                schoolName: this.props.name.toLowerCase()
            });
        }
        if (this.state.enableLanguageDe) {
            mail.translations.push({
                lang: "de",
                title: this.state.titleMailDe,
                description: this.state.contentMailDe,
                schoolName: this.props.name.toLowerCase()
            });
        }
        mail.text = mail.translations.length > 0 ? mail.translations[0].title : '';

        selectedClients && lessons && this.uniqueBCActiveLessons(selectedClients).forEach((value) => {
            const lesson = lessons.find(l => value.id === l.id);
            if (lesson) {
                mail.to.push({
                    language: lesson.language,
                    mail: lesson.email,
                    mobile: lesson.mobile,
                    cid: lesson.id,
                    firstName: lesson.firstName,
                    lastName: lesson.lastName
                });
            }


        });


        if (this.props.name?.toLowerCase() === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'The school name is missing' }), "error");
            return;
        }
        if (mail.text === '') {
            showNotification(intl.formatMessage({ defaultMessage: 'You must add a message' }), "warning");
            //setSMSSending(false)
            return;
        }
        if (mail.to.length === 0) {
            showNotification(intl.formatMessage({ defaultMessage: 'Contact field is required' }), "warning");
            //setSMSSending(false)
            return;
        }

        Network.createClientMessages(mail).then(
            response => {
                const smsAccount = cloneDeep(this.props.smsAccount);
                if (smsAccount) {
                    smsAccount.sent = response;
                    this.props.changeSMSAccount(smsAccount);
                }

                // setShowModal(false)
                // setSMSSending(false)
                showNotification(intl.formatMessage({ defaultMessage: 'Email successfully sent' }), "success");
                this.props.finishCreatePromotion && this.props.finishCreatePromotion();
                this.props.cancelCreate && this.props.cancelCreate();
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while sending the email' }), "error");
                // setSMSSending(false)
            }
        );
    };

    getActiveLessons = () => {
        const { intl } = this.props;
        this.setState({ loadingLessons: true, selectedClients: undefined, isFilterModified: false, isLessonParameterModified: false, selectedLevelsIds: [] });
        const { loadLessonsBodyRequest } = this.state;
        //get directory listing
        Network.getBCLoadLessons(this.props.match.params.lang || 'fr', loadLessonsBodyRequest.From.clone().startOf('day'), loadLessonsBodyRequest.To.clone().endOf('day')).then(
            (response: BCActiveLessons[]) => {
                if (response) {
                    this.setState({ lessons: response, filteredLessons: response, loadingLessons: false, isFilterModified: false, selectedActivitiesIds: undefined, selectedLevelsIds: undefined, selectedPrgIds: undefined }, () => this.filterLessons());
                } else {
                    this.setState({ lessons: undefined, filteredLessons: undefined, loadingLessons: false, isLessonParameterModified: true, isFilterModified: false, selectedActivitiesIds: undefined, selectedLevelsIds: undefined, selectedPrgIds: undefined });
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers' }), "error");
                }
            },
            () => {
                this.setState({ lessons: undefined, filteredLessons: undefined, loadingLessons: false, isLessonParameterModified: true, isFilterModified: false, selectedActivitiesIds: undefined, selectedLevelsIds: undefined, selectedPrgIds: undefined });
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers' }), "error");
            },
        );
    };
    /**
     * Called when a client is (un)checked
     * @param keys the new keys of checked clients
     */
    onChangeSelectedClients = (selectedRowsKeys: React.Key[], selectedRows: BCActiveLessons[]) => {
        const { lessons, selectedClients } = this.state;

        const missingRowKeys = selectedClients?.map(sc => `${sc.id}-clients-selection-id-key-${sc.lessonFrom}-${sc.lessonTo}-${sc.activityId}-${sc.levelId}-${sc.prgId}`).sort((a, b) => a < b ? -1 : 1).filter(k => !selectedRowsKeys.includes(k));

        const excludedClientIds = missingRowKeys ? (missingRowKeys.map(k => {
            const splittedKey = k.split('-');
            return splittedKey.length > 0 ? Number(splittedKey[0]) : null;
        }).filter(k => k != null) as number[]).sort((a, b) => a < b ? -1 : 1) : [];

        const newSelectedClients = lessons?.filter(l => [...new Set(selectedRows.map(sr => sr.id))].includes(l.id) && !excludedClientIds.includes(l.id));

        this.setState({ selectedClients: newSelectedClients }, () => {
            if (this.props.setNextMail) {
                if (this.state.selectedClients && this.state.selectedClients.length > 0) {
                    this.props.setNextMail(true);
                } else {
                    this.props.setNextMail(false);
                }
            }
        });
    };


    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        // let { companyProducts, companyLevels } = this.props;
        const { companyCodeDiscounts } = this.props;
        const { loadLessonsBodyRequest } = this.state;
        let { isFilterModified, isLessonParameterModified } = this.state;
        let filterMissions = false;
        switch (type) {
            case DataType.CodesDiscount: {
                const selectedCodeDiscount = companyCodeDiscounts?.find((cp) => cp.id === value);
                this.setState({ selectedCodeDiscount }, () => {
                    if (this.state.selectedCodeDiscount === undefined) {
                        this.props.setNextClients && this.props.setNextClients(false);
                        this.props.setNextMail && this.props.setNextMail(false);
                    } else {
                        this.props.setNextClients && this.props.setNextClients(true);
                    }
                });
                break;
            }
            case DataType.Range:
                loadLessonsBodyRequest.From = moment(value[0]);
                loadLessonsBodyRequest.To = moment(value[1]);
                isLessonParameterModified = true;
                break;
            case DataType.From:
                if (!moment(value).isSame(loadLessonsBodyRequest.From, 'day')) {
                    isLessonParameterModified = true;
                }
                if (!this.resetDateAndKeepTime(moment(value)).isSame(this.resetDateAndKeepTime(loadLessonsBodyRequest.From))) {
                    filterMissions = true;
                }
                loadLessonsBodyRequest.From = moment(value);
                break;
            case DataType.To:
                if (!moment(value).isSame(loadLessonsBodyRequest.To, 'day')) {
                    isLessonParameterModified = true;
                }
                if (!this.resetDateAndKeepTime(moment(value)).isSame(this.resetDateAndKeepTime(loadLessonsBodyRequest.To))) {
                    filterMissions = true;
                }
                loadLessonsBodyRequest.To = moment(value);
                break;
            case DataType.CountryIds:
                loadLessonsBodyRequest.CountryIds = value;
                isLessonParameterModified = true;
                break;
            case DataType.LanguageIds:
                loadLessonsBodyRequest.LanguageIds = value;
                isFilterModified = true;
                break;
            case DataType.LevelId:
                this.setState({ selectedLevelsIds: value });
                isFilterModified = true;
                break;
            case DataType.PrgId:
                this.setState({ selectedPrgIds: value });
                isFilterModified = true;
                break;
            case DataType.ActivityIds:
                this.setState({ selectedActivitiesIds: value });
                isFilterModified = true;
                break;
            case DataType.MeetingId:
                this.setState({ selectedMeetingIds: value });
                isFilterModified = true;
                break;
        }

        this.setState({ loadLessonsBodyRequest, isFilterModified, isLessonParameterModified }, filterMissions ? () => this.filterLessons() : undefined);
    };

    renderFilters = () => {
        const { lessons } = this.state;
        return lessons?.map(c => <p key={`client-${c.id}`}>{c.email}</p>);
    };

    uniqueArray = (array: { id: string, title: string; }[]) => {
        return array.reduce((acc: { id: string, title: string, count: number; }[], cur) => {
            const existingItem = acc.find(item => cur.id === item.id);
            if (existingItem) {
                existingItem.count++;
            }
            else {
                acc.push({ ...cur, count: 1 });
            }
            return acc;
        }, []);
    };
    uniqueBCActiveLessons = (array: BCActiveLessons[] | undefined) => {
        if (array === undefined) {
            return [];
        }
        return array.reduce((acc: BCActiveLessonsClient[], cur) => {
            const existingItem = acc.find(item => cur.id === item.id);
            if (existingItem) {
                existingItem.count = existingItem.count ? existingItem.count + 1 : 1;
            }
            else {
                acc.push({ ...cur, count: 1 });
            }
            return acc;
        }, []);
    };
    resetDateAndKeepTime = (datetime: Moment) => {
        return moment(datetime.format('HH:mm:ss'), 'HH:mm:ss');
    };


    filterLessons = () => {
        const { isFilterModified, lessons, selectedPrgIds, selectedActivitiesIds, selectedMeetingIds, selectedLevelsIds, loadLessonsBodyRequest } = this.state;
        let filteredLessons = cloneDeep(lessons);

        const filterTimeFrom = this.resetDateAndKeepTime(loadLessonsBodyRequest.From);
        const filterTimeTo = this.resetDateAndKeepTime(loadLessonsBodyRequest.To);

        filteredLessons = filteredLessons?.filter(lesson => {
            const lessonTimeFrom = this.resetDateAndKeepTime(moment(lesson.lessonFrom, 'YYYY-MM-DDTHH:mm:ss'));
            const lessonTimeTo = this.resetDateAndKeepTime(moment(lesson.lessonTo, 'YYYY-MM-DDTHH:mm:ss'));

            if (lessonTimeFrom.isSameOrAfter(filterTimeFrom) && lessonTimeTo.isSameOrBefore(filterTimeTo)) {
                return true;
            }
            return false;
        });

        if (isFilterModified) {
            if (loadLessonsBodyRequest.LanguageIds && loadLessonsBodyRequest.LanguageIds.length > 0) {
                filteredLessons = filteredLessons?.filter(l => loadLessonsBodyRequest.LanguageIds?.includes(l.language));
            }
            if (selectedPrgIds && selectedPrgIds.length > 0) {
                filteredLessons = filteredLessons?.filter(l => selectedPrgIds?.includes(l.prgId));
            }
            if (selectedActivitiesIds && selectedActivitiesIds.length > 0) {
                filteredLessons = filteredLessons?.filter(l => selectedActivitiesIds?.includes(l.activityId));
            }
            if (selectedLevelsIds && selectedLevelsIds.length > 0) {
                filteredLessons = filteredLessons?.filter(l => selectedLevelsIds?.includes(l.levelId));
            }
            if (selectedMeetingIds && selectedMeetingIds.length > 0) {
                filteredLessons = filteredLessons?.filter(l => selectedMeetingIds?.includes(l.meetingId));
            }
            this.setState({ isFilterModified: false, filteredLessons });
        } else {
            this.setState({ filteredLessons });
        }
    };
    renderClients = () => {
        const { languages, intl } = this.props;
        const clientTableLocale = { emptyText: intl.formatMessage({ defaultMessage: 'No available customers' }) };
        const { lessons, filteredLessons, loadLessonsBodyRequest, loadingLessons, selectedClients, selectedMeetingIds, isFilterModified, isLessonParameterModified, selectedLevelsIds, selectedActivitiesIds, selectedPrgIds } = this.state;

        const levels = lessons ? this.uniqueArray(lessons.map(lesson => ({ id: lesson.levelId, title: lesson.levelText }))) : [];
        const prestations = lessons ? this.uniqueArray(lessons.map(lesson => ({ id: lesson.prgId, title: lesson.prgText }))) : [];
        const activities = lessons ? this.uniqueArray(lessons.map(lesson => ({ id: lesson.activityId, title: lesson.activityText }))) : [];
        const meetingPoints = lessons ? this.uniqueArray(lessons.map(lesson => ({ id: lesson.meetingId, title: lesson.meetingText }))) : [];
        const disableLevelsSelector = (lessons === undefined || lessons.length === 0 || levels.length === 0);
        const disableActivitiesSelector = (lessons === undefined || lessons.length === 0 || activities.length === 0);
        const disableMeetingsSelector = (lessons === undefined || lessons.length === 0 || activities.length === 0);
        const disablePrestationsSelector = (lessons === undefined || lessons.length === 0 || prestations.length === 0);
        return (
            <Row gutter={[15, 15]}>
                <Col span={24}>
                    <p><FormattedMessage defaultMessage={'Please select the customers who will receive the SMS notification.'} /></p>
                    <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'You can reduce the list by playing with the available filters.'} /></p>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }}>
                            <Card size="small" title={<FormattedMessage defaultMessage={'Select courses'} />}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span>
                                            <FormattedMessage defaultMessage={'Dates'} />
                                            <Tooltip title={intl.formatMessage({ defaultMessage: 'The course will be run between the selected dates.' })}>
                                                <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
                                            </Tooltip>
                                        </span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <DateTimePicker
                                            valueFrom={loadLessonsBodyRequest.From}
                                            onChangeFrom={d => this.dataChanged(DataType.From, d)}
                                            placeHolderDateFrom={intl.formatMessage({ defaultMessage: 'Start date' })}
                                            placeHolderTimeFrom={intl.formatMessage({ defaultMessage: 'Start hour' })}
                                            formatTimeFrom={getFormat('TIME_SHORT')}
                                            timeViewFrom={["hours", "minutes"]}
                                            // disableDateFrom={(date) => date.isBefore(currentEvent.startDate, 'days') || date.isAfter(currentEvent.endDate, 'days')}
                                            // disableTimeFrom={this.disableTime}

                                            valueTo={loadLessonsBodyRequest.To}
                                            onChangeTo={d => this.dataChanged(DataType.To, d)}
                                            tooLong={{ errorStr: 'Durée de la recherche anormalement longue', duration: 8760 }}
                                            placeHolderDateTo={intl.formatMessage({ defaultMessage: 'End date' })}
                                            placeHolderTimeTo={intl.formatMessage({ defaultMessage: 'End hour' })}
                                            formatTimeTo={getFormat('TIME_SHORT')}
                                            timeViewTo={["hours", "minutes"]}
                                            // disableDateTo={(date) => date.isBefore(currentEvent.startDate, 'days') || date.isAfter(currentEvent.endDate, 'days')}
                                            // disableTimeTo={this.disableTime}
                                            showTime={true}
                                            type={DateTimePickerType.RANGE}
                                            version={DateTimePickerVersion.FIELD}
                                        // setError={(error) => this.setState({ editBreakTimeError: error })}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>

                            <Button disabled={!isLessonParameterModified} loading={loadingLessons} type="primary" onClick={this.getActiveLessons} key="promotion-load-clients">
                                <FormattedMessage defaultMessage={'Load courses'} />
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>

                            <Card size="small" title={<FormattedMessage defaultMessage={'Filter'} />}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Language'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={languages === undefined || languages.length === 0 ? <FormattedMessage defaultMessage={'No language available'} /> : <FormattedMessage defaultMessage={'Select languages'} />}
                                            disabled={languages === undefined || languages.length === 0 || languages?.filter(l => lessons?.map(lesson => lesson.language).includes(l.Id)).length === 0}
                                            onChange={(id: string[]) => this.dataChanged(DataType.LanguageIds, id)}
                                            value={loadLessonsBodyRequest.LanguageIds}
                                            filterOption={true}
                                            optionFilterProp="label"
                                        >
                                            {languages?.filter(l => lessons?.map(lesson => lesson.language).includes(l.Id)).map(c => <Select.Option label={c.Text} value={c.Id} key={`crm-promotions-languages-${c.Id}`}>{c.Text}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Levels'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            loading={loadingLessons}
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={disableLevelsSelector ? <FormattedMessage defaultMessage={'No level available'} /> : <FormattedMessage defaultMessage={'Select levels'} />}
                                            disabled={disableLevelsSelector}
                                            onChange={(ids: string[]) => this.dataChanged(DataType.LevelId, ids)}
                                            value={selectedLevelsIds}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {levels.map(level => <Select.Option label={level.title} value={level.id} key={`cd-company-level-${level.id}`}><span title={level.title}>{level.title}</span></Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Service'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            loading={loadingLessons}
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={disablePrestationsSelector ? <FormattedMessage defaultMessage={'No service available'} /> : <FormattedMessage defaultMessage={'Select services'} />}
                                            disabled={disablePrestationsSelector}
                                            onChange={(ids: string[]) => this.dataChanged(DataType.PrgId, ids)}
                                            value={selectedPrgIds}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {prestations.map(level => <Select.Option label={level.title} value={level.id} key={`cd-company-level-${level.id}`}><span title={level.title}>{level.title}</span></Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Activity'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            loading={loadingLessons}
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={disableActivitiesSelector ? <FormattedMessage defaultMessage={'No activity available'} /> : <FormattedMessage defaultMessage={'Select activities'} />}
                                            disabled={disableActivitiesSelector}
                                            onChange={(ids: string[]) => this.dataChanged(DataType.ActivityIds, ids)}
                                            value={selectedActivitiesIds}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {activities.map(level => <Select.Option label={level.title} value={level.id} key={`cd-company-level-${level.id}`}><span title={level.title}>{level.title}</span></Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Meeting point'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            loading={loadingLessons}
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={disableMeetingsSelector ? <FormattedMessage defaultMessage={'No meeting point available'} /> : <FormattedMessage defaultMessage={'Select meeting points'} />}
                                            disabled={disableMeetingsSelector}
                                            onChange={(ids: string[]) => this.dataChanged(DataType.MeetingId, ids)}
                                            value={selectedMeetingIds}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {meetingPoints.map(meeting => <Select.Option label={meeting.title} value={meeting.id} key={`cd-company-meeting-${meeting.id}`}><span title={meeting.title}>{meeting.title}</span></Select.Option>)}
                                        </Select>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={!isFilterModified} type="primary" onClick={this.filterLessons} key="promotion-load-clients">
                                <FormattedMessage defaultMessage={'Filter customers'} />
                            </Button>
                        </Col>
                    </Row>


                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Card size="small" bodyStyle={{ padding: '1px 0 10px 0' }} title={<FormattedMessage defaultMessage={'Select customers'} />} extra={selectedClients ? this.uniqueArray(selectedClients.map(c => ({ id: `${c.id}`, title: c.firstName + ' ' + c.lastName }))).length : null}>
                        <Table
                            locale={clientTableLocale}
                            loading={loadingLessons}
                            key={`table-promotion-client-${filteredLessons ? filteredLessons.length : 'z'}`}
                            className='__poi-list'
                            rowKey={(sc: BCActiveLessons) => `${sc.id}-clients-selection-id-key-${sc.lessonFrom}-${sc.lessonTo}-${sc.activityId}-${sc.levelId}-${sc.prgId}`}
                            dataSource={filteredLessons}
                            columns={this.columns}
                            pagination={{
                                size: "small",
                                showLessItems: false,
                                defaultPageSize: 8,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range1}-{range2} of {total} customers'} values={{ range1: range[0], range2: range[1], total }} />
                            }}
                            scroll={{ x: 'max-content' }}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: this.onChangeSelectedClients,
                                selectedRowKeys: this.state.selectedClients?.map(sc => `${sc.id}-clients-selection-id-key-${sc.lessonFrom}-${sc.lessonTo}-${sc.activityId}-${sc.levelId}-${sc.prgId}`),
                                selections: [
                                    Table.SELECTION_ALL,
                                    Table.SELECTION_NONE,
                                ],
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        );
    };

    renderSMSByLanguage = (SMSView: (props: { value: { lang: string, message: string; }; }) => JSX.Element) => {
        const { newSmsTranslations, language, enableLanguageIt, enableLanguageEn, enableLanguageDe, enableLanguageFr } = this.state;
        const warningMessage = <p style={{ fontWeight: "bold", marginBottom: 25 }}><FormattedMessage defaultMessage={'This language will not be taken into account when sending the SMS. Please activate it if you wish to include it in the sending, and thus be able to modify it.'} /></p>;
        switch (language) {
            case "fr": {
                if (enableLanguageFr) {
                    let frSMSTranslations = newSmsTranslations.find(t => t.lang === 'fr');
                    if (frSMSTranslations === undefined) {
                        frSMSTranslations = {
                            lang: 'fr',
                            message: ''
                        };
                    }
                    return <SMSView
                        value={frSMSTranslations}
                    />;
                } else {
                    return warningMessage;
                }
            }
            case "it":
                if (enableLanguageIt) {
                    let itSMSTranslations = newSmsTranslations.find(t => t.lang === 'it');
                    if (itSMSTranslations === undefined) {
                        itSMSTranslations = {
                            lang: 'it',
                            message: ''
                        };
                    }
                    return <SMSView
                        value={itSMSTranslations}
                    />;
                } else {
                    return warningMessage;
                }
            case "de":
                if (enableLanguageDe) {
                    let deSMSTranslations = newSmsTranslations.find(t => t.lang === 'de');
                    if (deSMSTranslations === undefined) {
                        deSMSTranslations = {
                            lang: 'de',
                            message: ''
                        };
                    }
                    return <SMSView
                        value={deSMSTranslations}
                    />;
                } else {
                    return warningMessage;
                }
            default:
                if (enableLanguageEn) {
                    let enSMSTranslations = newSmsTranslations.find(t => t.lang === 'en');
                    if (enSMSTranslations === undefined) {
                        enSMSTranslations = {
                            lang: 'en',
                            message: ''
                        };
                    }
                    return <SMSView
                        value={enSMSTranslations}
                    />;
                } else {
                    return warningMessage;
                }
        }
    };

    renderMailByLanguage = (forceLanguage?: string) => {
        const { titleMailFr, contentMailFr, titleMailEn, contentMailEn, titleMailIt, contentMailIt, titleMailDe, contentMailDe, language, enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        const warningMessage = <p style={{ fontWeight: "bold" }}><FormattedMessage defaultMessage={'This language will not be taken into account when sending the e-mail. Please enable it if you wish to include it in the delivery, so that you can modify it.'} /></p>;
        switch (forceLanguage ? forceLanguage : language) {
            case "fr":
                if (enableLanguageFr) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailFr: value })}
                        modifyContent={(value: string) => this.setState({ contentMailFr: value })}
                        title={titleMailFr}
                        content={contentMailFr}
                    />;
                } else {
                    return warningMessage;
                }
            case "it":
                if (enableLanguageIt) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailIt: value })}
                        modifyContent={(value: string) => this.setState({ contentMailIt: value })}
                        title={titleMailIt}
                        content={contentMailIt}
                    />;
                } else {
                    return warningMessage;
                }
            case "de":
                if (enableLanguageDe) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailDe: value })}
                        modifyContent={(value: string) => this.setState({ contentMailDe: value })}
                        title={titleMailDe}
                        content={contentMailDe}
                    />;
                } else {
                    return warningMessage;
                }
            default:
                if (enableLanguageEn) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailEn: value })}
                        modifyContent={(value: string) => this.setState({ contentMailEn: value })}
                        title={titleMailEn}
                        content={contentMailEn}
                    />;
                } else {
                    return warningMessage;
                }
        }
    };

    changeSwitchValue = (language: string, value: boolean) => {
        const { enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        const { intl } = this.props;

        switch (language) {
            case "fr":
                if (!value && !enableLanguageEn && !enableLanguageDe && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. Impossible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageFr: value });
                }
                break;
            case "it":
                if (!value && !enableLanguageEn && !enableLanguageDe && !enableLanguageFr) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. Impossible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageIt: value });
                }
                break;
            case "de":
                if (!value && !enableLanguageEn && !enableLanguageFr && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. Impossible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageDe: value });
                }
                break;
            default:
                if (!value && !enableLanguageFr && !enableLanguageDe && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. Impossible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageEn: value });
                }
                break;
        }
    };

    renderSwitchByLanguage = () => {
        const { language, enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        switch (language) {
            case "fr":
                return <Switch size="small" checked={enableLanguageFr} onChange={(e) => this.changeSwitchValue(language, e)} />;
            case "it":
                return <Switch size="small" checked={enableLanguageIt} onChange={(e) => this.changeSwitchValue(language, e)} />;
            case "de":
                return <Switch size="small" checked={enableLanguageDe} onChange={(e) => this.changeSwitchValue(language, e)} />;
            default:
                return <Switch size="small" checked={enableLanguageEn} onChange={(e) => this.changeSwitchValue(language, e)} />;
        }
    };

    countEmailString = (value: string) => {
        const urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig);

        const splittedValue = value.split(/(\s+)/);
        let newCount = 0;
        for (let i = 0; i < splittedValue.length; i++) {
            if (i % 2 != 1) {
                if (splittedValue[i].match(urlRegex)) {
                    newCount += 18;
                } else {
                    newCount += splittedValue[i].length;
                }
            } else {
                newCount += String(splittedValue[i]).length;
            }
        }
        return newCount;
    };
    getBCLanguages = (lang: string) => {
        switch (lang) {
            case 'fr':
                return [
                    'M',
                    'B',
                    'H01'
                ];

            case 'it':
                return [
                    '81'
                ];
            case 'de':
                return [
                    'X',
                    'W81'
                ];
            case 'en':
                return [
                    'J1',
                    'U1',
                    '52',
                    'G2',
                    'R2',
                    '2S',
                    'VU',
                    'OX',
                    'A31',
                    '361',
                ];
            default:
                return [
                    'J1',
                    'U1',
                    '52',
                    'G2',
                    'R2',
                    '2S',
                    'VU',
                    'OX',
                    'A31',
                    '361',
                ];
        }
    };
    checkSMSTranslationsChanges = (newSmsTranslations: { lang: string, message: string; }[]) => {
        const { lessons, selectedClients } = this.state;
        const uniqueClients: BCActiveLessonsClient[] = this.uniqueBCActiveLessons(lessons?.filter(l => selectedClients?.map(c => c.id)?.includes(l.id)));

        let totalNrOfSMS = 0;
        ['fr', 'de', 'it', 'en'].forEach(lang => {
            let langBasesNewSMSTranslation = newSmsTranslations.find(t => t.lang === lang);
            const langUniqueClients = uniqueClients.filter(c => this.getBCLanguages(lang).includes(c.language));
            if (langBasesNewSMSTranslation === undefined) {
                langBasesNewSMSTranslation = newSmsTranslations.length > 0 ? newSmsTranslations[0] : { lang, message: '' };
            }
            totalNrOfSMS += langUniqueClients.length * ceil(this.countEmailString(langBasesNewSMSTranslation.message) / 160);
            console.log('checksmschange', lang, newSmsTranslations, totalNrOfSMS, langUniqueClients);

        });
        this.props.smsAccount && this.props.checkCanSend(this.props.smsAccount, (totalNrOfSMS));
        this.setState({ newSmsTranslations });

    };
    renderSMSTextArea = ((props: { value: { lang: string, message: string; }; }) => {
        return (
            <div>
                <Input.TextArea
                    value={props.value.message}
                    placeholder={"Message"}
                    showCount={{
                        formatter: (arg) => {
                            const urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig);
                            const splittedValue = arg.value.split(/(\s+)/);
                            let newCount = 0;
                            for (let i = 0; i < splittedValue.length; i++) {
                                if (i % 2 != 1) {
                                    if (splittedValue[i].match(urlRegex)) {
                                        newCount += 18;
                                    } else {
                                        newCount += splittedValue[i].length;
                                    }
                                } else {
                                    newCount += String(splittedValue[i]).length;
                                }
                            }
                            const newNrOfSMS = ceil(newCount / 160);
                            return `${newCount} / ${newNrOfSMS > 0 ? `${160 * newNrOfSMS}` : 160} = ${newNrOfSMS}$`;
                        }
                    }}
                    maxLength={2500}
                    style={{ height: "100px", maxHeight: "300px" }}
                    autoSize={true}
                    onChange={(e) => {
                        const newSmsTranslations = this.state.newSmsTranslations;
                        let newSmsTranslation = newSmsTranslations.find(t => t.lang === props.value.lang);
                        if (newSmsTranslation) {
                            newSmsTranslation.message = e.target.value;
                        } else {
                            newSmsTranslation = {
                                message: e.target.value,
                                lang: props.value.lang
                            };
                            newSmsTranslations.push(newSmsTranslation);
                        }
                        this.setState({ newSmsTranslations });
                        this.checkSMSTranslationsChanges(newSmsTranslations);
                    }
                    }
                />
            </div>
        );
    });

    renderMessage = () => {
        const { delayChecked, newSms, language, lessons, selectedClients, titleMailEn, titleMailFr, titleMailDe, titleMailIt } = this.state;
        const { alerts, segment, intl } = this.props;
        return (
            <>
                {/* <Segmented 
                    block
                    style={{ width: "100%" }}
                    key={'message-segment-show-options'}
                    value={segment}
                    options={[
                        {
                            label: 'Mail',
                            value: 'mail'
                        },
                        {
                            label: 'SMS',
                            value: 'sms'
                        }
                    ]}
                    onChange={(value) => this.props.setSegment(value as ('sms' | 'mail'))}

                /> */}
                {segment === 'mail' ?
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <p><FormattedMessage defaultMessage={"Finally, please change the e-mail content to the desired languages. Customers who don't match any of the activated languages will receive the e-mail in the first available language."} /></p>
                            <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'To change the title, simply click on it (below the logo). The same applies to the message (below the title).'} /></p>
                        </Col>

                        <Col span={24}>
                            <Card>
                                <Row gutter={[15, 15]}>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <p className="__create-promotion-title" style={{ width: '25px', marginTop: '3px' }}>A: </p>
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch={false}
                                            allowClear={false}
                                            showArrow
                                            mode="multiple"
                                            tokenSeparators={[',', ';', ' ']}
                                            maxTagCount='responsive'
                                            defaultActiveFirstOption={false}
                                            placeholder={lessons === undefined || lessons.length === 0 ? <FormattedMessage defaultMessage={'No customer available'} /> : <FormattedMessage defaultMessage={'Select customers'} />}
                                            disabled={lessons === undefined || lessons.length === 0}
                                            value={[...new Set(selectedClients?.map(sc => sc.id))]}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            onChange={(values: number[]) => {
                                                const activeLessons = lessons?.filter(l => values.includes(l.id));
                                                this.setState({ selectedClients: activeLessons });
                                            }}
                                        >
                                            {this.uniqueBCActiveLessons(lessons).map(c => <Select.Option value={c.id} key={`create-promotion-render-message-client-${c.id}`}>{`${c.firstName} ${c.lastName} - ${c.email}`}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    <div className="__create-promotion-hr"></div>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <p className="__create-promotion-title" style={{ width: '55px' }}>Objet: </p>
                                        <p>{language === "fr" ? titleMailFr : language === "de" ? titleMailDe : language === "it" ? titleMailIt : titleMailEn}</p>
                                    </Col>
                                    <div className="__create-promotion-hr"></div>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span className="__create-promotion-title" style={{ lineHeight: "32px" }}>E-mail: </span>
                                        <div style={{ height: "32px", display: "flex" }}>
                                            {this.renderSwitchByLanguage()}
                                            <Select
                                                className="__create-promotion-select-lang"
                                                value={language}
                                                bordered={false}
                                                showArrow={false}
                                                onChange={n => this.changeLang(n)}>
                                                <Select.Option value={"en"} key={`language-en`}><img alt="" style={{ maxWidth: "100%" }} src={enUrl} /></Select.Option>
                                                <Select.Option value={"fr"} key={`language-fr`}><img alt="" style={{ maxWidth: "100%" }} src={frUrl} /></Select.Option>
                                                <Select.Option value={"de"} key={`language-de`}><img alt="" style={{ maxWidth: "100%" }} src={deUrl} /></Select.Option>
                                                <Select.Option value={"it"} key={`language-it`}><img alt="" style={{ maxWidth: "100%" }} src={itUrl} /></Select.Option>
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        {this.renderMailByLanguage()}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <p><FormattedMessage defaultMessage={'Finally, please edit the content of the SMS message.'} /></p>
                            <p><FormattedMessage defaultMessage={'Each credit designates 160 characters.'} /></p>
                        </Col>

                        <Col span={24}>
                            <Card>
                                <Row gutter={[25, 25]}>
                                    <Col xs={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Space style={{ width: "100%" }} direction={"vertical"}>
                                            <div style={{ paddingLeft: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                                    <Switch
                                                        size={'small'}
                                                        checked={newSms.simulate !== undefined ? newSms.simulate : false}
                                                        onChange={(checked) => {
                                                            const to_change_sms = cloneDeep(newSms);
                                                            to_change_sms.simulate = checked;
                                                            this.setState({ newSms: to_change_sms });
                                                        }
                                                        }
                                                        title={intl.formatMessage({ defaultMessage: 'Simulate SMS sending' })}
                                                    />
                                                    <span><FormattedMessage defaultMessage={'Simulate SMS sending'} /></span>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                                                    {this.renderSwitchByLanguage()}
                                                    <Select
                                                        className="__create-promotion-select-lang"
                                                        value={language}
                                                        bordered={false}
                                                        showArrow={false}
                                                        onChange={n => this.changeLang(n)}>
                                                        <Select.Option value={"en"} key={`language-en`}><img alt="" style={{ maxWidth: "100%" }} src={enUrl} /></Select.Option>
                                                        <Select.Option value={"fr"} key={`language-fr`}><img alt="" style={{ maxWidth: "100%" }} src={frUrl} /></Select.Option>
                                                        <Select.Option value={"de"} key={`language-de`}><img alt="" style={{ maxWidth: "100%" }} src={deUrl} /></Select.Option>
                                                        <Select.Option value={"it"} key={`language-it`}><img alt="" style={{ maxWidth: "100%" }} src={itUrl} /></Select.Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <Select
                                                style={{ width: '100%' }}
                                                showSearch={false}
                                                allowClear={false}
                                                showArrow
                                                mode="multiple"
                                                tokenSeparators={[',', ';', ' ']}
                                                maxTagCount='responsive'
                                                defaultActiveFirstOption={false}
                                                placeholder={lessons === undefined || lessons.length === 0 ? <FormattedMessage defaultMessage={'No customer available'} /> : <FormattedMessage defaultMessage={'Select customers'} />}
                                                disabled={lessons === undefined || lessons.length === 0}
                                                value={[...new Set(selectedClients?.map(sc => sc.id))]}
                                                filterOption={true}
                                                optionFilterProp="label"
                                                onChange={(values: number[]) => {
                                                    const activeLessons = lessons?.filter(l => values.includes(l.id));
                                                    this.setState({ selectedClients: activeLessons });
                                                }}
                                            >
                                                {this.uniqueBCActiveLessons(lessons).map(c => <Select.Option value={c.id} key={`create-promotion-render-message-client-${c.id}`}>{`${c.firstName} ${c.lastName} - ${c.mobile}`}</Select.Option>)}
                                            </Select>

                                            {this.renderSMSByLanguage(this.renderSMSTextArea)}



                                        </Space>
                                    </Col>
                                    <Col xs={{ span: 12 }} xxl={{ span: 12 }}>
                                        <Space style={{ width: "100%" }} direction={"vertical"}>
                                            <Checkbox
                                                checked={delayChecked}
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        const to_change_sms = cloneDeep(newSms);
                                                        to_change_sms.delay = undefined;
                                                        this.setState({ newSms: to_change_sms });
                                                    }
                                                    this.setState({ delayChecked: e.target.checked });
                                                }}
                                            >
                                                <FormattedMessage defaultMessage={'Set a send date and time'} />
                                            </Checkbox>
                                            <DatePicker
                                                disabled={!delayChecked}
                                                format={getFormat('DATE_AND_TIME_SHORT')}
                                                style={{ width: "100%" }}
                                                showTime
                                                value={newSms.delay !== undefined ? moment(newSms.delay) : newSms.delay}
                                                onChange={(e) => {
                                                    const to_change_sms = cloneDeep(newSms);
                                                    to_change_sms.delay = e?.format("YYYY-MM-DDTHH:mm:ss");
                                                    this.setState({ newSms: to_change_sms });
                                                }}
                                            />
                                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                                            {alerts.map(a => {
                                                return <Alert
                                                    key={a.key}
                                                    showIcon
                                                    type={a.type}
                                                    style={{ fontSize: "1em" }}
                                                    message={<span style={{ fontSize: 14, fontWeight: 600 }}>{a.title}</span>}
                                                    description={<span style={{ fontSize: 12 }}>{a.description}</span>}
                                                />;
                                            })

                                            }

                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
            </>
        );
    };

    renderSummary = () => {
        return <p>s</p>;
    };

    renderStep = () => {
        const { currentStep } = this.props;
        switch (currentStep) {
            case 0:
                return this.renderClients();
            case 1:
                return this.renderMessage();
            case 2:
                return this.renderMessage();
            case 4:
                return this.renderSummary();
            default:
                return this.renderSummary();

        }
    };

    render() {

        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    {
                        this.renderStep()
                    }
                </Col>
            </Row >

        );
    }


    columns: ColumnsType<BCActiveLessons> = [
        {
            title: <FormattedMessage defaultMessage={'Lesson'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Title'} />,
                    // dataIndex: 'description',
                    key: 'description',
                    onFilter: (value, record) => {
                        return record.description!
                            .toString()
                            .toLowerCase()
                            .includes(value.toString().toLowerCase());
                    },
                    width: 120,
                    ...tableColumnTextFilterConfig<BCActiveLessons>(),
                    sorter: (a: BCActiveLessons, b: BCActiveLessons) => !a.description ? -1 : !b.description ? 1 : a.description < b.description ? -1 : 1,
                    render: (ob: BCActiveLessons) => {
                        return (
                            <ShrinkedText key={`shrinked-text-lesson-description-${ob.id}`} maxSize={120} fullDescription={
                                <div>
                                    <p><span style={{ fontWeight: 600 }}>{ob.description}</span></p>
                                    <p><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Level'} />{':'} </span><span>{ob.levelText}</span></p>
                                    <p><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Service'} />{':'}</span><span>{ob.prgText}</span></p>
                                    <p><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Activity'} />{':'}</span><span>{ob.activityText}</span></p>
                                    <p><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Meeting point'} />{':'}</span><span>{ob.meetingText}</span></p>
                                </div>
                            }>{ob.description}</ShrinkedText>
                        );
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Date'} />,
                    // dataIndex: 'lessonFrom',
                    key: 'lessonFromDate',
                    defaultSortOrder: 'descend',
                    sorter: {
                        compare: (a, b) =>
                            moment(a.lessonFrom).unix() - moment(b.lessonFrom).unix(),
                    },
                    render: (ob: BCActiveLessons) => {
                        // return <div>{moment(ob.lessonFrom, 'YYYY-MM-DDTHH:mm:ss').format('Y-MM-DD HH:mm')}</div>
                        return shrinkDate(moment(ob.lessonFrom, 'YYYY-MM-DDTHH:mm:ss'), moment(ob.lessonTo, 'YYYY-MM-DDTHH:mm:ss'), 80);
                    },
                },
                {
                    title: <FormattedMessage defaultMessage={'Time'} />,
                    // dataIndex: 'lessonFrom',
                    key: 'lessonFromTime',
                    // defaultSortOrder: 'descend',
                    sorter: {
                        compare: (a, b) =>
                            this.resetDateAndKeepTime(moment(a.lessonFrom, 'YYYY-MM-DDTHH:mm:ss')).unix() - this.resetDateAndKeepTime(moment(b.lessonFrom, 'YYYY-MM-DDTHH:mm:ss')).unix(),
                    },
                    onCell: () => {
                        return {
                            'style': { background: '#dff2ee' }
                        };
                    },
                    render: (ob: BCActiveLessons) => {
                        // return <div>{moment(ob.lessonFrom, 'YYYY-MM-DDTHH:mm:ss').format('Y-MM-DD HH:mm')}</div>
                        return shrinkTime(moment(ob.lessonFrom, 'YYYY-MM-DDTHH:mm:ss'), moment(ob.lessonTo, 'YYYY-MM-DDTHH:mm:ss'));
                    },
                },


            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Customer'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'First name'} />,
                    // dataIndex: 'firstName',
                    key: 'firstName',
                    onFilter: (value, record) => {
                        return record.firstName!
                            .toString()
                            .toLowerCase()
                            .includes(value.toString().toLowerCase());
                    },
                    ...tableColumnTextFilterConfig<BCActiveLessons>(),
                    render: (ob: BCActiveLessons) => {
                        return <ShrinkedText fullDescription={<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}><span style={{ fontWeight: 600 }}>{ob.firstName + ' ' + ob.lastName}</span><span>{this.props.languages && this.props.languages.find(l => l.Id === ob.language)?.Text}</span></div>} key={`shrinked-text-lesson-name-${ob.id}`} maxSize={140}>{ob.firstName + ' ' + ob.lastName}</ShrinkedText>;
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Email'} />,
                    // dataIndex: 'email',
                    key: 'email',
                    onFilter: (value, record) => {
                        return record.email!
                            .toString()
                            .toLowerCase()
                            .includes(value.toString().toLowerCase());
                    },
                    ...tableColumnTextFilterConfig<BCActiveLessons>(),
                    sorter: (a: BCActiveLessons, b: BCActiveLessons) => !a.email ? -1 : !b.email ? 1 : a.email < b.email ? -1 : 1,
                    render: (ob: BCActiveLessons) => {
                        return <ShrinkedText key={`shrinked-text-lesson-email-${ob.id}`} maxSize={210}>{ob.email}</ShrinkedText>;
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Phone'} />,
                    dataIndex: 'mobile',
                    key: 'mobile',
                    onFilter: (value, record) => {
                        return record.mobile!
                            .toString()
                            .toLowerCase()
                            .includes(value.toString().toLowerCase());
                    },
                    ...tableColumnTextFilterConfig<BCActiveLessons>(),
                    sorter: (a: BCActiveLessons, b: BCActiveLessons) => !a.mobile ? -1 : !b.mobile ? 1 : a.mobile < b.mobile ? -1 : 1

                },
            ]
        },


    ];
}



const mapStateToProps = (state: ApplicationState) => ({
    medals: state.crm.medals,
    countries: state.crm.countries,
    languages: state.crm.languages,
    loadingCompanyCodeDiscounts: state.crm.loadingCompanyCodeDiscounts,
    companyCodeDiscounts: state.crm.companyCodeDiscounts,
    companyProducts: state.crm.companyProducts,
    loadingCompanyProducts: state.crm.loadingCompanyProducts,
    companyDetails: state.crm.companyDetails,
    name: state.crm.companyDetails?.name,
    smsAccount: state.messages.smsAccount,
    smsAccountLoading: state.messages.smsAccountLoading
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSMSAccountLoading: (loading: boolean) => dispatch(changeSMSAccountLoading(loading)),
    changeSMSAccount: (smsAccount: FullSMSAccount) => dispatch(changeSMSAccount(smsAccount)),
    dispatch,

});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(injectIntl(CreateMessage)));