import { useVirtualizer } from '@tanstack/react-virtual';
import { Select, Spin } from "antd";
import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Selectable from "react-selectable-box";
import { ImportCol, ImportRows, ImportTableErrors } from "../../../../utils/types/generalTypes";
import { generateUniqueIdentifier, showNotification } from "../../../../utils/utils";
import ImportRow from "./importRow";

interface Props {
    rawData: ImportRows;
    dynamicCols: ImportCol[];
    setRawData: Dispatch<SetStateAction<ImportRows>>
    setDynamicCols: Dispatch<SetStateAction<ImportCol[]>>
    loading: boolean;
    setLoading: (loading: boolean) => void;
    defaultSize: number;
    availableCols: ImportCol[];
    checkForErrors: (data: ImportRows, cols: ImportCol[]) => ImportTableErrors;
}

const ImportTable = (props: Props) => {
    const intl = useIntl();
    const {availableCols} = props;
    const [activeCell, setActiveCell] = useState([-1, -1]);
    const [activeRow, setActiveRow] = useState(-1);
    const [selectedCells, setSelectedCells] = useState<number[][] | undefined>(undefined)
    const [edit, setEdit] = useState(false)

    const maxHeight = useMemo(() => props.rawData.length - 1, [props.rawData.length]);
    const maxWidth = useMemo(() => props.dynamicCols.length - 1, [props.dynamicCols.length]);

    const checkForErrors = useCallback((params: { rows: ImportRows, cols: ImportCol[], prevRows?: ImportRows, modifiedUuid?: string, clear?: boolean }) => {
        const errors = props.checkForErrors(params.rows, params.cols)
        params.rows = params.rows.map(row => {
            return ({
                ...row,
                cells: row.cells.map(cell => {
                    return ({
                        ...cell,
                        error: {
                            ...cell.error,
                            fromNetwork: params.clear ? false : cell.error.fromNetwork && cell.value === params.prevRows?.find(r => r.uuid === row.uuid)?.cells[row.cells.findIndex(c => c === cell)].value,
                            error: false,
                        }
                    })
                })
            })
        })

        errors.forEach(err => {
            const rowIndex = params.rows.findIndex(row => row.uuid === err.uuid)
            params.rows[rowIndex].cells[err.col] = { ...params.rows[rowIndex].cells[err.col], error: err.error ? { ...err.error } : { error: false }, warn: { ...err.warn } }
        })
        return params.rows
    }, [props])

    const addOrRemoveRow = useCallback((rows: ImportRows) => {
        if (rows[rows.length - 1].cells.some(c => c.value !== ''))
            rows.push({
                uuid: generateUniqueIdentifier(),
                cells: props.dynamicCols.map(col => ({ value: '', type: col.type, width: col.width, error: { error: false } }))
            })
        else if (rows.length > 2 && rows[rows.length - 2].cells.filter(c => c.value === '').length === props.dynamicCols.length && rows.length - 1 >= props.defaultSize)
            rows.pop();

        return rows;
    }, [props.defaultSize, props.dynamicCols])

    const changeCellValue = useCallback((rows: Props['rawData'], value: string, rowIndex: number, cellIndex: number) => {
        rows[rowIndex].cells[cellIndex] = {
            ...rows[rowIndex].cells[cellIndex],
            value
        }
        return rows;
    }, [])

    const onPaste = useCallback(async (paste: React.ClipboardEvent<HTMLDivElement>, rowIndex: number, cellIndex: number) => {
        const pasteData = paste.clipboardData.getData('Text').split('\n');
        if (pasteData[pasteData.length - 1] === '')
            pasteData.pop();

        let data = cloneDeep(props.rawData);
        pasteData.filter(line => line.replace('\t', '').trim().length !== 0).forEach((line, lineIndex) => {
            line.split('\t').forEach((col, colIndex) => {
                const newRowIndex = rowIndex + lineIndex
                const newCellIndex = cellIndex + colIndex
                if (newCellIndex <= props.dynamicCols.length - 1)
                    data = changeCellValue(addOrRemoveRow(data), col.trim(), newRowIndex, newCellIndex)
            })
        })
        props.setRawData(checkForErrors({ rows: cloneDeep(data), cols: props.dynamicCols }));
        props.setLoading(false);
    }, [addOrRemoveRow, changeCellValue, checkForErrors, props])

    const onChange = useCallback((value: string, rowIndex: number, cellIndex: number) => {
        props.setRawData(prevData => checkForErrors({ rows: addOrRemoveRow(changeCellValue(cloneDeep(prevData), value, rowIndex, cellIndex)), cols: props.dynamicCols, prevRows: prevData, modifiedUuid: props.rawData[rowIndex].uuid }))
    }, [addOrRemoveRow, changeCellValue, checkForErrors, props])

    const changeCellTypesAndWidth = useCallback((rows: ImportRows, cols: ImportCol[]) => {
        const newRows = cloneDeep(rows);
        rows.forEach((row, rowIndex) => {
            cols.forEach((col, colIndex) => {
                newRows[rowIndex].cells[colIndex] = {
                    ...newRows[rowIndex].cells[colIndex],
                    width: availableCols[availableCols.findIndex(ac => ac.value === col.value)].width,
                    type: availableCols[availableCols.findIndex(ac => ac.value === col.value)].type
                }
            })
        })
        return newRows;
    }, [availableCols])

    const onColumnChange = useCallback((e: string, idx: number) => {
        props.setDynamicCols(prevCols => {
            const newCols = cloneDeep(prevCols);
            const newCol = newCols[newCols.findIndex(c => c.value === e)]
            const oldCol = newCols[idx]
            newCols[newCols.findIndex(c => c.value === e)] = oldCol;
            newCols[idx] = newCol;
            props.setRawData(changeCellTypesAndWidth(checkForErrors({ rows: cloneDeep(props.rawData), cols: newCols, clear: true }), newCols))
            return newCols
        })
    }, [changeCellTypesAndWidth, checkForErrors, props])

    const onMove = useCallback((direction: string) => {
        if (direction === 'ArrowUp') {
            const nextCell = activeCell[0] - 1;
            const newActiveCell = [nextCell < 0 ? maxHeight : nextCell, activeCell[1]]
            setActiveCell(newActiveCell)
        }
        if (direction === 'ArrowDown') {
            const nextCell = activeCell[0] + 1;
            const newActiveCell = [nextCell > maxHeight ? 0 : nextCell, activeCell[1]]
            setActiveCell(newActiveCell)
        }

        if (direction === 'ArrowLeft') {
            const nextCell = activeCell[1] - 1;
            const newActiveCell = [activeCell[0], nextCell < 0 ? maxWidth : nextCell]
            setActiveCell(newActiveCell)
        }
        if (direction === 'ArrowRight') {
            const nextCell = activeCell[1] + 1;
            const newActiveCell = [activeCell[0], nextCell > maxWidth ? 0 : nextCell]
            setActiveCell(newActiveCell)
        }
    }, [activeCell, maxHeight, maxWidth])

    const onMultipleCellChange = useCallback((value: string, cells: number[][]) => {
        props.setRawData(prevData => {
            const newData = cloneDeep(prevData)
            cells.forEach(([rowIndex, cellIndex]) => {
                newData[rowIndex].cells[cellIndex] = {
                    ...newData[rowIndex].cells[cellIndex],
                    value
                }
            })
            return checkForErrors({ rows: newData, cols: props.dynamicCols, prevRows: prevData });
        })
    }, [checkForErrors, props])

    const onMultipleCellCopy = useCallback(() => {
        const newPaste = selectedCells ? selectedCells.sort((a, b) => a[0] > b[0] ? 1 : 0).map((cell, index) => {
            const paste = `${props.rawData[cell[0]].cells[cell[1]].value}${selectedCells[index + 1] && selectedCells[index + 1][0] !== cell[0] ? '\n' : '\t'}`
            return paste
        }).join('') : ''

        navigator.clipboard.writeText(newPaste).then(
            () => showNotification(intl.formatMessage({ defaultMessage: 'Data copied to your clipboard' }), 'success'),
            () => showNotification(intl.formatMessage({ defaultMessage: 'Unable to copy the data to your clipboard' }), 'error'));

    }, [intl, props.rawData, selectedCells])

    const onMultipleCellDelete = useCallback(() => {
        onMultipleCellChange('', selectedCells || [])
    }, [onMultipleCellChange, selectedCells])

    const onRowDelete = useCallback((rowIndex: number) => {
        const allCells = props.rawData[rowIndex].cells.map((cell, index) => [rowIndex, index])
        onMultipleCellChange('', allCells)
    }, [onMultipleCellChange, props.rawData])

    const getSelectedCellsValue = useCallback((rowIndex: number, cellIndex: number) => {
        return `cell-${rowIndex}-${cellIndex}`;
    }, [])


    const rowVirtualizer = useVirtualizer({
        count: props.rawData.length,
        getScrollElement: () => document.getElementsByClassName('div-table')[0] as HTMLElement,
        estimateSize: () => 40,
        enabled: true,
    })

    useEffect(() => {
        rowVirtualizer.measure() //Parfait
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rawData])


    const gridTemplate = useMemo(() => `50px ` + '1fr '.repeat(props.dynamicCols.length).trim(), [props.dynamicCols.length])

    return (
        <Spin spinning={props.loading}>
            <Selectable
                boxStyle={{ display: 'none' }}
                scrollContainer={() => document.getElementsByClassName('div-table')[0] as HTMLElement}
                value={selectedCells ? selectedCells.map(c => getSelectedCellsValue(c[0], c[1])) : []}
                onStart={() => { setSelectedCells(undefined); setActiveCell([-1, -1]); setActiveRow(-1); }}
                onEnd={(selectingValue) => {
                    const result = selectingValue.map(a => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const [cell, rowIndex, cellIndex] = a.split('-').map(part => Number(part));
                        return [rowIndex, cellIndex];
                    });
                    setSelectedCells(result)
                    if (result.length > 0)
                        setActiveCell([result[0][0], result[0][1]])
                }}
                disabled={edit}
            >
                <div className='div-table' style={{
                    // height: `${rowVirtualizer.getTotalSize()}px`
                }}>
                    <div className='div-table-row div-table-header' style={{ gridTemplateColumns: gridTemplate, position: 'sticky', top: 0 }}>
                        <div className='div-table-cell' style={{ width: 50, minWidth: 50, maxWidth: 50 }} />
                        {
                            props.dynamicCols.map((col, index) => (
                                <div key={index} className={`div-table-cell`} style={col.width ? { width: col.width, minWidth: col.width } : {}}>
                                    <Select
                                        key={index}
                                        className='div-table-cell'
                                        value={col.value}
                                        options={availableCols}
                                        onChange={(e) => onColumnChange(e, index)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    {
                        rowVirtualizer.getVirtualItems().map((vItem, vIndex) => {
                            const data = props.rawData[vIndex]
                            const ignoreErrors = data.cells.some(d => d.value !== '') ? false : true

                            return (
                                <ImportRow
                                    key={vItem.key.toString()}
                                    cells={data.cells}
                                    selectedCellIndex={activeCell[0] === vIndex ? activeCell[1] : undefined}
                                    isActive={activeRow === vIndex}
                                    rowIndex={vIndex}
                                    ignoreErrors={ignoreErrors}
                                    gridTemplate={gridTemplate}
                                    onMove={onMove}
                                    setActiveCell={(e) => {
                                        setActiveCell(e);
                                    }}
                                    setActiveRow={() => setActiveRow(vIndex)}
                                    onCellChange={(value, row, cell) => onChange(value, row, cell)}
                                    onCellPaste={(value, row, cell) => onPaste(value, row, cell)}
                                    onCellFocus={(cell) => {
                                        setActiveRow(-1);
                                        if (activeCell[0] !== vIndex || activeCell[1] !== cell) {
                                            setActiveCell([vIndex, cell]);
                                            setSelectedCells(undefined);
                                        }
                                    }}
                                    onRowFocus={() => {
                                        setActiveRow(vIndex);
                                        setActiveCell([-1, -1]);
                                        setSelectedCells(undefined);
                                    }}
                                    onMultipleCellDelete={onMultipleCellDelete}
                                    onMultipleCellCopy={onMultipleCellCopy}
                                    onRowDelete={() => onRowDelete(vIndex)}
                                    isMultipleCellsSelected={selectedCells !== undefined}
                                    resetSelection={() => setSelectedCells(undefined)}
                                    setEdit={setEdit}
                                />
                            )
                        })
                    }
                </div>
            </Selectable>
        </Spin>
    );
}

export default ImportTable;