import * as Sentry from "@sentry/react";
import { Divider, Drawer, Popover, Space, Tag } from "antd";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../store/store";
import Network from "../../../utils/network";
import { ImportCol, ImportRows, ImportTableErrors } from "../../../utils/types/generalTypes";
import { NetworkUserImport } from "../../../utils/types/networkTypes";
import { generateUniqueIdentifier, isEmailValid, isPhoneValid, showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import ImportTable from "./components/importTable";

export interface IVerifiedData {
    sameUsername: boolean;
    sameEmail: boolean;
    user: NetworkUserImport;
}


export const AvailableImportCols: ImportCol[] = [
    { value: 'code', label: <FormattedMessage defaultMessage={'Code'} />, type: 'text', width: 250, placeholder: '0001' },
    { value: 'lastname', label: <FormattedMessage defaultMessage={'Last name'} />, type: 'text', width: 250, placeholder: 'Dupont' },
    { value: 'firstname', label: <FormattedMessage defaultMessage={'First name'} />, type: 'text', width: 250, placeholder: 'Élise' },
    { value: 'username', label: <FormattedMessage defaultMessage={'Username'} />, type: 'text', width: 250, placeholder: 'EliseDupont92' },
    { value: 'email', label: <FormattedMessage defaultMessage={'Email'} />, type: 'text', width: 400, placeholder: 'elise.dupont@sunkhro.ch' },
    { value: 'phone', label: <FormattedMessage defaultMessage={'Phone'} />, type: 'phone', width: 250, placeholder: '+41791234567' },
    { value: 'dob', label: <FormattedMessage defaultMessage={'Date of birth'} />, type: 'date', placeholder: '1992-08-05' },
    { value: 'contractName', label: <FormattedMessage defaultMessage={'Contract name'} />, type: 'text', width: 300, placeholder: 'CDI' },
    { value: 'contractRate', label: <FormattedMessage defaultMessage={'Contract rate'} />, type: 'percent', placeholder: '100' },
    { value: 'contractStart', label: <FormattedMessage defaultMessage={'Contract start'} />, type: 'date', placeholder: '2023-03-01' },
    { value: 'contractEnd', label: <FormattedMessage defaultMessage={'Contract end'} />, type: 'date', placeholder: '2023-09-30' },
    { value: 'contractWeeklyHours', label: <FormattedMessage defaultMessage={'Contract Weekly Hours'} />, type: 'number', placeholder: '41' },
    { value: 'contractInit', label: <FormattedMessage defaultMessage={'Contract initial hours'} />, type: 'number', placeholder: '3' },
];

interface Props {
    displayDrawer: boolean;
    closeDrawer: (refresh?: boolean) => void;
}

const ImportUsers = (props: Props) => {
    const intl = useIntl();
    const defaultSize = 20;

    const company = useAppSelector(state => state.user.company)

    const availableImportColsWithRights = useMemo(() => {
        if(company.userCodeDisplayAuthorization !== 'read-write'){
            return AvailableImportCols.filter(col => col.value !== 'code')
        }
        return AvailableImportCols
    }, [company])

    const [rawData, setRawData] = useState<ImportRows>(Array.from({ length: defaultSize }, () => ({ uuid: generateUniqueIdentifier(), cells: availableImportColsWithRights.map(col => ({ value: '', type: col.type, placeholder: col.placeholder, width: col.width, error: { error: false } })) })));
    const [dynamicCols, setDynamicCols] = useState<ImportCol[]>(availableImportColsWithRights);
    const [loading, setLoading] = useState<boolean>(false);

    const onErrors = useCallback((data: ImportRows, cols: ImportCol[]) => {
        const codeCol = cols.findIndex(c => c.value === 'code');
        const usernameCol = cols.findIndex(c => c.value === 'username');
        const emailCol = cols.findIndex(c => c.value === 'email');
        const lastnameCol = cols.findIndex(c => c.value === 'lastname');
        const firstnameCol = cols.findIndex(c => c.value === 'firstname');
        const dobCol = cols.findIndex(c => c.value === 'dob');
        const phoneCol = cols.findIndex(c => c.value === 'phone');
        const contractNameCol = cols.findIndex(c => c.value === 'contractName');
        const contractRateCol = cols.findIndex(c => c.value === 'contractRate');
        const contractWeeklyHoursCol = cols.findIndex(c => c.value === 'contractWeeklyHours');
        const contractStartCol = cols.findIndex(c => c.value === 'contractStart');
        const contractEndCol = cols.findIndex(c => c.value === 'contractEnd');
        const contractInitCol = cols.findIndex(c => c.value === 'contractInit');

        const contractCols = [
            { index: contractNameCol, message: defineMessage({ defaultMessage: 'The contract name must not be empty' }) },
            { index: contractRateCol, message: defineMessage({ defaultMessage: 'The contract rate must not be empty' }) },
            { index: contractWeeklyHoursCol, message: defineMessage({ defaultMessage: 'The contract weekly hours must not be empty' }) },
            { index: contractStartCol, message: defineMessage({ defaultMessage: 'The contract start must not be empty' }) },
            { index: contractEndCol, message: defineMessage({ defaultMessage: 'Contract without an end date, setting default end date 01.01.2200' }) },
        ];

        const errors: ImportTableErrors = [];

        data.forEach((row) => {
            const rowsWithoutCurrent = data.filter(r => r.uuid !== row.uuid);
            //* Code Error
            if (row.cells[codeCol].value !== '' && row.cells[codeCol].value.length > 10) {
                errors.push({ uuid: row.uuid, col: codeCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The code must not exceed 10 characters (current length: {length})' }), values: { length: row.cells[codeCol].value.length } }, icon: { prefix: 'fas', name: 'link-slash' } } });
            }

            //* Username Error
            if (row.cells[usernameCol].value === '') {
                errors.push({ uuid: row.uuid, col: usernameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Username must not be empty' }) }, icon: { prefix: 'fas', name: 'empty-set' } } });
            }
            else if (row.cells[usernameCol].value.length < 4) {
                errors.push({ uuid: row.uuid, col: usernameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Username must be at least 8 characters (current length: {length})' }), values: { length: row.cells[usernameCol].value.length } }, icon: { prefix: 'fas', name: 'shield' } } });
            }
            else if (rowsWithoutCurrent.some(r => r.cells[usernameCol].value === row.cells[usernameCol].value)) {
                errors.push({ uuid: row.uuid, col: usernameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Username must be unique' }) }, icon: { prefix: 'fas', name: 'clone' } } });
            }

            //* Email Error
            if (row.cells[emailCol].value === '') {
                errors.push({ uuid: row.uuid, col: emailCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Email must not be empty' }) }, icon: { prefix: 'fas', name: 'empty-set' } } });
            }
            else if (rowsWithoutCurrent.some(r => r.cells[emailCol].value === row.cells[emailCol].value)) {
                errors.push({ uuid: row.uuid, col: emailCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Email must be unique' }) }, icon: { prefix: 'fas', name: 'clone' } } });
            }
            else if (!isEmailValid(row.cells[emailCol].value)) {
                errors.push({ uuid: row.uuid, col: emailCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Email must be valid' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //* Firstname Error
            if (row.cells[firstnameCol].value === '') {
                errors.push({ uuid: row.uuid, col: firstnameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'First name must not be empty' }) }, icon: { prefix: 'fas', name: 'empty-set' } } });
            }

            //* Lastname Error
            if (row.cells[lastnameCol].value === '') {
                errors.push({ uuid: row.uuid, col: lastnameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Last name must not be empty' }) }, icon: { prefix: 'fas', name: 'empty-set' } } });
            }

            //* Dob Error
            if (row.cells[dobCol].value !== '' && !moment(row.cells[dobCol].value).isValid()) {
                errors.push({ uuid: row.uuid, col: dobCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Date of birth must be a valid date' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //* Phone Error
            if (row.cells[phoneCol].value !== '' && !isPhoneValid(row.cells[phoneCol].value)) {
                errors.push({ uuid: row.uuid, col: phoneCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Phone must be a valid phone number (ex: +41XXXXXXXXX ou +33XXXXXXXXX)' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }



            //* Code Error
            if (row.cells[codeCol].value !== '' && rowsWithoutCurrent.some(r => r.cells[codeCol].value === row.cells[codeCol].value)) {
                errors.push({ uuid: row.uuid, col: codeCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Code must be unique' }) }, icon: { prefix: 'fas', name: 'clone' } } });
            }
            else if (row.cells[codeCol].value !== '' && row.cells[codeCol].value.length > 10) {
                errors.push({ uuid: row.uuid, col: codeCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Code must be less than 10 characters' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //* Contract Error
            //? Contract Rate
            if (row.cells[contractRateCol].value !== '' && /^[.*[a-zA-Z].*/.test(row.cells[contractRateCol].value)) {
                errors.push({ uuid: row.uuid, col: contractRateCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract rate must be a valid number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }
            else if (row.cells[contractRateCol].value !== '' && !/^\d+(\.\d{1,2})?$/.test(row.cells[contractRateCol].value)) {
                errors.push({ uuid: row.uuid, col: contractRateCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract rate must be a valid number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }
            else if (row.cells[contractRateCol].value !== '' && (Number(row.cells[contractRateCol].value) < 0 || Number(row.cells[contractRateCol].value) > 100)) {
                errors.push({ uuid: row.uuid, col: contractRateCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract rate must be between 0 and 100' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //? Contract Start
            if (row.cells[contractStartCol].value !== '' && !moment(row.cells[contractStartCol].value).isValid()) {
                errors.push({ uuid: row.uuid, col: contractStartCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Contract start date must be a valid date' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //? Contract End
            if (row.cells[contractEndCol].value !== '' && !moment(row.cells[contractEndCol].value).isValid()) {
                errors.push({ uuid: row.uuid, col: contractEndCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Contract end date must be a valid date' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //? Contract end before start
            if (row.cells[contractEndCol].value !== '' && row.cells[contractStartCol].value !== '' && moment(row.cells[contractStartCol].value).isValid() && moment(row.cells[contractEndCol].value).isValid() && moment(row.cells[contractStartCol].value).diff(row.cells[contractEndCol].value) > 0) {
                errors.push({ uuid: row.uuid, col: contractStartCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Contract start date must be before the contract end date' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
                errors.push({ uuid: row.uuid, col: contractEndCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Contract end date must be after the contract start date' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //? Contract Init
            if (row.cells[contractInitCol].value !== '' && !/^\d+(\.\d{1,2})?$/.test(row.cells[contractInitCol].value)) {
                errors.push({ uuid: row.uuid, col: contractInitCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract initial hours must be a valid number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }
            else if (row.cells[contractInitCol].value !== '' && Number(row.cells[contractRateCol].value) < 0) {
                errors.push({ uuid: row.uuid, col: contractInitCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract initial hours must be a positive number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }
            else if (row.cells[contractInitCol].value !== '' && Number(row.cells[contractRateCol].value) > 100) {
                errors.push({ uuid: row.uuid, col: contractInitCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract initial hours must be less than 100' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }

            //? Contract Weekly Hours
            if (row.cells[contractWeeklyHoursCol].value !== '' && !/^\d+(\.\d{1,2})?$/.test(row.cells[contractWeeklyHoursCol].value)) {
                errors.push({ uuid: row.uuid, col: contractWeeklyHoursCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract weekly hours must be a valid number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }
            else if (row.cells[contractWeeklyHoursCol].value !== '' && Number(row.cells[contractWeeklyHoursCol].value) < 0) {
                errors.push({ uuid: row.uuid, col: contractWeeklyHoursCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'The contract weekly hours must be a positive number' }) }, icon: { prefix: 'fas', name: 'xmark' } } });
            }


            const isAnyContractColFilled = contractCols.some(col => row.cells[col.index].value !== '');
            if (isAnyContractColFilled) {
                contractCols.forEach(col => {
                    if (row.cells[col.index].value === '') {
                        const colIsContractEnd = col.index === contractEndCol;
                        errors.push({ uuid: row.uuid, col: col.index, error: colIsContractEnd ? { error: false } : { error: true, message: { text: col.message }, icon: { prefix: 'fas', name: 'empty-set' } }, warn: colIsContractEnd ? { message: { text: col.message }, icon: { prefix: 'fas', name: 'empty-set' } } : undefined });
                    }
                });
            }
        });

        return errors;
    }, []);

    const onResetData = useCallback(() => {
        setRawData(Array.from({ length: defaultSize }, () => ({ uuid: generateUniqueIdentifier(), cells: availableImportColsWithRights.map(col => ({ value: '', type: col.type, placeholder: col.placeholder, width: col.width, error: { error: false } })) })));
    }, [availableImportColsWithRights]);

    const dataWithNetworkErrors = useMemo(() => {
        return rawData.filter(data => data.cells.some(cell => cell.error.fromNetwork || cell.error.error) && data.cells.some(d => d.value !== ''));
    }, [rawData]);

    const onImportData = useCallback(() => {
        const codeCol = dynamicCols.findIndex(c => c.value === 'code');
        const usernameCol = dynamicCols.findIndex(c => c.value === 'username');
        const emailCol = dynamicCols.findIndex(c => c.value === 'email');
        const lastnameCol = dynamicCols.findIndex(c => c.value === 'firstname');
        const firstnameCol = dynamicCols.findIndex(c => c.value === 'lastname');
        const phoneCol = dynamicCols.findIndex(c => c.value === 'phone');
        const dobCol = dynamicCols.findIndex(c => c.value === 'dob');
        const contractNameCol = dynamicCols.findIndex(c => c.value === 'contractName');
        const contractWeeklyHoursCol = dynamicCols.findIndex(c => c.value === 'contractWeeklyHours');
        const contractRateCol = dynamicCols.findIndex(c => c.value === 'contractRate');
        const contractStartCol = dynamicCols.findIndex(c => c.value === 'contractStart');
        const contractEndCol = dynamicCols.findIndex(c => c.value === 'contractEnd');
        const contractInitCol = dynamicCols.findIndex(c => c.value === 'contractInit');
        setLoading(true);

        const usersRequest: NetworkUserImport[] = rawData.filter(r => r.cells.filter(c => c.value === '').length !== dynamicCols.length).map(data => {
            return ({
                uuid: data.uuid,
                code: data.cells[codeCol].value !== '' ? data.cells[codeCol].value : undefined,
                username: data.cells[usernameCol].value,
                email: data.cells[emailCol].value,
                firstname: data.cells[lastnameCol].value,
                lastname: data.cells[firstnameCol].value,
                phone: data.cells[phoneCol].value !== '' ? data.cells[phoneCol].value : undefined,
                dob: data.cells[dobCol].value !== '' ? moment(data.cells[dobCol].value).format('YYYY-MM-DD') : undefined,
                contractName: data.cells[contractNameCol].value !== '' ? data.cells[contractNameCol].value : undefined,
                contractWeeklyHours: data.cells[contractWeeklyHoursCol].value !== '' ? Number(data.cells[contractWeeklyHoursCol].value) : undefined,
                contractRate: data.cells[contractRateCol].value !== '' ? Number(data.cells[contractRateCol].value) : undefined,
                contractStart: data.cells[contractStartCol].value !== '' ? moment(data.cells[contractStartCol].value).format('YYYY-MM-DD') : undefined,
                contractEnd: data.cells[contractEndCol].value !== '' ? moment(data.cells[contractEndCol].value).format('YYYY-MM-DD') : undefined,
                contractInit: data.cells[contractInitCol].value !== '' ? Number(data.cells[contractInitCol].value) : undefined,
            });
        });

        Network.importUsers(usersRequest).then(
            (response) => {
                const code = response.code;

                if (code === 'ImportUsers-VerifyErrors') {
                    const codeCol = dynamicCols.findIndex(c => c.value === 'code');

                    const usernameCol = dynamicCols.findIndex(c => c.value === 'username');
                    const emailCol = dynamicCols.findIndex(c => c.value === 'email');

                    const rows = cloneDeep(rawData).map(row => ({ ...row, cells: row.cells.map(cell => ({ ...cell, error: { error: false } })) }));
                    const datas = response.data;
                    const errors: ImportTableErrors = [];

                    datas.forEach((data: any) => {
                        if (data.sameUsername)
                            errors.push({ uuid: data.user.uuid, col: usernameCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Username already used in Sunkhronos' }) }, icon: { prefix: 'fas', name: 'clone' }, fromNetwork: true } });
                        if (data.sameEmail)
                            errors.push({ uuid: data.user.uuid, col: emailCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Email already used in Sunkhronos' }) }, icon: { prefix: 'fas', name: 'clone' }, fromNetwork: true } });
                        if (data.sameCode)
                            errors.push({ uuid: data.user.uuid, col: codeCol, error: { error: true, message: { text: defineMessage({ defaultMessage: 'Code already used in this company' }) }, icon: { prefix: 'fas', name: 'clone' }, fromNetwork: true } });
                    });

                    errors.forEach(err => {
                        const rowIndex = rows.findIndex(row => row.uuid === err.uuid);
                        rows[rowIndex].cells[err.col] = { ...rows[rowIndex].cells[err.col], error: err.error ? err.error : { error: false } };
                    });

                    setRawData(rows);
                    showNotification(intl.formatMessage({ defaultMessage: 'Some users have errors' }), 'warning', intl.formatMessage({ defaultMessage: 'Please resolve them before importing' }));
                }
                else {
                    showNotification(intl.formatMessage({ defaultMessage: 'Users created successfully' }), 'success');
                    props.closeDrawer(true);
                    onResetData();
                }
                setLoading(false);
            },
            (err) => {
                let parsedError: any = undefined;
                try {
                    parsedError = JSON.parse(err.message);
                } catch (e) {
                    Sentry.captureMessage(err);
                    Sentry.captureMessage(err.message);
                    Sentry.captureException(e);
                }
                const code = parsedError.code;
                if (code === 'ImportUsers-CompanyNotFound')
                    showNotification(intl.formatMessage({ defaultMessage: 'Company not found' }), 'error', intl.formatMessage({ defaultMessage: 'Unable to find your company, please reload the page' }));
                else if (code === 'ImportUsers-FailedWhileCreatingUsers') {
                    const errors = parsedError.data.errors;
                    const userCreationErrorMessage = intl.formatMessage({ defaultMessage: 'Unable to create the user' });
                    const assignCompanyErrorMessage = intl.formatMessage({ defaultMessage: 'Unable to assign to a company' });
                    const contractCreationErrorMessage = intl.formatMessage({ defaultMessage: 'Unable to create the contract' });
                    const defaultErrorMessage = intl.formatMessage({ defaultMessage: 'Unknown error' });
                    const parsedErrors = errors.map((e: any) => intl.formatMessage({ defaultMessage: 'Line {line} - {message}' }, { line: rawData.findIndex(r => r.uuid === e.uuid) + 1, message: e.code === 'ImportUsers-UserCreationFailed' ? userCreationErrorMessage : e.code === 'ImportUsers-UserAssignCompanyFailed' ? assignCompanyErrorMessage : e.code === 'ImportUsers-UserContractCreationFailed' ? contractCreationErrorMessage : defaultErrorMessage }));
                    parsedErrors.push(intl.formatMessage({ defaultMessage: 'The creation has been cancelled, no user created' }));

                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the following users' }), 'error', parsedErrors.map((e: any) => (<>{e}<br /></>)));
                }
                else
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the users' }), 'error');
                setLoading(false);
            }
        );
    }, [dynamicCols, intl, onResetData, props, rawData]);

    const isEmpty = useMemo(() => rawData.filter(r => r.cells.filter(c => c.value === '').length === dynamicCols.length).length === rawData.length, [dynamicCols.length, rawData]);
    const hasErrors = useMemo(() => rawData.filter(r => r.cells.filter(c => c.value === '').length !== dynamicCols.length).some(row => row.cells.some(item => item.error.error || item.error.fromNetwork)), [dynamicCols.length, rawData]);

    return (
        <Drawer
            destroyOnClose={true}
            width={'100%'}
            onClose={() => {
                onResetData();
                props.closeDrawer(false);
            }}
            open={props.displayDrawer}
            placement="right"
            className="__drawer"
            title={<FormattedMessage defaultMessage={'Add multiple users'} />}
            extra={[
                <Space key='import-users-drawer'>
                    {
                        dataWithNetworkErrors.length !== 0 &&
                        <>
                            <Popover
                                title={<FormattedMessage defaultMessage={'Lines with error'} />}
                                content={
                                    <div style={{ maxHeight: 300, overflow: 'auto' }}>
                                        <b><FormattedMessage defaultMessage={'The following lines have errors :'} /></b>
                                        <br />
                                        {
                                            dataWithNetworkErrors.map((line) => (
                                                <div key={line.uuid}>
                                                    <FormattedMessage defaultMessage={'Line {line} :'} values={{ line: rawData.findIndex(r => r.uuid === line.uuid) + 1 }} />
                                                    {
                                                        line.cells.filter(cell => cell.error.message).map(cell => {
                                                            return (cell.error.error || cell.error.fromNetwork) && cell.error.message ? <p> - {intl.formatMessage(cell.error.message?.text, cell.error.message?.values)}</p> : '';
                                                        })
                                                    }
                                                    <br />
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            >
                                <Tag color='error' style={{ cursor: 'help' }}>
                                    <p style={{ fontSize: 16 }}>
                                        <FormattedMessage defaultMessage={'{count, plural, one {{count} error remaining} other {{count} errors remaining}}'} values={{ count: dataWithNetworkErrors.length }} />
                                    </p>
                                </Tag>
                            </Popover>
                            <Divider type='vertical' key='team-management-import-divider' />
                        </>

                    }
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='broom-wide' />}
                        disabled={isEmpty}
                        title={intl.formatMessage({ defaultMessage: 'Clear table' })}
                        key='team-management-reset-table'
                        onClick={onResetData}
                    />
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='floppy-disk' />}
                        title={intl.formatMessage({ defaultMessage: 'Create users' })}
                        key='team-management-add-users'
                        disabled={hasErrors || isEmpty}
                        loading={loading}
                        onClick={onImportData}
                    />
                </Space>
            ]}
        >
            <ImportTable
                setLoading={setLoading}
                rawData={rawData}
                dynamicCols={dynamicCols}
                setRawData={setRawData}
                setDynamicCols={setDynamicCols}
                availableCols={availableImportColsWithRights}
                checkForErrors={onErrors}
                loading={loading}
                defaultSize={defaultSize}
            />
        </Drawer>
    );
};

export default ImportUsers;