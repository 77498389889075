import { Select, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cloneDeep, isEqual } from "lodash";
import moment, { Moment } from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { selectActiveReportUsers } from "../../../../store/selectors/usersSelectors";
import { ContractTypeMode, GREEN_COLOR, MOMENT_MONTH_FORMAT, RED_COLOR } from "../../../../utils/constants";
import Network from "../../../../utils/network";
import { DictionaryNumber, IFilterUsers, User, UserJobTMP } from "../../../../utils/types/generalTypes";
import { CcntReport } from "../../../../utils/types/reportTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { filterUsers, showNotification } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";
import FAIcon from "../../../common/FAIcon";
import AmazingDatePicker, { PickerMode, PickerModeType } from "../../../common/fields/AmazingDatePicker/amazingDatePicker";
import CircleButton from "../../../common/fields/circleButton";
import FullUser from "../../../common/general/fullUser";
import VirtualTable from "../../../common/general/virtualTable";
import { FilterSidebar } from "../../../common/navigations/containerTabs";
import { ContainerTabsItem, ContainerTabsItemProps } from "../../../common/navigations/containerTabsItem";
import Filters from "../../../planningPerf/tabs/common/filters";
import DrawerCcntReport from "./drawerCcntReport";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps, ContainerTabsItemProps { }

interface State {
    startDate: Moment;
    endDate: Moment;
    selectedContracts: DictionaryNumber<number | undefined>;
    ccntBalances: DictionaryNumber<CcntBalancesByUserId>;
    loading: boolean;
    loadingCcnt: boolean;
    ccnt: CcntReport | undefined;
    filters: IFilterUsers;
    datePickerType?: PickerModeType;
}

class BalanceHours extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            startDate: moment().startOf('year'),
            endDate: moment().endOf(PickerMode.MONTH),
            selectedContracts: {},
            ccntBalances: {},
            loading: false,
            loadingCcnt: false,
            ccnt: undefined,
            datePickerType: PickerMode.MONTH,
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
        };
    }

    componentDidMount = () => {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.init();
    };

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
        const { endDate, selectedContracts, datePickerType } = this.state;
        const { users } = this.props;
        if (
            prevState.loading !== this.state.loading ||
            prevState.loadingCcnt !== this.state.loadingCcnt ||
            !prevState.endDate.isSame(this.state.endDate, datePickerType ?? 'day')
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }

        if (!isEqual(prevState.endDate, endDate) || !isEqual(prevProps.users, users)) {
            this.updateSelectedContracts();
        }

        if (Object.keys(selectedContracts).length > 0 && !isEqual(prevState.endDate, endDate)) {
            this.getAllCcntData();
        }
    };

    getExtra = () => {
        const { intl } = this.props;
        const { loading, loadingCcnt, endDate: selectedMonth } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.MONTH}
                    disabled={loadingCcnt}
                    loadingData={loading}
                    selectByDay
                    selectByMonth
                    controlled={{
                        valueFrom: selectedMonth,
                        onChange: this.onChangeDate,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={this.getAllCcntData}
                    disabled={loadingCcnt}
                    loading={loading} />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    onChangeDate = (from: Moment | null, to: Moment | null, type?: PickerModeType) => {
        if (!from || !to) return;
        const start = from.clone().startOf('year');
        const end = type ? to.clone().endOf(type) : to.clone();

        this.setState({ startDate: start, endDate: end, datePickerType: type });
    };

    init = async () => {
        await this.updateSelectedContracts();
        this.getAllCcntData();
    };

    updateSelectedContracts = async () => {
        const { endDate: selectedMonth } = this.state;
        const { users } = this.props;

        const newSelectedContracts = Object.fromEntries(users.map((u) => [u.id, u.job && this.availableContracts(selectedMonth, u.job).length > 0 ? this.availableContracts(selectedMonth, u.job)[0].id : undefined]));
        this.setState({ selectedContracts: newSelectedContracts });
    };

    getCcntReport = (userIds: number[], jobIds: number[]) => {
        const { startDate, endDate } = this.state;

        this.setState({ loadingCcnt: true });
        Network.generateReportsCcntV2(startDate.format(MOMENT_MONTH_FORMAT), endDate.format(MOMENT_MONTH_FORMAT), userIds, jobIds).then(
            (networkCCnt) => {
                const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                this.setState({ loadingCcnt: false, ccnt });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), 'warning');
                this.setState({ loadingCcnt: false });
            }
        );
    };

    renderTag = (value: number, inverted = false) => {
        const color = value < 0 ? (inverted ? GREEN_COLOR : RED_COLOR) : (inverted ? RED_COLOR : GREEN_COLOR);

        // By @Axel
        return <span style={{ background: color + "33", padding: '5px 10px', color: color, borderRadius: 999 }}>{value}</span>;
    };

    availableContracts = (month: Moment, contracts?: UserJobTMP[]) => {
        return contracts ? contracts.filter(c => c.id !== undefined && moment(c.contract_expiry_date, 'YYYY-MM-DD').isSameOrAfter(month, 'dates') && moment(c.date_in_report, 'YYYY-MM-DD').isSameOrBefore(month, 'dates')) : [];
    };

    getAllCcntData = () => {
        const { selectedContracts, startDate, endDate } = this.state;
        const userIds = Object.keys(selectedContracts).map(idx => parseInt(idx));
        const jobIds = Object.keys(selectedContracts).map(idx => selectedContracts[parseInt(idx)]).filter(i => i !== undefined) as number[];

        this.setState({ loading: true });
        Network.generateReportsCcntV2(startDate.format(MOMENT_MONTH_FORMAT), endDate.format(MOMENT_MONTH_FORMAT), userIds, jobIds).then(
            (response) => {

                const ccntBalances = Object.fromEntries(
                    this.props.users.filter(u => this.availableContracts(endDate, u.job).length > 0).map((u) => {
                        const userData = response[u.id] ? response[u.id] : undefined;
                        const monthValues = userData && userData.months[endDate.month()] ? userData.months[endDate.month()] : undefined;

                        const balances = userData ? userData.tally : {
                            finalWorkTime: 0,
                            finalRestDelta: 0,
                            finalVacationDelta: 0,
                            finalHolidayDelta: 0
                        };

                        const effectives = {
                            hours: monthValues ? monthValues.tally.T : 0,
                            rests: monthValues ? monthValues.tally.X : 0,
                            vacations: monthValues ? monthValues.tally.V : 0,
                            holidays: monthValues ? monthValues.tally.F : 0,
                        };

                        const daysOff = {
                            sickness: monthValues ? monthValues.tally.M + monthValues.tally.Ma : 0,
                            accident: monthValues ? monthValues.tally.A : 0,
                            military: monthValues ? monthValues.tally.Mi : 0,
                            others: monthValues ? monthValues.tally.D : 0,
                        };

                        return [
                            u.id,
                            {
                                balances: {
                                    hours: balances.finalWorkTimeDelta.toFixed(2),
                                    rests: balances.finalRestDelta.toFixed(2),
                                    vacations: balances.finalVacationDelta.toFixed(2),
                                    holidays: balances.finalHolidayDelta.toFixed(2)
                                },
                                effectives: {
                                    hours: effectives.hours.toFixed(2),
                                    rests: effectives.rests.toFixed(2),
                                    vacations: effectives.vacations.toFixed(2),
                                    holidays: effectives.holidays.toFixed(2)
                                },
                                daysOff: {
                                    sickness: daysOff.sickness.toFixed(2),
                                    accident: daysOff.accident.toFixed(2),
                                    military: daysOff.military.toFixed(2),
                                    others: daysOff.others.toFixed(2),
                                },
                                contractTypeMode: userData.data.contractTypeMode,
                            }
                        ];
                    })
                );
                this.setState({ loading: false, ccntBalances });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ loading: false });
            }
        );
    };

    isFixed = (contractTypeMode: number) => [ContractTypeMode.NORMAL].includes(contractTypeMode);

    handleOnRow = (record: User) => {
        const { selectedContracts } = this.state;
        const contractId = selectedContracts[record.id];
        if (contractId !== undefined)
            this.getCcntReport([record.id], [contractId]);
    };

    resetFilters = () => {
        this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });
    };

    render = () => {
        const { groups, users, height } = this.props;
        const { endDate: selectedMonth, selectedContracts, loading, loadingCcnt, ccnt, ccntBalances, filters } = this.state;
        let tableHeight = height - 170;
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);

        const columns: ColumnsType<User> = [
            {
                title: <FormattedMessage defaultMessage={'User'} />,
                key: 'user',
                className: "__width_300",
                fixed: this.props.isSmartphone ? undefined : "left",
                sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
                render: (user) => <FullUser user={user} withAvatar />
            },
            {
                title: <FormattedMessage defaultMessage={'Contract'} />,
                key: 'contract',
                className: '__width_200',
                width: '200px',
                render: (_, record) => {
                    const contracts = this.availableContracts(selectedMonth, record.job);
                    const selectedContract = contracts.find(c => selectedContracts[record.id] === c.id);
                    return (
                        <>
                            {
                                contracts.length === 1 ?
                                    selectedContract ?
                                        selectedContract.name
                                        :
                                        <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'Contract not found'} /></span>
                                    :
                                    contracts.length > 0 ?
                                        <Select
                                            onClick={(e) => e.stopPropagation()}
                                            value={selectedContracts[record.id]}
                                            options={contracts.map(j => ({ value: j.id, label: j.name }))}
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                const clonedSelectedContracts = cloneDeep(this.state.selectedContracts);
                                                clonedSelectedContracts[record.id] = e;
                                                this.setState({ selectedContracts: clonedSelectedContracts });
                                            }}
                                        />
                                        :
                                        <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'No contract available'} /></span>
                            }
                        </>
                    );
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Effective time'} />,
                key: 'effective',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'effective-hours',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].effectives.hours - ccntBalances[a.id].effectives.hours),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].effectives.hours ?
                                    ccntBalances[record.id].effectives.hours
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'effective-rests',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.rests - ccntBalances[b.id].effectives.rests),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.rests ?
                                    ccntBalances[record.id].effectives.rests
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'effective-vacations',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.vacations - ccntBalances[b.id].effectives.vacations),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.vacations ?
                                    ccntBalances[record.id].effectives.vacations
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'effective-holidays',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.holidays - ccntBalances[b.id].effectives.holidays),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.holidays ?
                                    ccntBalances[record.id].effectives.holidays
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Days off'} />,
                key: 'daysoff',
                className: '__width_480',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Sickness'} />,
                        key: 'daysoff-sickness-maternity',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].daysOff.sickness - ccntBalances[a.id].daysOff.sickness),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].daysOff.sickness ?
                                    ccntBalances[record.id].daysOff.sickness
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Accident'} />,
                        key: 'daysoff-accident',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.accident - ccntBalances[b.id].daysOff.accident),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.accident ?
                                    ccntBalances[record.id].daysOff.accident
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Military'} />,
                        key: 'daysoff-military',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.military - ccntBalances[b.id].daysOff.military),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.military ?
                                    ccntBalances[record.id].daysOff.military
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Others'} />,
                        key: 'daysoff-others',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.others - ccntBalances[b.id].daysOff.others),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.others ?
                                    ccntBalances[record.id].daysOff.others
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Balances'} />,
                key: 'balance',
                fixed: 'right',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'balance-hours',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].balances.hours - ccntBalances[a.id].balances.hours),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].balances.hours ?
                                    this.renderTag(ccntBalances[record.id].balances.hours, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'balance-rests',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.rests - ccntBalances[b.id].balances.rests),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.rests ?
                                    this.renderTag(ccntBalances[record.id].balances.rests, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'balance-vacations',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.vacations - ccntBalances[b.id].balances.vacations),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.vacations ?
                                    this.renderTag(ccntBalances[record.id].balances.vacations, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'balance-holidays',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.holidays - ccntBalances[b.id].balances.holidays),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.holidays ?
                                    this.renderTag(ccntBalances[record.id].balances.holidays, true)
                                    : 0
                                : '-'
                        )
                    }
                ]
            }
        ];

        return (
            <>
                <Spin spinning={loading} indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <VirtualTable
                            className='__basic-table'
                            onRow={(e: User) => ({
                                onClick: () => this.handleOnRow(e)
                            })}
                            rowClassName={(record: User) => selectedContracts[record.id] === undefined ? 'disabled-row' : ''}
                            dataSource={filteredUsers}
                            columns={columns}
                            // style={{ flex: 1, overflow: 'auto' }}
                            pagination={false}
                            scroll={{ x: true, y: tableHeight }}
                        />
                    </div>
                </Spin>
                <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={ccnt !== undefined} close={() => this.setState({ ccnt: undefined })} year={selectedMonth} />
            </>
        );
    };
}

const mapStateToProps = (state: ApplicationState) => ({
    users: selectActiveReportUsers(state),
    groups: state.teamManagement.groups,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(BalanceHours));


interface CcntBalancesByUserId {
    effectives: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    balances: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    daysOff: {
        sickness: number;
        accident: number;
        military: number;
        others: number;
    };
    contractTypeMode: number;
}