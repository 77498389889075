import { Space, Tabs } from "antd";
import { useIntl } from "react-intl";
import FAIcon from "../../../../../common/FAIcon";
import TimeclockDevices from "./timeclockDevices";
import TimeclockTemplates from "./timeclockTemplates";

export const Settings = () => {
    const intl = useIntl();
    return (
        <Tabs
            centered
            items={[
                {
                    key: SettingsTabLink.DEVICES,
                    label: <Space><FAIcon prefix='fad' name='tablet-screen' /> {intl.formatMessage({ defaultMessage: 'Timeclocks' })}</Space>,
                    children: <TimeclockDevices />
                },
                {
                    key: SettingsTabLink.TEMPLATES,
                    label: <Space><FAIcon prefix='fad' name='layer-group' /> {intl.formatMessage({ defaultMessage: 'Templates' })}</Space>,
                    children: <TimeclockTemplates />
                },
            ]}
        />
    );
};

export default Settings;

export const enum SettingsTabLink {
    TEMPLATES = "templates",
    DEVICES = "devices",
}