import { Col, Row, Spin } from "antd";
import FAIcon from "../FAIcon";


export const PageSpinner = () => {
    return (
        <Row>
            <Col span={24}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', flexDirection: 'column' }}>
                    <Spin size='large' style={{ marginBottom: '20px' }} indicator={<FAIcon prefix='fas' name='spinner-third' style={{ fontSize: 100 }} spin />} />
                </div>
            </Col>
        </Row>
    );
};
export default PageSpinner;