import { IconName, IconPrefix } from "@fortawesome/pro-regular-svg-icons";
import { Moment } from 'moment';
import { ReactNode } from "react";
import { defineMessages, MessageDescriptor, PrimitiveType } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Sidebar } from "../../components/common/navigations/containerTabs";
import ContainerTabsItem from '../../components/common/navigations/containerTabsItem';
import { IUserExportHoursGlobalOfficeUserData, NetworkBreakTime, NetworkDynamicFile, NetworkMonthlyPlanningRowPerfAddons, NetworkOccupancyRate, NetworkUserExtraVacations } from './networkTypes';
import { BreakTime, EventCalculated, ExternalAppOptimizedEvent, PlanningEvent, PlanningExclusion, PlanningPeriod, TypeOfDay, TypeOfDayOff, UserContractSmall } from './planningTypes';

// React router dom

export interface AppVersion {
    name: string;
    version: string;
    created: Moment;
    modified: Moment;
}

/**
 * Router props
 */
export interface RouterMatchParams {
    lang: string;
    id?: string;
    tab?: string;
    subtab?: string,
    isTouch?: string;
    dayView?: string;

    customerId?: string;
    mandateId?: string;
    siteId?: string;
    contactId?: string;
}

export interface RouterLocationState {
    successMessage?: string;
    fromLogin?: boolean;
    rememberMe?: boolean;
    redirectToUrl?: string;
    targetFeed?: 'userfeed' | 'newsfeed' | 'customers';
}


export interface TabItem {
    key: string;
    label: React.ReactNode;
    hideMobile?: boolean;
    children: React.ReactElement<typeof ContainerTabsItem> | null;
}

export type RouterProps = RouteComponentProps<RouterMatchParams, any, RouterLocationState>;

//General object types

//eslint-disable-next-line @typescript-eslint/ban-types
type TypeOrString<T> = T | (string & object);

/**
 * Type of a user's education
 */
export interface UserEducation {
    [key: string]: any;
    school?: string;
    degree?: string;
    start_date?: Moment | string;
    end_date?: Moment | string;
    created?: string;
    modified?: string;
    user?: number;
}

export type UserEducations = Array<UserEducation>;

/**
 * Type of a user's job
 */
export interface UserJobWithUser {
    id: number;
    weekly_working_hours?: number;
    vacationIncreasedPercentByHour?: number;
    department?: Department;
    yearly_vacation_days?: number;
    work_rate?: number;
    overtime_hours?: number;
    contract?: string;
    contract_expiry_date?: string;
    expiration_exclusion?: boolean;
    date_in_report?: string;
    notes?: string;
    user?: User;
}

export interface Contract {
    id?: number;
    name?: string;
    weeklyWorkingHours?: number;
    vacationIncreasedPercentByHour?: number;
    department?: Department;
    typeOfContract?: TypeOfContract;
    yearlyVacationDays?: number;
    workRate?: number;
    overtimeHours?: number;
    contract?: string | File;
    startDate: Moment;
    endDate: Moment;
    notes?: string;
}
export interface PlanningContract {
    id: number;
    name?: string;
    weeklyWorkingHours?: number;
    vacationIncreasedPercentByHour?: number;
    department?: Department;
    workRate?: number;
    startDate: Moment;
    endDate: Moment;
}
export interface FastPlanningContract {
    id: number;
    userId: number;
    name?: string;
    weeklyWorkingHours?: number;
    vacationIncreasedPercentByHour?: number;
    departmentId?: number;
    typeOfContractId?: number;
    workRate?: number;
    startDate: string;
    endDate: string;
    note: string;
}
export interface Period {
    startDate: string;
    endDate: string;
}
export interface UsersAvailability {
    user: UserSummary;
    availabilities: Period[];
    unavailabilities: Period[];
}

export interface Department {
    id?: number;
    name?: string;
}

export interface DepartmentWithUpdate {
    updated?: Moment;
    loading: boolean;
    data: Department[];
}

/**
 * Type of a user's job
 */
export interface UserJobTMP {
    id?: number;
    weekly_working_hours?: number;
    vacationIncreasedPercentByHour?: number;
    department?: Department;
    yearly_vacation_days?: number;
    work_rate?: number;
    overtime_hours?: number;
    contract?: string | File;
    contract_expiry_date?: string;
    typeOfContract?: TypeOfContract;
    date_in_report?: string;
    notes?: string;
    is_current?: boolean;
    expiration_exclusion?: boolean;
    name?: string;
    user_id?: number;
    lastCreatedFrom?: string;
    lastModifiedFrom?: string;

}

/**
 * Type for a type of Contract (job) object
 */
export interface TypeOfContract {
    id?: number;
    name: string;
    hoursMin?: number;
    hoursMax?: number;
    mode: number;
}

export interface TypeOfContractWithUpdate {
    updated?: Moment;
    data: TypeOfContract[];
}

export interface ISOCountry {
    code: string;
    name: string;
    indicativ: string;
    continent: string;
}
export interface CountriesWithUpdate {
    updated?: Moment;
    data: ISOCountry[];
}

export interface TypeOfVehicleWithUpdate {
    updated?: Moment;
    data?: TypeOfVehicle[];
}

export interface UserOnlyId {
    id: number;
}

export interface GroupAdmin {
    id: number;
    active: boolean;
    group_id: number;
    is_admin: boolean;
}

export interface UserInput {
    fieldId: number;
    floatNumberValue: number | null;
    integerNumberValue: number | null;
    textValue: string | null;
    textareaValue: string | null;
    booleanValue: boolean;
    dateValue: string | null;
    datetimeValue: string | null;
    timeValue: string | null;
    created: string | null;
    modified: string | null;
}
/**
 * Type of a user object
 */

export interface User {
    id: number;
    contact?: {
        id: number;
        alt_email: string;
        mobile: string;
        fax: string;
        skype: string;
        linkedin: string;
        phone: string;
        phone_ext: string;
        created: string;
        modified: string;
        user: number;
        location?: string;
        address?: string;
        zip?: string;
        country?: string;
    };
    about?: {
        id: number;
        known_as?: string;
        expertise?: string;
        skills?: string;
        dept?: string;
        bio?: string;
        dob?: string;
        gender: number;
        rel_status: number;
        sign_other: string;
        sign_other_dob?: string;
        address: string;
        languages: string;
        created: string;
        modified: string;
        user: number;
        department?: any;
    };
    educations?: UserEducation;
    company_detail?: {
        id: number;
        plans: boolean;
        admin_form: boolean;
        ext_group: boolean;
        ext: boolean;
        messages: boolean;
        company: number;
    };
    company_theme?: {
        id: number;
        logo: string;
        primary: string;
        secondary: string;
        button: string;
        logo_rectangle: boolean;
        logo_rounded: boolean;
        company: number;
    };
    groupsAdmin?: GroupAdmin[];
    group_users?: GroupUser[];
    job?: Array<UserJobTMP>;
    user_extra?: any;
    visible?: boolean;
    staffType?: StaffType[];
    username: string;
    password?: string;
    email: string;

    image?: string;
    device_id?: string;
    role: number;
    language: string;
    first_name: string;
    last_name: string;
    title?: any;
    function?: any;
    doj?: string;

    sex?: 'M' | 'F' | 'X' | undefined;
    active?: boolean;
    report?: boolean;
    created?: string;
    modified?: string;
    company_id?: number;
    client_id?: string;
    ext_id?: string;
    tnc?: boolean;
    company_client?: boolean;
    team_visibility?: boolean;
    can_edit_planning?: boolean;
    can_read_only_all_planning?: boolean;
    checkEventsOverlap?: boolean;
    availabilityClosedByDefault?: boolean;
    files?: NetworkDynamicFile[];
    hasPin?: boolean;
    inputs?: UserInput[];
    code?: string;
}

/**
 * Type of a user inside another object
 */
export interface UserSummary {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    image?: string;
    email: string;
    role: number;
    canEdit?: boolean;
    is_admin?: boolean;
    hasPin?: boolean;
    language: string;
}

export interface UserSmall {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

/**
 * Type of a group
 */
export interface Group {
    id?: number;
    int_id?: number;
    name?: string;
    active?: boolean;
    created?: string;
    modified?: string;
    company?: number;
    is_team_group?: boolean;
    is_admin?: boolean;
}


export interface GroupLive {
    id?: number;
    groupId?: number;
    groupName?: string;
}

export interface EventLive {
    id?: number;
    title?: string;
    description?: string;
    userId?: number;
    firstName?: string;
    lastName?: string;
    isInPlace?: boolean;
    isInPlaceTime?: string;
    latitude?: number;
    longitude?: number;
    startDate: Moment;
    endDate: Moment;
    mobile?: string;
    groups?: GroupLive[];
}

export interface CourseId {
    id: number;
}

export interface PoiLive {
    id?: number;
    title?: string;
    courseIds?: CourseId[];
    latitude?: number;
    longitude?: number;
    image?: string;
    events?: EventLive[];
}

export interface PoiLiveWithUpdate {
    updated?: Moment;
    data?: PoiLive[];
}

export interface smallEvent {
    id: number;
    title: string;
}

export interface POI {
    id?: number;
    title?: string;
    km?: number;
    address?: string;
    image?: any;
    latitude?: number;
    longitude?: number;
    sectors?: Sector[];
    courses?: Course[];
    groups?: Group[];
    toUpdate?: boolean;
    toDelete?: boolean;
    notToUpdate?: boolean;
    oldData?: POI;
    isBasePoi?: boolean;
    isPrimary?: boolean;
    department?: Department;
    distanceFromPrimary?: number;
    eventsWorkPlace?: smallEvent[];
    eventsDeparture?: smallEvent[];
    customerIds?: number[];
}

export interface PoiWithUpdate {
    updated?: Moment;
    data?: POI[];
}

export interface TypeOfVehicle {
    id?: number;
    name?: string;
    type?: number;
    description?: string;
}

export interface Majoration {
    id?: number;
    title: string;
    active: boolean;
    allDay: boolean;
    day: number;
    date?: Moment;
    percentage: number;
    startTime?: string;
    endTime?: string;
    ruleStartDate: Moment;
    ruleEndDate: Moment;
    created?: Moment;
    edited?: Moment;
}

export interface StaffType {
    id?: number;
    name?: string;
    nbTimes?: number;
    users?: UserSmall[];
}

export interface Sector {
    id?: number;
    title?: string;
    pois?: POI[];
}

export interface Course {
    id?: number;
    title?: string;
    color?: string;
    geoJson?: string;
    pois?: POI[];
}

/**
 * Type of a group's user
 */
export interface GroupUser {
    id?: number;
    active?: boolean;
    created?: string;
    modified?: string;
    group: number;
    group_name?: string;
    is_admin?: boolean;
    user?: UserSummary;
}

export interface UserField {
    id: number;
    name: string;
    type: 'IMG' | 'VDO' | 'FLE' | 'CHAR' | 'INT' | 'FLOAT' | 'TEXT' | 'BOOL' | 'DATE' | 'DATETIME' | 'TIME';
    isRequired: boolean;
    defaultFloatNumberValue: number | null;
    defaultIntegerNumberValue: number | null;
    defaultTextValue: string | null;
    defaultTextareaValue: string | null;
    defaultBooleanValue: boolean;
    defaultDateValue: string | null;
    defaultDatetimeValue: string | null;
    defaultTimeValue: string | null;
    created: string;
    modified: string;
}

export interface AbstractDynamicFile {
    id: number;
    file: string | File | undefined;
    field: UserField;

}

export interface DynamicFile extends AbstractDynamicFile {
    expiryDate?: Moment;
}

/**
 * Type of a company
 */
export interface Company {
    id: number;
    translation: any[];
    name: string;
    logo: string;
    header_url: string;
    int_id: string;
    department_id: string;
    token: string;
    client: boolean;
    desc: string;
    image: string;
    path: string;
    tnc_path: string;
    group: boolean;
    created: string;
    modified: string;
    can_select_instructor: boolean;
    must_select_instructor: boolean;
    version: number;
    type: string;
    enableNormalReport: boolean;
    isTimeClockEnabled: boolean;
    isProductivityModuleEnabled: boolean;
    timeClockConfirmBehaviour: string;
    timeClockConfirmAuthorization: string;
    timeClockReadAuthorization: string;
    timeClockMaxPhysicalDevices: number;
    isGlobalOfficeEnabled?: boolean;
    projectDisplayText: string;
    staffType_display_text: string;
    userFields?: UserField[];
    hasQuadrigisReport: boolean;
    lastNameFirst: boolean;
    language: string;
    userCodeDisplayAuthorization: 'hidden' | 'read-only' | 'read-write'
}
export type IIntegrationsType = 'officemaker' | 'globaloffice' | 'hotela' | 'unknown';
export interface IIntegrationDetails {
    activeIntegrationUsers: number;
    unactiveIntegrationUsers: number;
    usersSynced: number;
    usersNotSynced: number;
    lastUsersSync?: string;
    lastCustomersSync?: string;
    lastDataSync?: string;
    lastMandatesSync?: string;
}
export interface IIntegrations {
    globalOfficeEnabled: boolean;
    officeMakerEnabled: boolean;
    hotelaEnabled: boolean;
    hotelaUsersToLink: number;
    goUsersToLink: number;
}
export interface IIntegrationUsers {
    id: number;
    firstName: string;
    lastName: string;
    userId: number | undefined;
    code: string | undefined;
    email?: string;
    username?: string;
}

export interface IHotelaUsers {
    id: number;
    firstName: string;
    lastName: string;
    userId: number | undefined;
    insuredNumber: string;
    clientNumber?: string;
    dateOfBirth?: string;
    created: string;
    modified?: string;
}
export interface IGlobalOfficeUsers {
    id: number;
    firstName: string;
    email: string;
    lastName: string;
    userId: number | undefined;
    insuredNumber: string;
    clientNumber?: string;
    dateOfBirth?: string;
    created: string;
    modified?: string;
}
export interface IUserAvailable {
    id: number;
    email: string;
    role?: number;
    username: string;
    firstName: string;
    lastName: string;
    image: string;
    code?: string;
}
export interface IIntegrationsUsersBase<T> {
    integrationUsers: T[];
    usersAvailable: IUserAvailable[];
}

export interface SchoolClient {
    Id: number,
    Email: string,
    Firstname: string,
    Name: string;
}

export interface BCSchoolClient {
    Id?: number,
    Email: string,
    Firstname: string,
    Name: string,
    LanguageId: string;
}

export interface ProductAndActivity {
    ProductIds: string,
    ActivityIds: string,
}

export interface CompanyProduct {
    id: number;
    active: boolean;
    department_id: string;
    description: string;
    externalActivityId: string;
    externalId: string;
    is_visible: boolean;
    lesson_type: string;
    title: string;
    school: { name: string; };
}

export interface CompanyLevel {
    id: number;
    name: string;
    externalId: string;
}

export interface CodeDiscount {
    id: number;
    code: string;
    levels?: Levels[];
    lessons?: CompanyProduct[];
    expiryAt?: string;
    lessonFrom?: string;
    lessonTo?: string;
    nbOfTimes?: number;
    nbOfTimesUsed?: number;
    amount?: number;
    percent?: number;
    lessonDurationHours?: number;
    lessonDurationDays?: number;
}

export interface BCActiveLessonsClient {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    id: number;
    language: string;
    count?: number;
}
export interface BCActiveLessons extends BCActiveLessonsClient {
    lessonFrom: string;
    lessonTo: string;
    description: string;
    prgId: string;
    activityId: string;
    levelId: string;
    prgText: string;
    activityText: string;
    levelText: string;
    meetingId: string;
    meetingText: string;
}


export interface Levels {
    id: number;
    name?: string;
}

export interface Lessons {
    id: number;
    title?: string;
}

/**
 * Type for a school client (UserCompany)
 */
export interface SchoolClient {
    come_from_ski_resort_counter: boolean;
    never_logged_in: boolean;
    country?: any;
    language?: any;
    mobile?: any;
    phone?: any;
    town?: any;
    zip?: any;
    address?: any;
    company: Company;
    user: User;
    customerLoyalty?: CustomerLoyalty[];
}

/**
 * Type for a client's customer loyalty
 */
export interface CustomerLoyalty {
    id: number;
    companyId: number;
    points: number;
    totalSpending: number;
    userId?: number;
    modified?: string;
    modifiedUser?: string;
    beforeModifiedPoints?: number;
    beforeModifiedTotalSpending?: number;
    afterModifiedPoints?: number;
    afterModifiedTotalSpending?: number;
}

/**
 * Type of a news (a post)
 */
export interface Like {
    id: number;
    active: boolean;
    created: string;
    modified: string;
    news: number;
    user: number;
}
export interface News {
    id: number;
    translations?: any[];
    has_liked?: boolean;
    user_image?: string;
    can_delete?: boolean;
    company_name: string;
    title: string;
    text: string;
    res_url?: string;
    res_type?: number;
    push?: boolean;
    email?: boolean;
    start_time: string;
    end_time?: string;
    comment?: boolean;
    like?: boolean;
    type?: number;
    like_name?: string;
    ack?: boolean;
    user_name: string;
    user_title?: string;
    creater_role?: number;
    company_id: number;
    reported?: boolean;
    priority?: boolean;
    active?: boolean;
    is_instructor?: boolean;
    is_client?: boolean;
    created: string;
    likes?: Like[];
    modified?: string;
    creator?: number;
    aggregate?: {
        id?: number;
        like?: number;
        comment?: number;
        read?: number;
        notify?: number;
        created?: string;
        modified?: string;
        news: number;
    };
    files: {
        id: number;
        file_name: string;
        res_url: string;
        res_type: 'IMG' | 'VDO' | 'FLE';
    }[];
    groups?: {
        id: number;
        name: string;
    }[];
}

/**
 * Type for a Message
 */
export interface Message {
    id: number;
    user: UserSummary;
    group?: any;
    creator: UserSummary;
    user_task?: any[];
    title: string;
    text: string;
    type?: string;
    time?: string;
    optional?: boolean;
    state?: string;
    ext_id?: string;
    created: string;
    modified?: string;
    company?: number;
}
export interface NewsComment {
    id: number;
    news: number;
    user?: UserSummary;
    comment: string;
    created: string;
    modified: string;
}

/**
 * Type for the content of the NewsCard component
 */
export interface NewsCardContent {
    id: number;
    title: string;
    titleFr?: string;
    titleDe?: string;
    titleIt?: string;
    text: string;
    textFr?: string;
    textDe?: string;
    textIt?: string;
    avatar?: string;
    creator: string;
    created: string;
    destinator: string;
    can_delete?: boolean;
    image?: string;
    files: {
        id: number;
        file_name: string;
        res_url: string;
        res_type: 'IMG' | 'VDO' | 'FLE';
    }[];
    translations?: any[];
    has_liked?: boolean;
    user_image?: string;
    company_name: string;
    res_url?: string;
    res_type?: number;
    push?: boolean;
    email?: boolean;
    start_time: string;
    end_time?: string;
    comment?: boolean;
    like?: boolean;
    type?: number;
    like_name?: string;
    ack?: boolean;
    user_name: string;
    user_title?: string;
    creater_role?: number;
    company_id: number;
    reported?: boolean;
    priority?: boolean;
    active?: boolean;
    is_instructor?: boolean;
    is_client?: boolean;
    modified?: string;
    aggregate?: {
        id?: number;
        like?: number;
        comment?: number;
        read?: number;
        notify?: number;
        created?: string;
        modified?: string;
        news: number;
    };
}

export interface GenericFile {
    id: number,
    file_name: string;
    res_url: string;
    auth_url: string;
    res_type: 'IMG' | 'VDO' | 'FLE';
    bucket_name: string;
    temp: boolean;
    todelete: boolean;
    active: boolean;
    expiry_date: string;
    created: string;
    modified: string;
    type: 'GenericFile';

    // Params for specific links
    fieldId?: number;
    fieldExpiryDate?: string;
}
export interface PreValidateFile {
    index: number;
    file: File;
    file_name: string;
    res_type: 'IMG' | 'VDO' | 'FLE';
    expiryDate?: string;
    type: 'PreValidateFile',

    // Params for specific links
    fieldId?: number;
    fieldExpiryDate?: string;
}
export interface ValidateFile {
    index: number;
    fileId: number;
    file_name: string;
    orgiginalFile?: File;
    res_type?: 'IMG' | 'VDO' | 'FLE';
    expiryDate?: string;
    type: 'ValidateFile';

    // Params for specific links
    fieldId?: number;
    fieldExpiryDate?: string;
}

/**
 * Type for the content of the NewsCard component
 */
export interface MessageCardContent {
    id: number;
    title: string;
    text: string;
    avatar?: string;
    creator: string;
    created: string;
    destinator: string;
    image?: string;
    with_confirmation: boolean;
    confirmed: boolean;
    confirmationDate: string;
    can_delete: boolean;
}

/**
 * Type containing an attribute for each group, containing the corresponding periods
 */
export interface GroupPeriods {
    [key: string]: string | PlanningPeriod[];
}

/**
 * Type containing an attribute for each group, containing the corresponding exclusions
 */
export interface GroupExclusions {
    [key: string]: string | PlanningExclusion[];
}

/**
 * Type for rbg color
 */
export interface RGB {
    red: number;
    green: number;
    blue: number;
}

export interface PlanningStats {
    nbEvents: number;
    todayEvents: PlanningEvent[];
    startingTodayEvents: PlanningEvent[];
    endingTodayEvents: PlanningEvent[];
}

export interface StatsOnlyPlanning {
    nbEvents: number;
    todayEvents: ExternalAppOptimizedEvent[];
    groups: Group[];
}

/**
 * Type of statistics
 */
export interface Statistics {
    // general: {
    //     nbUsers: number;
    //     nbGroups: number;
    //     nbNews: number;
    //     nbMessages: number;
    // };
    planning: PlanningStats;
    availability: {
        users: User[];
        groups: Group[];
    };
}

export interface DashboardStats {
    nbUsers: number;
    nbGroups: number;
    nbNews: number;
    nbMessages: number;
    nbEvents: number;
    nbOccupancyRates: number;
}

/**
 * Type of an emergency object
 */
export interface Emergency {
    emergencyContacts: EmergencyContact[];
    emergencyNumbers: EmergencyNumber;
    emergencyHeaders: EmergencyHeader;
}

/**
 * Type of an emergency contact
 */
export interface EmergencyContact {
    id?: number;
    name: string;
    number: string;
    location: string;
    active?: boolean;
    created?: string;
    modified?: string;
}

/**
 * Type of an emergency number
 */
export interface EmergencyNumber {
    id: number;
    name: string;
    number: string;
    type: string;
    created?: string;
    modified?: string;
}

/**
 * Type of an emergency header
 */
export interface EmergencyHeader {
    id: number;
    text: string;
    created?: string;
    modified?: string;
}

/**
 * Type of a Listing item
 */
export interface ListingDirectory {
    id: number;
    list_data: ListingData[];
    name: string;
    desc: string;
    subtitle: string;
    start_time: string;
    end_time?: string;
    sort_order: number;
    active: boolean;
    created: string;
    modified: string;
    subcategory: number;
    company: number;
    creator: number;
}

/**
 * Type of a listing item data
 */
export interface ListingData {
    id: number;
    name: string;
    data: string;
    type: string;
    created: string;
    modified: string;
    listing: 1491;
}

/**
 * Type of a listing category
 */
export interface ListingCategory {
    id: number;
    ids: number[];
    name: string;
    subcategory: ListingSubCategory[];
    sort_order: number;
    image: string;
    icon: string;
    link: string;
    type: string;
    external: boolean;
    active: boolean;
    created?: string;
    modified?: string;
    company: number;
    groups?: number[];
}

/**
 * Type of a listing sub category
 */
export interface ListingSubCategory {
    id: number;
    ids: number[];
    name: string;
    sort_order: number;
    image: string;
    link: string;
    type: string;
    external: boolean;
    active: boolean;
    created?: string;
    modified?: string;
    company: number;
}

/**
 * Dashboard occupancy rate
 */
export interface DashboardOccupancyRate {
    occupancyRate: NetworkOccupancyRate;
    count: number;
    numberOfEvents: number;
    numberOfEventsOutOfHours: number;
    events: FastAndFuriousEvent[];
    date: Moment;
}

export interface FastAndFuriousEvent {
    id: number,
    userId: number;
    title: string;
    loadedUser?: User;
}

export interface DashboardExpiryVacations {
    user: UserSummary;
    vacations: NetworkUserExtraVacations[];
}

/**
 * Type for the list of countries in configurations
 */
export interface Countries {
    [key: string]: string;
}

/**
 * Type for the Booking Corner language object
 */
export interface BCLangauge {
    Id: string;
    Text: MessageDescriptor;
    Code: string;
}

/**
 * Type for a Booking Corner medal
 */
export interface Medal {
    Id: string;
    Text: string;
    Picture: string;
}

/**
 * Type for a Booking Corner country
 */
export interface Country {
    Id: string;
    Text: string;
    Code: string;
}

/**
 * Type for a Booking Corner country
 */
export interface BCLanguage {
    Id: string;
    Text: string;
    Code: string;
}

export interface Language {
    name: string;
    code: string;
}
export interface LanguageWithUpdate {
    updated?: Moment;
    data: Language[];
}

/**
 * Type for a type of vacations
 */
export interface TypeOfVacations {
    id: number;
    title: string;
}

export interface UserYearlyParams {
    id?: number;
    year: number;
    initial_overtime_hours?: number;
    user: number;
    userJob: UserContractSmall;
}
export interface Lang {
    code: string;
    name: string;
}
export interface FullSMSSentLinksClicked {
    id?: number,
    number_of_clicks: number;
    url: string;
    short_url: string;
    info1?: string;
    info2?: string;
    info3?: string;
    info4?: string;
    brand?: string;
    model?: string;
    os?: string;
    date?: string;
}
export interface FullSMSSentLinks {
    id: number;
    url: string;
    creation_date: string;
    clicked: FullSMSSentLinksClicked[];

}
export interface SMSStatus {
    code: number;
    message: string;
    description: string;
}
export interface FullSMSSentResponse {
    id: number;
    phone: string;
    text: string;
    date: string;
    received_date: string;

}
export interface FullSMSSent {
    id: number;
    sender: string;
    text: string;
    to: string;
    marketing: boolean;
    date: string;
    last_status_date: string;
    delayed_date?: string;
    credit: number;
    status?: SMSStatus;
    links?: FullSMSSentLinks[];
    response: FullSMSSentResponse[];
    simulate?: boolean;

}
export interface SMSBlacklist {
    id: number;
    phone: string;
    info1?: string;
    info2?: string;
    info3?: string;
    info4?: string;
    message?: string;
    date: string;
    sms_sent_id?: number;

}
export interface SMSTemplateContent {
    id?: number;
    language: string;
    text: string;
}
export interface SMSTemplate {
    id: number;
    name: string;
    marketing: boolean;
    simulate: boolean;
    type: string;
    content: SMSTemplateContent[];
}
export interface SMSTemplateFields {
    id: number;
    name: string;
    type: string;
    value: string;
    title: string;
}
export interface EditSMSTemplate {
    id?: number;
    name?: string;
    marketing?: boolean;
    simulate?: boolean;
    type?: string;
    content?: SMSTemplateContent[];
}
export interface SMSTransfert {
    id: number;
    credit: number;
    date: string;
    payed_date: string;
}
export interface FullSMSAccount {
    id: number;
    active: boolean;
    sender: string;
    phone: string;
    firstname: string;
    lastname: string;
    adress: string;
    zip: string;
    city: string;
    country: string;
    description: string;
    unlimited: boolean;
    credit: number;
    sent: FullSMSSent[];
    blacklist: SMSBlacklist[];
    thresholdLimit: number;
    template: SMSTemplate[];
    transfert: SMSTransfert[];


}
export interface SMSSend {
    message: string;
    to: string[];
    type?: string;
    delay?: string;
    links: string[];
    simulate?: boolean;

}
export interface SMSContactInfo {
    id: number;
    phone: string;
    mobile: string;
}
export interface SMSContact {
    id: number;
    first_name: string;
    last_name: string;
    active: boolean;
    contact: SMSContactInfo[];
}
export interface SMSAccount {
    id: number;
    active: boolean;
    sender: string;
    phone: string;
    firstname: string;
    lastname: string;
    adress: string;
    zip: string;
    city: string;
    country: string;
    description: string;
    unlimited: boolean;
    credit: number;
    thresholdLimit: number;
}
export interface TimeClockDevice {
    active: boolean;
    created: string;
    description: string;
    modified: string;
    name: string;
    os: string;
    uid: string;
    userAgent: string;
    version: string;
    deviceType: string;
    nrStartedEventClocked?: number;
    nrStopppedEventClocked?: number;
    nrManuallySetEventClocked?: number;
}
export interface TimeClockDeviceInit {
    pin: number;
    expiry?: string;
    nr_devices?: number;
    max_nr_devices?: number;
}
export interface UserPin {
    pin: number;
    created?: string;
    modified?: string;
}
export interface MobileAppTheme {
    button: string;
    logo: string | File;
    logo_rectangle: boolean;
    logo_rounded: boolean;
    primary: string;
    secondary: string;
    type: 'MobileAppTheme';
}
export interface MobileAppSettings {
    displayPin: boolean;
    allowTimeClock: boolean;

    allowClockingOfBreaktimes: boolean;
    allowClockingWithoutEvents: boolean;
    timeclockStopRequiresPOICheck: boolean;

    displayDocument: boolean;
    displayEmergency: boolean;
    displayNewsFeed: boolean;
    displayPlanning: boolean;
    displayTeam: boolean;
    displayUserFeed: boolean;
    loginValidityDuration: number;
    displayClokedDetails: boolean;
    type: 'MobileAppSettings';

}
export interface BreakTimeClocked {
    id: number;
    title: string;
    startDate?: Moment;
    endDate?: Moment;
    isPaid: boolean;
    totalHours: number;
    totalSeconds: number;
    manualStartDate?: Moment;
    manualEndDate?: Moment;
    manualIsPaid?: boolean;
    manualDescription?: string;
    adminStartDate?: Moment;
    adminEndDate?: Moment;
    adminIsPaid?: boolean;
    isManual: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
    startTimeClockDevice?: TimeClockDevice;
    endTimeClockDevice?: TimeClockDevice;
    manualTimeClockDevice?: TimeClockDevice;
    created: Moment;
    modified?: Moment;
}
export interface EventClocked {
    id: number;
    user: {
        id: number;
        name?: string;
        avatar?: string;
    };
    eventId: number;
    title: string;
    planStartDate?: Moment;
    planEndDate?: Moment;
    manualStartDate?: Moment;
    manualEndDate?: Moment;
    adminStartDate?: Moment;
    adminEndDate?: Moment;
    startDate?: Moment;
    endDate?: Moment;
    manualDescription?: string;
    isConfirmed: boolean;
    isRefused: boolean;
    isManual: boolean;
    description: string;
    color: string;
    breakTimesClocked?: BreakTimeClocked[];
    calculated?: EventClockedCalculated;
    userId: number;
    created: string;
    modified: string;
    name: string;
    startTimeClockDevice?: TimeClockDevice;
    endTimeClockDevice?: TimeClockDevice;
    manualTimeClockDevice?: TimeClockDevice;
    error: boolean;
}
export interface EventClockedSmall {
    id: number;
    startDate?: Moment;
    endDate?: Moment;
    manualStartDate?: Moment;
    manualEndDate?: Moment;
    manualDescription?: string;
    adminStartDate?: Moment;
    adminEndDate?: Moment;
    planStartDate: Moment;
    planEndDate: Moment;
    isManual: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
    breakTimesClocked: BreakTimeClocked[];
}

export interface EventClockedAdminBody {
    adminStartDate?: string;
    adminEndDate?: string;
    breakTimes?: NetworkBreakTime[];
}

export interface EventClockedWarnings {
    startDate: {
        isWarning: boolean;
        message: string;
        color: string;
    };
    endDate: {
        isWarning: boolean;
        message: string;
        color: string;
    };
}

export interface EventClockedDates {
    startDate?: Moment;
    endDate?: Moment;
    breakTimeDuration?: number;
}

export interface RangeOfMoment {
    startDate: Moment;
    endDate: Moment;
}

export interface TimeClockEventDuration {
    planDates: EventClockedDates;
    planDuration?: moment.Duration;
    planBreakTimes: BreakTime[];
    planBreakTimeSeconds: number;
    planEffectiveSeconds?: number;

    badgDates: EventClockedDates;
    badgDuration?: moment.Duration;
    badgBreakTimes: BreakTime[];
    badgBreakTimeSeconds: number;
    badgEffectiveSeconds?: number;

    planDurationIsHigher?: boolean;
    difference?: string;
    errors: string[];
}

export interface EventClockedFilters {
    waiting: boolean;
    running: boolean;
}

export interface EventClockedsByDate {
    date: Moment;
    eventClockeds: EventClocked[];
}

export interface EventWithEventClocked {
    id: number;
    title: string;
    color: string;
    startDate: Moment;
    endDate: Moment;
    calculated: EventCalculated;
    clocked?: SmallEventClocked;
    ignoreTimeClock?: boolean;
    typeOfDay?: TypeOfDay;
    typeOfDayOff?: TypeOfDayOff;
    breakTimes: BreakTime[];
    fromTimeClock?: boolean;
    userRemarks?: string;
    projectId?: number;
    user: {
        id: number;
        name: string;
        avatar?: string;
    };
}

export interface StartAndEndDates {
    startDate: Moment;
    endDate: Moment;
}

export interface SmallEventClocked {
    id: number;
    description: string;
    isConfirmed: boolean;
    isRefused: boolean;
    isManual: boolean;
    startDate: Moment;
    endDate?: Moment;
    manualStartDate?: Moment;
    manualEndDate?: Moment;
    manualDescription?: string;
    adminStartDate?: Moment;
    adminEndDate?: Moment;
    breakTimesClocked?: BreakTimeClocked[];
    calculated?: null | EventClockedCalculated;
    created: Moment;
    modified: Moment;
}

export interface EventClockedCalculated {
    id: number;
    startDate: string;
    endDate: string;
    tH: number; //total Hours
    tHWNPB: number; //totalHoursWithoutNonPaidBreaktimes
    tS: number; //total Seconds
    tSWNPB: number; //totalsecondsWithoutNonPaidBreaktimes
    created: string,
    modified: string;
}

export interface EventWithEventClockByDate {
    date: Moment;
    events: EventWithEventClocked[];
}

export const TimeClockControlFiltersCheck = {
    "RUNNING": 1,
    "WAITING": 2,
    "NOTCLOCKED": 3
};

const enum PlanningTypes {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

const PlanningTypesMessages = defineMessages<PlanningTypes>({
    [PlanningTypes.WEEKLY]: { defaultMessage: 'Weekly' },
    [PlanningTypes.MONTHLY]: { defaultMessage: 'Monthly' },
});

export const PlanningShowType = [
    {
        value: 'weekly',
        label: PlanningTypesMessages[PlanningTypes.WEEKLY]
    },
    {
        value: 'monthly',
        label: PlanningTypesMessages[PlanningTypes.MONTHLY]
    }
];

export interface UserHoursSummary {
    user: {
        id: number;
        fullName: string;
    };
    contract?: {
        id: number;
        workRate: number;
        name: string;
    };
    workRate: number;
    weeklyWorkingHours: number;
    dailyWorkingHours: number;
    monthTotals: {
        year: number;
        month: number;
        initialHours: number;
        effectiveHours: number;
        todo: number;
        toReport: number;
    }[];
    contractChanges: UserHoursSummaryContractChange[];
}

export interface UserHoursSummaryContractChange {
    reportFor: string;
    to_report: number;
    date: string;
}

export interface UserHoursSummaryWithContractDetails {
    contract: {
        id: number;
        name: string;
        workRate: number;
    },
    report: UserHoursSummary;
}
export interface ContainerTabsState {
    tabItems: TabItem[];
    tabListOfExtras?: { content: React.ReactNode, key: string; }[];
    tabListOfSidebars?: { content: Sidebar[], key: string; }[];
}

export interface ManagementTabLink {
}

export const DONE_TEXT = "done";

export type LoadingGroups = LoadingGroup[];

type LoadingGroupStatus = 'NONE' | 'PENDING' | 'LOADED';

export interface LoadingGroup {
    key: string;
    label: string | JSX.Element | JSX.Element[];
    status: LoadingGroupStatus;
    steps: (() => Promise<unknown>)[];
}

export interface ImportError {
    error: boolean;
    icon?: {
        prefix: TypeOrString<IconPrefix>;
        name: TypeOrString<IconName>;
    };
    message?: {
        text: MessageDescriptor;
        values?: Record<string, PrimitiveType>;
    };
    fromNetwork?: boolean;
}

export type ImportColTypes = 'text' | 'date' | 'percent' | 'number' | 'phone';

export interface ImportCol {
    value: string;
    label: ReactNode;
    type: ImportColTypes;
    placeholder: string;
    width?: number;
}

export interface ImportWarn {
    icon?: {
        prefix: TypeOrString<IconPrefix>;
        name: TypeOrString<IconName>;
    };
    message?: {
        text: MessageDescriptor;
        values?: Record<string, PrimitiveType>;
    };
}

export type Cell = { value: string; type: ImportColTypes, placeholder?: string, width?: number, error: ImportError, warn?: ImportWarn; };

export type ImportTableError = { uuid: string, col: number, error?: ImportError, warn?: ImportWarn; };
export type ImportTableErrors = ImportTableError[];

export type ImportRows = { uuid: string, cells: Cell[]; }[];

export interface PlanningUser {
    availabilityClosedByDefault: boolean;
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    fullNameNoSpace: string;
    image?: string;
    days: DictionaryString<PlanningCell>;
}


export interface PlanningUserPerf {
    availabilityClosedByDefault: boolean;
    id: number;
    firstName: string;
    lastName: string;
    code?: string;
    fullName: string;
    fullNameNoSpace: string;
    image?: string;
    days: DictionaryString<PlanningCell>;
    hasRights: boolean;
}

export interface PlanningCell {
    events: NetworkMonthlyPlanningRowPerfAddons[];
    // unavailabilities: null | { type: 'P', ranges: [string, string][]; } | { type: 'F'; }; // Partially of fully unavailable
    unavailabilities: null | { type: string, ranges?: [string, string][]; }; // Partially of fully unavailable
}


export type DictionaryString<V> = { [key: string]: V; };
export type DictionaryNumber<V> = { [key: number]: V; };
export type Dictionary<V> = { [key: string | number]: V; };


export interface HotelaUsersDataSyncable {
    userIds: number[];
}

export interface IFilterUsers {
    users: number[];
    groups: number[];
    usersToExclude: number[];
}


export const initFilterUsers: IFilterUsers = {
    users: [],
    groups: [],
    usersToExclude: [],
};

export interface ILockedEvent {
    id: number;
    color: string | undefined;
    title: string;
    startDate: string;
    endDate: string;
    isUnlockable: boolean;
}
export interface ILockedDays {
    date: string;
    id: number;
    temporary: boolean;
}
export interface IHOMonthData{
    id: number;
    dayOff: string;
    publicDays: string;
    vacation: string;
    unpaidDays: string;
    paidDayOff: string;
    paidPublicDays: string;
    paidVacation: string;
    militaryDays: string;
    dayCount: string;
    hourCount: string;
    regularOvertime: string;
    irregularOvertime: string;
    extraHoursBalance: string;
    extraDaysBalance: string;
    extraHolidaysDaysBalance: string;
    extraPublicDaysBalance: string;
    nightBonus10: string;
    nightBonus25: string;
    periods: {
        id: number;
        periodType: string;
        startDate: string;
        endDate: string;
        rate: number;
        created: string;
        modified: string;
    }[];
    created: string;
    modified: string;
} 
export interface IGOShift{
    
}

export interface IUserDetail {
    userId: number;
    lockedEvents: ILockedEvent[];
    lockedDays: ILockedDays[];
    isLockedAllMonth: boolean;
    hoMonthData: IHOMonthData | undefined | null
    goShiftsReadyToExport: {
        userData: IUserExportHoursGlobalOfficeUserData | undefined;
        isAuxiliary: boolean;
    } | undefined;
    goShiftsAlreadyExported: {
        userData: IUserExportHoursGlobalOfficeUserData | undefined;
        isAuxiliary: boolean;
    } | undefined;
}