import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "../../utils/types/storeTypes";

const selectUsers = (state:ApplicationState) => state.teamManagement.users;

export const selectActiveUsers = createSelector(
    [selectUsers],
    (users) => users.filter((u) => u.active && u.visible)
);

export const selectActiveReportUsers = createSelector(
    [selectActiveUsers],
    (users) => users.filter((u) => u.report)
);