import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Network from '../../../../../../utils/network';
import { RouterProps } from '../../../../../../utils/types/generalTypes';
import { GeneralResponse } from '../../../../../../utils/types/networkTypes';
import { PlanningTemplate } from '../../../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../../../utils/types/storeTypes';
import { convertNetworkTemplatesToPlanningTemplates, showNotification } from '../../../../../../utils/utils';
import { IntlProps } from '../../../../../app/LanguageProvider';
type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends RouterProps, ReduxProps, IntlProps {

}

interface State {
    loading: boolean;
    updatedAt?: Moment;
    templates: PlanningTemplate[];
    selectedTemplates: number[];
}

class TimeClocktemplates extends React.Component<Props, State> {
    columns: ColumnsType<PlanningTemplate> = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__min-width-200',
        },
        {
            title: <FormattedMessage defaultMessage={'Type of day'} />,
            key: 'typeOfDay',
            dataIndex: ['typeOfDay', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Type of vacation'} />,
            key: 'typeOfDayOff',
            dataIndex: ['typeOfDayOff', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Project'} />,
            key: 'project',
            dataIndex: ['project', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Color'} />,
            key: 'color',
            dataIndex: ['color', 'color'],
            className: '__width_100',
            render(color) {
                return (
                    <div style={{ height: '25px', width: '50px', backgroundColor: color, borderRadius: '18px' }} />
                );
            },
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            updatedAt: undefined,
            templates: [],
            selectedTemplates: [],
        };
    }

    componentDidMount() {
        this.getTimeClockTemplates();
    }

    getTimeClockTemplates = (force = false) => {
        if (force || !this.state.loading) {
            this.setState({ loading: true }, () => {
                Network.getTimeClocktemplates().then(
                    (response: GeneralResponse) => {
                        this.setState({ loading: false, templates: convertNetworkTemplatesToPlanningTemplates(response.data), updatedAt: moment() });
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the timeclock templates' }), "warning");
                        this.setState({ loading: false, templates: [], updatedAt: undefined });
                    }
                );
            });
        }
    };

    render() {
        const { height } = this.props;
        const { loading, templates } = this.state;
        let tableHeight = height - 570;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <Table
                loading={loading}
                rowKey={(u: PlanningTemplate) => `${u.id}`}
                dataSource={templates}
                columns={this.columns}
                pagination={false}
                scroll={{ x: true, y: tableHeight }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No template'} />} /> }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
});

const connector = connect(mapStateToProps);

export default connector(withRouter(injectIntl(TimeClocktemplates)));
