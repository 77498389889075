import {
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import Network from '../../../utils/network';
import type { User } from '../../../utils/types/generalTypes';
import { Loaded, StatusType } from '../../../utils/types/networkTypes';
import { IUserProductivityReport, MultiUserReportOverTime } from '../../../utils/types/productivityTypes';
import { showNotification } from '../../../utils/utils';
import PageSpinner from '../../common/general/pageSpinner';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors
);

interface UserProductivityGroupType {
    type: 'month' | 'week' | 'date';
    format: string;
}

interface Props {
    userId: User['id'];
    dateFrom: string;
    dateTo: string;
}

const primaryColor = '#C09F50';

const ProductivityGraphOverTime = (props: Props) => {
    const generateGroupType = useCallback((dateFrom: string, dateTo: string): UserProductivityGroupType => {
        const from = moment(dateFrom);
        const to = moment(dateTo);
        if (from.diff(to, "days") > 62) return { type: 'month', format: 'YYYY MMM' }
        else if (from.diff(to, "days") > 31) return { type: 'week', format: 'ww' }
        else return { type: 'date', format: 'dd DD' }
    }, []);
    const { dateFrom, dateTo, userId } = props;
    const intl = useIntl();
    const [data, setData] = useState<Loaded<MultiUserReportOverTime<IUserProductivityReport>>>({ status: StatusType.NONE });
    const [groupType, setGroupType] = useState<UserProductivityGroupType>(generateGroupType(dateFrom, dateTo));

    const loadData = useCallback(async () => {
        try {
            setData(d => ({ ...d, status: StatusType.PENDING }));

            const from = moment(dateFrom);
            const to = moment(dateTo);

            const reportsData = await Network.getProductivityReportGraph(userId, from, to, groupType.type);

            setData(d => ({
                ...d,
                updatedAt: moment().toISOString(),
                status: StatusType.FULFILLED,
                data: reportsData.data
            }));
        } catch (e) {
            setData(d => ({ ...d, status: StatusType.REJECTED }));
            showNotification(intl.formatMessage({ defaultMessage: "An error has occurred" }), 'error');
        }
    }, [dateFrom, dateTo, userId, intl, groupType]);


    useEffect(() => {
        setGroupType(generateGroupType(dateFrom, dateTo))
    }, [dateFrom, dateTo, generateGroupType])

    useEffect(() => {
        if (data.status === StatusType.NONE)
            loadData();
    }, [loadData, intl, props, data]);

    if (data.status === StatusType.PENDING)
        return (<PageSpinner />);
    return (
        <Line
            height={200}
            width={700}
            style={{ flex: 1 }}
            data={{
                labels: Object.keys(data.data ?? {}).map(k => moment(k).format(groupType.format)),
                datasets: [{
                    backgroundColor: primaryColor + "20",
                    borderColor: primaryColor,
                    hoverBorderColor: primaryColor,
                    pointBackgroundColor: primaryColor,
                    data: Object.values(data.data ?? {}).map(r => r[props.userId].avgProductivity),
                    pointRadius: 4,
                    tension: .3,
                    fill: true
                }],
            }}
            options={{
                plugins: {
                    legend: {
                        display: false
                    },
                    colors: {
                        enabled: false,
                    }
                },
                responsive: false,
                maintainAspectRatio: false,
            }}
        />
    );
};

export default ProductivityGraphOverTime;