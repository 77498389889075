import { IUserProductivityReport } from '../../../utils/types/productivityTypes';
import ProductivityGraphOverTime from './productivityGraphOverTime';

interface Props {
    report: IUserProductivityReport;
    dateFrom: string;
    dateTo: string;
}

const ProductivityUserDetails = (props: Props) => {
    // const workItems = useWorkItemsKeyed();

    return (
        <div className='__pr-user-details'>
            <div className='__pr-work-items'>

                {/* {Object.keys(props.report.workItems).map(workItemId => {
                    const workItem = workItems[workItemId];
                    if (workItem) {
                        return (
                            <div key={workItemId} className='__pr-work-item'>
                                <div>
                                    <div><FAIcon prefix={workItem.icon.split(" ")[0]} name={workItem.icon.split(' ')[1]} color={'#626262'} /></div>
                                    <div>{workItem.title}</div>
                                </div>
                                <div>{props.report.workItems[workItemId]}</div>
                            </div>
                        );
                    }
                    return (<></>);
                })} */}
            </div>

            <ProductivityGraphOverTime userId={props.report.userId} dateFrom={props.dateFrom} dateTo={props.dateTo} />
        </div>
    );
};

export default ProductivityUserDetails;