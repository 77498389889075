import { Avatar, Button, Dropdown, Menu } from 'antd';
import Linkify from 'linkify-react';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Rules } from '../../rbacRules';
import getFormat from '../../utils/Lang';
import { MessageCardContent } from '../../utils/types/generalTypes';
import FAIcon from '../common/FAIcon';
import Can from '../common/general/can';

interface Props {
    content: MessageCardContent;
    handleDelete?: (content: MessageCardContent) => void;
}

/**
 * Component that represent a news card
 */
class MessageCard extends React.Component<Props> {

    /**
     * Handle this message suppression
     */
    handleDelete = () => {
        if (this.props.handleDelete) this.props.handleDelete!(this.props.content);
    };

    render() {
        const { content } = this.props;

        return (
            <div id={`messagecard-${content.id}`} className="newscard-parent">
                <div className="newscard-avatar-parent">
                    <Avatar size={32} src={content.avatar} className="newscard-avatar" style={{ backgroundColor: 'var(--primary-color)', boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)' }}>
                        {
                            content.avatar ? null :
                                content.creator.slice(0, 1)
                        }
                    </Avatar>
                    <div className="newscard-avatar-shadow" />
                </div>
                <div className="newscard-item">
                    <div style={{ width: 'calc(100% - 40px)' }}>
                        <div className="newscard-item-header">
                            <span className="newscard-item-header-contacts">
                                <p className="newscard-item-header-creator" style={{ backgroundColor: 'var(--primary-color)' }}>{content.creator}</p>
                                <FAIcon prefix='fad' name='arrow-right' />
                                <p className="newscard-item-header-creator" style={{ color: 'var(--light-color)', backgroundColor: 'var(--secondary-color)' }}>{content.destinator}</p>
                            </span>
                            <span className="newscard-item-date">
                                <p>{moment(content.created).format(getFormat('TIME_SHORT'))}</p>
                                <p>{moment(content.created).format(getFormat('DATE'))}</p>
                            </span>
                        </div>
                        <div className="newscard-item-body">
                            <p className="newscard-item-title">{content.title}</p>
                            {
                                content.image && content.image.length > 0 ?
                                    <div>
                                        <img className="newscard-item-image" src={content.image} alt={`News ${content.id}`} />
                                    </div>
                                    : null
                            }
                            <p>
                                <Linkify options={{ target: 'blank' }}>
                                    {content.text}
                                </Linkify>
                            </p>
                        </div>
                    </div>
                    <div className="newscard-item-right">
                        <Can rule={Rules.Message.Delete} condition={content.can_delete} otherwise={<div />}>
                            <div style={{ width: '40px', textAlign: 'center' }} >
                                <Dropdown overlay={
                                    <Menu>
                                        <Menu.Item className="newscard-menu-item" onClick={this.handleDelete}>
                                            <FAIcon prefix='fad' name='trash-can' />
                                            <FormattedMessage defaultMessage={'Delete'} />
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <Button type="link" icon={<FAIcon prefix='fad' name='ellipsis-vertical' />} style={{ color: '#373737' }} />
                                </Dropdown>
                            </div>
                        </Can>
                        <div>
                            {
                                content.with_confirmation && (
                                    content.confirmed ?
                                        <div className="newscard-item-confirmed-container">
                                            <div>
                                                <span style={{ display: 'flex' }}>
                                                    <p style={{ marginRight: '10px' }}>{moment(content.confirmationDate).format(getFormat('TIME_SHORT'))}</p>
                                                    <FAIcon prefix='fad' name='thumbs-up' className="newscard-item-confirmed" style={{ color: 'green' }} />
                                                </span>
                                                <p>{moment(content.confirmationDate).format(getFormat('DATE'))}</p>
                                            </div>
                                        </div>
                                        :
                                        <FAIcon prefix='far' name='thumbs-up' className="newscard-item-confirmed" />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MessageCard;