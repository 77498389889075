import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import VerticalDivider from "../../general/verticalDivider";
import './actionsToolbar.css';

interface Props {
    open: boolean;
    title: React.ReactNode;
    actions: React.ReactNode;
    close: () => void;
}

const ActionsToolbar = (props: Props) => {
    return (
        <div className={`actions-toolbar ${props.open ? 'show' : ''}`}>
            <div>{props.title}</div>
            <div>
                {props.actions}
                <VerticalDivider />
                <Button className='quit-button' onClick={props.close}><FormattedMessage defaultMessage={'Cancel'} /></Button>
            </div>
        </div>

    );
};

export default ActionsToolbar;