import { Moment } from "moment";
import { User } from "./generalTypes";
import { ITemplate, PlanningTemplate } from "./planningTypes";

// #region ICustomer

export interface ICustomerNote {
    id: number;
    title: string;
    description: string;
    from_global_office: boolean;
    created: string;
    modified: string;
}
export interface ICustomerAbstract {
    id: number;
    title: string;
    description?: string;
    active: boolean;
    site: ISite;
    logoUrl?: string;
    bannerUrl?: string;
    color?: string;
    code?: string;
    unassigned: boolean;
    notes?: ICustomerNote[];
}

export interface ICustomerNetwork extends ICustomerAbstract {
    created: string;
    modified: string;
}

export interface ICustomer extends ICustomerAbstract {
    created: Moment;
    modified: Moment;
}

export interface ICustomerState {
    loading: boolean;
    updatedAt?: Moment;
    data?: ICustomer[];
}

export interface ICustomerStat {
    customerId: number;
    notes: number;
    contacts: number;
    sites: number;
    mandates: number;
    missions: number;
    missionsRules: number;
}
// #endregion

// #region IRole
export interface IRoleAbstract {
    id: number;
    title: string;
}

export interface IRoleNetwork extends IRoleAbstract {
    created: string;
    modified: string;
}

export interface IRole extends IRoleAbstract {
    created: Moment;
    modified: Moment;
}
// #endregion

// #region IContact
export interface IContactAbstract {
    id: number;
    customerId: number;
    title: string;
    active: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    mobile?: string;
    language?: string;
    country?: string;
    roles: IRole[];
}

export interface IContactNetwork extends IContactAbstract {
    created: string;
    modified: string;
}

export interface IContact extends IContactAbstract {
    created: Moment;
    modified: Moment;
}
// #endregion

export interface ISiteContact {
    id: number;
    contact: IContact;
    roles: IRole[];
    created: string;
    modified: string;
}

// #region ISite
export interface ISite {
    id: number;
    customerId: number;
    title: string;
    active: boolean;
    email?: string;
    phone?: string;
    adress?: string;
    zip?: string;
    city?: string;
    language?: string;
    country?: string;
    contacts: ISiteContact[];
    templates: PlanningTemplate[];
}
export interface ISiteState {
    loading: boolean;
    updatedAt?: string;
    data?: ISite[];
}
// #endregion

// #region IMandate
export interface IMandate {
    id: number;
    customerId: number;
    title: string;
    code?: string;
    active: boolean;
    site: ISite;
    description?: string;
    created: string;
    modified: string;
    startDate: string | number;
    endDate: string | number;
    adress?: string;
    zip?: string;
    city?: string;
    workPoi?: number;
    country?: number;
}

export interface IMandateState {
    loading: boolean;
    updatedAt?: string;
    data?: IMandate[];
}
// #endregion

// #region IMission
export interface IMissionAbstract {
    i?: number;
    customerId?: number;
    startDate?: string;
    endDate?: string;
    active?: boolean;
    title?: string;
    description?: string;
}

export interface IMission extends IMissionAbstract {
    id: number;
    customerId: number;
    mandate: IMandate;
    startDate: string;
    endDate: string;
    active: boolean;
    title: string;
    description?: string;
    assignations: User[];
    rules?: IMissionRule[];
}

// export type IMissionEdit = Partial<Omit<IMission, "id">>
export interface IMissionEdit extends IMissionAbstract {
    id?: number;
    customerId?: number;
    mandateId?: number;
    startDate?: string;
    endDate?: string;
    title?: string;
    description?: string;
    assignations?: User[];
    rules?: IMissionRuleEdit[];
}

export interface IMissionState {
    loading: boolean;
    updatedAt?: string;
    data?: IMission[];
}

export enum IMissionRuleType {
    RECCURENT = 'recurrent',
    QUOTA = 'quota',
    ONCE = 'unique',

}
export interface IMissionRuleAbstract {
    id?: number;
    title?: string;
    type?: IMissionRuleType;
    missionId?: number;
    customerId?: number;
    startTime?: string;
    endTime?: string;
    startDate?: string;
    endDate?: string;
    rrule?: string;
    exdate?: string[];
    rdate?: string[];
    hoursQuantity?: number;
    humanQuantity?: number;
    created?: string;
    modified?: string;
    main?: boolean;
    pairId?: number;
    template?: ITemplate;
    templateId?: number;
}

export interface IMissionRule extends IMissionRuleAbstract {
    id: number;
    title: string;
    type: IMissionRuleType;
    missionId: number;
    customerId: number;
    startDate: string;
    endDate: string;
    rrule: string;
    exdate: string[];
    rdate: string[];
    humanQuantity: number;
    created: string;
    modified: string;
}

export interface IMissionRuleEdit extends IMissionRuleAbstract {
}

export interface IMissionStatus {
    timeToDo: number;
    humanToHave: number;
    eventsTotalHumans: number;
    eventsIds: number[];
    eventUserIds: number[];
    startDate: string;
    endDate: string;
    periodDurationPerHuman: number;
    eventsTotalDuration: number;
    totalizedEventsTotalDuration: number;
    totalizedEventsTotalHumans: number;
    remainingTimeToDo: number;
    remainingHumanToHave: number;
    ruleId: number;
    missionId: number;
    missionTitle: string;
    mandateId: number;
    mandateTitle: string;
    customerId: number;
    customerTitle: string;
    templateId?: number;
    mandateStartDate: string;
    mandateEndDate?: string;
    ruleStartDate: string;
    ruleEndDate: string;
    type: IMissionRuleType;
    colorId?: number;
    poiId?: number;
    data: any;
}

export interface IMissionStatusByDate {
    startDate: string;
    data: IMissionStatusByCustomer[];
}

export interface IMissionStatusByCustomer extends IMissionStatusByDate {
    customerId: number;
    customerTitle: string;
    data: IMissionStatusByMandate[];
}

export interface IMissionStatusByMandate extends IMissionStatusByCustomer {
    mandateId: number;
    mandateTitle: string;
    data: IMissionStatusByMission[];
}
export interface IMissionStatusByMission extends IMissionStatusByMandate {
    missionId: number;
    missionTitle: string;
    data: IMissionStatus[];
}
// #endregion

// #region Aggregated Events
export interface IRubric {
    id: number;
    title: string;
    code: string;
    description: string;
    active: boolean;
    created: Date;
    modified: Date;
}

export interface IAnalyticPlan {
    id: number;
    title: string;
    code: string;
    description: string;
    unit: string;
    active: boolean;
    created: string;
    modified: string;
}

export interface IAccountingCombination {
    id: number;
    title: string;
    code: string;
    description: string;
    unit: string;
    active: boolean;
    created: string;
    modified: string;
    rubric?: IRubric;
    analyticPlan?: IAnalyticPlan;
}

export interface IIncreasedHoursByDay {
    id: number;
    title: string;
    active: boolean;
    allDay: boolean;
    day: string;
    date: string;
    percentage: number;
    startTime: string;
    endTime: string;
    ruleStartDate: string;
    ruleEndDate: string;
    created: string;
    modified: string;
}

export interface ISplittedEvent {
    id: number;
    date: string;
    event: string;
    userId: number;
    startDatetime: string;
    endDatetime: string;
    duration: string;
    increasedHoursByDay: IIncreasedHoursByDay;
    accountingCombination: IAccountingCombination;
    created: string;
    modified: string;
}

export interface IAggregatedEvent {
    id: number;
    userId: number;
    mandateId?: number;
    projectId?: number;
    customerId?: number,
    date: string;
    duration: number;
    isConfirmed: boolean;
    accountingCombination?: IAccountingCombination;
    splittedEvents: ISplittedEvent[];
}

export interface IAggregatedEventWithUser extends IAggregatedEvent {
    user?: User;
    typeOfDayId?: number;
    typeOfDayOffId?: number;
}

// #endregion