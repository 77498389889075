
import { Avatar, Image } from "antd";
import { useFullName } from "../../../hooks/useUsers";
import { User } from "../../../utils/types/generalTypes";
import { isNullOrEmpty } from "../../../utils/utils";
import FAIcon from "../FAIcon";

interface Props {
    user: User;
    withAvatar?: boolean;
}
const FullUser = (props: Props) => {
    const fullName = useFullName()(props.user);
    return (
        <div className="full_user">
            {
                props.withAvatar ?
                    <Avatar
                        onClick={(e) => e?.stopPropagation()}
                        size="large"
                        src={
                            isNullOrEmpty(props.user.image) ?
                                undefined
                                :
                                <Image
                                    onClick={e => e.stopPropagation()}
                                    sizes='small'
                                    preview={{ mask: <FAIcon prefix='fad' name='eye' /> }}
                                    height={'40px'} width={'40px'}
                                    src={props.user.image}
                                    style={{ objectFit: 'cover' }} />
                        }
                        icon={<FAIcon prefix='fad' name='user' />}
                        style={{ backgroundColor: 'var(--primary-color)' }}
                    />
                    : null
            }
            <span title={fullName} className="full_name">{fullName}</span>
        </div>
    );
};

export default FullUser;