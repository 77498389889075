import { Divider, DividerProps } from "antd";

interface Props extends DividerProps {
}
const VerticalDivider = (props: Props) => {
    return (
        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} {...props} />
    );
};

export default VerticalDivider;