import * as Sentry from "@sentry/react";
import { Button, Modal } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Network from "../../utils/network";
import { GenericFile, PreValidateFile, ValidateFile } from "../../utils/types/generalTypes";
import { showNotification } from "../../utils/utils";
import FileUploaderMultiple from "../common/fields/fileUploaderMultiple";
import ImageUploaderMultiple from "../common/fields/imageUploaderMultiple";


interface Props {
    open: boolean;
    title?: string;
    onClose(cancel?: boolean): void;
    files: GenericFile[];
    addFiles(files: GenericFile[]): void;
    filesToValidate: ValidateFile[];
    setFilesToValidate(files: ValidateFile[]): void;
    type: 'IMG' | 'VDO' | 'FLE';
    templateId: number | null;
}

export const UploadModal = (props: Props) => {
    const { type, open, files, title, templateId, onClose, addFiles, filesToValidate, setFilesToValidate } = props;
    const [filesToRemove, setFilesToRemove] = useState<number[]>([]);
    const intl = useIntl();


    const filteredFiles = useMemo(() => files.filter(f => f.res_type === type && !filesToRemove.includes(f.id)), [files, type, filesToRemove]);
    const concatFiles = useMemo(() => [...filteredFiles, ...(filesToValidate.filter(f => f.orgiginalFile !== undefined).map(f => f.orgiginalFile) as File[])], [filesToValidate, filteredFiles]);

    const [loading, setLoading] = useState<boolean>(false);

    const checkFile = useCallback((file: File): boolean => {
        const isLessThan20MB = file.size / 1024 / 1024 <= 20;

        if (!isLessThan20MB) showNotification(intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB' }, { size: 20 }), "warning");

        return isLessThan20MB;
    }, [intl]);

    const uploadFiles = useCallback((value_files: (File | string | GenericFile)[]) => {
        setLoading(true);
        const filesToValidateIdList = filesToValidate.map(f => f.orgiginalFile);

        const newFiles: File[] = value_files.filter(f => typeof f !== 'string' && f instanceof File && checkFile(f) && !filesToValidateIdList.includes(f)) as File[];
        const buildNewFiles: PreValidateFile[] = newFiles.map((f, index) => ({ index, file: f, file_name: f.name, res_type: type, type: 'PreValidateFile' }));

        const alreadyUploadedIdList = (value_files.filter(f => typeof f !== 'string' && (f instanceof File)) as File[]);
        const genericAlreadyUploadedIdList = (value_files.filter(f => typeof f !== 'string' && !(f instanceof File)) as GenericFile[]).map(f => f.id);

        let finalFilesToValidate = cloneDeep(filesToValidate);
        concatFiles.forEach(async f => {
            if (f instanceof File) {
                if (!alreadyUploadedIdList.includes(f)) {
                    const fileToValidate = filesToValidate.find(fTV => fTV.orgiginalFile === f);
                    if (fileToValidate) {
                        finalFilesToValidate = finalFilesToValidate.filter(fTV => fTV.orgiginalFile !== f);
                        setFilesToValidate(finalFilesToValidate);
                        setFilesToRemove((actualFilesToRemove) => actualFilesToRemove.concat(fileToValidate.fileId));
                    }
                }
            } else if (f.type === 'GenericFile') {
                if (!genericAlreadyUploadedIdList.includes(f.id)) {
                    setFilesToRemove((actualFilesToRemove) => actualFilesToRemove.concat(f.id));
                }
            }
        });
        if (buildNewFiles.length > 0) {
            Network.putTemplateFile(buildNewFiles).then(
                (response: ValidateFile[]) => {
                    console.log('ZZUUII2', finalFilesToValidate, response, [...finalFilesToValidate, ...response]);
                    setFilesToValidate(finalFilesToValidate.concat(response));
                    setLoading(false);
                },
                (e) => {
                    Sentry.captureException(e);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while uploading the files' }), "warning");
                    setLoading(false);
                }
            );
        } else {
            setLoading(false);
        }
    }, [filesToValidate, concatFiles, checkFile, type, setFilesToValidate, intl]);


    const deleteFiles = useCallback(async () => {
        setLoading(true);
        if (templateId && filesToRemove.length > 0) {
            await Network.removeTemplateFiles(templateId, filesToRemove).then(
                (response) => {
                    if (response.error === false && response.data) {
                        addFiles(response.data);
                    }

                },

            );
        }

    }, [templateId, addFiles, filesToRemove]);

    const validateFiles = useCallback(async () => {
        if (templateId) {
            await deleteFiles();
            const flatFilesToValidate = filesToValidate.map(fTV => fTV.fileId);
            Network.validateTemplateFiles(templateId, filesToValidate).then(
                (response) => {
                    addFiles(response.data);
                    const actualFilesToValidate = cloneDeep(filesToValidate);
                    setFilesToValidate(actualFilesToValidate.filter(fTV => !flatFilesToValidate.includes(fTV.fileId)));
                    setLoading(false);
                    onClose();
                },
                (e) => {
                    Sentry.captureException(e);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while uploading the files' }), "warning");
                    setLoading(false);
                }
            );
        } else {
            onClose();
        }
    }, [templateId, deleteFiles, filesToValidate, addFiles, setFilesToValidate, onClose, intl]);

    return (
        <Modal
            open={open ?? true}
            width={420}
            style={{ top: 150 }}
            title={title || <FormattedMessage defaultMessage={'Add documents'} />}
            //open={this.state.addDocuments}
            onCancel={() => onClose()}
            destroyOnClose={false}
            footer={[

                <div key={`templatemodal-add-breaktime-action`} style={{ display: 'flex', justifyContent: templateId ? 'space-between' : 'end' }}>
                    {templateId ? <Button type="dashed" onClick={() => { setFilesToRemove([]); onClose(true); }} loading={loading} key="breaktime-modal-button-ok">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button> : null}
                    <Button type="primary" onClick={validateFiles} loading={loading} key="breaktime-modal-button-ok">
                        {templateId ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Continue'} />}
                    </Button>
                </div>
            ]}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'center' }}>
                {type === 'IMG' ?
                    <ImageUploaderMultiple
                        loading={loading}
                        deleteTextMaxWidth={250}
                        //className="newsfeed-image-uploader"
                        files={concatFiles}
                        max={10}
                        editText={intl.formatMessage({ defaultMessage: 'Add more images' })}
                        addText={intl.formatMessage({ defaultMessage: 'Add one or more images' })}
                        containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, justifyContent: 'center', alignItems: 'center' }}
                        onUploaded={uploadFiles}
                    />
                    :
                    type === 'FLE' ?
                        <FileUploaderMultiple
                            loading={loading}
                            deleteTextMaxWidth={200}
                            accept=".pdf"
                            max={5}
                            editText={intl.formatMessage({ defaultMessage: 'Add more PDF documents' })}
                            addText={intl.formatMessage({ defaultMessage: 'Add one or more PDF documents' })}
                            //className="newsfeed-image-uploader"
                            containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, justifyContent: 'center', alignItems: 'center' }}
                            files={concatFiles}
                            onUploaded={uploadFiles}
                        />
                        :
                        null
                }

            </div>
        </Modal>
    );
};
export default UploadModal;