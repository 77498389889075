import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { ReloadTimes } from '../../utils/constants';
import Network from '../../utils/network';
import { Company } from '../../utils/types/generalTypes';
import { StatusType } from '../../utils/types/networkTypes';
import type { IProductivitySlice, IWorkItem, IWorkItemBare } from '../../utils/types/productivityTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { isDeprecated } from '../../utils/utils';
import { changeCompany } from '../actions/user';
import { CHANGE_CURRENT_COMPANY } from '../reducer/user';


export const INIT_STATE: IProductivitySlice = {
    enabled: false,
    workItems: {
        status: StatusType.NONE,
    }
};

export const workItemsFetched = createAsyncThunk(
    'work-items/fetch',
    async () => {
        const response = await Network.getWorkItems();
        return { updatedAt: moment(), items: response.data };
    },
    {
        condition: (arg: { forceReload?: boolean; }, thunkAPI) => {
            const { productivity } = thunkAPI.getState() as ApplicationState;
            console.log(productivity);
            return arg.forceReload || !productivity.workItems.updatedAt || isDeprecated(moment(productivity.workItems.updatedAt), ReloadTimes.MEDIUM_RELOAD);
        },
    }
);


export const workItemDeleted = createAsyncThunk(
    'work-items/delete',
    async (arg: { workItemId: IWorkItem['id']; }) => {
        await Network.deleteWorkItem(arg.workItemId);
    }
);

export const workItemCreated = createAsyncThunk(
    'work-items/create',
    async (arg: { workItem: IWorkItemBare; onSuccess?: () => void; onFailure?: () => void; }) => {
        try {
            const response = await Network.createWorkItem(arg.workItem);
            if (arg.onSuccess)
                arg.onSuccess();
            return response.data;
        }
        catch (e) {
            if (arg.onFailure)
                arg.onFailure();
        }
        return undefined;
    }
);


export const workItemEdited = createAsyncThunk(
    'work-items/edit',
    async (arg: { workItem: IWorkItemBare; onSuccess?: () => void; onFailure?: () => void; }) => {
        try {

            const response = await Network.editWorkItem(arg.workItem);
            if (arg.onSuccess)
                arg.onSuccess();
            return response.data;
        }
        catch (e) {
            if (arg.onFailure)
                arg.onFailure();
        }
        return undefined;
    }
);

const productivitySlice = createSlice({
    name: 'productivity',
    initialState: INIT_STATE,
    reducers: {
        reset: () => {
            return {
                ...INIT_STATE,
            };
        }
    },
    extraReducers(builder) {
        // #region customersFetched
        builder.addCase(workItemsFetched.pending, (state) => {
            state.workItems.status = StatusType.PENDING;
        });

        builder.addCase(workItemsFetched.fulfilled, (state, action) => {
            state.workItems = {
                ...state.workItems,
                status: StatusType.FULFILLED,
                updatedAt: action.payload.updatedAt.toISOString(),
                data: action.payload.items,
            };
        });

        builder.addCase(workItemsFetched.rejected, (state) => {
            state.workItems.status = StatusType.REJECTED;
        });

        builder.addCase(workItemDeleted.fulfilled, (state, action) => {
            state.workItems.data = state.workItems.data?.filter(w => w.id !== action.meta.arg.workItemId);
        });

        builder.addCase(workItemCreated.fulfilled, (state, action) => {
            if (action.payload)
                state.workItems.data?.push(action.payload);
        });

        builder.addCase(workItemEdited.fulfilled, (state, action) => {
            if (action.payload) {
                const newItem = action.payload;
                state.workItems.data = state.workItems.data?.map(w => w.id === newItem.id ? { ...newItem } : w);
            }
        });

        builder.addCase(CHANGE_CURRENT_COMPANY, (state, action: ReturnType<typeof changeCompany>) => {
            state.enabled = (action.data as Company).isProductivityModuleEnabled;
        });
        // #endregion
    },
});

export const { reset } = productivitySlice.actions;

export default productivitySlice.reducer;
