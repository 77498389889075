import { Button, Checkbox, Col, DatePicker, Drawer, Empty, Input, Row, Space } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnProps } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeProject } from '../../../store/actions/configurations';
import { CaseType, GREEN_COLOR, RED_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { RouterProps } from '../../../utils/types/generalTypes';
import { Project, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { getCaseAndPlural, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import SwitchLabeled from '../../common/fields/switchLabeled';
import VirtualTable from '../../common/general/virtualTable';
import { FilterSidebar } from '../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';

interface Filters {
    searchValue: string;
    activeProjects: boolean;
}
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps, ContainerTabsItemProps { }

interface State {
    selectedProjects: number[];
    createTypeOfDay: TypeOfDay | undefined;
    loadingProjects: boolean;
    searchedProjects?: Project[];
    addingProject: boolean;
    showingProject: boolean;
    createProject?: Project;
    showProject?: Project;
    createProjectTitleError: boolean;
    filters: Filters;
}

export class ListOfProjects extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedProjects: [],
            createTypeOfDay: undefined,
            addingProject: false,
            showingProject: false,
            createProjectTitleError: false,
            loadingProjects: false,
            filters: {
                searchValue: '',
                activeProjects: true,
            }
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isEqual(this.state.loadingProjects, prevState.loadingProjects)) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { loadingProjects } = this.state;
        return (
            <>
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'far'} name="rotate" />}
                    onClick={this.refresh}
                    loading={loadingProjects} />
                <CircleButton
                    small
                    disabled={loadingProjects}
                    onClick={() => this.setState({ showingProject: true, showProject: {} }, () => this.showAddProject())}
                    icon={<FAIcon prefix='far' name='plus' />}
                    title={intl.formatMessage({ defaultMessage: 'Add a {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }) })}
                    placement="left"
                    key="team-management-add-project" />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { searchValue, activeProjects } = this.state.filters;
        const content = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Search
                    value={searchValue}
                    placeholder={this.props.intl.formatMessage({ defaultMessage: 'Search by title or code' })}
                    allowClear
                    key={`search-customer`}
                    onChange={(event) => this.searchProjects(event.target.value)} />
                <SwitchLabeled
                    style={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}
                    checked={activeProjects}
                    onChange={e => this.onChangeActiveProjects(e)}
                    label={intl.formatMessage({ defaultMessage: 'Active projects' })}
                />
            </div>
        );
        return [FilterSidebar(content, intl)];
    };

    onChangeActiveProjects = (checked: boolean) => {
        this.setState(prevState => {
            const filters: Filters = { ...prevState.filters, activeProjects: checked };
            return ({ filters, searchedProjects: this.filterProjects(filters) });
        });
    };

    searchProjects = (value: string) => {
        const search = value.toLocaleLowerCase();
        this.setState(prevState => {
            const filters: Filters = { ...prevState.filters, searchValue: search };
            return ({ filters, searchedProjects: this.filterProjects(filters) });
        });
    };


    filterProjects = (filters: Filters) => {
        const { projects } = this.props;
        const { searchValue, activeProjects } = filters;
        let searchedProjects = cloneDeep(projects);

        if (searchValue.length > 0) {
            const matchSearch = (p: Project) => p.title?.toLowerCase().includes(searchValue.toLowerCase()) || p.customId?.toLowerCase().includes(searchValue.toLowerCase());
            searchedProjects = projects.filter(matchSearch);
        }

        searchedProjects = searchedProjects?.filter((p) => p.isArchived === !activeProjects);

        return searchedProjects;
    };

    refresh = () => {
        if (!this.state.loadingProjects) {
            this.setState({ loadingProjects: true });
            Network.getProjects().then(
                (response: Project[]) => {
                    this.setState({ loadingProjects: false });
                    this.props.changeProject(response);
                },
                () => {
                    this.setState({ loadingProjects: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error");
                },
            );
        }
    };

    showAddProject = (modifyProject?: Project) => {
        let newProject: Project;
        if (modifyProject) {
            const tmpModifyProject = { ...modifyProject };
            tmpModifyProject.startDate = tmpModifyProject.startDate == null ? undefined : moment(tmpModifyProject.startDate);
            tmpModifyProject.endDate = tmpModifyProject.endDate == null ? undefined : moment(tmpModifyProject.endDate);

            newProject = tmpModifyProject;
        } else {
            newProject = {
                inReports: true,
            };
        }
        this.setState({ addingProject: true, createProject: newProject });
    };

    closeAddProject = () => {
        if (this.state.showProject && this.state.showProject.id) {
            this.setState({ addingProject: false, createProject: undefined, createProjectTitleError: false });
        } else {
            this.setState({ addingProject: false, createProject: undefined, createProjectTitleError: false, showingProject: false, showProject: undefined });
        }
    };

    showDetailsProject = (project: Project) => {
        this.setState({ showingProject: true, showProject: project });
    };

    closeShowDetailsProject = () => {
        this.setState({ showingProject: false, showProject: undefined });
    };

    archiveProject = (project: Project) => {
        project.id ?
            Network.archiveProjects(project.id).then(
                () => {
                    let projects = cloneDeep(this.props.projects);
                    projects = projects?.map(p => p.id === project.id ? { ...p, isArchived: true } : p);
                    this.props.changeProject(projects);
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully archived' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning");
    };

    unarchiveProject = (project: Project) => {
        project.id ?
            Network.unarchiveProjects(project.id).then(
                () => {
                    let projects = cloneDeep(this.props.projects);
                    projects = projects?.map(p => p.id === project.id ? { ...p, isArchived: false } : p);
                    this.props.changeProject(projects);
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully unarchived' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while unarchiving the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning");
    };

    updateProject = () => {
        const { createProject } = this.state;
        const projects = cloneDeep(this.props.projects);
        if (createProject !== undefined) {
            createProject.title && createProject.title.length > 0 ?
                Network.updateProject(createProject).then(
                    (response: Project) => {
                        // this.refresh();
                        if (createProject && createProject.id) {
                            const indexProject = projects.map(p => p.id === response.id).indexOf(true);
                            projects[indexProject] = response;
                        } else {
                            projects.unshift(response);
                        }
                        this.props.changeProject(projects);
                        this.setState({ showProject: response });
                        this.closeAddProject();
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully created' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success");
                    },
                    () => {
                        this.closeAddProject();
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error");
                    },
                )
                :
                this.setState({ createProjectTitleError: true });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning");
        }
    };

    deleteProject = (project: Project) => {
        project.id ?
            Network.deleteProject(project.id).then(
                () => {
                    let projects = cloneDeep(this.props.projects);
                    projects = projects?.filter(p => p.id !== project.id);
                    this.props.changeProject(projects);
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully deleted' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning");
    };

    projectsOnRow = (record: Project) => ({
        onClick: () => this.showDetailsProject(record)
    });

    onChangeSelectedProjects = (keys: React.Key[]) => this.setState({ selectedProjects: keys as number[] });

    render() {
        const { createProject, showProject, loadingProjects, selectedProjects } = this.state;
        const { intl, projects, height } = this.props;

        let tableHeight = height - 133;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <>
                <VirtualTable
                    loading={loadingProjects}
                    className={"__team-management-user-list"}
                    rowKey={(u: any) => u.id}
                    dataSource={this.state.searchedProjects ? this.state.searchedProjects : projects}
                    columns={this.props.isSmartphone ? this.projectsColumnsSmartphone : this.projectsColumns}
                    onRow={this.projectsOnRow}
                    rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedProjects, selectedRowKeys: selectedProjects }}
                    scroll={{ x: true, y: tableHeight }}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No project'} />} /> }}
                    pagination={false} />
                <Drawer
                    destroyOnClose={true}
                    className="__drawer"
                    title={showProject?.id ? <FormattedMessage defaultMessage={'{project} {name}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Project' }), name: showProject?.title }} /> : <FormattedMessage defaultMessage={'Creation in progress'} />}
                    width={this.props.isSmartphone ? '100%' : '650px'}
                    onClose={this.closeShowDetailsProject}
                    visible={this.state.showingProject}
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {
                                <>
                                    <Button onClick={this.closeShowDetailsProject} style={{ marginRight: 8 }}>
                                        <FormattedMessage defaultMessage={'Close'} />
                                    </Button>
                                    <Button onClick={() => this.showAddProject(showProject)} type="primary">
                                        <FormattedMessage defaultMessage={'Edit'} />
                                    </Button>
                                </>
                            }
                        </div>
                    }
                >
                    {
                        showProject &&
                        <>
                            <Row gutter={[16, 24]}>
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Identifier'} />{':'} </span>{showProject.customId}</p>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Start date'} />{':'}  </span>{showProject.startDate && showProject.startDate !== null ? moment(showProject.startDate).format(getFormat('DATE')) : "-"}</p>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'End date'} />{':'} </span>{showProject.endDate && showProject.endDate !== null ? moment(showProject.endDate).format(getFormat('DATE')) : "-"}</p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Description'} />{':'} </span><pre style={{fontFamily: 'inherit'}}>{showProject.description}</pre></p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Options'} />{':'} </span><FormattedMessage defaultMessage={'This {project} {included}.'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), included: showProject.inReports ? intl.formatMessage({ defaultMessage: 'appears in the reports' }) : intl.formatMessage({ defaultMessage: 'does not appear in the reports' }) }} />{' '}
                                        <FormattedMessage defaultMessage={'This {project} {archived}.'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), archived: showProject.isArchived ? intl.formatMessage({ defaultMessage: 'is archived' }) : intl.formatMessage({ defaultMessage: 'is not archived' }) }} /></p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    {showProject.creator && <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Creation'} />{':'} </span><FormattedMessage defaultMessage={'This {project} has been created on {date} by {user}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), date: moment(showProject.created).format(getFormat('DATE')), user: `${showProject.creator?.first_name} ${showProject.creator?.last_name}` }} /></p>}
                                    {showProject.modifier && <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Modified'} />{':'} </span><FormattedMessage defaultMessage={'This {project} has been modified on {date} by {user}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), date: moment(showProject.modified).format(getFormat('DATE')), user: `${showProject.modifier?.first_name} ${showProject.modifier?.last_name}` }} /></p>}
                                </Col>
                            </Row>
                            <Drawer
                                destroyOnClose={true}
                                // zIndex={1011}
                                className="__drawer"
                                title={showProject?.id ? <FormattedMessage defaultMessage={'Edit {project} {name}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), name: showProject?.title }} /> : <FormattedMessage defaultMessage={'Create a new {project}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }) }} />}
                                width={this.props.isSmartphone ? '100%' : '650px'}
                                onClose={this.closeAddProject}
                                visible={this.state.addingProject}
                                // bodyStyle={{ paddingBottom: 80 }}
                                footer={
                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        {
                                            <>
                                                <Button onClick={this.closeAddProject} style={{ marginRight: 8 }}>
                                                    <FormattedMessage defaultMessage={'Cancel'} />
                                                </Button>
                                                <Button onClick={this.updateProject} type="primary">
                                                    <FormattedMessage defaultMessage={'Save'} />
                                                </Button>
                                            </>
                                        }
                                    </div>
                                }
                            >
                                {
                                    <Row gutter={[16, 24]}>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Input
                                                style={this.state.createProjectTitleError ? { borderColor: 'red' } : {}}
                                                value={createProject?.title}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                                onChange={(v) => {
                                                    createProject!.title = v.target.value;
                                                    this.setState({ createProject });
                                                }}
                                            />
                                            {this.state.createProjectTitleError &&
                                                <span style={{ color: 'red', fontStyle: 'italic', fontSize: '86%' }}><FormattedMessage defaultMessage={'Please enter a title'} /></span>}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Input
                                                value={createProject?.customId}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Identifier' })}
                                                onChange={(v) => {
                                                    createProject!.customId = v.target.value;
                                                    this.setState({ createProject });
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <DatePicker
                                                value={createProject?.startDate}
                                                style={{ width: '100%' }}
                                                format={getFormat('DATE')}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Please select a start date' })}
                                                onChange={(v) => {
                                                    createProject!.startDate = v == null ? undefined : v;
                                                    this.setState({ createProject });
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <DatePicker
                                                value={createProject?.endDate}
                                                style={{ width: '100%' }}
                                                format={getFormat('DATE')}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Please select an end date' })}
                                                onChange={(v) => {
                                                    createProject!.endDate = v == null ? undefined : v;
                                                    this.setState({ createProject });
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Input.TextArea
                                                value={createProject?.description}
                                                rows={4}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Description' })}
                                                onChange={(v) => {
                                                    createProject!.description = v.target.value;
                                                    this.setState({ createProject });
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Checkbox
                                                checked={createProject?.inReports}
                                                onChange={(v) => {
                                                    createProject!.inReports = v.target.checked;
                                                    this.setState({ createProject });
                                                }}
                                            ><FormattedMessage defaultMessage={'Appears in the report?'} /></Checkbox>
                                        </Col>
                                    </Row>
                                }

                            </Drawer>
                        </>
                    }

                </Drawer>
            </>
        );

    }

    projectsColumnsSmartphone: ColumnProps<Project>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            fixed: true,
            sorter: (a: Project, b: Project) => (a.title && b.title) ? a.title.localeCompare(b.title) : 0,
            defaultSortOrder: 'ascend',
        },
        {
            className: '__table-text-centered',
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '115px',
            render: (record: Project) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Space>
                        <CircleButton
                            small
                            icon={record.isArchived ? <FAIcon prefix='far' name='check' /> : <FAIcon prefix='fad' name='box-archive' />}
                            title={record.isArchived ? this.props.intl.formatMessage({ defaultMessage: 'Unarchive' }) : this.props.intl.formatMessage({ defaultMessage: 'Archive' })}
                            // small={this.props.small}
                            // style={this.props.style}
                            onClick={(e) => {
                                e.stopPropagation();
                                record.isArchived ? this.unarchiveProject(record) : this.archiveProject(record);
                            }}
                            placement={'left'} />
                        <CircleButton
                            disabled={!record.isArchived}
                            small
                            icon={<FAIcon prefix='far' name='trash-can' />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Delete permanently' })}
                            // small={this.props.small}
                            // style={this.props.style}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.deleteProject(record);
                            }}
                            placement={'left'} />
                    </Space>
                </div>;
            }
        }
    ];
    projectsColumns: ColumnProps<Project>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            fixed: this.props.isSmartphone ? undefined : 'left',
            className: '__min-width-220',
            sorter: (a: Project, b: Project) => (a.title && b.title) ? a.title.localeCompare(b.title) : 0,
        },
        {
            title: <FormattedMessage defaultMessage={'Identifier'} />,
            dataIndex: 'customId',
            key: 'customId',
            className: ' __width_300',
            sorter: (a: Project, b: Project) => (a.customId && b.customId) ? a.customId.localeCompare(b.customId) : !a.customId && !b.customId ? 0 : !a.customId ? -1 : +1,
        },
        {
            title: <FormattedMessage defaultMessage={'Created'} />,
            // dataIndex: 'creator',
            key: 'created',
            className: ' __width_300',
            render: (c: Project) => c.creator && c.creator?.first_name + " " + c.creator?.last_name + " (" + moment(c.created).format(getFormat('DATE')) + ")",
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        // {
        //     title: 'Modification',
        //     key: 'modified',
        //     render: (m: Project) => m.modifier ? m.modifier?.first_name + " " + m.modifier?.last_name + " (" + moment(m.modified).format("DD/MM/YYYY") + ")" : "Aucune modification",
        //     // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        // },
        {
            className: '__table-text-centered __width_80',
            title: <FormattedMessage defaultMessage={'Report'} />,
            dataIndex: 'inReports',
            key: 'inReports',
            render: (report: boolean) => report ? <FAIcon prefix='fad' name='circle-check' color={GREEN_COLOR} /> : <FAIcon prefix='fad' name='circle-xmark' color={RED_COLOR} />,
        },
        {
            className: '__table-text-centered __width_70',
            title: <FormattedMessage defaultMessage={'Active'} />,
            dataIndex: 'isArchived',
            key: 'isArchived',
            render: (archived: boolean) => archived ? <FAIcon prefix='fad' name='circle-xmark' color={RED_COLOR} /> : <FAIcon prefix='fad' name='circle-check' color={GREEN_COLOR} />,
        },
        {
            title: <FormattedMessage defaultMessage={'Start date'} />,
            dataIndex: 'startDate',
            key: 'startDate',
            className: ' __width_180',
            render: (startDate: Moment) => startDate !== null && startDate !== undefined ? moment(startDate).format(getFormat('DATE')) : "-",
            sorter: (a: Project, b: Project) => a.startDate && b.startDate ? moment(a.startDate).unix() - moment(b.startDate).unix() : !a.startDate && !b.startDate ? 0 : !a.startDate ? -1 : +1
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        {
            title: <FormattedMessage defaultMessage={'End date'} />,
            dataIndex: 'endDate',
            key: 'endDate',
            className: ' __width_180',
            render: (endDate: Moment) => endDate !== null && endDate !== undefined ? moment(endDate).format(getFormat('DATE')) : "-",
            sorter: (a: Project, b: Project) => a.endDate && b.endDate ? moment(a.endDate).unix() - moment(b.endDate).unix() : !a.endDate && !b.endDate ? 0 : !a.endDate ? -1 : +1
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        {
            className: '__table-text-centered  __width_150',
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            render: (record: Project) => {
                return (
                    <Space>
                        <CircleButton
                            small
                            icon={record.isArchived ? <FAIcon prefix='far' name='check' /> : <FAIcon prefix={"fad"} name='box-archive' />}
                            title={record.isArchived ? this.props.intl.formatMessage({ defaultMessage: 'Unarchive' }) : this.props.intl.formatMessage({ defaultMessage: 'Archive' })}
                            onClick={(e) => {
                                e.stopPropagation();
                                record.isArchived ? this.unarchiveProject(record) : this.archiveProject(record);
                            }}
                            placement={'left'} />
                        <DeleteButton
                            small
                            disabled={!record.isArchived}
                            text={<FormattedMessage defaultMessage={'Do you want to delete this {project}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'project' }) }} />}
                            onConfirm={(e) => { e?.stopPropagation(); this.deleteProject(record); }}
                            placement="left"
                            buttonPlacement="right"
                        />
                    </Space>
                );
            }
        }
    ];
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    isSmartphone: state.window.isSmartphone,
    typesOfDay: state.configurations.typesOfDay,
    height: state.window.height,
    projects: state.configurations.project
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(injectIntl(ListOfProjects)));