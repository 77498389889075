import { Badge, DatePicker, Divider, Select, Space, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useUsers from "../../../hooks/useUsers";
import { useAppSelector } from "../../../store/store";
import { ContractTypeMode, GREEN_COLOR, RED_COLOR } from "../../../utils/constants";
import Network from "../../../utils/network";
import { DictionaryNumber, User, UserJobTMP } from "../../../utils/types/generalTypes";
import { CcntReport } from "../../../utils/types/reportTypes";
import { showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";
import VirtualTable from "../../common/general/virtualTable";
import Filters from "../../planningPerf/tabs/common/filters";
import DrawerCcntReport from "../ccnt/drawerCcntReport";

interface CcntBalancesByUserId {
    effectives: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    balances: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    daysOff: {
        sickness: number;
        accident: number;
        military: number;
        others: number;
    };
    contractTypeMode: number;
}

interface Filters {
    users: number[];
    groups: number[];
    usersToExclude: number[];
}

const ReportBalancesCCNT = () => {
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [selectedContracts, setSelectedContracts] = useState<DictionaryNumber<number | undefined>>({});
    const [ccntBalances, setCcntBalances] = useState<DictionaryNumber<CcntBalancesByUserId>>({});
    const [loading, setLoading] = useState(false);
    const [loadingCcnt, setLoadingCcnt] = useState(false);
    const [ccnt, setCcnt] = useState<undefined | CcntReport>(undefined);
    const [filters, setFilters] = useState<Filters>({ users: [], groups: [], usersToExclude: [] });
    const [showFilters, setShowFilters] = useState(false);
    const selectedMonthRef = useRef<Moment | undefined>(undefined);
    const users = useUsers();
    const intl = useIntl();
    const height = useAppSelector(state => state.window.height);

    const availableContracts = useCallback((month: Moment, contracts?: UserJobTMP[]) => {
        return contracts ? contracts.filter(c => c.id !== undefined && moment(c.contract_expiry_date, 'YYYY-MM-DD').isSameOrAfter(month, 'dates') && moment(c.date_in_report, 'YYYY-MM-DD').isSameOrBefore(month, 'dates')) : [];
    }, []);

    const getAllCcntData = useCallback((month: Moment) => {
        const userIds = Object.keys(selectedContracts).map(idx => parseInt(idx));
        const jobIds = Object.keys(selectedContracts).map(idx => selectedContracts[parseInt(idx)]).filter(i => i !== undefined) as number[];
        setLoading(true);
        Network.generateReportsCcntV2(month.format("YYYY-01-01"), month.clone().endOf('month').format("YYYY-MM-DD"), userIds, jobIds).then(
            (response) => {
                setCcntBalances(() => Object.fromEntries(
                    users.filter(u => availableContracts(month, u.job).length > 0).map((u) => {
                        const userData = response[u.id] ? response[u.id] : undefined;
                        const monthValues = userData && userData.months[month.month()] ? userData.months[month.month()] : undefined;

                        const balances = userData ? userData.tally : {
                            finalWorkTime: 0,
                            finalRestDelta: 0,
                            finalVacationDelta: 0,
                            finalHolidayDelta: 0
                        };

                        const effectives = {
                            hours: monthValues ? monthValues.tally.T : 0,
                            rests: monthValues ? monthValues.tally.X : 0,
                            vacations: monthValues ? monthValues.tally.V : 0,
                            holidays: monthValues ? monthValues.tally.F : 0,
                        };

                        const daysOff = {
                            sickness: monthValues ? monthValues.tally.M + monthValues.tally.Ma : 0,
                            accident: monthValues ? monthValues.tally.A : 0,
                            military: monthValues ? monthValues.tally.Mi : 0,
                            others: monthValues ? monthValues.tally.D : 0,
                        };

                        return [
                            u.id,
                            {
                                balances: {
                                    hours: balances.finalWorkTimeDelta.toFixed(2),
                                    rests: balances.finalRestDelta.toFixed(2),
                                    vacations: balances.finalVacationDelta.toFixed(2),
                                    holidays: balances.finalHolidayDelta.toFixed(2)
                                },
                                effectives: {
                                    hours: effectives.hours.toFixed(2),
                                    rests: effectives.rests.toFixed(2),
                                    vacations: effectives.vacations.toFixed(2),
                                    holidays: effectives.holidays.toFixed(2)
                                },
                                daysOff: {
                                    sickness: daysOff.sickness.toFixed(2),
                                    accident: daysOff.accident.toFixed(2),
                                    military: daysOff.military.toFixed(2),
                                    others: daysOff.others.toFixed(2),
                                },
                                contractTypeMode: userData.data.contractTypeMode,
                            }
                        ];
                    })
                ));
                setLoading(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                setLoading(false);
            }
        );
    }, [availableContracts, intl, selectedContracts, users]);

    useEffect(() => {
        setSelectedContracts(() => Object.fromEntries(
            users.map((u) => [u.id, u.job && availableContracts(selectedMonth, u.job).length > 0 ? availableContracts(selectedMonth, u.job)[0].id : undefined])
        ));
    }, [availableContracts, selectedMonth, users]);

    useEffect(() => {
        if (Object.keys(selectedContracts).length > 0 && (!selectedMonthRef.current || selectedMonthRef.current.format('YYYY-MM-DD') !== selectedMonth.format('YYYY-MM-DD'))) {
            getAllCcntData(selectedMonth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContracts]);

    const renderTag = useCallback((value: number, inverted = false) => {
        const color = value < 0 ? (inverted ? GREEN_COLOR : RED_COLOR) : (inverted ? RED_COLOR : GREEN_COLOR);

        // By @Axel
        return <span style={{ background: color + "33", padding: '5px 10px', color: color, borderRadius: 999 }}>{value}</span>;
    }, []);

    const isFixed = useCallback((contractTypeMode: number) => {
        return [ContractTypeMode.NORMAL].includes(contractTypeMode);
    }, []);

    const getCcntReport = useCallback((userIds: number[], jobIds: number[]) => {
        setLoadingCcnt(true);
        Network.generateReportsCcntV2(selectedMonth.format("YYYY-MM-01"), selectedMonth.format("YYYY-MM-DD"), userIds, jobIds).then(
            (networkCCnt) => {
                const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                setLoadingCcnt(false);
                setCcnt(ccnt);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), 'warning');
                setLoadingCcnt(false);
            }
        );
    }, [intl, selectedMonth]);

    const columns = useMemo((): ColumnsType<User> => ([
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            dataIndex: 'user',
            fixed: 'left',
            className: '__min-width-200',
            render: (_, record) => `${record.first_name} ${record.last_name}`
        },
        {
            title: <FormattedMessage defaultMessage={'Contract'} />,
            key: 'contract',
            className: '__width_200',
            width: '200px',
            render: (_, record) => {
                const contracts = availableContracts(selectedMonth, record.job);
                const selectedContract = contracts.find(c => selectedContracts[record.id] === c.id);
                return (
                    <>
                        {
                            contracts.length === 1 ?
                                selectedContract ?
                                    selectedContract.name
                                    :
                                    <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'Contract not found'} /></span>
                                :
                                contracts.length > 0 ?
                                    <Select
                                        onClick={(e) => e.stopPropagation()}
                                        value={selectedContracts[record.id]}
                                        options={contracts.map(j => ({ value: j.id, label: j.name }))}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {

                                            setSelectedContracts((oldContracts) => {
                                                const newContracts = cloneDeep(oldContracts);
                                                newContracts[record.id] = e;
                                                return newContracts;
                                            });
                                        }}
                                    />
                                    :
                                    <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'No contract available'} /></span>
                        }
                    </>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Effective time'} />,
            key: 'effective',
            className: '__width_480 __report-hours-summary-fixed-odd',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Hours'} />,
                    key: 'effective-hours',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].effectives.hours - ccntBalances[a.id].effectives.hours),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 && isFixed(ccntBalances[record.id].contractTypeMode) ?
                            ccntBalances[record.id].effectives.hours ?
                                ccntBalances[record.id].effectives.hours
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Rest'} />,
                    key: 'effective-rests',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.rests - ccntBalances[b.id].effectives.rests),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].effectives.rests ?
                                ccntBalances[record.id].effectives.rests
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Vacations'} />,
                    key: 'effective-vacations',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.vacations - ccntBalances[b.id].effectives.vacations),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].effectives.vacations ?
                                ccntBalances[record.id].effectives.vacations
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Holidays'} />,
                    key: 'effective-holidays',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.holidays - ccntBalances[b.id].effectives.holidays),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].effectives.holidays ?
                                ccntBalances[record.id].effectives.holidays
                                : 0
                            : '-'
                    )
                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Days off'} />,
            key: 'daysoff',
            className: '__width_480',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Sickness'} />,
                    key: 'daysoff-sickness-maternity',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].daysOff.sickness - ccntBalances[a.id].daysOff.sickness),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 && isFixed(ccntBalances[record.id].contractTypeMode) ?
                            ccntBalances[record.id].daysOff.sickness ?
                                ccntBalances[record.id].daysOff.sickness
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Accident'} />,
                    key: 'daysoff-accident',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.accident - ccntBalances[b.id].daysOff.accident),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].daysOff.accident ?
                                ccntBalances[record.id].daysOff.accident
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Military'} />,
                    key: 'daysoff-military',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.military - ccntBalances[b.id].daysOff.military),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].daysOff.military ?
                                ccntBalances[record.id].daysOff.military
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Others'} />,
                    key: 'daysoff-others',
                    className: '__width_120',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.others - ccntBalances[b.id].daysOff.others),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].daysOff.others ?
                                ccntBalances[record.id].daysOff.others
                                : 0
                            : '-'
                    )
                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Balances'} />,
            key: 'balance',
            fixed: 'right',
            className: '__width_480 __report-hours-summary-fixed-odd',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Hours'} />,
                    key: 'balance-hours',
                    fixed: 'right',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].balances.hours - ccntBalances[a.id].balances.hours),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 && isFixed(ccntBalances[record.id].contractTypeMode) ?
                            ccntBalances[record.id].balances.hours ?
                                renderTag(ccntBalances[record.id].balances.hours, true)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Rest'} />,
                    key: 'balance-rests',
                    fixed: 'right',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.rests - ccntBalances[b.id].balances.rests),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].balances.rests ?
                                renderTag(ccntBalances[record.id].balances.rests)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Vacations'} />,
                    key: 'balance-vacations',
                    fixed: 'right',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.vacations - ccntBalances[b.id].balances.vacations),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].balances.vacations ?
                                renderTag(ccntBalances[record.id].balances.vacations)
                                : 0
                            : '-'
                    )
                },
                {
                    title: <FormattedMessage defaultMessage={'Holidays'} />,
                    key: 'balance-holidays',
                    fixed: 'right',
                    className: '__width_120 __report-hours-summary-fixed-odd',
                    align: 'center',
                    sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.holidays - ccntBalances[b.id].balances.holidays),
                    render: (_, record) => (
                        ccntBalances[record.id] && availableContracts(selectedMonth, record.job).length > 0 ?
                            ccntBalances[record.id].balances.holidays ?
                                renderTag(ccntBalances[record.id].balances.holidays)
                                : 0
                            : '-'
                    )
                }
            ]
        },
        // {
        //     title: <FormattedMessage defaultMessage={'Action'} />,
        //     key: 'action',
        //     className: '__width_80',
        //     width: '80px',
        //     align: 'center',
        //     render: (_, record) => {
        //         return (
        //             <CircleButton
        //                 icon={<FAIcon prefix='fad' name='eye' />}
        //                 small
        //                 title="Show report"
        //                 disabled={selectedContracts[record.id] === undefined}
        //                 onClick={() => {
        //                     const contractId = selectedContracts[record.id];
        //                     if (contractId !== undefined)
        //                         getCcntReport([record.id], [contractId]);
        //                 }}
        //             />
        //         );
        //     }
        // },
    ]
    ), [availableContracts, selectedMonth, selectedContracts, ccntBalances, isFixed, renderTag]);

    const resetFilters = useCallback(() => {
        setFilters({
            groups: [],
            users: [],
            usersToExclude: []
        });
    }, []);

    const hasActiveFilters = () => {
        return filters.users.length > 0 || filters.groups.length > 0;
    };

    const handleOnRow = useCallback((record: User) => {
        const contractId = selectedContracts[record.id];
        if (contractId !== undefined)
            getCcntReport([record.id], [contractId]);
    }, [getCcntReport, selectedContracts]);

    const tableHeight = useMemo(() => {
        const newHeight = height - 270;
        return newHeight < 250 ? 250 : newHeight;
    }, [height]);

    const filteredUsers = useMemo(() => {
        let newUsers = cloneDeep(users).filter(u => u.visible);
        if (filters.users.length > 0)
            newUsers = newUsers.filter(u => filters.users.includes(u.id));
        if (filters.groups.length > 0)
            newUsers = newUsers.filter(u => u.group_users?.some(g => filters.groups.includes(g.group)));
        return newUsers;
    }, [filters.groups, filters.users, users]);

    return (
        <Card
            title={
                <Space>
                    <FormattedMessage defaultMessage={'Balances N-CLA'} />
                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='rotate' />}
                        onClick={() => getAllCcntData(selectedMonth)}
                    />
                </Space>
            }
            icon={<FAIcon prefix='fad' name='calculator' />}
            headerElements={[
                <>
                    <DatePicker.MonthPicker
                        key='balance-ccnt-month-picker'
                        format={"MM/YYYY"}
                        style={{ width: '110px' }}
                        allowClear={false}
                        value={selectedMonth}
                        onChange={(e) => {
                            if (e) {
                                setSelectedMonth(e);
                            }
                        }}
                    />
                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                    <CircleButton small type={showFilters ? 'primary' : 'default'} icon={<Badge dot={hasActiveFilters()}><FAIcon prefix='fad' name='filters' color={showFilters ? 'white' : 'black'} /></Badge>} onClick={() => setShowFilters(!showFilters)} />
                </>
            ]}
        >
            <Spin spinning={loading}>
                <div style={{ display: 'flex', gap: 10 }}>
                    <VirtualTable
                        className='__basic-table'
                        onRow={(e: User) => ({
                            onClick: () => handleOnRow(e)
                        })}
                        rowClassName={(record: User) => selectedContracts[record.id] === undefined ? 'disabled-row' : ''}
                        dataSource={filteredUsers}
                        columns={columns}
                        style={{ flex: 1, overflow: 'auto' }}
                        pagination={false}
                        scroll={{ x: true, y: tableHeight }}
                    />
                    {
                        showFilters &&
                        <div className={`__time-clock-filters2 ${showFilters ? '' : 'hidden-sidebar'}`}>
                            <p className='__mp-sider-title'>
                                {showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                            </p>
                            <div className='__mp-main-siders-content'>
                                {
                                    showFilters && filters ?
                                        <Filters
                                            reset={resetFilters}
                                            users={{
                                                selectedUsers: filters.users,
                                                changeUsers: (val) => setFilters({ ...filters, users: val })
                                            }}
                                            groups={{
                                                selectedGroups: filters.groups,
                                                usersToExclude: filters.usersToExclude,
                                                changeGroups: (groups, usersToExclude) => setFilters({ ...filters, groups, usersToExclude })
                                            }}
                                        />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Spin>
            <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={ccnt !== undefined} close={() => setCcnt(undefined)} year={selectedMonth} />
        </Card>
    );
};

export default ReportBalancesCCNT;