import { Popover, Select, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsers, toggleUsersLoading } from '../../../../store/actions/teamManagement';
import { selectActiveReportUsers } from '../../../../store/selectors/usersSelectors';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { IFilterUsers, RouterProps, User, UserJobTMP } from '../../../../utils/types/generalTypes';
import { MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray } from '../../../../utils/types/planningTypes';
import { CcntReport, CcntReports, CcntType, CctSecurityType, MonthlyReportType } from '../../../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { alert, downloadBlob, filterUsers, isNullOrEmpty, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import ActionsToolbar from '../../../common/fields/ActionsToolbar/actionsToolbar';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import FullUser from '../../../common/general/fullUser';
import VirtualTable from '../../../common/general/virtualTable';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import Filters from '../../../planningPerf/tabs/common/filters';
import DrawerCcntReport from './drawerCcntReport';
import MultipleCcntReportPrintPage from './multipleCcntReportPrintPage';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ContainerTabsItemProps, RouterProps, IntlProps, ReduxProps {
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
}

interface State {
    startMonth: Moment;
    endMonth: Moment;
    selectedUsers: number[];
    isLoading: number | undefined; //the id of the concerned user, or -1 if all
    users?: User[];
    selectedJob?: { userId: number, jobId: number | undefined; }[];
    isCcntVisible: boolean;
    isMultipleCcntVisible: boolean;
    ccnt?: CcntReport;
    cctSecurity?: CctSecurityType;
    multipleCcnt?: CcntReports;
    monthlyReport?: MonthlyReportType;
    monthlyHours?: MonthlyHoursOfTheYearArray;
    compressionProgression: number;
    manualForceRefreshTable: number;
    filters: IFilterUsers;
}

class Report extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            startMonth: moment().startOf("year"),
            endMonth: moment().endOf("month"),
            selectedUsers: [],
            isLoading: undefined,
            isCcntVisible: false,
            isMultipleCcntVisible: false,
            compressionProgression: 0.0,
            manualForceRefreshTable: 0,
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        this.getMonthlyHours();


        if (!isNullOrEmpty(this.props.users) && isNullOrEmpty(this.state.users)) {
            const users = cloneDeep(this.props.users);
            const selectedJob: { userId: number, jobId: number | undefined; }[] = [];
            users?.forEach((u: User) => {
                if (u.job && u.job.length > 0) {
                    u.job = this.contractsAvailableBetweenMonths(u.job);
                    selectedJob.push({
                        userId: u.id,
                        jobId: u.job[0]?.id
                    });
                }
            });

            this.setState({ users: users, selectedJob });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (
            prevState.isLoading !== this.state.isLoading ||
            !prevState.endMonth.isSame(this.state.endMonth, "month")
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }


        if (!isNullOrEmpty(this.props.users) && isNullOrEmpty(this.state.users)) {
            const users = cloneDeep(this.props.users);
            const selectedJob: { userId: number, jobId: number | undefined; }[] = [];
            users?.forEach((u: User) => {
                if (u.job && u.job.length > 0) {
                    u.job = this.contractsAvailableBetweenMonths(u.job);
                    selectedJob.push({
                        userId: u.id,
                        jobId: u.job[0]?.id
                    });
                }
            });

            this.setState({ users: users, selectedJob });
        }

        if (!prevState.startMonth.isSame(this.state.startMonth, "month") || !prevState.endMonth.isSame(this.state.endMonth, "month")) {
            const users2 = JSON.parse(JSON.stringify(this.props.users));

            users2?.forEach((u: User) => {
                u.job && (u.job = this.contractsAvailableBetweenMonths(u.job));
            });

            this.setState({ users: users2 });
        }

        if (!prevState.endMonth.isSame(this.state.endMonth, "year")) {
            this.getMonthlyHours();
        }
    }

    getExtra = () => {
        const { endMonth, isLoading } = this.state;
        return (
            <>
                <Popover
                    placement='bottomRight'
                    trigger={"click"}
                    title={<FormattedMessage defaultMessage={'N-CLA interactive message checklist'} />}
                    content={
                        <div style={{ maxWidth: '800px' }}>
                            <h3 style={{ marginBottom: '5px' }}><FormattedMessage defaultMessage={'Informations'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'More than 14 hours of work in a single day.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Warnings'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'Week with more than 50 working hours.'} /></li>
                                <li><FormattedMessage defaultMessage={'Week without 2 days off.'} /></li>
                                <li><FormattedMessage defaultMessage={'More than 24 hours of work in a single day. Check plausibility.'} /></li>
                                <li><FormattedMessage defaultMessage={'Negative working hours.'} /></li>
                                <li><FormattedMessage defaultMessage={'More than 6 working days without rest days.'} /></li>
                                <li><FormattedMessage defaultMessage={'Incompatible types of day according to N-CLA standards. If several event types are present on the same day, check that they are of type X, F, M or T.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Warnings visible in the printed version'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'More than 5 hours of work on a half-day off. The half-day of leave/holiday/sick leave will not be counted.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Errors'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'Invalid type of day. Correct event type. Valid types: T | X | F | V | Mi | M | A | Ma | D.'} /></li>
                                <li><FormattedMessage defaultMessage={'Too many different events on the same day. Check that there are no more than two types of day. Check that there are no more than 2 events if they are not of type X, F or T.'} /></li>
                            </ul>
                        </div>
                    }
                >
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='question' />}
                    />
                </Popover>
                <AmazingDatePicker
                    initialPickerType={PickerMode.MONTH}
                    loadingData={isLoading === 0}
                    controlled={{
                        valueFrom: endMonth,
                        onChange: this.changeOnlyMonth,
                    }}
                />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    resetFilters = () => this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });

    getMonthlyHours = () => {
        Network.getMonthlyHours(this.state.startMonth.year()).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHours: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                };
                this.setState({ monthlyHours: monthlyHours });
            },
            (error) => {
                if (error.message.includes("No monthly hours")) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                }
            },
        );
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    contractsAvailableBetweenMonths = (user_contracts: Array<UserJobTMP>) => {
        const { startMonth, endMonth } = this.state;
        const contracts = user_contracts.sort((a, b) => {
            const dateA = moment(a.date_in_report);
            const dateB = moment(b.date_in_report);
            return dateB.diff(dateA);
        });

        return contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "year") || startMonth.isAfter(moment(c.contract_expiry_date), "year")));
    };

    generateReports = (user?: User) => {
        let userIds: number[] | undefined = [];

        //if a userId is specified, create an array with one id 
        if (user) {
            userIds.push(user.id);
            this.setState({ isLoading: user.id });
        }
        // else use the selected users array
        else {
            userIds = this.state.selectedUsers;
            userIds.forEach(() => {
                // Faire userContracts
            });
            this.setState({ isLoading: -1 });
        }


        let jobIds: [number] | undefined = undefined;

        if (userIds && userIds.length > 0) {
            userIds.forEach(u => {
                const userJob = this.state.selectedJob?.find(e => e.userId === u);
                if (userJob && userJob && userJob.jobId) {
                    if (jobIds && jobIds.length > 0) {
                        jobIds.push(userJob.jobId);
                    } else {
                        jobIds = [userJob.jobId];
                    }
                } else {
                    if (jobIds && jobIds.length > 0) {
                        jobIds.push(-1);
                    } else {
                        jobIds = [-1];
                    }
                }
            });
        }

        const { selectedUsers } = this.state;
        if (selectedUsers.length > 0) {
            Network.generateReportsCcntV2(this.state.startMonth.format("YYYY-MM-DD"), this.state.endMonth.format("YYYY-MM-DD"), userIds, jobIds).then(
                (networkMultipleCCnt) => {
                    console.log(networkMultipleCCnt);
                    const ccntReports: CcntReports = networkMultipleCCnt;
                    this.setState({ isLoading: undefined, selectedUsers: [], multipleCcnt: ccntReports, isMultipleCcntVisible: true });
                },
                () => {
                    this.setState({ isLoading: undefined, isMultipleCcntVisible: true });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generatin the report n°{user}' }, { user: user?.id || 0 }), "warning");
                }
            );
        } else {
            this.setState({ isCcntVisible: true });
            Network.generateReportsCcntV2(this.state.startMonth.format("YYYY-MM-01"), this.state.endMonth.format("YYYY-MM-DD"), userIds, jobIds).then(
                (networkCCnt) => {
                    const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                    this.setState({ isLoading: undefined, selectedUsers: [], ccnt });
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                    this.setState({ isLoading: undefined });
                }
            );
        }
    };

    generateCompanyReport = () => {
        this.setState({ isLoading: -2 });
        // generate reports
        Network.generateCompanyReport(this.state.startMonth.format("YYYY-MM-01")).then(
            blob => {
                downloadBlob(blob, `company_${this.state.startMonth.format("YYYY-MM")}.xlsx`);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ isLoading: undefined });
            }
        );
    };

    changeOnlyMonth = (date: Moment | null) => {
        if (date) {
            this.setState({ startMonth: date.clone().startOf("year"), endMonth: date.clone().endOf("month") });
        }
    };

    usersOnRow = (record: User) => ({ onClick: () => this.generateReports(record) });

    columns = (): ColumnsType<User> => [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            className: "__width_300",
            fixed: this.props.isSmartphone ? undefined : "left",
            sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
            render: (user) => <FullUser user={user} withAvatar />
        },
        {
            title: <FormattedMessage defaultMessage={'Dates'} />,
            key: "dates",
            className: '__min-width-250',
            render: (record: User) => {
                const { selectedJob } = this.state;
                const selectValue = selectedJob?.find(e => e.userId === record.id);
                const job = record.job?.find(e => e.id === selectValue?.jobId);

                if (job) return <span><FormattedMessage defaultMessage={'Contract from {start} to {end}'} values={{ start: moment(job.date_in_report, "YYYY-MM-DD").format(getFormat('DATE')), end: moment(job.contract_expiry_date, "YYYY-MM-DD").format(getFormat('DATE')) }} /></span>;
                else return <span><FormattedMessage defaultMessage={'No contract selected'} /></span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Contract'} />,
            key: 'contract',
            className: '__width_280',
            fixed: this.props.isSmartphone ? undefined : "right",
            render: (record: User) => {
                if (record.job && record.job.length > 0) {
                    const { selectedJob } = this.state;
                    const selectValue = selectedJob?.find(e => e.userId === record.id);
                    return (
                        <Select
                            style={{ width: '250px' }}
                            value={selectValue?.jobId}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                            onChange={(e) => {
                                selectValue && (selectValue.jobId = e);
                                this.setState({ selectedJob });
                            }}
                        >
                            {record.job.map(contract => (
                                <Select.Option
                                    key={`contract-${contract.id}`}
                                    value={contract.id!}>
                                    {contract.name ? contract.name : <FormattedMessage defaultMessage={'Unamed contract'} />}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                } else return <span><FormattedMessage defaultMessage={'No contract available'} /></span>;
            }
        }
    ];

    render() {
        const { selectedUsers, ccnt, multipleCcnt, isCcntVisible, isMultipleCcntVisible, isLoading, users, filters } = this.state;
        const { intl, usersLoading, height, groups } = this.props;
        let tableHeight = height - 135;
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);

        return (
            <Spin spinning={usersLoading} indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                <div>
                    <VirtualTable
                        className="__basic-table"
                        dataSource={filteredUsers}
                        columns={this.columns()}
                        onRow={this.usersOnRow}
                        rowKey={(u: User) => u.id}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                        loading={this.props.usersLoading}
                        scroll={{ x: true, y: tableHeight }}
                    />
                    <ActionsToolbar
                        open={selectedUsers.length > 0}
                        close={() => this.setState({ selectedUsers: [] })}
                        title={`${selectedUsers.length.toString()} ${intl.formatMessage({ defaultMessage: "Users" })}`}
                        actions={
                            <CircleButton
                                small
                                icon={< FAIcon prefix='fad' name='download' />}
                                onClick={() => this.generateReports()}
                                loading={this.state.isLoading === -1}
                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Download {count} report} other {Download {count} reports}}' }, { count: this.state.selectedUsers.length })}
                            />
                        }
                    />
                </div>
                {
                    (isMultipleCcntVisible && multipleCcnt) ?
                        <MultipleCcntReportPrintPage multipleCcnt={multipleCcnt} close={() => this.setState({ isMultipleCcntVisible: false, multipleCcnt: undefined })} year={this.state.startMonth} />
                        : null
                }
                <DrawerCcntReport isLoading={Boolean(isLoading)} ccnt={ccnt} isVisible={isCcntVisible} close={() => this.setState({ /*ccnt: undefined,*/ isCcntVisible: false })} year={this.state.startMonth} />
            </Spin>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    toggleUsersLoading: (b: boolean) => dispatch(toggleUsersLoading(b)),
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    users: selectActiveReportUsers(state),
    groups: state.teamManagement.groups,
    company: state.user.company,
    usersLoading: state.teamManagement.usersLoading,
    width: state.window.width,
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
});


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(Report)));



