import { Empty, Select, Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import '../../../../styles/ccnt.css';
import Network from '../../../../utils/network';
import { GeneralResponse, LockedDaysOrMonth } from '../../../../utils/types/networkTypes';
import { UserShort } from '../../../../utils/types/planningTypes';
import { ContractPaidHoursSummary, PaidHoursAllMonths, PaidHoursSummary } from '../../../../utils/types/reportTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { contractsAvailableBetweenMonths, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import EditableTable, { ColumnTypesEditable } from '../../../common/general/editableTable';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';


type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, IntlProps, ContainerTabsItemProps {
}

interface State {
    year: Moment;
    isLoading: boolean;
    paidHoursSummary?: PaidHoursSummary[];
    lockedMonth?: LockedDaysOrMonth;
}

class PaidHours extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment(),
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.loadAllData();
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if ((prevState.year !== this.state.year && this.state.isLoading === false) ||
            (isEqual(prevProps.users, this.props.users) === false && this.state.isLoading === false)) {
            if (this.props.users && this.props.users.length > 0) {
                this.loadAllData();
            }
        }

        if (
            !prevState.year.isSame(this.state.year, "year") ||
            prevState.isLoading !== this.state.isLoading
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { year, isLoading } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.YEAR}
                    loadingData={isLoading}
                    controlled={{
                        valueFrom: year,
                        onChange: this.changeYear,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.loadAllData()}
                    loading={isLoading} />
            </>
        );
    }


    loadAllData = () => {
        this.getLockedMonths();
        if (this.props.users && this.props.users.length > 0) {
            this.getPaidHours();
        }
    };

    changeYear = (year: Moment | null) => {
        if (year) {
            this.setState({ year });
        }
    };

    getLockedMonths = async () => {
        await Network.getLockedMonths(this.state.year.year()).then(
            (response) => {
                if (response.error === false)
                    this.setState({ lockedMonth: response.data });
                else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading month state' }), "error");
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading month state' }), "error");
            },
        );
    };

    getPaidHours = () => {
        if (this.props.users && this.props.users.length > 0) {
            this.setState({ isLoading: true });
            Network.getPaidHoursSummary(this.state.year.year()).then(
                (response: GeneralResponse) => {
                    const paidHoursByUser = groupBy(response.data, (v: any) => v.userId);
                    const paidHoursSummary: PaidHoursSummary[] = [];

                    this.props.users.forEach(user => {
                        const paidHoursTmp: PaidHoursSummary = {
                            user: {
                                id: user.id,
                                firstName: user.first_name,
                                lastName: user.last_name,
                                availabilityClosedByDefault: false
                            },
                            contractsPaidHoursSummary: user.job ? contractsAvailableBetweenMonths(user.job, this.state.year.clone().startOf("year"), this.state.year.clone().endOf("year")).map(job => { return { ...job }; }) : [],
                            selectedPaidHoursSummary: undefined,
                        };

                        const hasUser = Object.prototype.hasOwnProperty.call(paidHoursByUser, user.id);
                        if (hasUser) {
                            const paidHoursRaw = paidHoursByUser[user.id as keyof typeof paidHoursByUser];
                            // eslint-disable-next-line no-var
                            var paidHoursByUserByContract = groupBy(paidHoursRaw, (v: any) => v.contractId);
                        }

                        paidHoursTmp.contractsPaidHoursSummary = paidHoursTmp.contractsPaidHoursSummary.map(contract => {
                            contract = {
                                id: Number(contract.id),
                                name: contract.name,
                                date_in_report: contract.date_in_report,
                                contract_expiry_date: contract.contract_expiry_date,
                            };

                            const hasContract = hasUser && contract.id !== undefined && Object.prototype.hasOwnProperty.call(paidHoursByUserByContract, contract.id);
                            if (hasContract) {
                                paidHoursByUserByContract[contract.id as keyof typeof paidHoursByUserByContract].forEach(data => {
                                    contract[`ph${data.month as number}` as keyof PaidHoursAllMonths] = { ...data };
                                });
                            }

                            return contract;
                        });

                        paidHoursTmp.selectedPaidHoursSummary = paidHoursTmp.contractsPaidHoursSummary.at(0);
                        paidHoursSummary.push(paidHoursTmp);
                    });

                    this.setState({ isLoading: false, paidHoursSummary });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the paid hours' }), "error");
                    this.setState({ isLoading: false });
                },
            );
        }
    };

    onChangeContract = (userId: number, contractId: number) => {
        const paidHoursSummary = cloneDeep(this.state.paidHoursSummary);
        const userDataId = paidHoursSummary?.findIndex(d => d.user.id === userId);
        if (userDataId != null && userDataId >= 0) {
            const userContractId = paidHoursSummary![userDataId].contractsPaidHoursSummary.findIndex(c => c.id === contractId);
            if (userContractId != null && userContractId >= 0) {
                paidHoursSummary![userDataId].selectedPaidHoursSummary = { ...paidHoursSummary![userDataId].contractsPaidHoursSummary[userContractId] };
                this.setState({ paidHoursSummary });
            }
        }
    };

    // columns = (): ColumnsType<PaidHoursSummary> => [
    columns = () => {
        const parsedCollumns: ColumnTypesEditable = [
            {
                title: <FormattedMessage defaultMessage={'User'} />,
                key: 'user',
                fixed: 'left',
                dataIndex: 'user',
                className: this.props.isSmartphone ? "__min-width-120" : "__min-width-220",
                render: (user: UserShort) => {
                    return (
                        <span>{this.props.isSmartphone ? `${user.lastName.at(0)}.` : user.lastName} {user.firstName}</span>
                    );
                }
            }
        ];

        for (let i = 0; i < 12; i++) {
            const actualMonth = moment().month(i);
            parsedCollumns.push(
                {
                    title: actualMonth.format("MMM"),
                    key: `${actualMonth.format("MMM")}Hours`,
                    editable: true,
                    disabled: (userId: number) => this.state.lockedMonth ? this.state.lockedMonth[i].includes(userId) : false,
                    // disabled: (userId: number) => this.state.lockedMonth ? this.state.lockedMonth[i].includes(userId) : false,
                    //TODO: Check if month is disabled
                    // disabled: (userId: number) => this.state.lockedDaysByUsersByMonth ? this.state.lockedDaysByUsersByMonth.every(day => day.includes(userId)) : false,
                    className: "__centered-text __width_90",
                    dataIndex: ['selectedPaidHoursSummary', `ph${actualMonth.format("M")}`, 'hours'],
                    render: (x, record) => {
                        if ((record as PaidHoursSummary).contractsPaidHoursSummary == null || (record as PaidHoursSummary).contractsPaidHoursSummary.length === 0) {
                            return null;
                        } else {
                            return <p>{x ?? 0}</p>;
                        }
                    }
                }
            );
        }

        parsedCollumns.push({
            title: <FormattedMessage defaultMessage={'Available contracts'} />,
            key: 'users_contracts',
            dataIndex: 'contractsPaidHoursSummary',
            className: "__width_350",
            render: (contractsPaidHoursSummary: ContractPaidHoursSummary[], record) => {
                const { Option } = Select;
                if (contractsPaidHoursSummary.length === 0) {
                    return <span><FormattedMessage defaultMessage={'No contract'} /></span>;
                }

                return (
                    <Select
                        value={(record as PaidHoursSummary).selectedPaidHoursSummary?.id}
                        style={{ width: 'calc(100% - 20px)' }}
                        onChange={(contractId) => this.onChangeContract((record as PaidHoursSummary).user.id, contractId)}
                    >
                        {contractsPaidHoursSummary.map(contract => (
                            <Option key={`contract-${contract.id}`} value={contract.id!}>{contract.name ? contract.name + ": " + contract.date_in_report + " > " + contract.contract_expiry_date : <FormattedMessage defaultMessage={'Unamed contract'} />}</Option>
                        ))}
                    </Select>
                );
            }
        });
        return parsedCollumns;
    };

    render() {
        const { isLoading, paidHoursSummary } = this.state;
        let tableHeight = this.props.height - 133;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <Spin spinning={isLoading} size="large" indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                {
                    paidHoursSummary && paidHoursSummary.length > 0 ?
                        <EditableTable
                            className="__basic-table"
                            year={this.state.year.year()}
                            dataSource={paidHoursSummary}
                            columns={this.columns()}
                            loading={isLoading}
                            pagination={false}
                            rowKey={s => `report-hours-summary-${(s as PaidHoursSummary).user.id}`}
                            scroll={{ x: true, y: tableHeight }}
                        />
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Spin>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    users: state.teamManagement.users,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(PaidHours));