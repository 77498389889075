import React from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import ResponsiveImage from '../ResponsiveImage/responsiveImage';
import './checkMobile.css';
import NoMobilePng from './no-mobile.png';
import NoMobileWebp from './no-mobile.webp';


interface CheckMobileProps {
    hideOnMobile?: boolean;
    minWidth?: undefined;
    children: React.ReactElement;
}

const CheckMobile: React.FC<CheckMobileProps> = ({ children, hideOnMobile, minWidth }) => {
    const windowWidth = useSelector((state: ApplicationState) => state.window.width);
    const intl = useIntl();

    return (
        hideOnMobile !== undefined ?
            hideOnMobile && isMobile ?
                <div className='no-mobile-container'>
                    <ResponsiveImage
                        className='check-mobile'
                        sources={[{ src: NoMobileWebp }]}
                        fallbackSrc={NoMobilePng}
                        alt={intl.formatMessage({ defaultMessage: "Unavailable on mobile" })}
                    />
                    <FormattedMessage defaultMessage={"Unavailable on mobile"} />
                </div>
                : children
            : minWidth !== undefined ?
                minWidth < windowWidth ?
                    <div className='no-mobile-container'>
                        <ResponsiveImage
                            className='check-mobile'
                            sources={[{ src: NoMobileWebp }]}
                            fallbackSrc={NoMobilePng}
                            alt={intl.formatMessage({ defaultMessage: "Unavailable on mobile" })}
                        />
                        <FormattedMessage defaultMessage={"Unavailable on mobile"} />
                    </div>
                    : children
                : children
    );
};

export default CheckMobile;
