import { forwardRef, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { IconPacks } from "../../../utils/fontawesome/IconPacks";
import { IWorkItemBare } from "../../../utils/types/productivityTypes";
import FontAwesomePicker from "../../common/fields/FontAwesomePicker/FontAwesomePicker";
import InputField from "../../common/fields/inputField";

interface Props { }

export interface WorkItemCreateRef {
    create: () => void;
    edit: (workItem: IWorkItemBare) => void;
    workItem: IWorkItemBare;
}

const workItemCreate = forwardRef((props: Props, ref) => {
    const [id, setId] = useState<number>(-1);
    const [icon, setIcon] = useState<string>("fas bed");
    const [title, setTitle] = useState<string>();
    const intl = useIntl();

    useImperativeHandle(ref, (): WorkItemCreateRef => ({
        create: () => {
            setId(-1);
            setIcon("fas bed");
            setTitle(undefined);
        },
        edit: (workItem: IWorkItemBare) => {
            console.log("Setting work item to edit", workItem);
            setId(workItem.id);
            setIcon(workItem.icon);
            setTitle(workItem.title);
        },
        workItem: {
            id,
            icon,
            title: title ?? ""
        }
    }), [id, icon, title]);

    return (<div className="__wic-work-item-create-form">
        <FontAwesomePicker pack={IconPacks.Productivity} selectedIcon={icon} setSelectedIcon={(i) => setIcon(i)} />
        <InputField placeholder={intl.formatMessage({ defaultMessage: "Title" })} value={title} onChange={(e) => setTitle(e.target.value)} />
    </div>
    );
});

workItemCreate.displayName = "WorkItemCreate";

export default workItemCreate;