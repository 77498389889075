import type { IMandate } from "./customerTypes";
import { Dictionary, DictionaryNumber } from "./generalTypes";
import { Loaded } from "./networkTypes";
import type { Project } from "./planningTypes";

export interface IProductivitySlice {
    enabled: boolean;
    workItems: Loaded<IWorkItem[]>;
}

export interface IWorkItem {
    id: number;
    title: string;
    icon: string;
    typeOfDayIds: number[];
}

export type IWorkItemBare = Omit<IWorkItem, 'typeOfDayIds'>;

export interface IWorkItemConfig {
    id: number;
    workItemId: number;
    secondsPerItem: number;
    typeOfDayId: number;
    projectId: Project['id'] | null;
    mandateId: IMandate['id'] | null;
}

export type IProductivityReports = {
    reports: DictionaryNumber<IUserProductivityReport>; // Key is the user id
    total: ITotalProductivityReport;
};

export type MultiUserReportOverTime<T> = Dictionary<Dictionary<T>>; // Keys are dates, within dates are user ids, and the values are the reports.

export interface IUserProductivityReport {
    userId: number;
    avgProductivity: number; // Average productivity percentage for the entire report's range.
    workTimeSeconds: number; // Total seconds worked in the report's range.
    theoreticalWorkTimeSeconds: number; // Seconds it should've taken to complete all work items done in the report's range.
    workItems: Dictionary<number>; // Total work items done by the user (key=workItemId, value=amount)
}

export interface ITotalProductivityReport {
    avgProductivity: number; // Average productivity percentage for the entire report's range.
    workTimeSeconds: number; // Total seconds worked in the report's range.
    theoreticalWorkTimeSeconds: number; // Seconds it should've taken to complete all work items done in the report's range.
}

export interface IWorkControlWorkRecord {
    id: number,
    amount: number,
    workItemId: number,
    eventId?: number;
}


export interface IWorkControlUser {
    id: number;
    firstName: string;
    lastName: string;
    avatar?: string;
}

export interface IWorkControlSmallEvent {
    id: number,
    title: string,
    startDate: string,
    endDate: string,
    user: IWorkControlUser;
    typeOfDayId: number,
    color?: IWorkControlEventColor;
}

export interface IWorkControlEvent extends IWorkControlEventEdit {
    title: string,
    startDate: string,
    endDate: string,
    user: IWorkControlUser;
    typeOfDayId: number,
    color?: IWorkControlEventColor;
}

export interface IWorkControlEventEdit {
    id: number,
    workRecords?: IWorkControlWorkRecord[];
}

export interface IWorkControlEventColor {
    id: number;
    title: string;
    color: string;
}

export interface IWorkControlEventTypeOfDay {
    id: number;
    title: string;
}

export interface WorkItemRecordPayload {
    id: number;
    amount: number;
}

export const enum WorkControlActions {
    ACCEPT = "accept",
    REFUSE = "refuse"
}

export const enum ActionWorkControlEventsErrors {
    NON_EDITABLE_EVENT_IDS = "non_editable_event_ids",
    WRONG_STATUS_EVENT_IDS = "wrong_status_event_ids",
    NON_EXISTING_EVENT_IDS = "non_existing_event_ids"
}
