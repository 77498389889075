import { Button, Modal, Popconfirm, Spin, Table } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { changeTypesOfDayOff } from '../../../../store/actions/configurations';
import { changeSelectGroups } from '../../../../store/actions/planning';
import { changeGroups, changeUsers } from '../../../../store/actions/teamManagement';
import { GREEN_COLOR, MOMENT_CCNT_DAY_FORMAT, RED_COLOR } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { Group, IFilterUsers, User } from '../../../../utils/types/generalTypes';
import { GeneralResponse } from '../../../../utils/types/networkTypes';
import { TypeOfDay } from '../../../../utils/types/planningTypes';
import { BalanceYearType, UserEffectiveHours } from '../../../../utils/types/reportTypes';
import { BalanceYearRequestBody } from '../../../../utils/types/requestBodyTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { convertNetworkUsersEffectiveHoursToUsersEffectiveHours, filterUsers, isNullOrEmpty, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import ActionsToolbar from '../../../common/fields/ActionsToolbar/actionsToolbar';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import EmptyData from '../../../common/general/emptyData';
import FullUser from '../../../common/general/fullUser';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import Filters from '../../../planningPerf/tabs/common/filters';
import { ColumnTypes, EditableCell, EditableRow } from './editableTableBalanceYear';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, ContainerTabsItemProps {
}

type ColumnTypesEditable = (ColumnTypes[number] & { editable?: boolean; dataIndex?: string | string[]; })[];

interface State {
    balanceYearData?: BalanceYearType[];
    updated?: Moment;
    columns?: ColumnTypesEditable;
    loading: boolean;
    year: Moment;
    selectedUsers: number[];
    openSummary: boolean;
    filters: IFilterUsers;
}

class BalanceYear extends ContainerTabsItem<Props, State> {
    carouselRef = React.createRef<CarouselRef>();
    components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            year: moment().subtract(1, "year"),
            columns: this.columns(),
            selectedUsers: [],
            openSummary: false,
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        // get types of day
        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff!(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "error")
        );
        this.loadData(this.state.year);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: State): void {
        if (
            !prevState.year.isSame(this.state.year, "month") ||
            prevState.loading !== this.state.loading
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }
        if (!isEqual(prevProps.typesOfDayOff, this.props.typesOfDayOff)) {
            this.setState({ columns: this.columns() });
        }
        if (!isEqual(prevProps.users, this.props.users)) {
            this.loadData(this.state.year);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { loading, year } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.YEAR}
                    loadingData={loading}
                    controlled={{
                        valueFrom: year,
                        onChange: this.changeYear,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.loadData(year)}
                    loading={loading} />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    resetFilters = () => this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });

    handleSave = (row: BalanceYearType) => {
        const { balanceYearData } = this.state;
        const newData = balanceYearData ? [...balanceYearData] : [];
        const index = newData.findIndex(item => row.user.id === item.user.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState(prevState => ({ balanceYearData: newData, selectedUsers: [...prevState.selectedUsers, row.user.id] }));
    };

    columns = () => {
        let parsedCollumns: ColumnTypesEditable = [
            {
                title: <FormattedMessage defaultMessage={'User'} />,
                key: 'user',
                className: "__min-width-220",
                fixed: true,
                sorter: (a: any, b: any) => a.last_name.localeCompare(b.last_name),
                render: (data) => <FullUser user={data.user} withAvatar />
            },
            {
                title: <FormattedMessage defaultMessage={'Contract'} />,
                key: 'contract',
                className: "__width_200",
                // sorter: (a: any, b: any) => (a.startDate as Moment).diff(b.startDate),
                render: (_, record: any) => {
                    const balanceYearType: BalanceYearType = record;
                    return (
                        <div style={{ display: 'flex', gap: '0px', flexDirection: 'column', fontSize: '0.9rem' }}>
                            <span>{balanceYearType.workRate}% ({balanceYearType.weeklyWorkingHours}h)</span>
                            <span>{balanceYearType.startDate.format(MOMENT_CCNT_DAY_FORMAT)} - {balanceYearType.endDate.format(MOMENT_CCNT_DAY_FORMAT)}</span>
                        </div>
                    );
                }
            },
        ];

        this.props.typesOfDayOff.forEach(dayOff => {
            parsedCollumns.push(
                {
                    title: dayOff.title,
                    key: `day-off-${dayOff.id}`,
                    className: "__centered-text __min-width-140",
                    editable: true,
                    dataIndex: ['daysOff', `dayOff-${dayOff.id}`, 'reportHours'],
                    sorter: (a: any, b: any) => {
                        const aa = a.daysOff[`dayOff-${dayOff.id}`] ?? 0;
                        const bb = b.daysOff[`dayOff-${dayOff.id}`] ?? 0;
                        return aa - bb;
                    },
                    render: (x: number, record: any) => {
                        const balanceYearType: BalanceYearType = record;
                        const defaultValue = balanceYearType.daysOff[`dayOff-${dayOff.id}`]?.defaultHours ?? 0;
                        const balanceHours = balanceYearType.daysOff[`dayOff-${dayOff.id}`]?.balanceHours ?? 0;
                        return <div title={`${this.props.intl.formatMessage({ defaultMessage: 'Default value: {defaultValue}' }, { defaultValue })}\n${this.props.intl.formatMessage({ defaultMessage: 'Year balance: {balance}' }, { balance: balanceHours })}\n${this.props.intl.formatMessage({ defaultMessage: 'To report: {report}' }, { report: x })}`} style={x ? { backgroundColor: x > 0 ? GREEN_COLOR : x < 0 ? RED_COLOR : undefined, borderRadius: '18px', color: 'white' } : undefined}>{x}</div>;
                    }
                }
            );
        });
        parsedCollumns.push(
            {
                title: <FormattedMessage defaultMessage={'Hours'} />,
                key: `balanceHours`,
                className: "__centered-text __width_150",
                editable: true,
                dataIndex: 'balanceHours',
                fixed: 'right',
                sorter: (a: any, b: any) => a.balanceHours - b.balanceHours,
                render: (x: number) => {
                    const value = x ?? 0;
                    return <div style={value ? { backgroundColor: value > 0 ? GREEN_COLOR : RED_COLOR, borderRadius: '18px', color: 'white' } : undefined}>{value}</div>;

                }
            }
        );
        const handleSave = this.handleSave;
        parsedCollumns = parsedCollumns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title?.toString(),
                    handleSave,
                }),
            };
        });
        return parsedCollumns;
    };

    loadData = (year: Moment) => {
        let usersId: number[] | undefined = this.props.users.filter(u => u.report).map(u => u.id);
        if (usersId.length === 0) {
            usersId = undefined;
            return;
        }
        this.setState({ loading: true });
        Network.getEffectiveHoursByUser(year.clone().startOf("year"), year.clone().endOf("year"), usersId).then(
            (response: GeneralResponse) => {
                this.parseData(convertNetworkUsersEffectiveHoursToUsersEffectiveHours(response.data.balanceData));
            },
            () => {
                this.setState({ loading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the datas' }), "error");
            }
        );
    };

    parseData = (data: UserEffectiveHours[]) => {
        const balanceYearData: BalanceYearType[] = [];
        let balanceYear: BalanceYearType;
        let user: User | undefined;
        data.forEach(d => {
            user = this.props.users.find(u => u.id === d.userId);
            if (user) {
                balanceYear = {
                    ...d,
                    user: {
                        id: user.id,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        availabilityClosedByDefault: false
                    },
                    daysOff: {}
                };
                d.calculatedDaysOff?.effectiveTimeByDaysOff?.forEach(dayoff => {
                    if (dayoff.balanceHours) {
                        balanceYear.daysOff[`dayOff-${dayoff.id}`] = { ...dayoff };
                    }
                });

                balanceYearData.push(balanceYear);
            }
        });

        this.setState({ balanceYearData, loading: false, updated: moment() });
    };

    changeYear = (year: Moment | null) => {
        if (year) {
            this.loadData(year);
            this.setState({ year });
        }
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    saveBalanceYear = () => {
        const { selectedUsers, balanceYearData, year } = this.state;
        const requestBody: BalanceYearRequestBody[] = [];
        if (balanceYearData && balanceYearData.length > 0) {
            balanceYearData.filter(b => selectedUsers.includes(b.user.id)).forEach(b => {
                const tmp: BalanceYearRequestBody = {
                    userId: b.user.id,
                    balanceHours: b.balanceHours,
                    daysOff: []
                };
                requestBody.push(tmp);
                Object.keys(b.daysOff).filter(key => !(key.includes('-default') || key.includes('-init'))).forEach(key => {
                    const match = key.match(/-?(\d+)/);
                    if (match) {
                        const extractedNumber = parseInt(match[1], 10);
                        const dayOff = this.props.typesOfDayOff.find(off => off.id === extractedNumber);
                        if (dayOff && dayOff.id) {
                            tmp.daysOff.push({
                                dayOffId: dayOff.id,
                                balanceDays: b.daysOff[key]?.reportHours ?? 0
                            });
                        }
                    }
                    return null;
                });
            });

            Network.saveBalanceYear(requestBody, year).then(
                (response: GeneralResponse) => {
                    if (response.error) {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the balance' }), "error");
                    } else {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The balance has been successfully updated' }), "success");
                        this.setState({ openSummary: false });
                    }
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the balance' }), "error");
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'No user selected' }), "error");
            this.setState({ openSummary: false });
        }
    };

    render() {
        const { loading, year, balanceYearData, columns, selectedUsers, openSummary, filters } = this.state;
        const { users, groups, intl } = this.props;
        let tableHeight = this.props.height - 170;
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);
        const filteredBalanceYearData = balanceYearData?.filter(d => filteredUsers?.some(u => u.id === d.user.id));

        return (
            <>
                <Spin spinning={loading} size="large" indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                    {
                        isNullOrEmpty(filteredBalanceYearData) ?
                            <EmptyData />
                            :
                            <div>
                                <Table
                                    components={this.components}
                                    dataSource={filteredBalanceYearData}
                                    columns={columns}
                                    rowKey={(p: any) => p.user.id}
                                    rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                                    pagination={false}
                                    scroll={{ x: true, y: tableHeight }}
                                />
                                <ActionsToolbar
                                    title={`${selectedUsers.length.toString()} ${intl.formatMessage({ defaultMessage: "Users" })}`}
                                    close={() => this.setState({ selectedUsers: [] })}
                                    open={selectedUsers.length > 0}
                                    actions={
                                        <CircleButton
                                            small
                                            placement='topRight'
                                            key={`balanceYear-header-actons-save`}
                                            title={this.props.intl.formatMessage({ defaultMessage: 'Save balance' })}
                                            icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                            onClick={() => this.setState({ openSummary: true })}
                                            disabled={loading || selectedUsers.length === 0} />
                                    }
                                />
                            </div>
                    }

                </Spin>

                <Modal
                    width="400px"
                    bodyStyle={{ maxHeight: 'calc(100vh - 240px)', overflow: 'auto' }}
                    title={<FormattedMessage defaultMessage={'Balance overview'} />}
                    open={openSummary}
                    onCancel={() => this.setState({ openSummary: false })}
                    destroyOnClose={true}
                    footer={[
                        <Button type="dashed" onClick={() => this.setState({ openSummary: false })} key="modal-cancel-button">
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Popconfirm
                            title={<>
                                <p><FormattedMessage defaultMessage={'The initial values of the hours and vacations of the users here present will be irreversibly modified for the year {year}.'} values={{ year: year.clone().add(1, "year").format(getFormat('YEAR')) }} /></p>
                                <p><FormattedMessage defaultMessage={'Do you want to continue?'} /></p>
                            </>}
                            key="modal-button-ok"
                            onConfirm={this.saveBalanceYear}
                            icon={<FAIcon prefix='fad' name='question' style={{ color: RED_COLOR }} />}
                            okText={<FormattedMessage defaultMessage={'Yes'} />}
                            cancelText={<FormattedMessage defaultMessage={'No'} />}>
                            <Button type="primary">
                                <FormattedMessage defaultMessage={'Save'} />
                            </Button>
                        </Popconfirm>
                    ]}>
                    {
                        balanceYearData?.filter(b => selectedUsers.includes(b.user.id)).map(b => {
                            return (
                                <div className='__balanceYearSummaryContent' key={`balanceYear-summary-user-${b.user.id}`}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <b><FormattedMessage defaultMessage={'Name'} />{':'} </b><p>{b.user.lastName} {b.user.firstName}</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <b><FormattedMessage defaultMessage={'Hours'} />{':'} </b><p>{b.balanceHours}</p>
                                    </div>
                                    {
                                        Object.keys(b.daysOff).filter(key => !(key.includes('-default') || key.includes('-init'))).map(key => {

                                            const match = key.match(/-?(\d+)/);
                                            if (match) {
                                                const extractedNumber = parseInt(match[1], 10);
                                                const dayOff = this.props.typesOfDayOff.find(off => off.id === extractedNumber);
                                                if (dayOff && dayOff.id) {
                                                    return (
                                                        <div key={`balanceYear-summary-user-${b.user.id}-dayoff-${key}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <b>{dayOff.title}:</b><p>{b.daysOff[key]?.reportHours ?? 0}</p>
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeSelectGroups: (sg?: number[]) => dispatch(changeSelectGroups(sg)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    users: state.teamManagement.users,
    typesOfDayOff: state.configurations.typesOfDayOff,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
    selectGroups: state.planning.selectGroups,
    groups: state.teamManagement.groups,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(BalanceYear));