import { Col, DatePicker, Empty, InputNumber, List, notification, Popconfirm, Row, Select, Spin, Tooltip } from 'antd';
import toFinite from 'lodash/toFinite';
import toInteger from 'lodash/toInteger';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { loadDepartments, loadTypesOfContract } from '../../store/actions/configurations';
import { changeUser } from '../../store/actions/teamManagement';
import { GOOGLE_STORAGE_URL } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { DepartmentWithUpdate, TypeOfContractWithUpdate, User, UserJobTMP, UserYearlyParams } from '../../utils/types/generalTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch, TeamManagementDispatchProps } from '../../utils/types/storeTypes';
import { alert, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import FileUploader from '../common/fields/fileUploader';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Card from '../common/general/card';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

interface IProps {
    user: User | undefined;
    typesOfContract: TypeOfContractWithUpdate;
    departments: DepartmentWithUpdate;
}

type Props = IProps & TeamManagementDispatchProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    year: number;
    loading: boolean;
    currentUserJob?: UserJobTMP;
    newUserJob?: UserJobTMP;
    newUserYearlyParams?: UserYearlyParams;
    isContractDateError: boolean;
    contractsError?: UserJobTMP[];
}

/**
 * Component that represent the user vacations tab
 */
class UserJobTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            loading: false,
            currentUserJob: undefined,
            newUserJob: undefined,
            newUserYearlyParams: undefined,
            isContractDateError: false,
            contractsError: undefined,
        };
    }

    componentDidMount() {
        let { currentUserJob } = this.state;

        this.props.loadTypesOfContract!();
        this.props.loadDepartments!();

        this.props.user?.job?.forEach((e: UserJobTMP) => {
            if (moment().isBetween(moment(e.date_in_report), moment(e.contract_expiry_date))) currentUserJob = e;
            // if (e.is_current === true) currentUserJob = e;
        });

        this.setState({ currentUserJob });
    }

    /**
     * Translate user payload information to CreateUserBodyRequest type values
     */
    onStartEditContract = (): void => {
        const tmp = { ...this.state.currentUserJob };
        this.setState({ newUserJob: tmp });
    };

    /**
     * Translate user payload information to CreateUserBodyRequest type values
     */
    cancelEdition = (): void => {
        this.setState({ newUserJob: undefined, isContractDateError: false, contractsError: undefined, currentUserJob: undefined });
    };

    cancelEditionYearlyParams = () => {
        this.setState({ newUserYearlyParams: undefined });
    };

    checkContractDates = () => {
        const { newUserJob } = this.state;
        let contractDateConflict: (UserJobTMP[] | undefined) = undefined;

        if (newUserJob !== undefined && newUserJob?.date_in_report && newUserJob?.contract_expiry_date && this.props.user && this.props.user.job && this.props.user.job.length > 0) {
            const newUserJobStartDate: Moment = moment(newUserJob.date_in_report);
            const newUserJobEndDate: Moment = moment(newUserJob.contract_expiry_date);

            contractDateConflict = this.props.user.job.filter(job => {
                return (
                    (job.date_in_report && job.contract_expiry_date && job.id !== newUserJob.id && job.department?.id === newUserJob.department?.id) &&
                    (
                        newUserJobStartDate.isBetween(job.date_in_report, job.contract_expiry_date, "days", "[]") ||
                        newUserJobEndDate.isBetween(job.date_in_report, job.contract_expiry_date, "days", "[]") ||
                        moment(job.date_in_report).isBetween(newUserJobStartDate, newUserJobEndDate, "days", "[]") ||
                        moment(job.contract_expiry_date).isBetween(newUserJobStartDate, newUserJobEndDate, "days", "[]")
                    )
                );
            });
            if (contractDateConflict.length > 0) {
                return [...new Set(contractDateConflict)];
            } else {
                return undefined;
            }

        } else {
            return contractDateConflict;
        }

    };

    openNotification = (title: string, description: string, type: string) => {
        switch (type) {
            case 'success':
                notification.success({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
            case 'error':
                notification.error({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
            case 'info':
                notification.info({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
            case 'warn':
                notification.warn({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
            case 'warning':
                notification.warning({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
            default:
                notification.info({
                    message: title,
                    description: description,
                    placement: 'bottomRight',
                });
                break;
        }
    };

    handleOnSubmit = () => {
        const { intl } = this.props;
        this.setState({ loading: true });
        let atLeastOneError = false;
        if (this.state.newUserJob === undefined || this.state.newUserJob.date_in_report === null || this.state.newUserJob.date_in_report === undefined) {
            atLeastOneError = true;
            this.openNotification(intl.formatMessage({ defaultMessage: 'Missing date' }), intl.formatMessage({ defaultMessage: 'Please enter the contract start date' }), "warn");
        }
        if (this.state.newUserJob === undefined || this.state.newUserJob.contract_expiry_date === null || this.state.newUserJob.contract_expiry_date === undefined) {
            atLeastOneError = true;
            this.openNotification(intl.formatMessage({ defaultMessage: 'Missing date' }), intl.formatMessage({ defaultMessage: 'Please enter the contract end date' }), "warn");
        }
        if (this.state.newUserJob === undefined || this.state.newUserJob.name === null || this.state.newUserJob.name === undefined) {
            atLeastOneError = true;
            this.openNotification(intl.formatMessage({ defaultMessage: 'Missing contract name' }), intl.formatMessage({ defaultMessage: 'Please enter the contract name' }), "warn");
        }


        if (atLeastOneError === true) {
            this.setState({ loading: false });
            return;
        }

        const contractDateConflit = this.checkContractDates();
        if (contractDateConflit && contractDateConflit.length > 0) {
            alert(intl.formatMessage({ defaultMessage: 'Contract start date in conflict with contract: {name}' }, { name: contractDateConflit[0].name }), "warning");
            this.setState({ loading: false });
        } else {
            this.state.newUserJob && Network.updateUserContract(this.state.newUserJob).then(
                () => {
                    this.setState({ loading: false });
                    this.openNotification(intl.formatMessage({ defaultMessage: 'Contract added' }), intl.formatMessage({ defaultMessage: 'The contract {name} has been successfully added' }, { name: this.state.newUserJob?.name }), "success");
                    this.cancelEdition();
                    this.refresh();
                },
                () => {
                    this.setState({ loading: false });
                    alert(intl.formatMessage({ defaultMessage: 'An error occurred while updating the contract' }), "warning");
                },
            );
        }
    };

    /**
     * Translate user payload information to CreateUserBodyRequest type values
     */
    cancelCreate = (): void => {
        this.setState({ newUserJob: undefined, isContractDateError: false, contractsError: undefined });
    };

    /**
     * Start creating a new contract
     */
    onStartCreateContract = () => {
        const emptyUserJob: UserJobTMP = {
            user_id: this.props.user?.id,
        };
        this.setState({ newUserJob: emptyUserJob });
    };

    /**
     * Called when an InputField changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeContractName = (event: InputFieldOnChangeEvent): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.name = event.target.value);
        this.setState({ newUserJob });
    };

    onChangeContractType = (value: number): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.typeOfContract = this.props.typesOfContract.data.find(typeOfContract => typeOfContract.id === value));

        // newUserJob && (newUserJob.type_of_contract?.id? = event.target.value);
        this.setState({ newUserJob });
    };

    onChangeDepartment = (value: number): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.department = this.props.departments.data.find(department => department.id === value));

        // newUserJob && (newUserJob.type_of_contract?.id? = event.target.value);
        this.setState({ newUserJob, isContractDateError: false, contractsError: undefined }, () => {
            const contractDateConflict = this.checkContractDates();
            if (contractDateConflict && contractDateConflict.length > 0) {
                this.setState({ isContractDateError: true, contractsError: contractDateConflict });
            }
        });
    };

    /**
     * Called when an InputField changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeContractNotes = (event: InputFieldOnChangeEvent): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.notes = event.target.value);
        this.setState({ newUserJob });
    };


    /**
     * Called when an InputNumber changed
     * @param number the number
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeWorkRate = (number: number | undefined): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.work_rate = number ? number : undefined);
        this.setState({ newUserJob });
    };

    /**
     * Called when an InputNumber changed
     * @param number the number
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeWeeklyHours = (number: number | undefined): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.weekly_working_hours = number ? number : undefined);
        this.setState({ newUserJob });
    };

    /**
     * Called when an InputNumber changed
     * @param number the number
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeYearlyVacations = (number: number | undefined): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.yearly_vacation_days = number ? number : undefined);
        this.setState({ newUserJob });
    };

    onChangeVacationIncrease = (number: number | null) => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.vacationIncreasedPercentByHour = (number ? number : undefined));
        this.setState({ newUserJob });
    };

    /**
     * Called when a DatePicker changed
     * @param date the selected date
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeContractStartDate = (date: Moment): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.date_in_report = date ? date.format("YYYY-MM-DD") : undefined);
        this.setState({ newUserJob, isContractDateError: false, contractsError: undefined }, () => {
            const contractDateConflict = this.checkContractDates();
            if (contractDateConflict && contractDateConflict.length > 0) {
                this.setState({ isContractDateError: true, contractsError: contractDateConflict });
            }
        });
    };

    /**
     * Called when a DatePicker changed
     * @param date the selected date
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeContractEndDate = (date: Moment): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.contract_expiry_date = date ? date.format("YYYY-MM-DD") : undefined);
        this.setState({ newUserJob, isContractDateError: false, contractsError: undefined }, () => {
            const contractDateConflict = this.checkContractDates();
            if (contractDateConflict && contractDateConflict.length > 0) {
                this.setState({ isContractDateError: true, contractsError: contractDateConflict });
            }
        });
    };

    /**
     * Called when the file was uploaded
     * @param file the file
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onFileUploaded = (file: File): void => {
        const { newUserJob } = this.state;
        newUserJob && (newUserJob.contract = file);
        this.setState({ newUserJob });
    };

    /**
     * Refresh user details
     * @param message a message to display as a success message - optional
     */
    refresh = (): void => {
        //get current user's details
        Network.getUser(this.props.user!.id).then(
            response => {
                this.props.changeUser!({ ...response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading, please reload the page' }), "error")
        );
    };

    /**
 * Change a type of day title
 * @param event the triggered event
 */
    onChangeNumberOfHours = (value: number) => {
        const { newUserYearlyParams } = this.state;
        newUserYearlyParams && (newUserYearlyParams.initial_overtime_hours = value ? value : undefined);
        this.setState({ newUserYearlyParams });
    };

    renderEditionYearlyParams() {
        return (
            <div className="team-create-user-body">
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Initial hours'} /></p>
                    <InputNumber
                        style={{ marginLeft: '10px' }}
                        placeholder={this.props.intl.formatMessage({ defaultMessage: 'N° hours' })}
                        value={this.state.newUserYearlyParams?.initial_overtime_hours}
                        onChange={(e) => this.onChangeNumberOfHours(toFinite(e))} />
                </span>
            </div>
        );
    }

    renderNormalYearlyParams(c: UserYearlyParams) {
        return (
            <div className="team-create-user-body">
                <span className="team-edit-user-item">
                    <FAIcon prefix='fad' name='clock' />
                    <p><FormattedMessage defaultMessage={'{count, plural, one {Start the year with {count} hour} other {Start the year with {count} hours}}'} values={{ count: c.initial_overtime_hours }} /></p>
                </span>
            </div>
        );
    }

    renderEdition(c: UserJobTMP) {
        const { intl } = this.props;
        return (
            <div className="team-create-user-body">
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Contract name'} />{'*'}</p>
                    <InputField value={c.name} placeholder={intl.formatMessage({ defaultMessage: 'Contract name' })} className="team-create-user-inputs"
                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeContractName(e)}
                    />
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Work rate (%)'} /></p>
                    <InputNumber
                        className="team-create-user-inputs-job"
                        min={0}
                        max={100}
                        placeholder={"Taux de travail"}
                        value={c.work_rate}
                        onChange={n => this.onChangeWorkRate(toInteger(n))}
                    />
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Weekly working hours'} /></p>
                    <span className="flex-row">
                        {
                            c.work_rate && c.weekly_working_hours && c.work_rate !== 100 ?
                                <p style={{ marginRight: '10px' }}>{`(${(c.weekly_working_hours * c.work_rate * 0.01).toFixed(2)} h.)`}</p>
                                : null
                        }
                        <InputNumber
                            className="team-create-user-inputs-job"
                            min={0}
                            placeholder={intl.formatMessage({ defaultMessage: 'Weekly working hours' })}
                            value={c.weekly_working_hours}
                            onChange={n => this.onChangeWeeklyHours(toFinite(n))}
                        />
                    </span>
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Vacation bonus (%)'} /></p>
                    <span className="flex-row">
                        <InputNumber
                            className="team-create-user-inputs-job"
                            placeholder={intl.formatMessage({ defaultMessage: 'Vacation bonus' })}
                            value={c.vacationIncreasedPercentByHour}
                            onChange={n => this.onChangeVacationIncrease(n)}
                        />
                    </span>
                </span>
                {
                    this.props.departments.updated && this.props.departments.data.length > 0 ?
                        <span className="team-create-user-group">
                            <p><FormattedMessage defaultMessage={'Department'} /></p>
                            <span className="flex-row">
                                <Select
                                    className="team-create-user-inputs"
                                    showSearch
                                    allowClear
                                    onChange={(e: number) => this.onChangeDepartment(e)}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Department' })}
                                    value={c.department?.id}
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                </Select>
                            </span>
                        </span>
                        : null
                }

                {/* <span className="team-create-user-group">
                    <p>{"Jours de vacances annuels"}</p>
                    <span className="flex-row">
                        <InputNumber
                            className="team-create-user-inputs-job"
                            placeholder={"Jours de vacances annuels"}
                            value={c.yearly_vacation_days}
                            onChange={n => this.onChangeYearlyVacations(toFinite(n))}
                        />
                    </span>
                </span> */}
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Contract'} /></p>
                    <FileUploader
                        className="team-create-user-inputs"
                        file={c.contract}
                        accept=".pdf"
                        onUploaded={(f: File) => this.onFileUploaded(f)}
                    />
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Type of contract'} /></p>
                    <Select
                        className="team-create-user-inputs"
                        showSearch
                        allowClear
                        onChange={(e: number) => this.onChangeContractType(e)}
                        placeholder={intl.formatMessage({ defaultMessage: 'Type of contract' })}
                        value={c.typeOfContract?.id}
                        filterOption={true}
                        optionFilterProp="label">
                        {this.props.typesOfContract.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                    </Select>
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Contract start date'} />
                        <Tooltip overlay={<FormattedMessage defaultMessage={'The report is generated from this date'} />} placement="right">
                            <FAIcon prefix='fad' name='info' style={{ marginLeft: '10px', cursor: 'pointer' }} />
                        </Tooltip></p>
                    <DatePicker
                        value={c.date_in_report ? moment(c.date_in_report) : undefined}
                        placeholder={getFormat('DATE')}
                        format={getFormat('DATE')}
                        style={this.state.isContractDateError ? { border: "1px solid red" } : {}}
                        className="team-create-user-inputs"
                        onChange={(m: any) => this.onChangeContractStartDate(m)}
                    />
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Contract expiration date'} /></p>
                    <DatePicker
                        value={c.contract_expiry_date ? moment(c.contract_expiry_date) : undefined}
                        placeholder={getFormat('DATE')}
                        format={getFormat('DATE')}
                        style={this.state.isContractDateError ? { border: "1px solid red" } : {}}
                        className="team-create-user-inputs"
                        onChange={(m: any) => this.onChangeContractEndDate(m,)}
                    />
                </span>
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Notes'} /></p>
                    <InputField
                        type="textarea"
                        autoSize={{ minRows: 2 }}
                        value={c.notes}
                        placeholder="Notes"
                        className="team-create-user-inputs"
                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeContractNotes(e)}
                    />
                </span>
            </div>
        );
    }

    renderNormal(c: UserJobTMP) {
        return (
            <div className="team-create-user-body">
                {c.work_rate &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='percent' />
                        <p><FormattedMessage defaultMessage={'Working at {percent}%'} values={{ percent: c.work_rate }} /></p>
                    </span>}
                {c.typeOfContract &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='file-contract' />
                        <p><FormattedMessage defaultMessage={'Type of contract: {type}'} values={{ type: c.typeOfContract.name }} /></p>
                    </span>}
                {c.weekly_working_hours &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='business-time' />
                        {c.work_rate && c.work_rate < 100 ?
                            <p><FormattedMessage defaultMessage={'{count, plural, one {Working {count} hour a week} other {Working {count} hours a week}}'} values={{ count: parseFloat((c.weekly_working_hours * c.work_rate * 0.01).toFixed(2)) }} /></p>
                            :
                            <p><FormattedMessage defaultMessage={'{count, plural, one {Working {count} hour a week} other {Working {count} hours a week}}'} values={{ count: c.weekly_working_hours }} /></p>
                        }
                    </span>}
                {c.vacationIncreasedPercentByHour &&
                    <span className="team-edit-user-item">
                        <span className='anticon'><FAIcon prefix='fad' name='arrow-up-right' /></span>
                        <p><FormattedMessage defaultMessage={'{percent}% hourly bonus (vacations)'} values={{ percent: c.vacationIncreasedPercentByHour }} /></p>
                    </span>}
                {c.department &&
                    <span className="team-edit-user-item">
                        <span className='anticon'><FAIcon prefix='fad' name='code-branch' /></span>
                        <p><FormattedMessage defaultMessage={'Department-related contract {name}'} values={{ name: c.department.name }} /></p>
                    </span>}
                {c.overtime_hours !== undefined && c.overtime_hours !== null &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='circle-plus' />
                        <p><FormattedMessage defaultMessage={'{count, plural, one {Start the year with {count} hour} other {Start the year with {count} hours}}'} values={{ count: c.overtime_hours }} /></p>
                    </span>}
                {c.date_in_report &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='circle-play' />
                        <p>
                            <FormattedMessage defaultMessage={'Its contract starts on {start}'} values={{ start: moment(c.date_in_report).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')) }} />
                            <Tooltip overlay={<FormattedMessage defaultMessage={'The report is generated from this date'} />} placement="right">
                                <FAIcon prefix='fad' name='info' style={{ marginLeft: '10px', cursor: 'pointer' }} />
                            </Tooltip>
                        </p>
                    </span>}
                {c.contract_expiry_date &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='ban' />
                        <p><FormattedMessage defaultMessage={'Its contract expires on {start}'} values={{ start: moment(c.contract_expiry_date).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')) }} /></p>
                    </span>}
                {c.notes &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='clipboard' />
                        <p>{c.notes}</p>
                    </span>}
                {c.lastModifiedFrom && c.lastModifiedFrom !== 'sunk-admin' ?
                    <span className="team-edit-user-item gray">
                        <FAIcon name="grid-2-plus" prefix="far" />
                        <p><FormattedMessage defaultMessage={'Modified by {name}'} values={{ name: c.lastModifiedFrom }} /></p>
                    </span>
                    :
                    c.lastCreatedFrom !== undefined && c.lastCreatedFrom !== 'sunk-admin' ?
                        <span className="team-edit-user-item gray">
                            <FAIcon name="grid-2-plus" prefix="far" />
                            <p><FormattedMessage defaultMessage={'Created by {name}'} values={{ name: c.lastCreatedFrom }} /></p>
                        </span>
                        :
                        undefined
                }
                {c.contract &&
                    <span className="team-edit-user-item">
                        <FAIcon prefix='fad' name='download' />
                        <p>
                            <a href={GOOGLE_STORAGE_URL + c.contract}><FormattedMessage defaultMessage={'Download contract'} /></a>
                        </p>
                    </span>}
            </div>
        );
    }

    /**
     * Change the current year
     * @param date the moment date 
     */
    changeYear = (date: Moment | null | undefined) => {
        if (!date) return;
        const year = date.year();
        this.setState({ year });
    };

    deleteContract = (contract?: UserJobTMP) => {
        const { intl } = this.props;
        if (contract && contract.id) {
            Network.deleteUserContract(contract.id).then(
                () => {
                    this.openNotification(intl.formatMessage({ defaultMessage: 'Deletion of contract' }), intl.formatMessage({ defaultMessage: 'The contract {name} has been successfully deleted' }, { name: contract.name }), "success");
                    this.cancelEdition();
                    this.refresh();
                },
                () => this.openNotification(intl.formatMessage({ defaultMessage: 'Deletion of contract' }), intl.formatMessage({ defaultMessage: 'An error occurred while deleting the contract' }), "error"),
            );
        }
    };

    render() {
        const { intl } = this.props;
        const { currentUserJob, newUserJob, contractsError } = this.state;

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Contracts'} />} icon={<FAIcon prefix='fad' name='file-contract' />}
                        headerElements={[
                            !newUserJob ? <CircleButton small key={`on-start-create-contract`} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Create a contract' })} icon={<FAIcon prefix='far' name='plus' />} onClick={this.onStartCreateContract} />
                                : <CircleButton small key={`on-start-create-contract-disabled`} disabled style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Create a contract' })} icon={<FAIcon prefix='far' name='plus' />} onClick={this.onStartCreateContract} />
                        ]}>
                        {
                            this.props.user && this.props.user.job &&
                                this.props.user?.job.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No contract'} />} />
                                :
                                <List
                                    className="__user-contract-list"
                                    itemLayout="horizontal"
                                    dataSource={this.props.user?.job}
                                    renderItem={item => (
                                        <List.Item
                                            onClick={() => !newUserJob && this.setState({ currentUserJob: item })}
                                            className={"__user-contract-list-item" + (!newUserJob ? " __user-contract-list-item-cursor" : "") + ((!newUserJob || newUserJob.id) && currentUserJob === item ? " __user-contract-list-item-selected" : "")}>
                                            <List.Item.Meta title={item.name ? item.name + (item.department ? ` (${item.department.name})` : '') : <FormattedMessage defaultMessage={'Contract without name'} />} />
                                            <div>
                                                <span style={contractsError && contractsError?.filter(job => job === item) && contractsError.filter(job => job === item).length > 0 ? { border: '1px solid red', borderRadius: '30px', padding: '5px' } : {}}>{moment(item.date_in_report).format(getFormat('DATE'))} - {moment(item.contract_expiry_date).format(getFormat('DATE'))} {((item.lastModifiedFrom && item.lastModifiedFrom !== 'sunk-admin') || (item.lastCreatedFrom && item.lastCreatedFrom !== 'sunk-admin')) && <FAIcon name="grid-2-plus" prefix="far" style={{ color: '#838383' }} />}</span>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                        }
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} >
                    <Card
                        title={
                            !newUserJob ?
                                currentUserJob ?
                                    <FormattedMessage defaultMessage={'Contract data : {contract}'} values={{ contract: ((currentUserJob && currentUserJob.name) ? currentUserJob.name : intl.formatMessage({ defaultMessage: 'Unnamed' })) }} />
                                    :
                                    <FormattedMessage defaultMessage={'No data'} />
                                :
                                newUserJob && !newUserJob.id ?
                                    <FormattedMessage defaultMessage={'New contract'} />
                                    :
                                    <FormattedMessage defaultMessage={'Edit contract: {contact}'} values={{ contact: currentUserJob?.name }} />
                        }
                        icon={newUserJob ? <FAIcon prefix='fad' name='file-plus' /> : <FAIcon prefix='fad' name='file-lines' />}
                        headerElements={[
                            newUserJob && (this.state.loading ?
                                <CircleButton small key={`on-${newUserJob.id ? "edit" : "create"}-contract-disabled`} disabled title={newUserJob.id ? intl.formatMessage({ defaultMessage: 'Save' }) : intl.formatMessage({ defaultMessage: 'Create' })} icon={<FAIcon prefix='fas' name='spinner-third' />} />
                                : <CircleButton small key={`on-${newUserJob.id ? "edit" : "create"}-contract`} title={newUserJob.id ? intl.formatMessage({ defaultMessage: 'Save' }) : intl.formatMessage({ defaultMessage: 'Create' })} icon={<FAIcon prefix='fad' name='floppy-disk' />} onClick={() => this.handleOnSubmit()} />),
                            !newUserJob && currentUserJob && (
                                this.state.loading ?
                                    <CircleButton small key={`on-start-delete-contract-disabled`} disabled style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Delete contract' })} icon={<FAIcon prefix='far' name='trashcan' />} />
                                    :
                                    <Popconfirm
                                        key={`on-start-delete-contract-popover`}
                                        placement="bottomRight"
                                        title={<FormattedMessage defaultMessage={'Do you want to permanently delete the contract?'} />}
                                        onConfirm={() => this.deleteContract(currentUserJob)}
                                        okText={<FormattedMessage defaultMessage={'Yes'} />}
                                        cancelText={<FormattedMessage defaultMessage={'No'} />}
                                    ><CircleButton small key={`on-start-delete-contract`} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Delete contract' })} icon={<FAIcon prefix='far' name='trashcan' />} /></Popconfirm>
                            ),
                            newUserJob && (this.state.loading ?
                                <CircleButton small key={`on-cancel-contract-disabled`} disabled style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Cancel' })} icon={<FAIcon prefix='far' name='xmark' />} />
                                : <CircleButton small key={`on-cancel-contract`} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Cancel' })} icon={<FAIcon prefix='far' name='xmark' />} onClick={() => this.cancelEdition()} />
                            ),
                            !newUserJob && currentUserJob && <CircleButton small key={`on-start-edit-contract`} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Edit contract' })} onClick={() => this.onStartEditContract()} icon={<FAIcon prefix='fad' name='pencil' />} />,
                        ]}>
                        <Spin spinning={this.state.loading}>
                            {
                                newUserJob ?
                                    this.renderEdition(newUserJob)
                                    :
                                    currentUserJob ?
                                        this.renderNormal(currentUserJob)
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No contract selected'} />} />

                            }
                        </Spin>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
    loadTypesOfContract: (fr?: boolean) => dispatch(loadTypesOfContract(fr)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
});


const mapStateToProps = (state: ApplicationState) => ({
    user: state.teamManagement.user,
    typesOfContract: state.configurations.typesOfContract,
    departments: state.configurations.departments
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserJobTab));