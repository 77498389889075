import { Col, Modal, Row, Spin, Switch } from 'antd';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { changeReportSettings } from '../../../store/actions/planning';
import { CaseType } from '../../../utils/constants';
import Network from '../../../utils/network';
import { ReportSettings as ReportSettingsType } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { checkPrivilege, checkRBACRule, getCaseAndPlural, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
}

interface State {
    settings?: ReportSettingsType;
    loading: boolean;
}

/**
 * Component that represent the planning settings modal
 */
class ReportSettings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            settings: this.props.settings ? { ...this.props.settings } : undefined,
            loading: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.open && !prevProps.open && !this.props.settings) {
            this.loadSettings();
        }
        if (!isEqual(prevProps.settings, this.props.settings)) {
            this.setState({ settings: this.props.settings ? { ...this.props.settings } : undefined });
        }
    }

    loadSettings = () => {
        if (!this.props.settings) {
            this.setState({ loading: true })
            Network.getReportSettings()
                .then(
                    (response: ReportSettingsType) => {
                        this.props.changeReportSettings(response);
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "error");
                    }
                )
                .catch(() => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "error")
                })
                .finally(() => {
                    this.setState({ loading: false });
                })
        }
    }

    onOk = () => {
        const { settings } = this.state;
        if (settings) {
            Network.updateReportSettings(settings)
                .then(
                    (response: ReportSettingsType) => {
                        this.props.changeReportSettings(response);
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The settings have been successfully updated' }), "success")
                        this.props.onOk();
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "error");
                    }
                )
                .catch(() => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "error");
                })
                .finally(() => {
                    this.setState({ loading: false });
                })
        }
    }

    onCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { settings } = this.state;
        return (
            <Modal
                title={<FormattedMessage defaultMessage={'Settings'} />}
                open={this.props.open}
                onOk={this.onOk}
                onCancel={this.onCancel}
                destroyOnClose={true}
                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                okText={<FormattedMessage defaultMessage={'Save'} />}
                cancelButtonProps={{ type: 'dashed' }}
                width={350}>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }}>
                        {
                            settings ?
                                <>
                                    {
                                        !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && (this.props.currentUser?.company_detail?.ext === true ? false : true) && (checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) || checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) ?
                                            <div className='__space-between'>
                                                <span><FormattedMessage defaultMessage={'Show {projects}'} values={{ projects: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : this.props.intl.formatMessage({ defaultMessage: 'Projects' }) }} /></span>
                                                <Switch
                                                    checked={settings.showProject}
                                                    onChange={(value) => {
                                                        settings.showProject = value;
                                                        this.setState({ settings });

                                                    }}
                                                />
                                            </div>
                                            : null
                                    }
                                    <div className='__space-between'>
                                        <span><FormattedMessage defaultMessage={'Show distances (km)'} /></span>
                                        <Switch
                                            checked={settings.showDistance}
                                            onChange={(value) => {
                                                settings.showDistance = value;
                                                this.setState({ settings });
                                            }}
                                        />
                                    </div>
                                    <div className='__space-between'>
                                        <span><FormattedMessage defaultMessage={'Show days of week'} /></span>
                                        <Switch
                                            checked={settings.showDayOfWeek}
                                            onChange={(value) => {
                                                settings.showDayOfWeek = value;
                                                this.setState({ settings });
                                            }}
                                        />
                                    </div>
                                </>
                                :
                                <Spin />
                        }
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeReportSettings: (reportSettings: ReportSettingsType) => dispatch(changeReportSettings(reportSettings)),
});

const mapStateToProps = (state: ApplicationState) => ({
    settings: state.planning.reportSettings,
    company: state.user.company,
    currentUser: state.user.currentUser
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(ReportSettings));