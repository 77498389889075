import { Badge, DatePicker, Divider, Select, Space, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cloneDeep, isEqual } from "lodash";
import moment, { Moment } from "moment";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { ContractTypeMode, GREEN_COLOR, RED_COLOR } from "../../../utils/constants";
import Network from "../../../utils/network";
import { DictionaryNumber, User, UserJobTMP } from "../../../utils/types/generalTypes";
import { CcntReport } from "../../../utils/types/reportTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";
import VirtualTable from "../../common/general/virtualTable";
import Filters from "../../planningPerf/tabs/common/filters";
import DrawerCcntReport from "../ccnt/drawerCcntReport";

interface CcntBalancesByUserId {
    effectives: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    balances: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    daysOff: {
        sickness: number;
        accident: number;
        military: number;
        others: number;
    };
    contractTypeMode: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {

}

interface State {
    selectedMonth: Moment;
    selectedContracts: DictionaryNumber<number | undefined>;
    ccntBalances: DictionaryNumber<CcntBalancesByUserId>;
    loading: boolean;
    loadingCcnt: boolean;
    ccnt: CcntReport | undefined;
    filters: {
        users: number[];
        groups: number[];
        usersToExclude: number[];
    };
    showFilters: boolean;
}

class ReportBalanceCCNTClass extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedMonth: moment(),
            selectedContracts: {},
            ccntBalances: {},
            loading: false,
            loadingCcnt: false,
            ccnt: undefined,
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
            showFilters: false
        };
    }

    init = async () => {
        const { selectedMonth } = this.state;
        await this.updateSelectedContracts();
        this.getAllCcntData(selectedMonth);
    };

    componentDidMount = () => {
        this.init();
    };

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
        const { selectedMonth, selectedContracts } = this.state;
        const { users } = this.props;

        if (!isEqual(prevState.selectedMonth, selectedMonth) || !isEqual(prevProps.users, users)) {
            this.updateSelectedContracts();
        }

        if (Object.keys(selectedContracts).length > 0 && !isEqual(prevState.selectedMonth, selectedMonth)) {
            this.getAllCcntData(selectedMonth);
        }
    };

    updateSelectedContracts = async () => {
        const { selectedMonth } = this.state;
        const { users } = this.props;

        const newSelectedContracts = Object.fromEntries(users.map((u) => [u.id, u.job && this.availableContracts(selectedMonth, u.job).length > 0 ? this.availableContracts(selectedMonth, u.job)[0].id : undefined]));
        this.setState({ selectedContracts: newSelectedContracts });
    };

    getCcntReport = (userIds: number[], jobIds: number[]) => {
        const { selectedMonth } = this.state;
        this.setState({ loadingCcnt: true });
        Network.generateReportsCcntV2(selectedMonth.format("YYYY-MM-01"), selectedMonth.format("YYYY-MM-DD"), userIds, jobIds).then(
            (networkCCnt) => {
                const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                this.setState({ loadingCcnt: false, ccnt });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), 'warning');
                this.setState({ loadingCcnt: false });
            }
        );
    };

    renderTag = (value: number, inverted = false) => {
        const color = value < 0 ? (inverted ? GREEN_COLOR : RED_COLOR) : (inverted ? RED_COLOR : GREEN_COLOR);

        // By @Axel
        return <span style={{ background: color + "33", padding: '5px 10px', color: color, borderRadius: 999 }}>{value}</span>;
    };

    availableContracts = (month: Moment, contracts?: UserJobTMP[]) => {
        return contracts ? contracts.filter(c => c.id !== undefined && moment(c.contract_expiry_date, 'YYYY-MM-DD').isSameOrAfter(month, 'dates') && moment(c.date_in_report, 'YYYY-MM-DD').isSameOrBefore(month, 'dates')) : [];
    };

    getAllCcntData = (month: Moment) => {
        const { selectedContracts } = this.state;
        const userIds = Object.keys(selectedContracts).map(idx => parseInt(idx));
        const jobIds = Object.keys(selectedContracts).map(idx => selectedContracts[parseInt(idx)]).filter(i => i !== undefined) as number[];

        this.setState({ loading: true });
        Network.generateReportsCcntV2(month.format("YYYY-01-01"), month.clone().endOf('month').format("YYYY-MM-DD"), userIds, jobIds).then(
            (response) => {

                const ccntBalances = Object.fromEntries(
                    this.props.users.filter(u => this.availableContracts(month, u.job).length > 0).map((u) => {
                        const userData = response[u.id] ? response[u.id] : undefined;
                        const monthValues = userData && userData.months[month.month()] ? userData.months[month.month()] : undefined;

                        const balances = userData ? userData.tally : {
                            finalWorkTime: 0,
                            finalRestDelta: 0,
                            finalVacationDelta: 0,
                            finalHolidayDelta: 0
                        };

                        const effectives = {
                            hours: monthValues ? monthValues.tally.T : 0,
                            rests: monthValues ? monthValues.tally.X : 0,
                            vacations: monthValues ? monthValues.tally.V : 0,
                            holidays: monthValues ? monthValues.tally.F : 0,
                        };

                        const daysOff = {
                            sickness: monthValues ? monthValues.tally.M + monthValues.tally.Ma : 0,
                            accident: monthValues ? monthValues.tally.A : 0,
                            military: monthValues ? monthValues.tally.Mi : 0,
                            others: monthValues ? monthValues.tally.D : 0,
                        };

                        return [
                            u.id,
                            {
                                balances: {
                                    hours: balances.finalWorkTimeDelta.toFixed(2),
                                    rests: balances.finalRestDelta.toFixed(2),
                                    vacations: balances.finalVacationDelta.toFixed(2),
                                    holidays: balances.finalHolidayDelta.toFixed(2)
                                },
                                effectives: {
                                    hours: effectives.hours.toFixed(2),
                                    rests: effectives.rests.toFixed(2),
                                    vacations: effectives.vacations.toFixed(2),
                                    holidays: effectives.holidays.toFixed(2)
                                },
                                daysOff: {
                                    sickness: daysOff.sickness.toFixed(2),
                                    accident: daysOff.accident.toFixed(2),
                                    military: daysOff.military.toFixed(2),
                                    others: daysOff.others.toFixed(2),
                                },
                                contractTypeMode: userData.data.contractTypeMode,
                            }
                        ];
                    })
                );
                this.setState({ loading: false, ccntBalances });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ loading: false });
            }
        );
    };

    isFixed = (contractTypeMode: number) => {
        return [ContractTypeMode.NORMAL].includes(contractTypeMode);
    };

    handleOnRow = (record: User) => {
        const { selectedContracts } = this.state;
        const contractId = selectedContracts[record.id];
        if (contractId !== undefined)
            this.getCcntReport([record.id], [contractId]);
    };

    resetFilters = () => {
        this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });
    };

    filteredUsers = () => {
        const { filters } = this.state;
        let newUsers = cloneDeep(this.props.users).filter(u => u.visible);
        if (filters.users.length > 0)
            newUsers = newUsers.filter(u => filters.users.includes(u.id));
        if (filters.groups.length > 0)
            newUsers = newUsers.filter(u => u.group_users?.some(g => filters.groups.includes(g.group)));
        return newUsers;
    };

    render = () => {
        const { selectedMonth, showFilters, selectedContracts, filters, loading, loadingCcnt, ccnt, ccntBalances } = this.state;
        const hasActiveFilters = filters.users.length > 0 || filters.groups.length > 0;
        const tableHeight = this.props.height - 270 < 250 ? 250 : this.props.height - 270;

        const columns: ColumnsType<User> = [
            {
                title: <FormattedMessage defaultMessage={'User'} />,
                key: 'user',
                dataIndex: 'user',
                fixed: 'left',
                className: '__min-width-200',
                render: (_, record) => `${record.first_name} ${record.last_name}`
            },
            {
                title: <FormattedMessage defaultMessage={'Contract'} />,
                key: 'contract',
                className: '__width_200',
                width: '200px',
                render: (_, record) => {
                    const contracts = this.availableContracts(selectedMonth, record.job);
                    const selectedContract = contracts.find(c => selectedContracts[record.id] === c.id);
                    return (
                        <>
                            {
                                contracts.length === 1 ?
                                    selectedContract ?
                                        selectedContract.name
                                        :
                                        <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'Contract not found'} /></span>
                                    :
                                    contracts.length > 0 ?
                                        <Select
                                            onClick={(e) => e.stopPropagation()}
                                            value={selectedContracts[record.id]}
                                            options={contracts.map(j => ({ value: j.id, label: j.name }))}
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                const clonedSelectedContracts = cloneDeep(this.state.selectedContracts);
                                                clonedSelectedContracts[record.id] = e;
                                                this.setState({ selectedContracts: clonedSelectedContracts });
                                            }}
                                        />
                                        :
                                        <span style={{ fontStyle: 'italic', color: 'gray' }}><FormattedMessage defaultMessage={'No contract available'} /></span>
                            }
                        </>
                    );
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Effective time'} />,
                key: 'effective',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'effective-hours',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].effectives.hours - ccntBalances[a.id].effectives.hours),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].effectives.hours ?
                                    ccntBalances[record.id].effectives.hours
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'effective-rests',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.rests - ccntBalances[b.id].effectives.rests),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.rests ?
                                    ccntBalances[record.id].effectives.rests
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'effective-vacations',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.vacations - ccntBalances[b.id].effectives.vacations),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.vacations ?
                                    ccntBalances[record.id].effectives.vacations
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'effective-holidays',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].effectives.holidays - ccntBalances[b.id].effectives.holidays),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].effectives.holidays ?
                                    ccntBalances[record.id].effectives.holidays
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Days off'} />,
                key: 'daysoff',
                className: '__width_480',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Sickness'} />,
                        key: 'daysoff-sickness-maternity',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].daysOff.sickness - ccntBalances[a.id].daysOff.sickness),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].daysOff.sickness ?
                                    ccntBalances[record.id].daysOff.sickness
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Accident'} />,
                        key: 'daysoff-accident',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.accident - ccntBalances[b.id].daysOff.accident),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.accident ?
                                    ccntBalances[record.id].daysOff.accident
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Military'} />,
                        key: 'daysoff-military',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.military - ccntBalances[b.id].daysOff.military),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.military ?
                                    ccntBalances[record.id].daysOff.military
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Others'} />,
                        key: 'daysoff-others',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].daysOff.others - ccntBalances[b.id].daysOff.others),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].daysOff.others ?
                                    ccntBalances[record.id].daysOff.others
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Balances'} />,
                key: 'balance',
                fixed: 'right',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'balance-hours',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[b.id].balances.hours - ccntBalances[a.id].balances.hours),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 && this.isFixed(ccntBalances[record.id].contractTypeMode) ?
                                ccntBalances[record.id].balances.hours ?
                                    this.renderTag(ccntBalances[record.id].balances.hours, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'balance-rests',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.rests - ccntBalances[b.id].balances.rests),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.rests ?
                                    this.renderTag(ccntBalances[record.id].balances.rests)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'balance-vacations',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.vacations - ccntBalances[b.id].balances.vacations),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.vacations ?
                                    this.renderTag(ccntBalances[record.id].balances.vacations)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'balance-holidays',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.id] ? -1 : !ccntBalances[b.id] ? 1 : ccntBalances[a.id].balances.holidays - ccntBalances[b.id].balances.holidays),
                        render: (_, record) => (
                            ccntBalances[record.id] && this.availableContracts(selectedMonth, record.job).length > 0 ?
                                ccntBalances[record.id].balances.holidays ?
                                    this.renderTag(ccntBalances[record.id].balances.holidays)
                                    : 0
                                : '-'
                        )
                    }
                ]
            }
        ];

        return (
            <Card
                title={
                    <Space>
                        <FormattedMessage defaultMessage={'Balances N-CLA'} />
                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                        <CircleButton
                            small
                            icon={<FAIcon prefix='fad' name='rotate' />}
                            onClick={() => this.getAllCcntData(selectedMonth)}
                        />
                    </Space>
                }
                icon={<FAIcon prefix='fad' name='calculator' />}
                headerElements={[
                    <>
                        <DatePicker.MonthPicker
                            key='balance-ccnt-month-picker'
                            format={"MM/YYYY"}
                            style={{ width: '110px' }}
                            allowClear={false}
                            value={selectedMonth}
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedMonth: e });
                                }
                            }}
                        />
                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                        <CircleButton small type={showFilters ? 'primary' : 'default'} icon={<Badge dot={hasActiveFilters}><FAIcon prefix='fad' name='filters' color={showFilters ? 'white' : 'black'} /></Badge>} onClick={() => this.setState({ showFilters: !this.state.showFilters })} />
                    </>
                ]}
            >
                <Spin spinning={loading}>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <VirtualTable
                            className='__basic-table'
                            onRow={(e: User) => ({
                                onClick: () => this.handleOnRow(e)
                            })}
                            rowClassName={(record: User) => selectedContracts[record.id] === undefined ? 'disabled-row' : ''}
                            dataSource={this.filteredUsers()}
                            columns={columns}
                            style={{ flex: 1, overflow: 'auto' }}
                            pagination={false}
                            scroll={{ x: true, y: tableHeight }}
                        />
                        {
                            showFilters &&
                            <div className={`__time-clock-filters2 ${showFilters ? '' : 'hidden-sidebar'}`}>
                                <p className='__mp-sider-title'>
                                    {showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                                </p>
                                <div className='__mp-main-siders-content'>
                                    {
                                        showFilters && filters ?
                                            <Filters
                                                reset={this.resetFilters}
                                                users={{
                                                    selectedUsers: filters.users,
                                                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                                                }}
                                                groups={{
                                                    selectedGroups: filters.groups,
                                                    usersToExclude: filters.usersToExclude,
                                                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                                                }}
                                            />
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Spin>
                <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={ccnt !== undefined} close={() => this.setState({ ccnt: undefined })} year={selectedMonth} />
            </Card>
        );
    };
}

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
    height: state.window.height,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(ReportBalanceCCNTClass));