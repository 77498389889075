import type { FC } from 'react';
import { memo } from 'react';

import { Empty, List } from 'antd';
import { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';
import { BreakTime } from '../../../../../../utils/types/planningTypes';
import { type breaktimeErrors } from '../editEventClockedModal';
import { BreaktimeObject } from './breaktimeObject';

interface Props {
    activeBreaks: BreakTime[];
    errors: breaktimeErrors;
    breaks: BreakTime[];
    dateBounds: { from?: Moment; to?: Moment; };
    onBreakStateChange: (newState: boolean, breakTimeId: number) => void;
}

export const BreaktimeContainer: FC<Props> = memo(function BreaktimeContainer(props: Props) {
    return (
        <div>
            <div style={{ overflow: 'hidden', clear: 'both' }}>
                <List
                    dataSource={props.breaks}
                    renderItem={item => (
                        <List.Item style={{ padding: '0px 0px' }}>
                            <BreaktimeObject
                                dateBounds={props.dateBounds}
                                key={item.id}
                                breakTime={item}
                                error={props.errors.find(e => e.breaktimeId === item.id)?.error ?? 'NONE'}
                                onActiveChange={(checked, id) => props.onBreakStateChange(checked, id)}
                                isActive={props.activeBreaks.includes(item)}
                            />
                        </List.Item>
                    )}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No break'} />} /> }}
                />
            </div>
        </div>
    );
});
