import { useAppSelector } from "../store/store";
import { keyify } from "../utils/array_utils";
import { User } from "../utils/types/generalTypes";
import { getFullName } from "../utils/utils";
import { useLastNameFirst } from "./useCompany";

export const useUsersVisible = () => useAppSelector(({ teamManagement }) => (teamManagement.users as User[]).filter(u => u.visible));

export const useUsersVisibleKeyed = () => {
    const users = useUsersVisible();
    return keyify(users, 'id')
};

export const useFullName = () => {
    const lastNameFirst = useLastNameFirst();
    return (user: User) => getFullName(user, lastNameFirst);
};

export default () => useAppSelector(({ teamManagement }) => teamManagement.users as User[]);