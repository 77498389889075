import { Space } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { CaseType } from '../../utils/constants';
import { ContainerTabsState, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { getCaseAndPlural } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import Can from '../common/general/can';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateSidebars, Sidebar } from '../common/navigations/containerTabs';
import { MenuLinks } from '../common/navigations/sideMenu';
import ListOfProjects from './listOfProjects/listOfProjects';
import ProjectReports from './projectReports/projectReports';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps { }
interface State extends ContainerTabsState {
}

export enum ProjectManagementTabLink {
    LIST_OF_PROJECTS = "list-of-projects",
    PROJECT_REPORT = "project-reports",
}

class ProjectManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tabItems: [
                {
                    key: ProjectManagementTabLink.LIST_OF_PROJECTS,
                    label: <Space><FAIcon prefix={'fad'} name="sitemap" />{props.company?.projectDisplayText ? getCaseAndPlural(props.company.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={"Projects"} />}</Space>,
                    children: <ListOfProjects keyLink={ProjectManagementTabLink.LIST_OF_PROJECTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                },
                {
                    key: ProjectManagementTabLink.PROJECT_REPORT,
                    label: <Space><FAIcon prefix={'fad'} name="file-excel" /><FormattedMessage defaultMessage={"Reports"} /></Space>,
                    children: <ProjectReports keyLink={ProjectManagementTabLink.PROJECT_REPORT} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                },
            ],
        };
    }

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));
    addOrUpdateSidebars = (content: Sidebar[], key: string) => this.setState(prevState => addOrUpdateSidebars(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfSidebars } = this.state;
        const { intl, company } = this.props;
        return (
            <Can rule={Rules.ProjectV2.Visit} redirect="/dashboard">
                <ContainerTabs
                    contentClassName='__new-right-panel'
                    breadcrumb={[{ title: company?.projectDisplayText ? getCaseAndPlural(company.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Projects' }), link: `/${MenuLinks.CRM}/${this.props.match.params.tab}` }]}
                    tabItems={tabItems}
                    tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                    tabSidebars={tabListOfSidebars?.find(item => item.key === this.props.match.params.tab)?.content}
                />
            </Can>
        )
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(ProjectManagement));
