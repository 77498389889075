import { Avatar, Badge, Button, Col, Comment, Divider, Dropdown, Input, Menu, Popover, Row, Segmented, Space, Spin, Tooltip } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import Linkify from 'linkify-react';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { MOMENT_FORMAT_DISPLAY } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { Like, News, NewsCardContent, NewsComment } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import Can from '../common/general/can';
import Carousel from '../common/general/carousel';
import injectIntlHOC from '../common/wrapper/injectIntlHOC';

type ReduxProx = ConnectedProps<typeof connector>;

interface Props extends ReduxProx, WrappedComponentProps, IntlProps {
    content: NewsCardContent;
    news: News;
    handleDelete?: (content: any) => void;
    changeNews: (id: number, newNews: News) => void;
    openLikeDetail: number | undefined;
    changeOpenLikeDetail: (newsId: number | undefined) => void;
}

interface State {
    loadingComments: boolean;
    comments: NewsComment[];
    openComment: boolean;
    newComment: string;
    openLikeDetail: boolean;
    segmentedValue: SegmentedValue;
}
/**
 * Component that represent a news card
 */
class NewsCard extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loadingComments: false,
            openComment: false,
            comments: [],
            newComment: '',
            openLikeDetail: false,
            segmentedValue: 'liked'
        };
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.openLikeDetail !== this.props.openLikeDetail) {
            if (this.props.openLikeDetail !== undefined && this.props.openLikeDetail === this.props.news.id) {
                if (!this.state.openLikeDetail) {
                    this.setState({ openLikeDetail: true });
                }
            } else if (this.props.openLikeDetail === undefined || this.props.openLikeDetail !== this.props.news.id) {
                if (this.state.openLikeDetail) {
                    this.setState({ openLikeDetail: false });
                }
            }
        }

    }
    /**
     * Handle this news suppression
     */
    handleDelete = () => {
        if (this.props.handleDelete) this.props.handleDelete!(this.props.content);
    };
    unloadComments = () => {
        this.changeOpenLikeDetail(false);
        this.setState({ openComment: false });
    };
    loadComments = () => {
        this.changeOpenLikeDetail(false);
        const { loadingComments } = this.state;
        if (loadingComments === false) {
            this.setState({ loadingComments: true, openComment: true }, () => {
                Network.getNewsComments(this.props.content.id).then(
                    (response: NewsComment[]) => {
                        this.state.comments;
                        if (Array.isArray(response))
                            this.setState({ loadingComments: false, comments: response.sort((a, b) => a.created < b.created ? -1 : 1), openComment: true });
                    },
                    () => {
                        this.setState({ loadingComments: false, comments: [], openComment: true });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the comments' }), "error");
                    }
                );
            });

        }

    };
    createNewComment = () => {
        if (this.state.newComment !== '') {

            Network.createNewsComment(this.props.content.id, this.state.newComment).then(
                (response: any) => {

                    const newNews = this.props.news;
                    if (newNews.aggregate) {
                        if (newNews.aggregate.comment)
                            newNews.aggregate.comment += 1;
                        else
                            newNews.aggregate.comment = 1;
                    } else {
                        newNews.aggregate = {
                            news: this.props.content.id,
                            comment: 1
                        };
                    }
                    this.props.changeNews(this.props.content.id, newNews);


                    const comments = cloneDeep(this.state.comments);
                    comments.push(response);
                    this.setState({ comments, newComment: '' });


                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while sending the comment' }), 'warning')
            );
        }
        else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'You can not send an empty comment' }), 'warning');
        }
    };
    likeNews = () => {
        Network.likeNews(this.props.content.id).then(
            (response: Like) => {
                const newNews = this.props.news;
                if (newNews.aggregate) {
                    if (newNews.aggregate.like)
                        newNews.aggregate.like += 1;
                    else
                        newNews.aggregate.like = 1;
                } else {
                    newNews.aggregate = {
                        news: this.props.content.id,
                        like: 1
                    };
                }
                newNews.has_liked = true;
                if (newNews.likes) {
                    newNews.likes.push(response);

                } else {
                    newNews.likes = [response];
                }
                this.props.changeNews(this.props.content.id, newNews);


            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while sending the like' }), 'warning')
        );
    };
    unlikeNews = () => {
        Network.unlikeNews(this.props.content.id).then(
            (response: Like) => {
                const newNews = this.props.news;
                if (newNews.aggregate) {
                    if (newNews.aggregate.like != null)
                        newNews.aggregate.like -= 1;
                    else
                        newNews.aggregate.like = 0;
                } else {
                    newNews.aggregate = {
                        news: this.props.content.id,
                        like: 0
                    };
                }
                newNews.has_liked = false;
                newNews.likes = newNews.likes ? newNews.likes.filter(like => like.id != response.id) : [];

                this.props.changeNews(this.props.content.id, newNews);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while removing the like' }), 'warning')
        );
    };
    genDurationText = (date: Moment) => {
        const duration = moment.duration(moment().diff(date));
        const { intl } = this.props;
        let durationText = intl.formatMessage({ defaultMessage: '{duration} seconds' }, { duration: duration.format('s') });
        if (duration.asMonths() >= 12) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} years' }, { duration: moment().diff(date, 'years') });
        } else if (duration.asWeeks() >= 8) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} months' }, { duration: moment().diff(date, 'months') });
        } else if (duration.asDays() >= 14) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} weeks' }, { duration: moment().diff(date, 'weeks') });
        } else if (duration.asHours() >= 24) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} days' }, { duration: moment().diff(date, 'days') });
        } else if (duration.asMinutes() >= 60) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} hours' }, { duration: duration.format('H') });
        } else if (duration.asSeconds() >= 60) {
            durationText = intl.formatMessage({ defaultMessage: '{duration} minutes' }, { duration: duration.format('m') });
        }
        return durationText;
    };
    changeOpenLikeDetail = (openLikeDetail: boolean) => {
        console.log('openLIKE DETAIL', this.props.news.id, openLikeDetail);
        if (this.props.openLikeDetail === this.props.news.id) {
            if (!openLikeDetail) {
                console.log('openLIKE DETAIL 1', this.props.news.id, openLikeDetail);
                this.props.changeOpenLikeDetail(undefined);
            }
        } else if (this.props.openLikeDetail !== this.props.news.id) {
            if (openLikeDetail) {
                console.log('openLIKE DETAIL 2', this.props.news.id, openLikeDetail);
                this.props.changeOpenLikeDetail(this.props.news.id);
            }
        }
    };
    render() {
        const { content } = this.props;
        const images = content.files.filter(f => f.res_type === 'IMG');
        const files = content.files.filter(f => f.res_type === 'FLE');
        const likes_user_ids_flat = this.props.news.likes ? this.props.news.likes.map(l => l.user) : [];

        return (
            <div id={`newscard-${content.id}`} className="newscard-parent">
                <div className="newscard-avatar-parent">
                    <Avatar size={32} src={content.avatar} className="newscard-avatar" style={{ backgroundColor: 'var(--primary-color)', boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)' }}>
                        {
                            content.avatar ? null :
                                content.creator.slice(0, 1)
                        }
                    </Avatar>
                    <div className="newscard-avatar-shadow" />
                </div>
                <div className="newscard-item">
                    <div style={{ width: content.can_delete ? 'calc(100% - 40px)' : 'calc(100% - 10px)' }}>
                        <div className="newscard-item-header">
                            <span className="newscard-item-header-contacts">
                                <p className="newscard-item-header-creator" style={{ backgroundColor: 'var(--primary-color)' }}><span title={content.creator} className='newscard-item-header-creator-span'>{content.creator}</span></p>
                                <FAIcon prefix='fad' name='arrow-right' />
                                <p className="newscard-item-header-creator" style={{ color: 'var(--light-color)', backgroundColor: 'var(--secondary-color)' }}><span title={content.destinator} className='newscard-item-header-creator-span'>{content.destinator}</span></p>
                            </span>
                            <span className="newscard-item-date">
                                <p>{moment(content.start_time).format(getFormat('TIME_SHORT'))}</p>
                                <p>{moment(content.start_time).format(getFormat('DATE'))}</p>
                            </span>
                        </div>
                        <div className="newscard-item-body">

                            <Row style={{
                                fontSize: '18px',
                                //backgroundColor: 'rgba(6, 156, 124, 0.02)',
                                border: '1px solid #b1b1b1',
                                borderRadius: 'var(--border-radius)',
                                padding: 15,
                                marginBottom: 15
                            }} >

                                <Col span={24}>
                                    {
                                        content.textFr && content.textDe && content.textIt ?
                                            <div style={{ marginBottom: '10px' }}>
                                                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Titre</p>
                                                <span className="newscard-item-translation"><p style={{ marginRight: '10px' }}>{"En:"}</p><p>{content.title}</p></span>
                                                <span className="newscard-item-translation"><p style={{ marginRight: '10px' }}>{"Fr:"}</p><p>{content.titleFr}</p></span>
                                                <span className="newscard-item-translation"><p style={{ marginRight: '10px' }}>{"De:"}</p><p>{content.titleDe}</p></span>
                                                <span className="newscard-item-translation"><p style={{ marginRight: '10px' }}>{"It:"}</p><p>{content.titleIt}</p></span>
                                            </div>
                                            :
                                            <p className="newscard-item-title" style={{ fontSize: '20px', fontWeight: 'bold' }}>{content.title}</p>
                                    }

                                    {
                                        images && images.length > 0 ?
                                            <div>
                                                <Carousel
                                                    leftText=''
                                                    rightText=''
                                                    images={images.map(i => {
                                                        return {
                                                            id: i.id,
                                                            name: i.file_name,
                                                            image: i.file_name,
                                                            fullImageUrl: i.res_url,
                                                            src: i.res_url,
                                                            file: i.file_name,
                                                            active: true
                                                        };
                                                    })}
                                                    width={800}
                                                    height={450}
                                                />
                                            </div>
                                            : null
                                    }
                                </Col>
                                <Col span={24}>
                                    {
                                        content.textFr && content.textDe && content.textIt ?
                                            <div>
                                                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Contenu</p>
                                                <span className="newscard-item-translation">
                                                    <p style={{ marginRight: '10px' }}>{"En:"}</p>
                                                    <p>{content.text}</p>
                                                </span>
                                                <span className="newscard-item-translation">
                                                    <p style={{ marginRight: '10px' }}>{"Fr:"}</p>
                                                    <p>{content.textFr}</p>
                                                </span>
                                                <span className="newscard-item-translation">
                                                    <p style={{ marginRight: '10px' }}>{"De:"}</p>
                                                    <p>{content.textDe}</p>
                                                </span>
                                                <span className="newscard-item-translation">
                                                    <p style={{ marginRight: '10px' }}>{"It:"}</p>
                                                    <p>{content.textIt}</p>
                                                </span>
                                            </div>
                                            :
                                            <div style={{ width: '100%', display: 'flex', justifyContent: images && images.length > 0 ? 'start' : 'start', alignItems: 'end' }}>
                                                <pre style={{
                                                    width: '100%',
                                                    fontFamily: 'inherit',
                                                    whiteSpace: 'pre-wrap',
                                                    fontSize: '18px',
                                                    //textAlign: images && images.length > 0?'center':'left',
                                                    //backgroundColor: 'rgba(251,192,45,.09549019607843137)',
                                                    //borderRadius: 'var(--border-radius)',
                                                    //padding: 15,
                                                    marginBottom: 0
                                                }}>
                                                    <Linkify options={{ target: 'blank' }}>
                                                        {content.text}
                                                    </Linkify>
                                                </pre>
                                            </div>
                                    }
                                </Col>

                                {
                                    files && files.length > 0 ? <>
                                        <Col span={24}>
                                            <Divider type={'horizontal'} style={{
                                                marginTop: 0,
                                                width: '100%',
                                                fontFamily: 'inherit',
                                                whiteSpace: 'pre-wrap',
                                                fontSize: '18px',
                                                //padding: 15
                                            }} />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <div style={{ width: 350, fontSize: '14px' }}>
                                                <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Files'} /></h1>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                    {files.map(f => {
                                                        return <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'right', alignItems: 'center' }} key={`file-${f.id}-${f.file_name}`}>
                                                            <div style={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><span>{f.file_name}</span></div>
                                                            <a target={'_blank'} href={f.res_url} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                                                            <FAIcon prefix='fad' name='file' />
                                                        </div>;
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                        :
                                        null
                                }

                                {this.state.openComment && this.state.comments !== undefined &&
                                    <>
                                        <Col span={24}>
                                            <Divider type={'horizontal'} style={{
                                                //margin: 0,
                                                width: '100%',
                                                fontFamily: 'inherit',
                                                whiteSpace: 'pre-wrap',
                                                fontSize: '18px',
                                                //padding: 15
                                            }} />
                                        </Col>


                                        <Col span={24}>
                                            {this.state.loadingComments === true ?
                                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Spin indicator={<FAIcon prefix='fas' name='spinner-third' spin />} size='large' style={{ marginBottom: '40px', marginTop: '30px' }} />
                                                </div>
                                                :
                                                this.state.comments.map(comment => {
                                                    const durationText = this.genDurationText(moment(comment.created));
                                                    return (
                                                        <Comment
                                                            className={'no-padding'}
                                                            key={`comment-${comment.id}-${moment(comment.modified).format('YYYYMMDDHHmmss')}`}
                                                            avatar={<Avatar src={comment.user?.image} alt={comment.user?.username} />}
                                                            author={`${comment.user?.first_name} ${comment.user?.last_name}`}
                                                            datetime={<Tooltip title={moment(comment.created).format(getFormat('DATE_AND_ON_TIME'))}>
                                                                <span><FormattedMessage defaultMessage={'{duration} ago'} values={{ duration: durationText }} /></span>
                                                            </Tooltip>
                                                            }
                                                            content={
                                                                <div style={{ width: '100%', display: 'flex', justifyContent: images && images.length > 0 ? 'start' : 'start', alignItems: 'center' }}>
                                                                    <pre style={{
                                                                        //width: '100%', 
                                                                        fontFamily: 'inherit',
                                                                        whiteSpace: 'pre-wrap',
                                                                        fontSize: '14px',
                                                                        //textAlign: images && images.length > 0?'center':'left',
                                                                        //backgroundColor: 'rgba(251,192,45,.09549019607843137)',
                                                                        borderRadius: 'var(--border-radius)',
                                                                        //padding: 15
                                                                    }}>
                                                                        <Linkify options={{ target: 'blank' }}>
                                                                            {comment.comment}
                                                                        </Linkify>
                                                                    </pre>
                                                                </div>
                                                            }
                                                        />
                                                    );
                                                })

                                            }
                                            <Comment
                                                className={'no-padding'}
                                                avatar={<Avatar src={this.props.currentUser?.image} alt={this.props.currentUser?.username} />}
                                                author={`${this.props.currentUser?.first_name} ${this.props.currentUser?.last_name}`}
                                                content={
                                                    <Input
                                                        onKeyDown={(e) => e.key === 'Enter' && this.createNewComment()}
                                                        style={{ height: 35, minHeight: '35px !important' }}
                                                        value={this.state.newComment}
                                                        onChange={(event) => this.setState({ newComment: event.target.value })}
                                                        suffix={<CircleButton title={this.props.intl.formatMessage({ defaultMessage: "Send" })} style={{ border: 'none', height: 27, backgroundColor: 'transparent', right: -10 }} onClick={this.createNewComment} icon={<FAIcon prefix='fad' name='paper-plane-top' />} />}
                                                    />
                                                }
                                            />
                                        </Col>
                                    </>
                                }


                            </Row>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'end' }}>
                                    {
                                        <div style={{ width: '100%', fontSize: '14px', display: 'flex', flexDirection: 'row', gap: 8, justifyContent: content.like ? 'left' : 'right', alignItems: 'center' }}>
                                            {content.like && <div style={{ width: '50%', display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-around' }} >
                                                <Button type={this.props.content.has_liked ? 'primary' : 'default'} onClick={this.props.content.has_liked ? undefined : this.likeNews} style={{ width: 40, display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'center', alignItems: 'center' }} >
                                                    <div>
                                                        <FAIcon prefix='fad' name='thumbs-up' />
                                                    </div>
                                                </Button>
                                                <Popover open={this.state.openLikeDetail} content={
                                                    <div>
                                                        <Segmented
                                                            block
                                                            value={this.state.segmentedValue}
                                                            options={[
                                                                {
                                                                    label: <span style={{ fontSize: '14px' }}>{this.props.intl.formatMessage({ defaultMessage: "Have reacted" })}</span>,
                                                                    value: "liked"
                                                                },
                                                                {
                                                                    label: <span style={{ fontSize: '14px' }}>{this.props.intl.formatMessage({ defaultMessage: "Did not react" })}</span>,
                                                                    value: "didntLiked"
                                                                }
                                                            ]}
                                                            onChange={(e) => {
                                                                this.setState({ segmentedValue: e });
                                                            }
                                                            }
                                                        />
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 10, maxHeight: 210, overflowY: 'scroll' }}>
                                                            {this.state.segmentedValue === 'liked' ? this.props.news.likes?.map(like => {
                                                                const user = this.props.users.find(u => u.id === like.user);
                                                                const durationText = this.genDurationText(moment(like.modified));
                                                                return (

                                                                    <div key={`user-like-${like.news}-${like.user}`} style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-between', alignItems: 'center', minWidth: '250px' }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                                            {user !== undefined && user.image ? <Avatar size={40} icon={<FAIcon prefix='fad' name='user' />} src={user?.image} /> : <div style={{ width: 40, height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><FAIcon prefix='fad' name='user' fontSize={28} /></div>}
                                                                            {user !== undefined ? <span style={{ fontSize: '14px' }}>{`${user.first_name} ${user.last_name}`}</span> : <span></span>}
                                                                        </div>
                                                                        <Tooltip title={moment(like.modified).format(MOMENT_FORMAT_DISPLAY)}>

                                                                            <span style={{ marginRight: 10, color: '#ccc', fontSize: '12px' }}><FormattedMessage defaultMessage={'{duration} ago'} values={{ duration: durationText }} /></span>
                                                                        </Tooltip>

                                                                    </div>

                                                                );
                                                            }) :
                                                                this.props.users.filter(u => !likes_user_ids_flat.includes(u.id)).sort((a, b) => a.first_name > b.first_name ? 1 : -1).map(user => {
                                                                    return (

                                                                        <div key={`user-didntlike-${user.id}`} style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-between', alignItems: 'center', minWidth: '250px' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                                                {user !== undefined && user.image ? <Avatar size={40} icon={<FAIcon prefix='fad' name='user' />} src={user?.image} /> : <div style={{ width: 40, height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><FAIcon prefix='fad' name='user' fontSize={28} /></div>}
                                                                                {user !== undefined ? <span style={{ fontSize: '14px' }}>{`${user.first_name} ${user.last_name}`}</span> : <span></span>}
                                                                            </div>
                                                                        </div>

                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }>
                                                    <Button type={this.state.openLikeDetail ? 'primary' : 'default'} onClick={() => this.changeOpenLikeDetail(!this.state.openLikeDetail)} style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'center', alignItems: 'center' }} >
                                                        <Tooltip title={this.props.intl.formatMessage({ defaultMessage: "Informations on users having reacted to this post" })}><FAIcon prefix='fad' name='heart' /></Tooltip>
                                                        <span style={{ marginLeft: 0 }}>{`${content.like_name}`}</span>
                                                        {(content.aggregate !== undefined && content.aggregate.like !== undefined && content.aggregate.like > 0) && <Badge color={this.state.openLikeDetail ? 'transparent' : 'var(--primary-color)'} style={{ border: 'none', boxShadow: 'none' }} count={(content.aggregate !== undefined && content.aggregate.like !== undefined) ? content.aggregate.like : 0} />}
                                                    </Button>
                                                </Popover>
                                            </div>}
                                            {content.comment && <div style={{ width: '50%' }} >
                                                <Button type={this.state.openComment ? 'primary' : 'default'} onClick={this.state.openComment ? () => this.unloadComments() : () => this.loadComments()} style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'center', alignItems: 'center' }} >
                                                    <FAIcon prefix='fad' name='comment' />
                                                    <span style={{ marginLeft: 0 }}>{this.props.intl.formatMessage({ defaultMessage: "Comments" })}</span>
                                                    {(content.aggregate !== undefined && content.aggregate.comment !== undefined && content.aggregate.comment > 0) && <Badge color={this.state.openComment ? 'transparent' : 'var(--primary-color)'} style={{ border: 'none', boxShadow: 'none' }} count={(content.aggregate !== undefined && content.aggregate.comment !== undefined) ? content.aggregate.comment : 0} />}


                                                </Button>
                                            </div>}
                                        </div>
                                    }
                                </div>
                            </div>




                        </div>
                    </div>
                    {
                        content.can_delete &&
                        <Can rule={Rules.Newsfeed.Delete}>
                            <div style={{ width: '40px', textAlign: 'center' }} >
                                <Dropdown overlay={
                                    <Menu>
                                        <Menu.Item className="newscard-menu-item" onClick={this.handleDelete}>
                                            <Space>
                                                <FAIcon prefix='fad' name='trash-can' />
                                                {this.props.intl.formatMessage({ defaultMessage: "Delete" })}
                                            </Space>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <Button type="link" icon={<FAIcon prefix='fad' name='ellipsis-vertical' />} style={{ color: '#373737' }} />
                                </Dropdown>
                            </div>
                        </Can>
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    users: state.teamManagement.users
});

const connector = connect(mapStateToProps);

export default injectIntlHOC(connector(injectIntl(NewsCard)));