import { BackTop, Breadcrumb, Button, Divider, Drawer, Layout, Tabs } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { CSSProperties } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeSidebarType } from '../../../store/actions/window';
import { ContainerTabsState, RouterProps, TabItem } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { isNullOrEmpty } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../FAIcon';
import CircleButton from '../fields/circleButton';
import CheckMobile from '../general/CheckMobile/checkMobile';
import SpaceContent from '../general/spaceContent';
import SideMenu, { checkScroll, Direction } from './sideMenu';

type ReduxProps = ConnectedProps<typeof connector>;


interface Props extends ReduxProps, RouterProps, IntlProps {
    breadcrumb: { title: string; link: string; }[];
    backTop?: boolean;

    contentClassName?: string;
    contentStyle?: CSSProperties;

    tabItems: TabItem[];
    tabExtra?: React.ReactNode;
    tabSidebars?: Sidebar[];

    backUrl?: string;
}

interface State {
    openDrawer: boolean;
    hasFilterActivated: boolean;
}

class ContainerTabs extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            openDrawer: false,
            hasFilterActivated: false,
        };
    }

    componentDidMount = () => {
        const anchor = document.getElementById('anchor');
        if (anchor) anchor.scrollIntoView();
    };

    toggleOpenDrawer = () => this.setState(prevState => ({ openDrawer: !prevState.openDrawer }));

    goTo = (link: string) => this.props.history.push(`/${this.props.match.params.lang}${link}`);

    sidebarsButtons = () => {
        const { tabSidebars, sidebarType, changeSidebarType } = this.props;
        if (!isNullOrEmpty(tabSidebars)) {
            return tabSidebars.map((s, idx) => (
                <CircleButton
                    key={`sidebar-button-${s.type}-${idx}`}
                    small
                    type={sidebarType === s.type ? "primary" : "default"}
                    icon={s.button.icon}
                    onClick={() => changeSidebarType(s.type === sidebarType ? SidebarType.NONE : s.type)}
                    title={s.button.title} />
            ));
        } else {
            return null;
        }
    };

    render() {
        const { isTablet, isSmartphone, collapsed, tabExtra, tabItems, contentStyle, contentClassName, backTop, breadcrumb, containerHeight } = this.props;
        const { backUrl, tabSidebars, sidebarType } = this.props;
        const { Sider, Content } = Layout;
        const hasScroll = checkScroll(document.getElementById("container-tabs-content-wrapper"), Direction.Vertical);

        const sidebarsButtons = this.sidebarsButtons();

        const hasSidebar = !isNullOrEmpty(tabSidebars);

        let sidebarContent: React.ReactNode;
        let sidebarTitle: React.ReactNode;
        let sidebarIcon: React.ReactNode;
        let sidebarExtra: React.ReactNode;
        let openSidebar = false;
        if (hasSidebar) {
            const foundSidebar = tabSidebars?.find(s => s.type === sidebarType);
            if (foundSidebar) {
                sidebarContent = foundSidebar?.content;
                if (foundSidebar.titleBar?.title)
                    sidebarTitle = foundSidebar.titleBar.title;
                if (foundSidebar.titleBar?.icon)
                    sidebarIcon = foundSidebar.titleBar.icon;
                if (foundSidebar.titleBar?.extra)
                    sidebarExtra = foundSidebar.titleBar.extra;

                if (sidebarType !== SidebarType.NONE) {
                    openSidebar = true;
                }
            }
        }

        return (
            <>
                <Layout className="container-layout" style={{ marginLeft: isSmartphone ? '0px' : (collapsed ? '80px' : '300px') }}>
                    {
                        isSmartphone ?
                            null :
                            <Sider width="300" trigger={null} collapsible collapsed={collapsed} style={{ position: 'fixed', maxHeight: '100%', height: '100%', left: 0, zIndex: 2 }}>
                                <SideMenu />
                            </Sider>
                    }
                    <Layout className={`main-container`}>
                        {backTop ? <BackTop /> : null}
                        {
                            isSmartphone ?
                                <Header className="container-header">
                                    <Breadcrumb>
                                        {
                                            breadcrumb.map((item, idx) => {
                                                return (
                                                    <Breadcrumb.Item key={`breadcrumb-${item.title}-${idx}`}>
                                                        <span onClick={() => this.goTo(item.link)}>{item.title}</span>
                                                    </Breadcrumb.Item>
                                                );
                                            })
                                        }
                                    </Breadcrumb>
                                    <Button icon={<FAIcon prefix='fad' name='bars' />} type="link" onClick={this.toggleOpenDrawer} style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={this.toggleOpenDrawer}
                                        open={this.state.openDrawer}>
                                        <SideMenu />
                                    </Drawer>
                                </Header>
                                : null
                        }
                        <Content style={contentStyle} id="container" className={`__new-container${hasSidebar && !isTablet ? '-filters' : ''} container-content-background background-white ${contentClassName ?? ''}`}>
                            <Tabs
                                tabBarStyle={{ paddingLeft: '5px', paddingRight: '5px' }}
                                animated
                                activeKey={this.props.match.params.tab}
                                onChange={(path) => {
                                    this.props.history.push(`${path}`);
                                }}
                                tabBarExtraContent={
                                    {
                                        left: (
                                            backUrl ? (
                                                <div style={{ marginRight: '10px' }}>
                                                    {
                                                        backUrl ?
                                                            <FAIcon prefix='fad' name='arrow-left' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/${this.props.match.params.lang}${backUrl}`)} />
                                                            : null
                                                    }
                                                </div>
                                            )
                                                : undefined
                                        ),
                                        right: (
                                            <SpaceContent>
                                                {tabExtra}
                                                {
                                                    sidebarsButtons ?
                                                        <>
                                                            {
                                                                tabExtra ?
                                                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                                                    : null
                                                            }
                                                            {sidebarsButtons}
                                                        </>
                                                        : null
                                                }
                                            </SpaceContent>
                                        )
                                    }
                                }
                                items={tabItems.map(item => {
                                    return {
                                        ...item,
                                        children: (
                                            <CheckMobile hideOnMobile={item.hideMobile}>
                                                <div style={{ position: 'relative', paddingLeft: isSmartphone ? '5px' : undefined, height: `${isTablet ? (containerHeight - 45) : containerHeight}px`, maxHeight: `${isTablet ? (containerHeight - 45) : containerHeight}px`, overflow: 'hidden' }} className={`__container-tabs-main ${hasSidebar ? 'filters' : 'nofilters'}`}>
                                                    <div className={`__container-tabs-main-table-container ${hasSidebar ? 'filters' : 'nofilters'}`}>
                                                        <div id='container-tabs-content-wrapper' style={{ paddingRight: hasScroll || hasSidebar ? '5px' : undefined }}>
                                                            {item.children}
                                                        </div>
                                                    </div>
                                                    {
                                                        hasSidebar ?
                                                            isTablet ?
                                                                <Drawer
                                                                    closable={false}
                                                                    style={{ position: 'absolute' }}
                                                                    drawerStyle={{ backgroundColor: 'var(--background-color)' }}
                                                                    onClose={() => changeSidebarType(SidebarType.NONE)}
                                                                    width={"100%"}
                                                                    getContainer={false}
                                                                    open={isTablet && openSidebar}
                                                                    footer={[
                                                                        <SpaceContent key={"container-tabs-drawer-footer"}>
                                                                            <Button type='primary' onClick={() => changeSidebarType(SidebarType.NONE)}>
                                                                                <FormattedMessage defaultMessage={'Show results'} />
                                                                            </Button>
                                                                        </SpaceContent>
                                                                    ]}
                                                                    footerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                >
                                                                    <div style={{ padding: '15px 10px' }}>
                                                                        <SidebarContent content={sidebarContent} title={sidebarTitle} icon={sidebarIcon} extra={sidebarExtra} />
                                                                    </div>
                                                                </Drawer>
                                                                :
                                                                <div className={`__container-tabs-main-filters ${openSidebar ? 'filters' : 'nofilters'}`}>
                                                                    <SidebarContent content={sidebarContent} title={sidebarTitle} icon={sidebarIcon} extra={sidebarExtra} />
                                                                </div>
                                                            : null
                                                    }
                                                </div>
                                            </CheckMobile>
                                        )
                                    };
                                })} />
                        </Content>
                    </Layout >
                </Layout >
            </>
        );
    }
}

// #region Redux Store
const mapStateToProps = (state: ApplicationState) => ({
    isTablet: state.window.isTablet,
    isSmartphone: state.window.isSmartphone,
    sidebarType: state.window.sidebarType,
    collapsed: state.window.navigationCollapsed,
    containerHeight: state.window.containerHeight
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSidebarType: (type: SidebarType) => dispatch(changeSidebarType(type)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

export default withRouter(connector(injectIntl(ContainerTabs)));

export const addOrUpdateExtra = (prevState: ContainerTabsState, content: React.ReactNode, key: string) => {
    const newExtra = { key: key, content: content };
    if (prevState.tabListOfExtras && prevState.tabListOfExtras.length > 0) {
        const extraFound = prevState.tabListOfExtras.find(extra => extra.key === key);
        if (extraFound) {
            return {
                tabListOfExtras: prevState.tabListOfExtras.map(extra => {
                    if (extra.key === key) return newExtra;
                    return extra;
                })
            };
        } else {
            return { tabListOfExtras: [...prevState.tabListOfExtras].concat(newExtra) };
        }
    } else {
        return { tabListOfExtras: [newExtra] };
    }
};


export const addOrUpdateSidebars = (prevState: ContainerTabsState, content: Sidebar[], key: string) => {
    const newSidebars = { key: key, content: content };
    if (prevState.tabListOfSidebars && prevState.tabListOfSidebars.length > 0) {
        const extraFound = prevState.tabListOfSidebars.find(extra => extra.key === key);
        if (extraFound) {
            return {
                tabListOfSidebars: prevState.tabListOfSidebars.map(sidebars => {
                    if (sidebars.key === key) return newSidebars;
                    return sidebars;
                })
            };
        } else {
            return { tabListOfSidebars: [...prevState.tabListOfSidebars].concat(newSidebars) };
        }
    } else {
        return { tabListOfSidebars: [newSidebars] };
    }
};

const SidebarContent: React.FC<{ content: React.ReactNode, title?: React.ReactNode, icon?: React.ReactNode, extra?: React.ReactNode; }> = ({ content, title, icon, extra }) => {
    return (
        <>
            {
                title ?
                    <>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px', marginBottom: '10px' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                {icon ?? null}
                                <div className='title' style={{ margin: '0' }}>{title}</div>
                            </div>
                            {extra ?? null}
                        </div>
                        <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                    </>
                    : null
            }
            {content}
        </>
    );
};


export const enum SidebarType {
    NONE = 'None',
    FILTERS = 'Filters',
    SETTINGS = "Settings"
}

export interface SidebarButton {
    title: string;
    icon: React.ReactNode;
}

export interface Sidebar {
    type: SidebarType;
    button: SidebarButton;
    titleBar?: {
        title?: React.ReactNode;
        icon?: React.ReactNode;
        extra?: React.ReactNode;
    };
    content: React.ReactNode;
}

export const FilterSidebar = (content: React.ReactNode, intl: IntlShape): Sidebar => ({
    type: SidebarType.FILTERS,
    button: {
        title: intl.formatMessage({ defaultMessage: 'Filters' }),
        icon: <FAIcon prefix={"fad"} name='filters' />
    },
    content: content,
    titleBar: {
        title: <FormattedMessage defaultMessage={'Filters'} />,
        icon: <FAIcon prefix={"fad"} name='filters' />,
    }
});