import axios from "axios";
import Cookie from "js-cookie";

let Token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMxMTkzMzQ2LCJqdGkiOiJhNGUzNmY3MWNlMDI0ZmVlYjNhY2MzMzkzMTExOGM1MSIsInVzZXJfaWQiOjJ9.6au_KR08SBERdlNfSrQ9Ygcwl3rugdumyvv8piZ60EA";

let API: string | undefined = process.env.REACT_APP_BACK_PRODUCT_URL;
if (!API) {
    API = "https://product.swisskischool.ch";
}

if (Cookie.get("authentication")) {
    Token = JSON.parse(Cookie.get("authentication") || "");
}

const getDomain = (): string => {
    return window.location.hostname;
};

const axiosapi = axios.create({
    baseURL: API,
    timeout: 40000,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
    },
});

axiosapi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { config, response } = error;
        let status: number | undefined = undefined;
        let data: { code?: string } | null = null;

        if (response && response.status) status = response.status;
        if (response && response.data) data = response.data;

        if (
            status === 401 &&
            data?.code &&
            (data.code === "bad_authorization_header" || data.code === "token_not_valid")
        ) {
            // Login webadmin to API
            return axiosapi
                .post(`${API}/api/token`, {
                    "username": "front",
                    "password": "gE6WU8kA"
                })
                .then((response) => {
                    const accessToken = response.data.access;
                    Cookie.set("authentication", JSON.stringify(accessToken), { domain: getDomain() });
                    // update config and axiosapi Authorization header
                    config.headers.Authorization = `Bearer ${accessToken}`;
                    axiosapi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
                    return axiosapi.request(config);
                });
        }
        return Promise.reject(error);
    }
);

export default axiosapi;
