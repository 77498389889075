import { BackTop, Button, Drawer, Layout } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { CSSProperties, ReactNode } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import FAIcon from '../FAIcon';
import Breadcrumb from './breadcrumb';
import SideMenu from './sideMenu';

interface Props {
    breadcrumb: { title: string; link: string; }[];
    children: ReactNode;
    isSmartphone: boolean;
    collapsed: boolean;
    actions?: ReactNode[];
    dispatch: StoreDispatch;
    backTop?: boolean;
    className?: string;
    rightClassName?: string;
    style?: CSSProperties;
}

interface State {
    openDrawer: boolean;
}

/**
 * Component that represent the container of most pages
 */
class Container extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            openDrawer: false,
        };
    }

    componentDidMount = () => {
        //scroll up
        const anchor = document.getElementById('anchor');
        if (anchor) anchor.scrollIntoView();
    };

    /**
     * Toggle the side menu navigation on smartphone opened state
     */
    toggleOpenDrawer = () => this.setState({ openDrawer: !this.state.openDrawer });

    render() {
        const { Sider, Content } = Layout;
        return (
            <Layout className="container-layout" style={{ marginLeft: this.props.isSmartphone ? '0px' : (this.props.collapsed ? '80px' : '300px') }}>
                {
                    this.props.isSmartphone ?
                        null :
                        <Sider width="300" trigger={null} collapsible collapsed={this.props.collapsed} style={{ position: 'fixed', maxHeight: '100%', height: '100%', left: 0, zIndex: 2 }}>
                            <SideMenu />
                        </Sider>
                }
                <Layout className={`main-container ${this.props.rightClassName ?? ''}`}>
                    {this.props.backTop ? <BackTop /> : null}
                    {
                        this.props.isSmartphone &&
                        <Header className="container-header">
                            <Breadcrumb items={this.props.breadcrumb} />
                            {
                                this.props.actions && this.props.actions.length > 0 ?
                                    <div className="container-actions">
                                        {this.props.actions.map((a, i) => <span className="container-actions-item" key={`container-actions-item-${i}`}>{a}</span>)}
                                    </div>
                                    : null
                            }
                            <Button icon={<FAIcon prefix='fad' name='bars' />} type="link" onClick={this.toggleOpenDrawer} style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
                            <Drawer
                                placement="right"
                                closable={false}
                                onClose={this.toggleOpenDrawer}
                                visible={this.state.openDrawer}>
                                <SideMenu />
                            </Drawer>
                        </Header>
                    }
                    {/* :
                            <Header className="container-header">
                                <Button type="primary" onClick={this.toggleCollapsed} style={{boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)'}}>
                                    {
                                        this.props.collapsed ?
                                        <MenuUnfoldOutlined />
                                        :
                                        <MenuFoldOutlined />
                                    }
                                </Button>
                                <Breadcrumb items={this.props.breadcrumb}/>
                                {
                                    this.props.actions && this.props.actions.length > 0 ?
                                    <div className="container-actions">
                                        {this.props.actions.map((a, i) => <span className="container-actions-item" key={`container-actions-item-${i}`}>{a}</span>)}
                                    </div>
                                    : null
                                }
                            </Header>
                    } */}
                    <Content style={this.props.style} id="container" className={`container-content-background ${this.props.className ? this.props.className : ''}`}>
                        {this.props.children}
                    </Content>
                    {/* <Footer className="container-footer">
                        {`© ${moment().year()} - Sunkhronos - All rights reserved`}
                    </Footer> */}
                </Layout >
            </Layout >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    collapsed: state.window.navigationCollapsed,
});

export default connect(mapStateToProps)(Container);