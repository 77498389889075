import { Fab } from "@mui/material";
import { Avatar, Image, Input } from "antd";
import moment from "moment";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import '../../../../styles/phone-overview.css';
import { MobileAppSettings, MobileAppTheme } from "../../../../utils/types/generalTypes";
import { colorIsBright } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";

interface Props {
    theme: MobileAppTheme;
    mobileAppSettings: MobileAppSettings;
    pageTitle: string;
    page: 'newsfeed' | 'emergencies',
    sideMenuOpen?: boolean,
    data?: any;
}

export const Phone = (props: Props) => {
    const { theme, mobileAppSettings, pageTitle, page, data } = props;

    const TopPageTitle = useCallback(() => {
        const fontColor = colorIsBright(theme.primary) ? "black" : 'white';
        return (
            <div className="phone-overview-phone-navbar" style={theme !== undefined ? { backgroundColor: theme.primary } : {}}>
                <div className='phone-overview-phone-navbar-pictos'>
                    <FAIcon prefix='fad' name='bars' color={fontColor} style={{ cursor: 'pointer' }} />
                    <Image preview={false} style={(theme !== undefined && theme.logo_rectangle) ? { borderRadius: theme.logo_rounded ? 30 : 0, marginRight: 2, maxWidth: 42, maxHeight: 32 } : { marginRight: 2, borderRadius: theme.logo_rounded ? 30 : 0, width: 32, height: 32 }} src={(typeof theme?.logo === 'string') ? theme.logo : URL.createObjectURL(theme.logo)} />
                </div>
                <div className='phone-overview-phone-navbar-title-name'>
                    <span style={{ fontSize: "12px", color: fontColor }}>{pageTitle}</span>
                </div>
            </div>
        );
    }, [pageTitle, theme]);

    const TopInputField = useCallback(() => {
        return (
            <div className="phone-overview-phone-header">
                <Input addonBefore={<FAIcon prefix='fad' name='magnifying-glass' />} size='small' placeholder='Recherche...' />
            </div>
        );
    }, []);

    const BottomPageMenu = useCallback((props: { activeKey?: 'newsfeed' | 'calendar' | 'messages' | 'userfeed'; }) => {
        return (
            <div className="phone-overview-phone-footer">
                {mobileAppSettings?.displayNewsFeed && <span>{props.activeKey === 'newsfeed' ? <FAIcon prefix='fad' name='house' /> : <FAIcon prefix='far' name='house' />}</span>}
                {mobileAppSettings?.displayPlanning && <span>{props.activeKey === 'calendar' ? <FAIcon prefix='fad' name='calendar' /> : <FAIcon prefix='far' name='calendar' />}</span>}
                <span>{props.activeKey === 'messages' ? <FAIcon prefix='fad' name='envelope' /> : <FAIcon prefix='far' name='envelope' />}</span>
                {mobileAppSettings?.displayUserFeed && <span>{props.activeKey === 'userfeed' ? <FAIcon prefix='fad' name='newspaper' /> : <FAIcon prefix='far' name='newspaper' />}</span>}
            </div>
        );
    }, [mobileAppSettings.displayNewsFeed, mobileAppSettings.displayPlanning, mobileAppSettings.displayUserFeed]);

    const PageContentNewsFeed = useCallback(() => {
        return (
            <>
                <div className="phone-overview-phone-news-block">
                    <div className="phone-overview-phone-news-block-title">
                        <Avatar size={'small'} icon={<FAIcon prefix='fad' name='user' />} />
                        <div style={{ width: '100%' }}>
                            <div className="phone-overview-phone-news-details">
                                <span><FormattedMessage defaultMessage={'Company administrator'} /></span>
                                <span>{moment('2024-01-01 17:48').format('HH[h]mm')}</span>
                            </div>
                            <div className="phone-overview-phone-news-details2">
                                <span><FormattedMessage defaultMessage={'To: All members'} /></span>
                                <span>{moment('2023-03-24').format('ddd DD.MM.YYYY')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="phone-overview-phone-news-block-content-title" style={{ width: "100%" }}>
                        <span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Important information!'} /></span>
                        <p><FormattedMessage defaultMessage={'Hello,'} /></p>
                        <br />
                        <p><FormattedMessage defaultMessage={"Here's a list of things to bring to ....."} /></p>
                        <ul>
                            <li><FormattedMessage defaultMessage={'Item 1'} /></li>
                            <li><FormattedMessage defaultMessage={'Item 2'} /></li>
                            <li><FormattedMessage defaultMessage={'Item 3'} /></li>
                        </ul>
                        <br />
                        <p><FormattedMessage defaultMessage={'Have a nice weekend!'} /></p>
                        <div className='phone-overview-phone-likes-button-container'>
                            <div className='phone-overview-phone-likes-button'>
                                <FAIcon prefix='fas' name='heart' /> <span><FormattedMessage defaultMessage={'2 likes'} /></span>
                            </div>
                            <div className='phone-overview-phone-likes-button'>
                                <FAIcon prefix='fas' name='message' /> <span><FormattedMessage defaultMessage={'1 comment'} /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="phone-overview-phone-dots">{"..."}</p>
                </div>
            </>
        );
    }, []);
    const PageContentEmergencies = useCallback(() => {
        const datta: {
            headerText?: string,
            buttonText?: string,
            contacts: {
                name?: string,
                location?: string;
            }[];
        } = data;
        const buttonFontColor = colorIsBright(theme.button) ? "black" : 'white';

        return (
            <>
                <div className="emergency-phone-header" >
                    <pre style={{ width: '100%', fontFamily: 'inherit', whiteSpace: 'break-spaces', fontSize: '9px', textAlign: 'center', overflowWrap: "break-word" }}>{datta.headerText ?? "<Texte de l'en-tête>"}</pre>
                </div>
                <div>
                    <span className="emergency-phone-button-container">
                        <p style={{ cursor: 'pointer', textAlign: 'center', paddingRight: 10, paddingLeft: 10, minWidth: 110, maxWidth: 115, minHeight: 28, backgroundColor: theme !== undefined ? theme.button : 'var(--button-color)', color: buttonFontColor, fontSize: '11px' }} className="emergency-phone-button">
                            {datta.buttonText ?? "<Titre du bouton>"}
                        </p>
                    </span>
                    {datta.contacts.slice(0, 5).map((c, i) => (
                        <div style={{ borderRadius: '30px', marginLeft: 5, marginRight: 5 }} className="emergency-phone-contact" key={`emergency-phone-contact-${i}`}>
                            <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5, fontSize: '11px' }}>
                                <p>{c.name ?? ''}</p>
                                {/*<p style={{ fontSize: '12px' }}>{c.location??''}</p>*/}
                            </div>
                            <div style={{ borderRadius: '50%', backgroundColor: 'var(--planning-outside-period)', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FAIcon prefix='fad' name='phone' color='green' /></div>
                        </div>
                    ))}
                    <p className="emergency-phone-dots">{"..."}</p>

                </div>
            </>
        );
    }, [data, theme]);

    const RenderNewsFeed = useCallback(() => {
        const buttonFontColor = colorIsBright(theme.button) ? "black" : 'white';

        return (
            <>
                <TopPageTitle />
                <TopInputField />
                <PageContentNewsFeed />
                <Fab className='phone-overview-phone-plus-button' disableRipple sx={{ zIndex: 990, color: buttonFontColor, width: 30, height: 30, minHeight: 30, position: 'absolute', backgroundColor: theme !== undefined ? theme.button : 'var(--button-color)', fontSize: '18px', fontWeight: 600, '&:hover': { backgroundColor: theme !== undefined ? theme.secondary : 'var(--secondary-color)' } }} size='small' aria-label="edit">
                    <FAIcon prefix='far' name='plus' />
                </Fab>
                <BottomPageMenu activeKey={'newsfeed'} />
            </>
        );
    }, [BottomPageMenu, PageContentNewsFeed, TopInputField, TopPageTitle, theme]);


    const RenderEmergencies = useCallback(() => {
        return (
            <>
                <TopPageTitle />
                <div style={{ overflowY: 'scroll', height: 405 }}>
                    <PageContentEmergencies />
                </div>
                <BottomPageMenu />
            </>
        );
    }, [BottomPageMenu, PageContentEmergencies, TopPageTitle]);


    return (
        <div className="phone-overview-phone">
            {page === 'newsfeed' ?
                <RenderNewsFeed />
                :
                page === 'emergencies' &&
                <RenderEmergencies />
            }



        </div>

    );
};
export default Phone;