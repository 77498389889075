
import { faBath, faBed, faBedBunk, faBicycle, faBrush, faBedFront as fadBedFront, faTree as fadTree, faEngine, faEnvelope, faFile, faFireExtinguisher, faFolder, faGlass, faHatChef, faHotel, faHouse, faPanFrying, faRestroom, faRoute, faStrawberry, faTire, faToilet, faTruckMoving, faWashingMachine } from '@fortawesome/pro-duotone-svg-icons';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';

// Vitre différentes


/**
 * Pack used for documents
 * ----------- !!!! WARNING !!!! -----------------
 * Ce fichier doit être copié dans Sunkhronos-timeclock à chaque changement. Une mise à jour des applications doit être faite à chaque changements
 * This file must be copied over to Sunkhronos-timeclock at every changes. An update is required for mobile applications everytime this file changes
 * -----------------------------------------------
 */
export const productivityIconPack = FA.createIconPack(
    IconPacks.Productivity,
    faHouse,
    faHotel,
    faRestroom,
    faToilet,
    faBath,
    faGlass,
    fadBedFront,
    faBed,
    faBedBunk,
    fadTree,
    faTire,
    faBicycle,
    faEngine,
    faFolder,
    faFireExtinguisher,
    faFile,
    faPanFrying,
    faRoute,
    faHatChef,
    faTruckMoving,
    faWashingMachine,
    faBrush,
    faStrawberry,
    faEnvelope,
);