
import { Empty, EmptyProps } from "antd";
import { useIntl } from "react-intl";
import EmptyDataPng from '../../../images/illustrations/empty-data.png';
import EmptyDataWebp from '../../../images/illustrations/empty-data.webp';
import ResponsiveImage from './ResponsiveImage/responsiveImage';

interface Props extends EmptyProps {
}
const EmptyData = (props: Props) => {
    const intl = useIntl();
    return (
        <Empty
            style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            imageStyle={{ width: '450px', height: 'auto' }}
            image={
                <ResponsiveImage
                    sources={[{ src: EmptyDataWebp }]}
                    fallbackSrc={EmptyDataPng}
                    alt={intl.formatMessage({ defaultMessage: "Empty" })}
                />
            }
            {...props} />
    );
};

export default EmptyData;