import { Space, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { CompanyTypes } from '../../utils/constants';
import { Company, ContainerTabsState, RouterProps, TabItem, User } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateSidebars, Sidebar } from '../common/navigations/containerTabs';
import { MenuLinks } from '../common/navigations/sideMenu';
import ProductivityReport from './productivityReport';
import ReportTabv2 from './reportTabv2';
import PaidHours from './v2/common/paidHours';
import BalanceHours from './v2/hotelAndCatering/balanceHours';
import MonthlyReport from './v2/hotelAndCatering/monthlyReport';
import ReportHotelAndCatering from './v2/hotelAndCatering/report';
import BalanceYear from './v2/normal/balanceYear';
import HoursTab from './v2/normal/hoursTab';
import ReportNormal from './v2/normal/report';
import ReportSecurity from './v2/security/report';
import VacationsTabv2 from './vacationsTabv2';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps { }
interface State extends ContainerTabsState {
}

export enum ReportsManagementTabLink {
    HOURS_REPORTS = "hours-reports",
    CCNT_REPORTS = "ccnt-reports",
    CCNT_BALANCE = "ccnt-balance",
    CCT_REPORTS = "cct-reports",
    DAY_OFF_REPORTS = "day-off-reports",
    NORMAL_REPORTS = "normal-reports",
    MONTHLY_REPORTS = "monthly-reports",
    QUADRIGIS_REPORTS = "quadrigis",
    PRODUCTIVITY_REPORTS = "productivity-report",
    PAID_HOURS = 'paid-hours',
    BALANCE_YEAR = "balance-year"
}

export const getMenusForCompanyAndUser = (user: User | undefined, company: Company | undefined, productivityEnabled = false) => {
    const menus: ReportsManagementTabLink[] = [];
    switch (company?.type) {
        case CompanyTypes.NORMAL:
            menus.push(ReportsManagementTabLink.HOURS_REPORTS);
            menus.push(ReportsManagementTabLink.DAY_OFF_REPORTS);
            menus.push(ReportsManagementTabLink.NORMAL_REPORTS);
            if (productivityEnabled)
                menus.push(ReportsManagementTabLink.PRODUCTIVITY_REPORTS);
            if ([47, 55].includes(company.id))
                menus.push(ReportsManagementTabLink.BALANCE_YEAR);
            break;
        case CompanyTypes.CCNT:
            menus.push(ReportsManagementTabLink.CCNT_REPORTS);
            menus.push(ReportsManagementTabLink.CCNT_BALANCE);
            menus.push(ReportsManagementTabLink.MONTHLY_REPORTS);
            if (company.enableNormalReport) {
                menus.push(ReportsManagementTabLink.NORMAL_REPORTS);
            }
            if (productivityEnabled)
                menus.push(ReportsManagementTabLink.PRODUCTIVITY_REPORTS);
            if (user === undefined || [2, 3].includes(user?.role))
                menus.push(ReportsManagementTabLink.PAID_HOURS);
            break;
        case CompanyTypes.OLD:
            menus.push(ReportsManagementTabLink.MONTHLY_REPORTS);
            menus.push(ReportsManagementTabLink.DAY_OFF_REPORTS);
            if (productivityEnabled)
                menus.push(ReportsManagementTabLink.PRODUCTIVITY_REPORTS);
            break;
        case CompanyTypes.SECURITY:
            menus.push(ReportsManagementTabLink.CCT_REPORTS);
            if (company.enableNormalReport) {
                menus.push(ReportsManagementTabLink.NORMAL_REPORTS);
            }
            if (productivityEnabled)
                menus.push(ReportsManagementTabLink.PRODUCTIVITY_REPORTS);
            break;
        default:
            break;
    }
    return menus;
};


class ReportsManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tabItems: this.buildMenus(getMenusForCompanyAndUser(props.currentUser, props.company, props.productivityEnabled), props.company)
        };
    }

    buildMenus = (menus: ReportsManagementTabLink[], company: Company | undefined) => {
        const tabItems: TabItem[] = [];
        let menu: TabItem | undefined;
        for (const m of menus) {
            menu = undefined;
            switch (m) {
                case ReportsManagementTabLink.HOURS_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.HOURS_REPORTS,
                        label: <Space><FAIcon prefix={"fad"} name='clock' /><FormattedMessage defaultMessage={"Hours"} /></Space>,
                        children: <HoursTab keyLink={ReportsManagementTabLink.HOURS_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.DAY_OFF_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.DAY_OFF_REPORTS,
                        label: <Space><FAIcon prefix={"fad"} name='island-tropical' /><FormattedMessage defaultMessage={"Vacations"} /></Space>,
                        children: <VacationsTabv2 />
                    };
                    break;
                case ReportsManagementTabLink.NORMAL_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.NORMAL_REPORTS,
                        label: <Space><FAIcon prefix={"fad"} name='chart-simple' /><FormattedMessage defaultMessage={"Monthly reports"} /></Space>,
                        children: <ReportNormal keyLink={ReportsManagementTabLink.NORMAL_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.BALANCE_YEAR:
                    menu = {
                        key: ReportsManagementTabLink.BALANCE_YEAR,
                        label: <Space><FAIcon prefix={"fad"} name='memo-circle-info' /><FormattedMessage defaultMessage={"Close out the year"} /></Space>,
                        children: <BalanceYear keyLink={ReportsManagementTabLink.BALANCE_YEAR} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.CCNT_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.CCNT_REPORTS,
                        label: <Space><FAIcon prefix={"fad"} name='chart-simple' /><FormattedMessage defaultMessage={"N-CLA"} /></Space>,
                        children: <ReportHotelAndCatering keyLink={ReportsManagementTabLink.CCNT_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.MONTHLY_REPORTS:
                    if (company?.type === CompanyTypes.OLD)
                        menu = {
                            key: ReportsManagementTabLink.MONTHLY_REPORTS,
                            label: <Space><FAIcon prefix={"fad"} name='file-spreadsheet' /><FormattedMessage defaultMessage={"Monthly reports"} /></Space>,
                            children: <ReportTabv2 />
                        };
                    else
                        menu = {
                            key: ReportsManagementTabLink.MONTHLY_REPORTS,
                            label: <Space className='space-tooltip'>
                                <FAIcon prefix={"fad"} name='file-spreadsheet' /><FormattedMessage defaultMessage={"Monthly reports"} />
                                <Tooltip title={<FormattedMessage defaultMessage={'Monthly reports to create pay slips'} />}>
                                    <FAIcon prefix='fad' name='info' className="tab-tooltip-info-new" />
                                </Tooltip>
                            </Space>,
                            children: <MonthlyReport keyLink={ReportsManagementTabLink.MONTHLY_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                        };
                    break;
                case ReportsManagementTabLink.PAID_HOURS:
                    menu = {
                        key: ReportsManagementTabLink.PAID_HOURS,
                        label: <Space><FAIcon prefix={"fad"} name='business-time' /><FormattedMessage defaultMessage={"Paid overtimes"} /></Space>,
                        children: <PaidHours keyLink={ReportsManagementTabLink.PAID_HOURS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.CCT_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.CCT_REPORTS,
                        label: <Space><FAIcon prefix={"fad"} name='chart-simple' /><FormattedMessage defaultMessage={"CCT"} /></Space>,
                        children: <ReportSecurity keyLink={ReportsManagementTabLink.CCT_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                    };
                    break;
                case ReportsManagementTabLink.PRODUCTIVITY_REPORTS:
                    menu = {
                        key: ReportsManagementTabLink.PRODUCTIVITY_REPORTS,
                        label: <Space><FAIcon prefix='fad' name='chart-mixed-up-circle-dollar' /><FormattedMessage defaultMessage={"Productivity"} /></Space>,
                        children: <ProductivityReport keyLink={ReportsManagementTabLink.PRODUCTIVITY_REPORTS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />,
                        hideMobile: true
                    };
                    break;
                case ReportsManagementTabLink.CCNT_BALANCE:
                    menu = {
                        key: ReportsManagementTabLink.CCNT_BALANCE,
                        label: <Space><FAIcon prefix={"fad"} name='calculator' /><FormattedMessage defaultMessage={'Balances N-CLA'} /></Space>,
                        children: <BalanceHours keyLink={ReportsManagementTabLink.CCNT_BALANCE} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />,
                    };
                    break;
                default:
                    // menu = <p>Not implemented yet.</p>;
                    break;
            }
            if (menu !== undefined)
                tabItems.push(menu);
        }

        return tabItems;
    };

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));
    addOrUpdateSidebars = (content: Sidebar[], key: string) => this.setState(prevState => addOrUpdateSidebars(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfSidebars } = this.state;
        const { intl } = this.props;
        return (
            // <Can rule={Rules.Planning.Visit} redirect="/dashboard">
            <ContainerTabs
                contentClassName='__new-right-panel'
                breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Work control' }), link: `/${MenuLinks.Reports}/${this.props.match.params.tab}` }]}
                tabItems={tabItems}
                tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                tabSidebars={tabListOfSidebars?.find(item => item.key === this.props.match.params.tab)?.content}
            />
            // </Can>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    productivityEnabled: state.productivity.enabled
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(ReportsManagement));
