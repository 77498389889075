import { Card as CardA, Col, Empty, Popconfirm, Popover, Row, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import uniqBy from 'lodash/uniqBy';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TimeClockDeviceStates, TimeClockDeviceTypes } from '../../../../../../utils/constants';
import Network from '../../../../../../utils/network';
import { RouterProps, TimeClockDevice } from '../../../../../../utils/types/generalTypes';
import { ApplicationState, TeamManagementDispatchProps, UserDispatchProps } from '../../../../../../utils/types/storeTypes';
import { showNotification } from '../../../../../../utils/utils';
import { IntlProps } from '../../../../../app/LanguageProvider';
import FAIcon from '../../../../../common/FAIcon';
import ActionsToolbar from '../../../../../common/fields/ActionsToolbar/actionsToolbar';
import CircleButton from '../../../../../common/fields/circleButton';
import VirtualTable from '../../../../../common/general/virtualTable';
import { dashboardDisableFilters as dashboardFilters } from '../../../../../courseManagement/tableSearch';
import InitNewDeviceModal from './timeClockDevices/addNewDeviceModal';
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {

}

type Props = RouterProps & IProps & ReduxProps & TeamManagementDispatchProps & UserDispatchProps & IntlProps;

interface State {
    isTimeClockDevicesLoading: boolean;
    updatedAt?: Moment;
    timeClockDevices: TimeClockDevice[];
    selectedTimeClockDevices: number[];
    active: boolean;
    openInitModal: boolean;
}

class TimeClockDevices extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isTimeClockDevicesLoading: false,
            updatedAt: undefined,
            timeClockDevices: [],
            selectedTimeClockDevices: [],
            active: true,
            openInitModal: false,
        };

    }
    componentDidMount() {
        this.getTimeClockDevices();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.active !== this.state.active) {
            this.getTimeClockDevices();
        }
    }

    getTimeClockDevices = (force = false) => {
        if (force || !this.state.isTimeClockDevicesLoading) {
            this.setState({ isTimeClockDevicesLoading: true }, () => {
                Network.getTimeClockDevices(TimeClockDeviceTypes.TIMECLOCKAPP, this.state.active ? TimeClockDeviceStates.ACTIVE : TimeClockDeviceStates.NOTACTIVE).then(
                    response => {
                        this.setState({ isTimeClockDevicesLoading: false, timeClockDevices: response.data, updatedAt: moment() });
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the timeclock devices' }), "warning");
                        this.setState({ isTimeClockDevicesLoading: false });
                    }
                );
            });

        }

    };

    close_modal = (refresh = false) => {
        this.setState({ openInitModal: false }, () => {
            refresh && this.getTimeClockDevices(true);
        });
    };

    render() {
        const { isSmartphone, height, intl, company } = this.props;
        const { isTimeClockDevicesLoading, selectedTimeClockDevices, active, timeClockDevices, openInitModal } = this.state;
        let tableHeight = height - 500;
        if (tableHeight < 250) tableHeight = 250;

        const max_physical_devices = company ? company.timeClockMaxPhysicalDevices : 0;
        let nr_physical_devices = 0;
        timeClockDevices.forEach(tcd => {
            if (tcd.deviceType === TimeClockDeviceTypes.TIMECLOCKAPP) {
                nr_physical_devices++;
            }
        });

        const TimeClockDevicesColumns: ColumnsType<TimeClockDevice> = [
            // TODO
            // {
            //     title: <FormattedMessage defaultMessage={'Active'} />,
            //     key: 'active',
            //     className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_50' : '__width_50'}`,
            //     sorter: (a: TimeClockDevice, b: TimeClockDevice) => !a.active ? -1 : !b.active ? 1 : a.active < b.active ? -1 : 1,
            //     render: (ob: TimeClockDevice) => ob.active ? <FAIcon prefix='fad' name='circle-check' /> : <FAIcon prefix='fad' name='circle-xmark' />,
            // },
            {
                title: <FormattedMessage defaultMessage={'Name'} />,
                key: 'name',
                className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_180' : '__width_220'}`,
                onFilter: (value, record) => {
                    return record.name!
                        .toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase());
                },
                ...dashboardFilters<TimeClockDevice>(!this.props.isSmartphone, uniqBy(this.state.timeClockDevices.map(tcd => ({ text: tcd.name, value: tcd.name })).sort((a, b) => a.text.localeCompare(b.text)), 'text')),
                sorter: (a: TimeClockDevice, b: TimeClockDevice) => !a.name ? -1 : !b.name ? 1 : a.name < b.name ? -1 : 1,
                render: (ob: TimeClockDevice) => {

                    return (
                        <Popover
                            overlayStyle={{ maxWidth: '600px' }}
                            content={
                                <CardA size='small' title={<FormattedMessage defaultMessage={'Details'} />}>
                                    <Row gutter={[10, 10]}>

                                        <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='tablet-screen' /></Col>
                                        <Col xs={{ span: 0 }} md={{ span: 6 }}><FormattedMessage defaultMessage={'Identifier'} />{':'}</Col>
                                        <Col xs={{ span: 20 }} md={{ span: 16 }}>{ob.uid}</Col>

                                        <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='microchip' /></Col>
                                        <Col xs={{ span: 0 }} md={{ span: 6 }}><FormattedMessage defaultMessage={'Operating system'} />{':'}</Col>
                                        <Col xs={{ span: 20 }} md={{ span: 16 }}>{ob.os}</Col>

                                        <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='code-commit' /></Col>
                                        <Col xs={{ span: 0 }} md={{ span: 6 }}><FormattedMessage defaultMessage={'Version'} />{':'}</Col>
                                        <Col xs={{ span: 20 }} md={{ span: 16 }}>{ob.version}</Col>

                                        {/* <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='globe-pointer' /></Col>
                                        <Col xs={{ span: 0 }} md={{ span: 6 }}><FormattedMessage defaultMessage={'User platform'} />{':'}</Col>
                                        <Col xs={{ span: 20 }} md={{ span: 16 }}>{ob.userAgent}</Col> */}

                                    </Row>
                                </CardA>
                            }
                        >
                            <div className="both-center">{ob.name}</div>
                        </Popover>
                    );
                },
            },
            {
                title: <FormattedMessage defaultMessage={'Description'} />,
                key: 'description',
                className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_180' : '__width_220'}`,
                sorter: (a: TimeClockDevice, b: TimeClockDevice) => !a.description ? -1 : !b.description ? 1 : a.description < b.description ? -1 : 1,
                render: (ob: TimeClockDevice) => {
                    return <div className="both-center">{ob.description}</div>;
                },
            },
            // TODO
            // {
            //     title: <FormattedMessage defaultMessage={'Type'} />,
            //     key: 'deviceType',
            //     className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_180' : '__width_220'}`,
            //     defaultSortOrder: 'descend',
            //     onFilter: (value, record) => {
            //         return record.deviceType!
            //             .toString()
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase());
            //     },
            //     ...dashboardFilters<TimeClockDevice>(!this.props.isSmartphone, [{ 'text': 'Tous', 'value': TimeClockDeviceTypes.ALL }, { 'text': 'Application Mobile Sunkhronos', 'value': TimeClockDeviceTypes.MOBILLEAPP }, { 'text': 'Badgeuse', 'value': TimeClockDeviceTypes.TIMECLOCKAPP }, { 'text': 'Sunkhronos Admin', 'value': TimeClockDeviceTypes.SUNKHRONOS }]),
            //     sorter: (a: TimeClockDevice, b: TimeClockDevice) => !a.deviceType ? -1 : !b.deviceType ? 1 : a.deviceType < b.deviceType ? -1 : 1,
            //     render: (ob: TimeClockDevice) => {
            //         return <div className="both-center">
            //             {
            //                 ob.deviceType === TimeClockDeviceTypes.MOBILLEAPP ?
            //                     <FormattedMessage defaultMessage={'Sunkhronos Mobile App'} />
            //                     :
            //                     ob.deviceType === TimeClockDeviceTypes.TIMECLOCKAPP ?
            //                         <FormattedMessage defaultMessage={'Timeclock'} />
            //                         :
            //                         ob.deviceType === TimeClockDeviceTypes.SUNKHRONOS ?
            //                             <FormattedMessage defaultMessage={'Sunkhronos Admin'} />
            //                             :
            //                             ''
            //             }
            //         </div>;
            //     },
            // },
            {
                title: <FormattedMessage defaultMessage={'Usage'} />,
                key: 'usage',
                className: `timeclock-control-table-fixed-odd ${isSmartphone ? '__width_180' : '__width_220'}`,
                sorter: (a: TimeClockDevice, b: TimeClockDevice) => {
                    const aTotal = (a.nrManuallySetEventClocked ?? 0) + (a.nrStartedEventClocked ?? 0) + (a.nrStopppedEventClocked ?? 0);
                    const bTotal = (b.nrManuallySetEventClocked ?? 0) + (b.nrStartedEventClocked ?? 0) + (b.nrStopppedEventClocked ?? 0);

                    return !aTotal ? -1 : !bTotal ? 1 : aTotal < bTotal ? -1 : 1;
                },
                render: (ob: TimeClockDevice) => {
                    const total = (ob.nrManuallySetEventClocked ?? 0) + (ob.nrStartedEventClocked ?? 0) + (ob.nrStopppedEventClocked ?? 0);
                    return (
                        <Popover
                            overlayStyle={{ maxWidth: '400px' }}
                            content={
                                <CardA size='small' title={<FormattedMessage defaultMessage={'{count, plural, one {{count} action made} other {{count} actions made}}'} values={{ count: total }} />}>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='circle-play' /></Col>
                                        <Col xs={{ span: 4 }} md={{ span: 2 }}>{ob.nrStartedEventClocked}</Col>
                                        <Col xs={{ span: 16 }} md={{ span: 20 }}><FormattedMessage defaultMessage={'events started'} /></Col>
                                        <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='circle-pause' /></Col>
                                        <Col xs={{ span: 4 }} md={{ span: 2 }}>{ob.nrStopppedEventClocked}</Col>
                                        <Col xs={{ span: 16 }} md={{ span: 20 }}><FormattedMessage defaultMessage={'events stopped'} /></Col>
                                        {
                                            ob.deviceType != TimeClockDeviceTypes.TIMECLOCKAPP &&
                                            <>
                                                <Col xs={{ span: 4 }} md={{ span: 2 }}><FAIcon prefix='fad' name='pencil' /></Col>
                                                <Col xs={{ span: 4 }} md={{ span: 2 }}>{ob.nrManuallySetEventClocked}</Col>
                                                <Col xs={{ span: 16 }} md={{ span: 20 }}><FormattedMessage defaultMessage={'manualy clocked events'} /></Col>
                                            </>
                                        }
                                    </Row>
                                </CardA>
                            }
                        >
                            <div className="both-center">{total}</div>
                        </Popover>
                    );
                },
            },
            // TODO
            // {
            //     title: (
            //         <span><FormattedMessage defaultMessage={'Actions'} />
            //             <Tooltip title={
            //                 <div>
            //                     <p><FormattedMessage defaultMessage={'Confirm, modify or reject'} /></p>
            //                     <p><FormattedMessage defaultMessage={'Unavailable actions:'} /></p>
            //                     <p><FormattedMessage defaultMessage={' - Timeclock in progress'} /></p>
            //                     <p><FormattedMessage defaultMessage={' - Insufficient rights'} /></p>
            //                 </div>
            //             }>
            //                 <FAIcon prefix='fad' name='info' className="tab-tooltip-info" />
            //             </Tooltip>
            //         </span>
            //     ),
            //     key: 'modifier',
            //     className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_120' : '__width_180'}`,
            //     render: (timeclockDevice: TimeClockDevice) => {
            //         const total = (timeclockDevice.nrManuallySetEventClocked ?? 0) + (timeclockDevice.nrStartedEventClocked ?? 0) + (timeclockDevice.nrStopppedEventClocked ?? 0);

            //         return (
            //             <>
            //                 <div key={`modifier-div-object-${timeclockDevice.uid}`}>
            //                     <Space key={`modifier-space-object-${timeclockDevice.uid}`}>
            //                         {timeclockDevice.active ?
            //                             <CircleButton
            //                                 small
            //                                 disabled
            //                                 key={`modifier-circlebutton-object-${timeclockDevice.uid}-disable`}
            //                                 icon={<FAIcon prefix='fad' name='display-slash' />}
            //                                 title={intl.formatMessage({ defaultMessage: 'Deactivate device' })}
            //                                 onClick={() => console.log('Désactiver l\'appareil')}
            //                             />
            //                             :
            //                             <CircleButton
            //                                 small
            //                                 disabled
            //                                 key={`modifier-circlebutton-object-${timeclockDevice.uid}-enable`}
            //                                 icon={<FAIcon prefix='fad' name='display' />}
            //                                 title={intl.formatMessage({ defaultMessage: 'Activate device' })}
            //                                 onClick={() => console.log('Activer l\'appareil')}
            //                             />
            //                         }
            //                         {total === 0 &&
            //                             <DeleteButton
            //                                 text={<FormattedMessage defaultMessage={'Do you want to delete this device permanently?'} />}
            //                                 key="template-modal-delete-color"
            //                                 onConfirm={() => console.log('Supprimer l\'appareil')}
            //                                 placement="topRight"
            //                                 buttonPlacement="right"
            //                             />
            //                         }
            //                     </Space>
            //                 </div>
            //             </>
            //         );
            //     }
            // }
        ];

        return (
            <>
                <div>
                    <Space direction='horizontal'>
                        {/* <Tooltip title={<Space><FAIcon prefix='fad' name='display-slash' />{active ? <FormattedMessage defaultMessage={'Only active devices'} /> : <FormattedMessage defaultMessage={'Only inactive devices'} />}</Space>}>
                            <Switch checked={!active} onChange={() => this.setState((state) => ({ active: !state.active }))} style={{ marginRight: '5px' }} disabled={isTimeClockDevicesLoading} />
                        </Tooltip> */}
                        <CircleButton
                            small
                            key={`modifier-circlebutton-object-add-device`}
                            icon={<FAIcon prefix='far' name='circle-plus' />}
                            title={intl.formatMessage({ defaultMessage: 'Add a timeclock' })}
                            placement='topRight'
                            // disabled={isTimeClockDevicesLoading}
                            disabled={(nr_physical_devices >= max_physical_devices) || isTimeClockDevicesLoading}
                            onClick={() => this.setState({ openInitModal: true })}
                        />
                    </Space>
                    <VirtualTable
                        loading={isTimeClockDevicesLoading}
                        className='__time-clock-table'
                        size='small'
                        rowKey={(i: TimeClockDevice) => i.uid}
                        // TODO
                        // rowSelection={{
                        //     type: "checkbox",
                        //     onChange: (keys: React.Key[]) => this.setState({ selectedTimeClockDevices: (keys as number[]) }),
                        //     selectedRowKeys: selectedTimeClockDevices,
                        // }}
                        dataSource={timeClockDevices}
                        scroll={{ x: true, y: tableHeight }}
                        columns={TimeClockDevicesColumns}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No device'} />} /> }}
                    />
                    <ActionsToolbar
                        title={`${selectedTimeClockDevices.length.toString()} ${intl.formatMessage({ defaultMessage: "devices" })}`}
                        actions={[
                            <>
                                {
                                    active ?
                                        <Popconfirm
                                            key="eventclocked-popup-button-confirm"
                                            title={<FormattedMessage defaultMessage={'{count, plural, one {{count} device is about to be deactivated. Would you like to continue?} other {{count} devices is about to be deactivated. Would you like to continue?}}'} values={{ count: selectedTimeClockDevices.length }} />}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            icon={<FAIcon prefix='fad' name='circle-question' color='#ff2626' />}
                                            placement={"top"}
                                            onConfirm={() => console.log('To Disable')}
                                        >
                                            <CircleButton
                                                disabled
                                                small
                                                icon={<FAIcon prefix='fad' name='display-slash' />}
                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Deactivate {count} device} other {Deactivate {count} devices}}' }, { count: selectedTimeClockDevices.length })}
                                            />
                                        </Popconfirm>
                                        :
                                        <Popconfirm
                                            key="eventclocked-popup-button-confirm"
                                            title={<FormattedMessage defaultMessage={'{count, plural, one {{count} device is about to be activated. Would you like to continue?} other {{count} devices is about to be activated. Would you like to continue?}}'} values={{ count: selectedTimeClockDevices.length }} />}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            icon={<FAIcon prefix='fad' name='circle-question' color='#ff2626' />}
                                            placement={"top"}
                                            onConfirm={() => console.log('To Enable')}
                                        >
                                            <CircleButton
                                                small
                                                disabled
                                                type="primary"
                                                icon={<FAIcon prefix='fad' name='display-slash' />}
                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Activate {count} device} other {Activate {count} devices}}' }, { count: selectedTimeClockDevices.length })}
                                            />
                                        </Popconfirm>
                                }

                            </>
                        ]}
                        open={selectedTimeClockDevices.length > 0}
                        close={() => this.setState({ selectedTimeClockDevices: [] })}
                    />
                </div>
                {
                    openInitModal ?
                        <InitNewDeviceModal isSmartphone={this.props.isSmartphone} onClose={this.close_modal} nrDevices={nr_physical_devices} maxDevices={max_physical_devices} />
                        : null
                }

            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company,
    height: state.window.height,
});

const connector = connect(mapStateToProps);

export default connector(withRouter(injectIntl(TimeClockDevices)));
