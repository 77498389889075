import { Result, ResultProps, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { GREEN_COLOR } from "../../../../utils/constants";
import FAIcon from "../../FAIcon";

interface Props extends ResultProps {
}

const WorkDone = (props: Props) => {
    return (
        <Result
            status="success"
            icon={(
                <video autoPlay loop muted width="600">
                    <source src="https://storage.googleapis.com/sunkhronos/animation/cool.webm" type="video/webm" />
                </video>
            )}
            title={<Space size={"middle"}><FormattedMessage defaultMessage={'Congrats'} /><FAIcon size='sm' color={GREEN_COLOR} prefix='fad' name='party-horn' /></Space>}
            subTitle={<FormattedMessage defaultMessage={'All the work performed for the selected period has been validated.'} />}
            {...props}
        />
    );
};

export default WorkDone;

