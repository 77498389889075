import { Avatar, Card, Col, Empty, Popover, Row, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Moment } from "moment";
import { ForwardedRef, forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { MOMENT_FORMAT_DATE_TO_NETWORK } from "../../../utils/constants";
import Network from "../../../utils/network";
import { IIntegrationsType, User } from "../../../utils/types/generalTypes";
import { NetworkSyncableUsers } from "../../../utils/types/networkTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import VirtualTable from "../../common/general/virtualTable";
import { tableColumnTextFilterConfig } from "../../courseManagement/tableSearch";
import ExportGlobalOffice from "./globaloffice/exportGlobalOffice";
import ExportHotela from "./hotela/exportHotela";

interface Props {
    selectedIntegration: IIntegrationsType;
    selectedDate: Moment;
    setSelectedDate: (e: Moment) => void;
    selectedEndDate: Moment;
    setSelectedEndDate: (e: Moment) => void;
    onNext: () => void;
    onPrevious: () => void;
    loading: boolean;
    setLoading: (e: boolean) => void;
}

export type UsersManagementRef = {
    refresh: () => void;
};

interface IFilteredUser {
    id: number;
    avatar: ReactNode;
    code?: string;
    firstname: string;
    lastname: string;
    waitTimeClock: boolean;
    waitAggregated: boolean;
    notLinked: boolean;
    noData: boolean;
    pendingRetry: boolean;
}

const Export = (props: Props, ref: ForwardedRef<UsersManagementRef>) => {

    const users = useSelector((state: ApplicationState) => state.teamManagement.users);
    const { selectedDate, selectedIntegration, setLoading, selectedEndDate } = props;

    const [syncableUsersIds, setSyncableUsersIds] = useState<number[]>([]);
    const [waitingAggregatedValidationIds, setWaitingAggregatedValidationIds] = useState<number[]>([]);
    const [waitingTimeclockValidationIds, setWaitingTimeclockValidationIds] = useState<number[]>([]);
    const [usersNotLinkedIds, setUsersNotLinkedIds] = useState<number[]>([]);
    const [usersWithoutDataIds, setUsersWithoutDataIds] = useState<number[]>([]);
    const [usersPendingRetryIds, setUsersPendingRetryIds] = useState<number[]>([]);
    const [selectedUserId, setSelectedUserId] = useState(-1);
    const selectedDateRef = useRef<Moment | undefined>(undefined);

    const height = useSelector((state: ApplicationState) => state.window.height);

    const intl = useIntl();

    const fillData = useCallback((data: NetworkSyncableUsers) => {
        setSyncableUsersIds(data.usersExportable);
        setWaitingAggregatedValidationIds(data.waitingAggregatedValidation);
        setWaitingTimeclockValidationIds(data.waitingTimeClockValidation);
        setUsersNotLinkedIds(data.usersNotLinked);
        setUsersWithoutDataIds(data.usersWithoutData);
        setUsersPendingRetryIds(data.pendingRetry ? data.pendingRetry : []);
    }, []);

    const loadUsers = useCallback(() => {
        const clonedSelectedDate = selectedDate.clone();
        const clonedSelectedEndDate = selectedEndDate.clone();
        setLoading(true);
        setSelectedUserId(-1);
        if (selectedIntegration === 'hotela')
            Network.getHotelaSyncableUsers(parseInt(clonedSelectedDate.format('YYYY')), parseInt(clonedSelectedDate.format('MM'))).then(
                (response) => {
                    fillData(response.data);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the users to sync' }), 'error');
                }
            ).finally(() => setLoading(false));
        else if (selectedIntegration === 'globaloffice')
            Network.getGlobalOfficeSyncableUsers(clonedSelectedDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), clonedSelectedEndDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)).then(
                (response) => {
                    fillData(response.data);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the users to sync' }), 'error');
                }
            ).finally(() => setLoading(false));
    }, [fillData, intl, selectedDate, selectedEndDate, selectedIntegration, setLoading]);

    const reset = useCallback(() => {
        loadUsers();
    }, [loadUsers]);

    const refreshIntegration = useCallback(() => {
        loadUsers();
    }, [loadUsers]);

    useEffect(() => {
        if (selectedDateRef.current !== props.selectedDate) {
            selectedDateRef.current = props.selectedDate;
            reset();
        }
    }, [reset, props.selectedDate]);

    const filteredUsers = useMemo(() => {
        const createUser = (id: number, user: User | undefined, waitTimeClock: boolean, waitAggregated: boolean, notLinked: boolean, noData: boolean, pendingRetry: boolean) => {
            return ({
                id: id,
                avatar: <Avatar src={user?.image} icon={<FAIcon name='user' prefix='fad' />} />,
                firstname: user?.first_name || '',
                lastname: user?.last_name || '',
                waitTimeClock,
                waitAggregated,
                notLinked,
                noData,
                pendingRetry
            });
        };

        const tmpUsers: IFilteredUser[] = [];

        syncableUsersIds.forEach((id) => {
            const user = users.find(u => u.id === id);
            tmpUsers.push(createUser(id, user, false, false, false, false, false));
        });

        waitingTimeclockValidationIds.forEach((id) => {
            const idx = tmpUsers.findIndex(u => u.id === id);
            if (idx !== -1) {
                tmpUsers[idx].waitTimeClock = true;
            }
            else {
                const user = users.find(u => u.id === id);
                tmpUsers.push(createUser(id, user, true, false, false, false, false));
            }
        });

        waitingAggregatedValidationIds.forEach((id) => {
            const idx = tmpUsers.findIndex(u => u.id === id);
            if (idx !== -1) {
                tmpUsers[idx].waitAggregated = true;
            }
            else {
                const user = users.find(u => u.id === id);
                tmpUsers.push(createUser(id, user, false, true, false, false, false));
            }
        });

        usersNotLinkedIds.forEach((id) => {
            const idx = tmpUsers.findIndex(u => u.id === id);
            if (idx !== -1) {
                tmpUsers[idx].notLinked = true;
            }
            else {
                const user = users.find(u => u.id === id);
                tmpUsers.push(createUser(id, user, false, false, true, false, false));
            }
        });

        usersWithoutDataIds.forEach((id) => {
            const idx = tmpUsers.findIndex(u => u.id === id);
            if (idx !== -1) {
                tmpUsers[idx].noData = true;
            }
            else {
                const user = users.find(u => u.id === id);
                tmpUsers.push(createUser(id, user, false, false, false, true, false));
            }
        });

        usersPendingRetryIds.forEach((id) => {
            const idx = tmpUsers.findIndex(u => u.id === id);
            if (idx !== -1) {
                tmpUsers[idx].noData = true;
            }
            else {
                const user = users.find(u => u.id === id);
                tmpUsers.push(createUser(id, user, false, false, false, false, true));
            }
        });

        return tmpUsers;
    }, [syncableUsersIds, users, usersNotLinkedIds, usersWithoutDataIds, waitingAggregatedValidationIds, waitingTimeclockValidationIds, usersPendingRetryIds]);

    const disabledIds = useMemo(() => {
        const idsToDisable: number[] = [];
        filteredUsers.forEach(u => {
            if (u.waitTimeClock || u.waitAggregated || u.notLinked || u.noData)
                idsToDisable.push(u.id);
        });
        return idsToDisable;
    }, [filteredUsers]);

    const pendingIds = useMemo(() => {
        const idsToDisable: number[] = [];
        filteredUsers.forEach(u => {
            if (u.pendingRetry)
                idsToDisable.push(u.id);
        });
        return idsToDisable;
    }, [filteredUsers]);

    const renderUserState = useCallback((record: IFilteredUser) => {
        return (
            <div>
                {
                    disabledIds.includes(record.id) ?
                        <Popover
                            title={<FormattedMessage defaultMessage={"Unable to export"} />}
                            placement='right'
                            content={
                                <ul>
                                    {
                                        record.notLinked ?
                                            <li><FAIcon name='link' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'User not linked to the intregrator'} /></li>
                                            :
                                            <>
                                                {
                                                    record.waitTimeClock &&
                                                    <li><FAIcon name='clock' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'Timeclock still waiting for validation'} /></li>
                                                }
                                                {
                                                    record.waitAggregated &&
                                                    <li><FAIcon name='calendar' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={'Hours still waiting for validation'} /></li>
                                                }
                                                {
                                                    record.noData &&
                                                    <li><FAIcon name='empty-set' prefix='fad' color={'var(--icon-red)'} /> <FormattedMessage defaultMessage={"User's data is empty"} /></li>
                                                }
                                            </>
                                    }

                                </ul>
                            }
                        >
                            <FAIcon name='circle-xmark' prefix='fad' color='var(--icon-red)' />
                        </Popover>

                        :
                        pendingIds.includes(record.id) ?
                            <Tooltip
                                title={<FormattedMessage defaultMessage={"Exporting ..."} />}
                                placement='right'
                            >
                                <FAIcon name='circle-ellipsis' prefix='fad' color='var(--icon-blue)' />
                            </Tooltip>
                            :
                            <FAIcon name='circle-check' prefix='fad' color='var(--icon-green)' />
                }
            </div>
        );
    }, [disabledIds, pendingIds]);

    const tableColumns = useMemo((): ColumnProps<IFilteredUser>[] => [
        {
            key: 'state',
            title: <FormattedMessage defaultMessage={'Status'} />,
            dataIndex: 'state',
            className: '__width_80 ',
            align: 'center',
            filters: [
                {
                    text: intl.formatMessage({ defaultMessage: 'Ready to export' }),
                    value: 'ready'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Waiting timeclock' }),
                    value: 'timeclock'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Waiting confirmation' }),
                    value: 'confirm'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'No data' }),
                    value: 'data'
                },
                {
                    text: intl.formatMessage({ defaultMessage: 'Not linked' }),
                    value: 'link'
                }
            ],
            onFilter: (value, record) => {
                if (value === 'ready')
                    if (!record.noData && !record.notLinked && !record.waitAggregated && !record.waitTimeClock) return true;
                if (value === 'timeclock')
                    if (record.waitTimeClock && !record.notLinked) return true;
                if (value === 'confirm')
                    if (record.waitAggregated && !record.notLinked) return true;
                if (value === 'data')
                    if (record.noData && !record.notLinked) return true;
                if (value === 'link')
                    if (record.notLinked) return true;
                return false;
            },
            render: (_, record) => renderUserState(record),
        },
        {
            key: 'avatar',
            title: <FormattedMessage defaultMessage={'Image'} />,
            dataIndex: 'avatar',
            className: '__width_80',
            align: 'center',
        },
        {
            title: <FormattedMessage defaultMessage={'Code'} />,
            dataIndex: 'code',
            key: 'code',
            className: '__width_80',
            sorter: (a, b) => (a.code ?? '').localeCompare(b.code??''),
            // defaultSortOrder: 'ascend',
        },
        {
            key: 'firstname',
            className: '__min-width-200',
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'firstname',
            onFilter: (value, record) => {
                return record.firstname!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IFilteredUser>(),
            sorter: (a, b) => a.firstname.toLocaleLowerCase().localeCompare(b.firstname.toLocaleLowerCase()),
        },
        {
            key: 'lastname',
            className: '__min-width-200',
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'lastname',
            onFilter: (value, record) => {
                return record.lastname!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IFilteredUser>(),
            sorter: (a, b) => a.lastname.toLocaleLowerCase().localeCompare(b.lastname.toLocaleLowerCase()),
        }
    ], [intl, renderUserState]);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refreshIntegration();
        }
    }));

    const waitAggregated = useMemo(() => waitingAggregatedValidationIds.findIndex(e => e === selectedUserId) !== -1, [waitingAggregatedValidationIds, selectedUserId]);
    const waitTimeclock = useMemo(() => waitingTimeclockValidationIds.findIndex(e => e === selectedUserId) !== -1, [waitingTimeclockValidationIds, selectedUserId]);
    const tableHeight = useMemo(() => height - 349 < 250 ? 250 : height - 300, [height]);

    return (
        <Row gutter={[10, 10]} style={{ height: '100%' }}>
            <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                <Card
                    size='small'
                    title={<FormattedMessage defaultMessage={'Waiting users'} />}
                >
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }}>
                            <VirtualTable
                                style={{ flex: 1, overflow: 'auto' }}
                                dataSource={filteredUsers}
                                columns={tableColumns}
                                rowKey={(e: any) => e.id}
                                loading={props.loading}
                                scroll={{ x: true, y: tableHeight }}
                                pagination={false}
                                onRow={(e: any) => ({
                                    onClick: () => {
                                        if (!disabledIds.includes(e.id) && !pendingIds.includes(e.id))
                                            if (selectedUserId !== e.id)
                                                setSelectedUserId(e.id);
                                            else
                                                setSelectedUserId(-1);
                                    },
                                    style: { cursor: disabledIds.includes(e.id) || pendingIds.includes(e.id) ? 'default' : 'pointer' },
                                    // style: {background: disabledIds.includes(e.id) ? '#efefef' : 'none', cursor: disabledIds.includes(e.id) ? 'default' : 'pointer'},

                                })}
                                rowSelection={{
                                    hideSelectAll: true,
                                    getCheckboxProps: (record: any) => ({
                                        disabled: disabledIds.includes(record.id) || pendingIds.includes(record.id)
                                    }),
                                    onSelect: (e: any) => {
                                        if (!disabledIds.includes(e.id) && !pendingIds.includes(e.id))
                                            if (selectedUserId !== e.id)
                                                setSelectedUserId(e.id);
                                            else
                                                setSelectedUserId(-1);
                                    },
                                    selectedRowKeys: [selectedUserId]
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col >
            <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                {
                    props.selectedIntegration === 'hotela' ?
                        <ExportHotela
                            loadUsers={loadUsers}
                            loading={props.loading}
                            reset={reset}
                            userIds={syncableUsersIds}
                            waitAggregated={waitAggregated}
                            waitTimeclock={waitTimeclock}
                            selectedDate={props.selectedDate}
                            selectedUserId={selectedUserId}
                            setLoading={props.setLoading}
                        />
                        :
                        props.selectedIntegration === 'globaloffice' ?
                            <ExportGlobalOffice
                                loadUsers={loadUsers}
                                loading={props.loading}
                                reset={reset}
                                userIds={syncableUsersIds}
                                waitAggregated={waitAggregated}
                                waitTimeclock={waitTimeclock}
                                selectedDate={selectedDate}
                                selectedEndDate={selectedEndDate}
                                selectedUserId={selectedUserId}
                                setLoading={props.setLoading}
                            />
                            :
                            <Empty />
                }
            </Col>
        </Row >

    );
};
export default forwardRef(Export);