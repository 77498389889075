import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../../styles/newsfeed.css';
import Network from '../../../../utils/network';
import { PoiLive, RouterProps } from '../../../../utils/types/generalTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import AmazingDatePicker from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import MapLiveLeaflet from '../../../common/general/mapLive';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps, ContainerTabsItemProps { }

interface State {
    isLoading: boolean;
    isLoaded: boolean;
    pois: PoiLive[];
    day: Moment;
}

class PositionTracking extends ContainerTabsItem<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoaded: false,
            pois: [],
            day: moment()
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.refreshSunkhronosLive();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.isLoading, prevState.isLoading ||
            !this.state.day.isSame(prevState.day, "day")
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { isLoading, day } = this.state;

        return (
            <>
                <AmazingDatePicker
                    loadingData={isLoading}
                    controlled={{
                        valueFrom: day,
                        onChange: this.onChange,
                    }}
                />
                <CircleButton
                    small
                    title={intl.formatMessage({ defaultMessage: 'Refresh' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.refreshSunkhronosLive(true)}
                    loading={isLoading} />
            </>
        );
    };

    refreshSunkhronosLive = (force = false) => {
        if (!force && this.state.isLoading) return;
        this.setState({ isLoading: true, isLoaded: false });
        Network.getSunkhronosLive(this.state.day.format("YYYY-MM-DD")).then(
            (response: PoiLive[]) => {
                this.setState({ isLoading: false, pois: response, isLoaded: true });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the live check' }), "error");
                this.setState({ isLoading: false });
            }
        );
    };


    onChange = (sD: Moment | null) => {
        if (sD) this.setState({ day: sD }, () => this.refreshSunkhronosLive());
    };

    render() {
        const { pois, isLoading, isLoaded } = this.state;
        const { sectors, courses } = this.props;

        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    <div className="__filter-map-content" style={{ "height": "calc(100vh - 95px)", borderRadius: 'var(--border-radius)' }}>
                        <MapLiveLeaflet
                            loading={isLoaded === false && isLoading}
                            refresh={() => this.refreshSunkhronosLive(true)}
                            pois={pois}
                            sectors={sectors}
                            courses={courses}
                            isEditMode={false}
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    courses: state.location.courses,
    sectors: state.location.sectors,
    pois: state.location.pois,
});
const connector = connect(mapStateToProps);

export default connector(withRouter(injectIntl(PositionTracking)));