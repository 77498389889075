import { Space, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { CompanyDetailsPrivilege, Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { ContainerTabsState, RouterProps, TabItem } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { checkCompanyDetailsPrivilege, checkPrivilege, checkRBACRule } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateSidebars, Sidebar } from '../../common/navigations/containerTabs';
import { MenuLinks } from '../../common/navigations/sideMenu';
import OvertimeTracking from './overtimeTracking/overtimeTracking';
import PositionTracking from './positionTracking/positionTracking';
import ProductionTracking from './productionTracking/productionTracking';
import TimeTracking from './timeTracking/timeTracking';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps { }
interface State extends ContainerTabsState {
}

export enum WorkControlManagementTabLink {
    OVERTIME_TRACKING = "overtime-tracking",
    TIME_TRACKING = "time-tracking",
    POSITION_TRACKING = "position-tracking",
    PRODUCTION_TRACKING = "production-tracking",
}

class WorkControlManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let tabItems: TabItem[];
        if (checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser)) {
            tabItems = [
                {
                    key: WorkControlManagementTabLink.TIME_TRACKING,
                    label: <Space className='space-tooltip'>
                        <FAIcon prefix={"fad"} name='stopwatch' /><FormattedMessage defaultMessage={"Time Tracking"} />
                        <Tooltip title={this.props.intl.formatMessage({ defaultMessage: 'Administrators can validate clocked events. Any information validated or modified will change the event' })}>
                            <FAIcon prefix='fad' name='info' className="tab-tooltip-info-new" />
                        </Tooltip>
                    </Space>,
                    children: <TimeTracking keyLink={WorkControlManagementTabLink.TIME_TRACKING} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                },
            ];
            if (this.props.productivityEnabled) {
                tabItems.push({
                    key: WorkControlManagementTabLink.PRODUCTION_TRACKING,
                    label: <Space><FAIcon prefix={"fad"} name='clipboard-list-check' /><FormattedMessage defaultMessage={"Production Tracking"} /></Space>,
                    children: <ProductionTracking keyLink={WorkControlManagementTabLink.PRODUCTION_TRACKING} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />,
                    hideMobile: true
                });
            }
        } else {
            tabItems = [
                {
                    key: WorkControlManagementTabLink.OVERTIME_TRACKING,
                    label: <Space><FAIcon prefix={"fad"} name='stopwatch' /><FormattedMessage defaultMessage={"Overtime Tracking"} /></Space>,
                    children: <OvertimeTracking keyLink={WorkControlManagementTabLink.OVERTIME_TRACKING} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                }
            ];
        }

        if (checkRBACRule(Rules.SunkhronosLive.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) {
            tabItems.push({
                key: WorkControlManagementTabLink.POSITION_TRACKING,
                label: <Space><FAIcon prefix={"fad"} name='location-check' /><FormattedMessage defaultMessage={"Position Tracking"} /></Space>,
                children: <PositionTracking keyLink={WorkControlManagementTabLink.POSITION_TRACKING} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />,
                hideMobile: true
            });
        }

        this.state = {
            tabItems: [
                ...tabItems,
            ]
        };
    }

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));
    addOrUpdateSidebars = (content: Sidebar[], key: string) => this.setState(prevState => addOrUpdateSidebars(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfSidebars } = this.state;
        const { intl } = this.props;
        return (
            // <Can rule={Rules.Planning.Visit} redirect="/dashboard">
            <ContainerTabs
                contentClassName='__new-right-panel'
                breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Work control' }), link: `/${MenuLinks.WorkControl}/${this.props.match.params.tab}` }]}
                tabItems={tabItems}
                tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                tabSidebars={tabListOfSidebars?.find(item => item.key === this.props.match.params.tab)?.content}
            />
            // </Can>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    productivityEnabled: state.productivity.enabled
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(WorkControlManagement));
