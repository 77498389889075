import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactToPrint from 'react-to-print';
import '../../../../styles/ccnt.css';
import { CcntType } from '../../../../utils/types/reportTypes';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import Ccnt from './ccnt';

interface IProps {
    multipleCcnt?: CcntType[];
    close: () => void;
    year: Moment;
}

type Props = IProps & IntlProps;

interface State {
    showTools: boolean;

}


class MultipleCcntPrintPage extends React.Component<Props, State> {


    constructor(props: Props) {
        super(props);

        this.escFunction = this.escFunction.bind(this);

        this.state = {
            showTools: false,
        };

    }

    //quits the current ccnt print page preview  
    escFunction(event: any) {
        if (event.code === "Escape") {
            this.props.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        const { multipleCcnt } = this.props;

        return (
            <>
                <div className='ccntPrintBody' style={{ backgroundColor: "white" }}>
                    <div>
                        {/* Ccnt Header for printed version */}
                        <div className='ccntPaper' id='ccntPaper'
                            onMouseEnter={() => this.setState({ showTools: true })}
                            onMouseLeave={() => this.setState({ showTools: false })}
                            onClick={(e) => e.stopPropagation()}
                        >

                            {
                                multipleCcnt?.map((ccnt, idx) => {
                                    return (
                                        <div key={`multipleCcntPrintPage-ccnt-${ccnt.employee}-${idx}`} style={idx === 0 ? {} : { marginTop: "50px" }} className='ccntPaperGroup'>
                                            <div className='header'>
                                                <div className='headerLeft'>
                                                    <p><FormattedMessage defaultMessage={'CCNT'} description={'CCNT'} /><br>
                                                    </br><FormattedMessage defaultMessage={'Convention collective nationale de travail pour les hôtels, restaurants et cafés'} description={'CCNT'} /></p>

                                                </div>
                                                <div className='headerCenter'>
                                                    <p>Dufourstrasse 23<br>
                                                    </br><FormattedMessage defaultMessage={'Case postale 357 - 4010 Bâle'} description={'CCNT'} /></p>
                                                </div>
                                                <div className='headerRight'>
                                                    <p >Vers.INF.FR 01.21</p>
                                                </div>
                                            </div>
                                            <div className='ccntPaperMainBody'>
                                                <Ccnt ccnt={ccnt} year={this.props.year} />
                                            </div>
                                        </div>
                                    );


                                })
                            }
                        </div>
                    </div>

                    <div className={`ccntPrintButtons`}>
                        <CircleButton small icon={<FAIcon prefix='far' name='xmark' />} title={this.props.intl.formatMessage({ defaultMessage: 'Close' })} onClick={() => this.props.close()} style={{ marginRight: 8, opacity: 0.8 }} />

                        <ReactToPrint
                            key="assign-modal-button-print"
                            trigger={() => {
                                return <CircleButton small title={this.props.intl.formatMessage({ defaultMessage: 'Print' })} icon={<FAIcon prefix='fad' name='print' />} type="primary" onClick={() => this.props.close()} />;
                            }}
                            content={() => document.getElementById("ccntPaper")}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(MultipleCcntPrintPage);