import { Col, Row, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import { defineMessage, defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import '../../../../styles/ccnt.css';
import { ContractTypeMode, ContractTypeModeType } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import { CcntErrorType, type CcntReport as CcntReportType } from '../../../../utils/types/reportTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { IntlProps } from '../../../app/LanguageProvider';
import CcntErrorsTooltip from './ccntErrorsTooltip';
import Arrow from './FineLongArrow.png';

declare module "./FineLongArrow.png";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    ccnt: CcntReportType;
    toPrint?: boolean;
    year: Moment;
}

const CCNT_WARNING_DEFAULT_CLASS_HIDDEN = 'ccntDefaultErrorMessageHidden';
const CCNT_WARNING_DEFAULT_CLASS = 'ccntDefaultErrorMessage';

const enum CCNTTypesOfDay {
    T = 'T',
    X = 'X',
    F = 'F',
    V = 'V',
    Mi = 'Mi',
    M = 'M',
    A = 'A',
    Ma = 'Ma',
    D = 'D',
}

const CCNTTypesOfDayMessages = defineMessages<CCNTTypesOfDay>({
    [CCNTTypesOfDay.T]: { defaultMessage: 'T', description: 'CCNT' },
    [CCNTTypesOfDay.X]: { defaultMessage: 'X', description: 'CCNT' },
    [CCNTTypesOfDay.F]: { defaultMessage: 'F', description: 'CCNT' },
    [CCNTTypesOfDay.V]: { defaultMessage: 'V', description: 'CCNT' },
    [CCNTTypesOfDay.Mi]: { defaultMessage: 'Mi', description: 'CCNT' },
    [CCNTTypesOfDay.M]: { defaultMessage: 'M', description: 'CCNT' },
    [CCNTTypesOfDay.A]: { defaultMessage: 'A', description: 'CCNT' },
    [CCNTTypesOfDay.Ma]: { defaultMessage: 'Ma', description: 'CCNT' },
    [CCNTTypesOfDay.D]: { defaultMessage: 'D', description: 'CCNT' },
});

class CcntReport extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    //force an number to a given number of decimals || for display purpose
    decimalize(numberToDecimalize: number, nbDecimals = 2) {
        return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
    }

    //creats the table for each month of the Ccnt sheet
    _renderMonthRow = () => {
        const rows: ReactNode[] = [];
        const { ccnt } = this.props;
        const isFixed = [ContractTypeMode.NORMAL].includes(ccnt.data.contractTypeMode);
        //TODO: Uncomment to show other than fixed contract
        const isHourlyAndMinimal = [ContractTypeMode.HOURLY].includes(ccnt.data.contractTypeMode) && ccnt.data.weeklyPlannedHours > 0;
        // const isHourlyAndMinimal = false;

        ccnt.months.forEach((month, monthIdx) => {
            const columns: ReactNode[] = [];
            const monthNbr = monthIdx + 1;
            columns.push(<td className='tableCell' style={{ fontWeight: "bold" }} key={`monthIdx-${monthIdx}-${monthIdx}`}>{monthNbr}</td>);
            // Part 1/2 : Hours per day (left side of table)
            month.days.forEach((day, dayIdx) => {

                const cells = (<>
                    <p className=' tableTextLetter' key={`tableTextLetter-${monthIdx}-${dayIdx}`}>{day[0] !== undefined && day[0] !== '' ? this.props.intl.formatMessage(CCNTTypesOfDayMessages[day[0] as CCNTTypesOfDay]) : ''}</p>
                    <p className=' tableTextNumber' key={`tableTextNumber-${monthIdx}-${dayIdx}`}>{day[1]}</p>
                </>);

                let tdClasseName = "";
                let column: ReactNode = <td></td>;
                const dayNbr = dayIdx + 1;

                // February design for days 29, 30, 31
                if (monthNbr === 2) {
                    switch (dayNbr) {
                        case 29:
                            tdClasseName += ' tableCellGray';
                            break;
                        case 30:
                            tdClasseName += ' tableCellBlack';
                            break;
                    }
                }

                if ([2, 4, 6, 9, 11].includes(monthNbr) && dayNbr === 31) {
                    tdClasseName += ' tableCellBlack';
                }

                const dayDate = `${this.props.ccnt.data.workYear ?? 1901}-${monthNbr.toString().padStart(2, '0')}-${dayNbr.toString().padStart(2, '0')}`;
                const dailyErrors = this.props.ccnt.errors.filter((error) => {
                    return error.date === dayDate;
                }) ?? [];

                if (dailyErrors.length === 1)
                    tdClasseName = `ccnt-table-${dailyErrors[0].type}`;
                else if (dailyErrors.length > 1) {
                    const ccntWarning = dailyErrors.find(e => e.type === CcntErrorType.CCNT_WARNING);
                    if (ccntWarning)
                        tdClasseName = `ccnt-table-${CcntErrorType.CCNT_WARNING}`;
                    else {
                        const error = dailyErrors.find(e => e.type === CcntErrorType.ERROR);
                        if (error)
                            tdClasseName = `ccnt-table-${CcntErrorType.ERROR}`;
                        else {
                            const warning = dailyErrors.find(e => e.type === CcntErrorType.WARNING);
                            if (warning)
                                tdClasseName = `ccnt-table-${CcntErrorType.WARNING}`;
                            else {
                                const info = dailyErrors.find(e => e.type === CcntErrorType.INFO);
                                if (info)
                                    tdClasseName = `ccnt-table-${CcntErrorType.INFO}`;
                            }
                        }
                    }

                }

                column = (
                    <td className={`${tdClasseName} dataCell ${dayNbr % 2 === 0 ? ' tableCellEven' : ' tableCell'}`} key={`tableTextLetter-and-tableTextNumber-${monthIdx}-${dayIdx}`} style={{ 'position': 'relative' }}>
                        {
                            dailyErrors.length > 0 ?
                                <>
                                    <Tooltip title={<CcntErrorsTooltip errors={dailyErrors} />}>
                                        {dailyErrors.length > 1 ?
                                            <div className='ccnt-error-badge'>{dailyErrors.length}</div>
                                            :
                                            <></>
                                        }
                                        {cells}
                                    </Tooltip>
                                </>
                                :
                                <>{cells}</>
                        }
                    </td>
                );
                columns.push(column);
            });

            // Part 2/2 : Resume of month (right side of table)
            columns.push([<td key={`starrWorkDone-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.T)}</p></td>]);
            if (isFixed || isHourlyAndMinimal)
                columns.push([
                    <td key={`starrTheoreticalWork-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.theoreticalWorkTime)}</p></td>,
                ]);

            if (isFixed)
                columns.push([
                    <td key={`starrDeltaWork-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.workTimeDelta)}</p></td>,
                    <td key={`starrPaidHours-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.paidHours)}</p></td>,
                ]);

            columns.push([
                <td key={`starrRestRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.X)}</p></td>,
                <td key={`starrRestToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.restToGet)}</p></td>,
                <td key={`starrDeltaRest-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.restDelta)}</p></td>,
                <td key={`starrPublicHolidayRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.F)}</p></td>,
                <td key={`starrPublicHolidayToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.holidayToGet)}</p></td>,
                <td key={`starrVacationRecieved-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.V)}</p></td>,
                <td key={`starrVacationToGet-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.vacationToGet)}</p></td>,
                <td key={`starrMaternitySickness-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.M + month.tally.Ma)}</p></td>,
                <td key={`starrAccidents-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.A)}</p></td>,
                <td key={`starrArmy-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.Mi)}</p></td>,
                <td key={`starrOther-${monthIdx}`} className='rightStandartCell'><p>{this.decimalize(month.tally.D)}</p></td>,
                <td key={`rightSignCell-${monthIdx}`} className='rightSignCell'><p></p></td>
            ]);

            rows.push(<tr className='tableRow' key={`monthidx-${monthIdx}`}>{columns}</tr>);
        });
        return rows;
    };

    render() {
        const { toPrint, ccnt } = this.props;
        const isFixed = [ContractTypeMode.NORMAL].includes(ccnt.data.contractTypeMode);
        //TODO: Uncomment to show other than fixed contract
        const isHourlyAndMinimal = [ContractTypeMode.HOURLY].includes(ccnt.data.contractTypeMode) && ccnt.data.weeklyPlannedHours > 0;

        //TODO: Uncomment to show other than fixed contract
        const ContractTypeModeMessages = defineMessages<ContractTypeModeType>({
            NORMAL: { defaultMessage: 'Contrat fixe' },
            HOURLY: { defaultMessage: 'Contrat horaire' },
            AUXILIARY: { defaultMessage: 'Contrat extra/auxilaire' },
        });

        const contractMode = Object.keys(ContractTypeMode).find(i => ContractTypeMode[i as ContractTypeModeType] === this.props.ccnt.data.contractTypeMode);
        const contratModeMessage = contractMode ? ContractTypeModeMessages[contractMode as ContractTypeModeType] : defineMessage({ defaultMessage: 'Contrat inconnu' });

        return (
            <div id="ccntBody">
                {/* ---------- Ccnt Header ----------*/}
                {
                    toPrint &&
                    <Row gutter={0} >
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p><FormattedMessage defaultMessage={'CCNT'} description={'CCNT'} /></p>
                            <p><FormattedMessage defaultMessage={'Convention collective nationale de travail pour les hôtels, restaurants et cafés'} description={'CCNT'} /></p>
                        </Col>
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p style={{ textAlign: 'center' }}>Dufourstrasse 23</p>
                            <p style={{ textAlign: 'center' }}><FormattedMessage defaultMessage={'Case postale 357 - 4010 Bâle'} description={'CCNT'} /></p>
                        </Col>
                        <Col xs={{ span: 8 }} className="gutter-row" >
                            <p style={{ textAlign: 'right' }}>Vers.INF.FR 01.21</p>
                        </Col>
                    </Row>
                }
                <Row gutter={0}>
                    {/* ---------- Main Table ---------- */}
                    <table className='topTable'>
                        <tbody>
                            <tr>
                                <td rowSpan={2} colSpan={9}>
                                    <h1 style={isFixed ? {} : { marginBottom: 0 }}>
                                        <FormattedMessage defaultMessage={'Saisie du temps'} description={'CCNT'} />
                                        <br />
                                        <FormattedMessage defaultMessage={'de travail CCNT'} description={'CCNT'} />
                                    </h1>
                                    {
                                        !isFixed &&
                                        <>
                                            <h3 style={{ margin: '.3rem 0px .3rem 0px' }}>
                                                {this.props.intl.formatMessage(contratModeMessage)}
                                            </h3>
                                        </>
                                    }
                                    <h3><FormattedMessage defaultMessage={'(art. 15 et art. 21)'} description={'CCNT'} /></h3>
                                </td>

                                <td colSpan={9} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Année de travail'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'> <strong>{ccnt.data.workYear} </strong></p>
                                </td>
                                <td colSpan={14} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Etablissement'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText' > <strong>{ccnt.data.company} </strong></p>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Temps effectif'} description={'CCNT'} /></span>
                                </td>
                                {
                                    (isFixed || isHourlyAndMinimal) &&
                                    <>
                                        <td className='verticalAllignBottom' rowSpan={3} >
                                            <span className='rotatedTopTitle'>
                                                {
                                                    isHourlyAndMinimal ?
                                                        <FormattedMessage defaultMessage={'Temps théorique minimal'} description={'CCNT'} />
                                                        :
                                                        <FormattedMessage defaultMessage={'Temps théorique'} description={'CCNT'} />
                                                }
                                            </span>
                                            <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 15 CCNT'} description={'CCNT'} /></span>
                                        </td>
                                    </>
                                }
                                {
                                    isFixed &&
                                    <>
                                        <td className='verticalAllignBottom' rowSpan={3} >
                                            <span className='rotatedTitle'><FormattedMessage defaultMessage={'Heures effectuées en +/-'} description={'CCNT'} /></span>
                                        </td>
                                        <td className='verticalAllignBottom' rowSpan={3} >
                                            <span className='rotatedTitle'><FormattedMessage defaultMessage={'Heures payées'} description={'CCNT'} /></span>
                                        </td>
                                    </>
                                }
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Repos perçu'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Repos à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 16 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Repos en +/-'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Fériés perçus'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Fériés à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 18 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Vacances perçues'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={3}>
                                    <span className='rotatedTopTitle'><FormattedMessage defaultMessage={'Vacances à percevoir'} description={'CCNT'} /></span>
                                    <span className='rotatedTitle rotatedsubTitle'><FormattedMessage defaultMessage={'art. 17 CCNT'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Maladie / Maternité'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Accident'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle'><FormattedMessage defaultMessage={'Militaire'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom' rowSpan={2}>
                                    <span className='rotatedTitle RTSDivers'><FormattedMessage defaultMessage={'Divers'} description={'CCNT'} /></span>
                                </td>
                                <td className='verticalAllignBottom topRightCellSignature' rowSpan={2}>
                                    <span className='rotatedTitle RTSSignature'><FormattedMessage defaultMessage={'Signature'} description={'CCNT'} /></span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={9} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Collaborateur'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{ccnt.data.employee}</strong></p>
                                </td>
                                <td colSpan={isFixed || isHourlyAndMinimal ? 4 : 7} className='topTableTitleCell'>

                                    <p className='cellTitle'><FormattedMessage defaultMessage={"Date d'entrée"} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{moment(ccnt.data.entryDate).format(getFormat('DATE'))}</strong></p>
                                </td>
                                <td colSpan={isFixed || isHourlyAndMinimal ? 4 : 7} className='topTableTitleCell'>
                                    <p className='cellTitle'><FormattedMessage defaultMessage={'Date de sortie'} description={'CCNT'} />{' *'}</p>
                                    <p className='cellInformationText'><strong>{moment(ccnt.data.exitDate).format(getFormat('DATE'))}</strong></p>
                                </td>

                                {
                                    (isFixed || isHourlyAndMinimal) &&
                                    <>
                                        <td className='standartCellCenter align-left' colSpan={4} >
                                            <h4>
                                                <FormattedMessage defaultMessage={'Durée hebdomadaire'} description={'CCNT'} />
                                                <br />
                                                {
                                                    isHourlyAndMinimal ?
                                                        <FormattedMessage defaultMessage={'de travail minimal'} description={'CCNT'} />
                                                        :
                                                        <FormattedMessage defaultMessage={'de travail'} description={'CCNT'} />
                                                }
                                                {' *'}
                                            </h4>
                                            {
                                                isFixed &&
                                                <img src={Arrow} className='arrowPngWorkPerWeek' alt='' />
                                            }
                                        </td>
                                        <td className='hoursInputCell' colSpan={2} >
                                            <p><strong>{this.decimalize(ccnt.data.weeklyPlannedHours ?? 0)}</strong></p>
                                        </td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className='firsttableCell'>
                                    <p><FormattedMessage defaultMessage={'Jour'} description={'CCNT'} /></p>
                                </td>
                                {
                                    // Column of day number
                                    new Array(31).fill(0).map((day, dayIdx) => {
                                        return <td key={`day-number-${dayIdx}`} rowSpan={2} className={dayIdx % 2 === 0 ? 'tableCell' : 'tableCellEven'} style={{ fontWeight: 'bold' }}>{dayIdx + 1}</td>;
                                    })
                                }
                                <td colSpan={5} rowSpan={2} className='specialCellGray'>
                                    <p className={ccnt.errors.filter(e => e.type === CcntErrorType.CCNT_WARNING).length > 0 ? CCNT_WARNING_DEFAULT_CLASS : CCNT_WARNING_DEFAULT_CLASS_HIDDEN}>
                                        <FormattedMessage defaultMessage={'ATTENTION: Les 1/2 jours de repos et les'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'1/2 jours fériés en rouge ne sont pas'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'correctement perçus (+5h00 de travail) et'} description={'CCNT'} />
                                        <br /> <FormattedMessage defaultMessage={'ne sont comptabilisés'} description={'CCNT'} />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ borderTop: '1px solid black' }}>
                                <td className='firsttableCell'>
                                    <p><FormattedMessage defaultMessage={'Mois'} description={'CCNT'} /></p>
                                </td>

                                {
                                    isFixed ?
                                        <>
                                            <td colSpan={3} className='specialCell' >
                                                <img src={Arrow} className='arrowPngHours' alt=''></img>
                                                <p className='specialText'><FormattedMessage defaultMessage={'Solde heures'} description={'CCNT'} /><br></br><FormattedMessage defaultMessage={'année précédente'} description={'CCNT'} /></p>
                                            </td>
                                            <td className='specialCell' >
                                                <p className='specialTextbig'>{this.decimalize(ccnt.data.lastYearsWorkHours ?? 0)}</p>
                                            </td>
                                        </>
                                        :
                                        isHourlyAndMinimal ?
                                            <>
                                                <td className='specialCellBlack'>
                                                    <p className='specialText'></p>
                                                </td>
                                                <td className='specialCellBlack'>
                                                    <p className='specialText'></p>
                                                </td>
                                            </>
                                            :

                                            <>
                                                <td className='specialCellBlack'>
                                                    <p className='specialText'></p>
                                                </td>
                                            </>
                                }

                                <td colSpan={2} className='specialCell'>
                                    <img src={Arrow} className='arrowPngRest' alt=''></img>
                                    <p className='specialText'><FormattedMessage defaultMessage={'Solde année'} description={'CCNT'} /><br></br><FormattedMessage defaultMessage={'précédente'} description={'CCNT'} /></p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(ccnt.data.lastYearsRest ?? 0)}</p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(ccnt.data.lastYearsPublicVacation ?? 0)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p className='specialText'></p>
                                </td>
                                <td className='specialCell'>
                                    <p className='specialTextbig'>{this.decimalize(ccnt.data.lastYearsVacation ?? 0)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                </td>
                            </tr>
                            {/* ---------- Main Table / left part, data for every day---------- */}
                            {this._renderMonthRow()}
                            {/* ---------- Main Table / right side, monthly summary of the data and result of monthly calculations ---------- */}
                            <tr style={{ border: 'none' }}>
                                <td className='emptycolumnTable' colSpan={32}>
                                    <p className='totalTitleParagraph'><FormattedMessage defaultMessage={'Total'} description={'CCNT'} /></p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.T ?? 0)}</p>
                                </td>
                                {
                                    (isFixed || isHourlyAndMinimal) &&
                                    <>
                                        <td className='rightStandartCell' >
                                            <p>{this.decimalize(ccnt.tally.theoreticalWorkTime ?? 0)}</p>
                                        </td>
                                    </>
                                }
                                {
                                    isFixed &&
                                    <>
                                        <td className='specialCellBlack' >
                                            <p></p>
                                        </td>
                                        <td className='specialCellBlack' >
                                            <p></p>
                                        </td>
                                    </>
                                }
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.X ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.restToGet ?? 0)}</p>
                                </td>
                                <td className='specialCellBlack'>
                                    <p></p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.F ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.holidayToGet ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.V ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.vacationToGet ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt ? ccnt.tally.M + ccnt.tally.Ma : 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.A ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.Mi ?? 0)}</p>
                                </td>
                                <td className='rightStandartCell'>
                                    <p>{this.decimalize(ccnt.tally.D ?? 0)}</p>
                                </td>
                                <td className='emptycolumnTable'></td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row gutter={0}>
                    <Col className="gutter-row" span={200}>
                        {/* ---------- bottom table, left, legend and signature ---------- */}
                        <div >
                            <table className='legendTable'>
                                <tbody>
                                    <tr>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'T'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Travail'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'X'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Repos'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'F'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Férié'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'V'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Vacances'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'Mi'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Militaire'} description={'CCNT'} /></p>
                                        </td>
                                    </tr>
                                    <tr><td><br></br></td></tr>
                                    <tr>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'M'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Maladie'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'A'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Accident'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'Ma'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Maternité / paternité'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='cellLegendLetter'>
                                            <p className='legendLetter'><strong><FormattedMessage defaultMessage={'D'} description={'CCNT'} /></strong></p>
                                        </td>
                                        <td colSpan={3} className='cellLegendDescription'>
                                            <p className='legendDescription'><FormattedMessage defaultMessage={'Divers'} description={'CCNT'} /> <span className='legendSubDescription'><FormattedMessage defaultMessage={'(congé de formation art.19 CCNT et congé payé art. 20 CCNT)'} description={'CCNT'} /></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='signatureTable'>
                                <tbody>
                                    <tr className='signatreRow'>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={"Signatures en fin d'année ou fin de période"} description={'CCNT'} />{':'}</p>
                                        </td>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={'Signature employeur'} description={'CCNT'} /></p>
                                            <div></div>
                                        </td>
                                        <td className='signatureCell'>
                                            <p><FormattedMessage defaultMessage={'Signature collaborateur'} description={'CCNT'} /></p>
                                            <div></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={200}>
                        {/* ---------- bottom table, right, yearly summary and calculations ---------- */}
                        <div >
                            <table className='totalTable'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p></p>
                                        </td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Temps effectif'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        {
                                            (isFixed || isHourlyAndMinimal) &&
                                            <>
                                                <td className='totalFineCells'>
                                                    <p>
                                                        {
                                                            isHourlyAndMinimal ?
                                                                <FormattedMessage defaultMessage={'Temps théo. min.'} description={'CCNT'} />
                                                                :
                                                                <FormattedMessage defaultMessage={'Temps théorique'} description={'CCNT'} />
                                                        }
                                                    </p>
                                                </td>
                                            </>
                                        }
                                        <td className='totalSeparatorCell'></td>
                                        {
                                            isFixed &&
                                            <>
                                                <td className='totalFineCells' >
                                                    <p><FormattedMessage defaultMessage={'Heures payées'} description={'CCNT'} /></p>
                                                </td>
                                                <td className='totalSeparatorCell'></td>
                                                <td className='totalFineCells' >
                                                    <p><FormattedMessage defaultMessage={'Solde heures'} description={'CCNT'} /></p>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Heures'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.T ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        {
                                            (isFixed || isHourlyAndMinimal) &&
                                            <>
                                                <td className='totalDataCell'>
                                                    <p>{this.decimalize(ccnt.tally.theoreticalWorkTime ?? 0)}</p>
                                                </td>
                                            </>
                                        }
                                        <td className='totalSeparatorCell'></td>
                                        {
                                            isFixed &&
                                            <>
                                                <td className='totalDataCell' >
                                                    <p>{this.decimalize(ccnt.tally.paidHours ?? 0)}</p>
                                                </td>
                                                <td className='totalSeparatorCell'></td>
                                                <td className='totalDataCell' >
                                                    <p>{this.decimalize(ccnt.tally.finalWorkTimeDelta ?? 0)}</p>
                                                </td>
                                                <td className='totalLegendCells' >
                                                    <p>{ccnt.tally.finalWorkTimeDelta > 0 ? <FormattedMessage defaultMessage={'heure(s) supplémentaires'} description={'CCNT'} /> : ccnt.tally.finalWorkTimeDelta < 0 ? <FormattedMessage defaultMessage={'heure(s) en négatif(s)'} description={'CCNT'} /> : ""}</p>
                                                </td>
                                            </>
                                        }

                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td>
                                            <p></p>
                                        </td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Perçus'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Droit'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Jours payé'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalFineCells'>
                                            <p><FormattedMessage defaultMessage={'Soldes jours'} description={'CCNT'} /></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Repos'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.X ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.restToGet ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.data.paidRestDays ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.finalRestDelta ?? 0)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{ccnt.tally.finalRestDelta > 0 ? <FormattedMessage defaultMessage={'repos à prendre/indemniser'} description={'CCNT'} /> : ccnt.tally.finalRestDelta < 0 ? <FormattedMessage defaultMessage={'repos accordé en sus'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Vacances'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.V ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.vacationToGet ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.data.paidVacationDays ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.finalVacationDelta ?? 0)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{ccnt.tally.finalVacationDelta > 0 ? <FormattedMessage defaultMessage={'vacances à prendre/indemniser'} description={'CCNT'} /> : ccnt.tally.finalVacationDelta < 0 ? <FormattedMessage defaultMessage={'vacances perçus en trop'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                    <tr><td className='totalSeparatorCell'></td></tr>
                                    <tr>
                                        <td className='totalThickCells'>
                                            <p><FormattedMessage defaultMessage={'Fériés'} description={'CCNT'} /></p>
                                        </td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.F ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.holidayToGet ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.data.paidHolidayDays ?? 0)}</p>
                                        </td>
                                        <td className='totalSeparatorCell'></td>
                                        <td className='totalDataCell'>
                                            <p>{this.decimalize(ccnt.tally.finalHolidayDelta ?? 0)}</p>
                                        </td>
                                        <td className='totalLegendCells'>
                                            <p>{ccnt.tally.finalHolidayDelta > 0 ? <FormattedMessage defaultMessage={'fériés à prendre/indemniser'} description={'CCNT'} /> : ccnt.tally.finalHolidayDelta < 0 ? <FormattedMessage defaultMessage={'fériés perçus en trop'} description={'CCNT'} /> : ""}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );

    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(CcntReport));