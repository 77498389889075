import { createTheme, LinearProgress, ThemeProvider } from '@mui/material';
import { Col, DatePicker, Divider, Row, Space } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeCourses, changeSectors } from '../../store/actions/location';
import '../../styles/newsfeed.css';
import Network from '../../utils/network';
import { Course, PoiLive, RouterProps, Sector } from '../../utils/types/generalTypes';
import { ApplicationState, LocationDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { showNotification, toggleFullScreen } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import Card from '../common/general/card';
import MapLiveLeaflet from '../common/general/mapLive';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
}

type Props = IProps & RouterProps & LocationDispatchProps & ReduxProps & IntlProps;

interface State {
    isLoading: boolean;
    isLoaded: boolean;
    pois: PoiLive[];
    day: Moment;
}

/**
 * Page that display the list of news
 */
class SunkhronosLiveMap extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoaded: false,
            pois: [],
            day: moment(),

        };
    }

    componentDidMount() {
        this.refreshSunkhronosLive();
        this.refreshSectors();
        this.refreshCourses();
    }


    refreshCourses = () => {
        Network.getCourse().then(
            (response: Course[]) => {
                const courses = response;
                this.props.changeCourses!(courses);
            }
        );
    };

    refreshSectors = () => {
        Network.getSector().then(
            (response: Sector[]) => {
                const sectors = response;
                this.props.changeSectors!(sectors);
            }
        );
    };

    refreshSunkhronosLive = (force = false) => {
        if (!force && this.state.isLoading) return;
        this.setState((state) => ({ isLoading: true, isLoaded: force ? false : state.isLoaded }));
        Network.getSunkhronosLive(this.state.day.format("YYYY-MM-DD")).then(
            (response: PoiLive[]) => {
                this.setState({ isLoading: false, pois: response, isLoaded: true });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the live check' }), "error");
                this.setState({ isLoading: false });
            }
        );
    };

    onChange = (sD: Moment | null) => {
        const { day } = this.state;

        if (sD != null && sD !== day) {
            this.setState({ day: sD }, () => this.refreshSunkhronosLive());

        }
    };

    onNext = () => {
        const { day } = this.state;
        const next = day.clone().add(1, 'day');
        this.setState({ day: next }, () => this.refreshSunkhronosLive());
    };

    onLast = () => {
        const { day } = this.state;
        const last = day.clone().subtract(1, 'day');
        this.setState({ day: last }, () => this.refreshSunkhronosLive());
    };

    CustomDatePicker = (cprops: { className?: string; }) => {
        const { isSmartphone, intl } = this.props;
        const { day } = this.state;

        return (
            <div key={`custom-date-picker-div-${day.format('YYYYMMDD')}`} className={cprops.className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
                <DatePicker
                    disabled={this.state.isLoading}
                    allowClear={false}
                    value={moment(day)}
                    placeholder={intl.formatMessage({ defaultMessage: 'Select day' })}
                    format="DD MMM YYYY"
                    onChange={this.onChange}
                    className={`__weekly-planning-month-picker ${cprops.className}-picker`}
                    key={`week-picker-${day.format("YYMMDD")}-${day.week()}`}
                    suffixIcon={null}
                    placement={'bottomLeft'}
                    size='large'
                    bordered={false}
                    inputReadOnly
                    style={{
                        textAlign: 'center'
                    }}
                />


            </div>


        );
    };

    render() {
        const { day, pois, isLoading, isLoaded } = this.state;
        const { sectors, courses, width, isSmartphone, fullscreen, intl } = this.props;


        const theme = () => createTheme({
            palette: {
                primary: {
                    main: '#C09F50',
                },
                secondary: {
                    main: '#3a1e2c',

                },
            },
        });
        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    <Card
                        key={"sunkronos-live-map-card"}
                        icon={<FAIcon prefix='fad' name='street-view' />}
                        //title={"Contrôle des positions"}
                        title={<Space>
                            {width > 440 &&
                                <CircleButton
                                    small icon={<FAIcon prefix='fal' name='chevron-left' />} onClick={() => this.onLast()} disabled={isLoading} />
                            }
                            <this.CustomDatePicker
                                className='work_control-datepicker-input-container'
                            />
                            {width > 440 &&
                                <CircleButton
                                    small icon={<FAIcon prefix='fal' name='chevron-right' />} onClick={() => this.onNext()} disabled={isLoading} />
                            }
                            {width > 470 &&
                                <CircleButton
                                    small
                                    icon={<FAIcon prefix='fad' name='house' />}
                                    title={"Revenir aujourd'hui"}
                                    onClick={() => this.onChange(moment())}
                                    disabled={isLoading || day.isSame(moment(), 'day')}
                                />
                            }
                            {
                                !isSmartphone && width > 1190 ?
                                    <>
                                        <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                        <CircleButton
                                            small
                                            title={intl.formatMessage({ defaultMessage: 'Refresh' })}
                                            icon={<FAIcon prefix={'fad'} name="rotate" />}
                                            onClick={() => this.refreshSunkhronosLive(true)}
                                            loading={isLoading} />
                                        {
                                            !isSmartphone &&
                                            <CircleButton
                                                small
                                                type={fullscreen ? "primary" : "default"}
                                                icon={fullscreen ? <FAIcon prefix='fad' name='minimize' /> : <FAIcon prefix='fad' name='maximize' />}
                                                onClick={() => toggleFullScreen()}
                                                title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                                            />
                                        }
                                    </>
                                    : null
                            }



                        </Space>}
                    >
                        <div className='planning-progressbar-container' style={{ width: '100%', position: "relative" }}>
                            <ThemeProvider theme={theme}>
                                <LinearProgress
                                    sx={{
                                        transition: 'none',
                                    }}
                                    style={{
                                        transition: 'height 0s ease-out 1s',
                                        height: (isLoading === false && isLoaded === true) ? 1 : 3,
                                        position: 'absolute',
                                        width: '100%',
                                        top: '-10px'
                                    }}
                                    color={
                                        (isLoaded === false && isLoading === false) ? 'inherit'
                                            : isLoading ? 'primary'
                                                : (isLoaded === true && isLoading === false) ? 'success'
                                                    : 'error'
                                    }
                                    variant={(isLoading === false) ? 'determinate' : 'indeterminate'}
                                    value={(isLoading === false && isLoaded === true) ? 100 : 50}
                                />
                            </ThemeProvider>
                        </div>

                        <div className="__filter-map-content">
                            <MapLiveLeaflet loading={isLoaded === false && isLoading} refresh={() => this.refreshSunkhronosLive(true)} key={`map-live-parent}`} pois={pois} sectors={sectors} courses={courses} isEditMode={false} />
                        </div>

                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeCourses: (courses: Course[]) => dispatch(changeCourses(courses)),
    changeSectors: (sectors: Sector[]) => dispatch(changeSectors(sectors)),
});

const mapStateToProps = (state: ApplicationState) => ({
    fullscreen: state.window.fullscreen,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    courses: state.location.courses,
    sectors: state.location.sectors,
    pois: state.location.pois,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(SunkhronosLiveMap)));