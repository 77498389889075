import { Col, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { usersColumns } from '../../../utils/constants';
import { StaffType, User, UserSmall } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import TableTransfer from '../../common/fields/tableTransfer';

interface IProps {
    staffTypeShow: StaffType;
    users: User[];
    setStaffTypeEdit: (staffType: StaffType) => void;
}
type ReduxProps = ConnectedProps<typeof connector>;

type Props = IProps & ReduxProps;

interface State {
}


class StaffTypeShowForm extends React.Component<Props, State> {
    

    onChange = (nextTargetKeys: number[]) => {
        const staffType = { ...this.props.staffTypeShow };
        const users = this.props.users.filter(u => nextTargetKeys.findIndex(id => id === u.id) !== -1);
        const parsedUsers: UserSmall[] = [];
        users.forEach((u) => {
            const user: UserSmall = {
                id: u.id,
                firstName: u.first_name,
                lastName: u.last_name,
                email: u.email,
            };
            parsedUsers.push(user);
        })
        staffType && (staffType.users = parsedUsers);
        this.props.setStaffTypeEdit(staffType);
    };

    render() {
        const { staffTypeShow, users } = this.props;
        return (
            <Row gutter={[10, 10]}>
                {/* <Col xs={{ span: 12 }}>
                    <p style={{ textAlign: 'left' }}>Titre: </p>
                </Col>
                <Col xs={{ span: 12 }}>
                    {
                        staffTypeShow.id == null ?
                            <InputField
                                onChange={(e: { target: { value: string | undefined; }; }) => {
                                    const staffType = staffTypeShow;
                                    staffType && (staffType.name = e.target.value);
                                    this.props.setStaffTypeEdit(staffType);
                                }}
                                value={staffTypeShow?.name}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                            : <p style={{ textAlign: 'right' }}>{staffTypeShow.name}</p>
                    }

                </Col> */}
                {/* <Col xs={{ span: 12 }}>
                    <p style={{ textAlign: 'left' }}>Utilisateurs: </p>
                </Col>
                <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                    <Space direction="vertical">
                        {
                            staffTypeShow.users?.map(u => (
                                <Tag className="__tags-sunkhronos" color="#f5f5f5" key={`staff-type-user-${u.id}`} >
                                    {`${u.firstName} ${u.lastName}`}
                                </Tag>
                            ))
                        }
                    </Space>
                </Col> */}
                <Col xs={{ span: 24 }}>
                    <TableTransfer
                        className={"__group-member-transfert"}
                        titles={[
                            <span key={`TableTransfer-users-avalable`} style={{ textTransform: "uppercase", fontSize: "93%" }}><FormattedMessage defaultMessage={'Available users'} /></span>,
                            <span key={`TableTransfer-users-members-of-group`} style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: "95%" }}><FormattedMessage defaultMessage={'Group members'} /></span>
                        ]}
                        rowKey={(item: any) => item.id}
                        dataSource={users}
                        columns={usersColumns.filter(c => c.key !== "contact.mobile" && c.dataIndex !== 'active' && c.key !== "username" && c.key !== "role" && (this.props.company && ['read-only', 'read-write'].includes(this.props.company.userCodeDisplayAuthorization) || c.key !== 'code'))}
                        targetKeys={staffTypeShow.users ? staffTypeShow.users.map(user => user.id) : []}
                        onChange={this.onChange}
                        filterOption={(inputValue, item) => `${item.last_name}${item.first_name}`.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                    />
                </Col>
            </Row>
        )
    }
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company
});
const connector = connect(mapStateToProps);


export default connector(StaffTypeShowForm);