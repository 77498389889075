import { Card as CardA, Col, Empty, Row, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeTypesOfDayOff } from '../../../../store/actions/configurations';
import '../../../../styles/ccnt.css';
import { MOMENT_SHORT_TIME_FORMAT, VehicleTypesDescription, VehicleTypesIds } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import { TypeOfDay, TypeOfDayOff } from '../../../../utils/types/planningTypes';
import { CctSecurityEventsByContractByMonth, CctSecurityUserEventsByMonth, CctSecurityUserEventsByMonthOverview, CctSecurityUserEventsByMonthRecap, CctSecurityUserEventsByMonthSummary, CctSecurityUserEventsByMonthTravelSummary, CctSecurityUserHoursSummaryInMonth } from '../../../../utils/types/reportTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../../../utils/types/storeTypes';
import { showNotification, val } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import { ReportEventType } from '../../common/generateReportNew';
import { downloadReportToExcel } from './utils';

interface IProps {
    isSmartphone: boolean;
    height: number;
    print: boolean;
    togglePrint: () => void;

    ref?: React.LegacyRef<ReportContent> | undefined;

    monthReport: Moment;
    typesOfDayOff?: TypeOfDayOff[];

    cctSecurity: CctSecurityEventsByContractByMonth;

    type: ReportEventType;
}

type Props = IProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    cctSecurityEventsByMonth?: CctSecurityUserEventsByMonth;
    cctSecurityEventsByMonthSummary?: CctSecurityUserEventsByMonthSummary[];
    currentTypesOfDay: TypeOfDay[] | undefined;
    isLoading: boolean;
}

class ReportContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentTypesOfDay: undefined,
            isLoading: true,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false }, () => {
            const userEventSummaryParsed: CctSecurityEventsByContractByMonth | undefined = this.props.cctSecurity;
            let cctSecurityEventsByMonth: CctSecurityUserEventsByMonth | undefined = undefined;
            if (userEventSummaryParsed) {
                cctSecurityEventsByMonth = this.parseEventsByDay(userEventSummaryParsed);
            } else {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'No available data with the defined settings' }), "info");
            }

            this.setState({ isLoading: false, cctSecurityEventsByMonth });
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.print && !prevProps.print) {
            if (this.state.cctSecurityEventsByMonth !== undefined) {
                downloadReportToExcel(this.props.intl, this.props.type, this.state.cctSecurityEventsByMonth, this.props.monthReport);

                this.props.togglePrint();
            } else {
                //TODO Throw an error
            }
        }
    }

    parseEventsByDay = (userEventSummary: CctSecurityEventsByContractByMonth) => {
        const parseEventsToDisplay = (month: CctSecurityUserHoursSummaryInMonth) => {
            //? Preparing arrays
            let numberOfTargetMonth = -1;
            let userEventsByMonthTravel: CctSecurityUserEventsByMonthTravelSummary[] = [];
            const userEventsByMonthOverview: CctSecurityUserEventsByMonthOverview[] = [];
            const userEventsByMonthRecap: CctSecurityUserEventsByMonthRecap[] = [];
            let userEventsByMonthSummary: CctSecurityUserEventsByMonthSummary[] = [];

            if (month !== undefined && month.summaryByDay) {
                //? Month recap
                //: Adding the values to display
                month.summaryByDay.forEach((day, idx) => {
                    day.events?.forEach((event, idx2) => {
                        //? Push data into array to show in main table
                        const typeOfVehicleName = VehicleTypesDescription.find(type => type.id === event.vehicle?.type)?.name;

                        userEventsByMonthRecap.push({
                            id: ((idx + 1) * 1000) + idx2,
                            color: 'ffffff',
                            date: day.date!,
                            title: (event.title === undefined ? "" : event.title),
                            schedule: `(${event.dateFrom.format(MOMENT_SHORT_TIME_FORMAT)} - ${event.dateTo.format(MOMENT_SHORT_TIME_FORMAT)})`,
                            typeOfDay: (event.typeOfDay?.title !== undefined ? event.typeOfDay.title : (event.typeOfDayOff?.title !== undefined ? event.typeOfDayOff.title : "")),
                            hoursPerEvent: val(event.summary?.eventHours, -1),
                            breaktime: val(event.summary?.breaktimesHours),
                            paidBreaktime: val(event.summary?.breaktimesPaidHours),
                            notPaidBreaktime: val(event.summary?.breaktimesNotPaidHours),
                            overtime: val(event.summary?.overtimesHours),
                            totalHours: val(event.summary?.totalHours, -1),
                            effectiveHours: val(event.summary?.effectiveHours),
                            eventHours: val(event.summary?.eventHours, -1),
                            increasedHours: val(event.summary?.increasedHours, 0),
                            vacationIncreasedHours: val(event.summary?.vacationIncreasedHours),
                            project: (event.summary?.project === undefined ? "" : event.summary.project),
                            projectNumber: (event.summary?.projectNumber === undefined ? "" : event.summary.projectNumber),
                            address: (event.poi?.address ? event.poi.address : "-"),
                            startingPoint: (event.basePoi?.title === undefined ? "" : event.basePoi?.title),
                            vehicle: typeOfVehicleName === undefined ? "" : this.props.intl.formatMessage(typeOfVehicleName),
                            travelZone: event.summary?.deplacement?.compensationText ? event.summary.deplacement.compensationText : '', //TODO Calculate travel zone
                            distanceMade: event.distance,
                            distanceCompensated: event.summary?.deplacement?.distanceTotalCompensated, //TODO Calculate compensated distance
                            remarks: (event.summary?.userRemarks === undefined ? "" : event.summary?.userRemarks),
                        });

                        //? setting month Number
                        numberOfTargetMonth = parseInt(this.props.monthReport.format("MM"));

                        //? Month Travel
                        //: Adding the travel zones
                        userEventsByMonthTravel = [
                            {
                                id: month.totEngagementZone?.travelZone?.id!,
                                name: month.totEngagementZone?.travelZone?.name!,
                                passengerVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance),
                                ownVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance),
                                workVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance),
                            },
                            {
                                id: month.totFlatRateZone1?.travelZone?.id!,
                                name: month.totFlatRateZone1?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totFlatRateZone2?.travelZone?.id!,
                                name: month.totFlatRateZone2?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totEngagementZone2?.travelZone?.id!,
                                name: month.totEngagementZone2?.travelZone?.name!,
                                passengerVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totDynamicRateZone?.travelZone?.id!,
                                name: month.totDynamicRateZone?.travelZone?.name!,
                                passengerVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                        ];
                    });
                });
            }

            //? Month Overview tot hours
            //: Adding the total hours (at the end to get the totHours value)
            userEventsByMonthOverview.push({
                id: 0,
                name: this.props.intl.formatMessage({ defaultMessage: 'Total hours' }),
                prevMonthBalance: val(month.monthSummary?.prevTotalHours),
                currentMonthBalance: val(month.monthSummary?.totalHours),
                nextMonthBalance: val(month.monthSummary?.nextTotalHours)
            });

            month.monthSummary?.lstTypeDayOff?.forEach(element => {
                userEventsByMonthOverview.push({
                    id: element.typeDayOff?.id!,
                    name: element.typeDayOff?.title!,
                    prevMonthBalance: val(element.previousBalance),
                    currentMonthBalance: val(element.actualBalance),
                    nextMonthBalance: val(element.nextBalance),
                });
            });

            //? Month Summary
            //: Add month summary (hours, increased hours and vacation) to display
            userEventsByMonthSummary = [
                {
                    id: 0,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Brut hours' }),
                    value: val(month.monthSummary?.eventHours)
                },
                {
                    id: 1,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Overtimes' }),
                    value: val(month.monthSummary?.overtimeUndertimeBalance)
                },
                {
                    id: 2,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Paid break hours' }),
                    value: val(month.monthSummary?.breaktimePaidBalance)
                }, {
                    id: 3,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Unpaid break hours' }),
                    value: val(month.monthSummary?.breaktimeNotPaidBalance)
                },
                {
                    id: 4,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Effective hours' }),
                    value: val(month.monthSummary?.effectiveHours)
                },
                {
                    id: 10,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus hours' }),
                    value: val(month.monthSummary?.increasedHours)
                },
                {
                    id: 20,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus vacations' }),
                    value: val(month.monthSummary?.vacationIncreasedHours)
                },
                {
                    id: 30,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Total balance' }),
                    value: val(month.monthSummary?.totalHours)
                }
            ];

            return {
                monthNumber: numberOfTargetMonth,
                recap: userEventsByMonthRecap,
                summary: userEventsByMonthSummary,
                overview: userEventsByMonthOverview,
                travel: userEventsByMonthTravel
            };
        };

        let monthData: CctSecurityUserHoursSummaryInMonth | undefined;
        switch (this.props.monthReport.month()) {
            case 0:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
            case 1:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.febHours;
                break;
            case 2:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.marHours;
                break;
            case 3:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.aprHours;
                break;
            case 4:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.mayHours;
                break;
            case 5:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.junHours;
                break;
            case 6:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.julHours;
                break;
            case 7:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.augHours;
                break;
            case 8:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.sepHours;
                break;
            case 9:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.octHours;
                break;
            case 10:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.novHours;
                break;
            case 11:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.decHours;
                break;
            default:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
        }

        if (monthData) {
            const cctSecurityUserEventsByMonth: CctSecurityUserEventsByMonth = {
                user: userEventSummary.user,
                contractName: userEventSummary.name,
                contractType: userEventSummary.typeOfContract,
                months: [
                    parseEventsToDisplay(monthData)
                ]
            };

            return cctSecurityUserEventsByMonth;
        }

        showNotification(this.props.intl.formatMessage({ defaultMessage: 'No data for this month' }), "error");
        return undefined;
    };

    //? Columns
    columnsEventsByMonthRecap = (): ColumnsType<CctSecurityUserEventsByMonthRecap> => [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: "date",
            fixed: this.props.isSmartphone ? undefined : "left",
            className: '__width_220',
            render: (value, record) => {
                return (
                    <span>{record.date.format(getFormat('DATE'))} {record.schedule}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: "title",
            className: '__width_250',
            dataIndex: 'title'
        },
        {
            title: <FormattedMessage defaultMessage={'Type of day'} />,
            key: "type_of_day",
            className: '__width_200',
            dataIndex: 'typeOfDay'
        },
        {
            title: <FormattedMessage defaultMessage={'Hours'} />,
            key: "hours",
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Brut'} />,
                    key: "hours_per_event",
                    className: '__width_100 __centered-text',
                    dataIndex: 'hoursPerEvent'
                },
                {
                    title: <FormattedMessage defaultMessage={'Overtimes'} />,
                    key: "overtimes",
                    className: '__width_100 __centered-text',
                    dataIndex: 'overtime'
                },
                {
                    title: <FormattedMessage defaultMessage={'Breaks'} />,
                    key: "breaktime",
                    className: '__width_100 __centered-text',
                    render: (value, record) => {
                        return (
                            <>
                                <Tooltip title={`Nombre d'heures payées: ${record.paidBreaktime}`}>
                                    <span>{record.breaktime}</span>
                                </Tooltip>
                            </>
                        );
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Effectives'} />,
                    key: "total_hours",
                    className: '__width_100 __centered-text',
                    dataIndex: 'effectiveHours'
                },

            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Bonus'} />,
            key: "increased",
            children: [{
                title: <FormattedMessage defaultMessage={'Hours'} />,
                key: "increased_hours",
                className: '__width_100 __centered-text',
                dataIndex: 'increasedHours'
            },
            {
                title: <FormattedMessage defaultMessage={'Vacations'} />,
                key: "increased_holidays",
                className: '__width_100 __centered-text',
                dataIndex: 'vacationIncreasedHours'
            }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Total'} />,
            key: "solde_hours",
            className: '__width_100 __centered-text',
            dataIndex: 'totalHours'
        },
        {
            title: <FormattedMessage defaultMessage={'Project'} />,
            key: "project",
            className: '__width_300',
            dataIndex: 'project'
        },
        {
            title: <FormattedMessage defaultMessage={'Address / Location'} />,
            key: "address",
            className: '__width_250',
            dataIndex: 'address'
        },
        {
            title: <FormattedMessage defaultMessage={'Place of employment'} />,
            key: "starting_point",
            className: '__width_200',
            dataIndex: 'startingPoint'
        },
        {
            title: <FormattedMessage defaultMessage={'Vehicle'} />,
            key: "vehicle",
            className: '__width_180',
            dataIndex: 'vehicle'
        },
        {
            title: <FormattedMessage defaultMessage={'Flat-rate zone'} />,
            key: "travel_zone",
            className: '__width_150',
            render: (value, record) => {
                return (
                    <span>{record.travelZone ? record.travelZone : ""}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Distance (km)'} />,
            key: "distance",
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Outward'} />,
                    key: "distance_made",
                    className: '__width_100 __centered-text',
                    dataIndex: 'distanceMade'
                },
                {
                    title: <FormattedMessage defaultMessage={'Compensated'} />,
                    key: "distance_compensated",
                    className: '__width_120 __centered-text',
                    render: (value, record) => {
                        return (
                            <span>{record.distanceCompensated! > 0 ? record.distanceCompensated : ""}</span>
                        );
                    }
                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Notes'} />,
            key: "remarks",
            className: '__width_400',
            dataIndex: 'remarks'
        }
    ];

    columnsEventsByMonthSummary = (): ColumnsType<CctSecurityUserEventsByMonthSummary> => [
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            key: "monthly_summary",
            render: (value, record) => {
                return (
                    <span>{record.name}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Hours'} />,
            key: "hours",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.value}</span>
                );
            }
        }
    ];

    columnsEventsByMonthOverview = (): ColumnsType<CctSecurityUserEventsByMonthOverview> => [
        {
            title: <FormattedMessage defaultMessage={'Overview'} />,
            key: "overview",
            render: (value, record) => {
                return (
                    <span>{record.name}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Init'} />,
            key: "prev_month",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.prevMonthBalance}</span>
                );
            }
        },
        {
            title: this.props.monthReport.format("MMM")[0].toLocaleUpperCase() + this.props.monthReport.format("MMM").substring(1),
            key: "current_month",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.currentMonthBalance}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Balance'} />,
            key: "total",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.nextMonthBalance}</span>
                );
            }
        }
    ];

    columnsEventsByMonthTravel = (): ColumnsType<CctSecurityUserEventsByMonthTravelSummary> => [
        {
            title: <FormattedMessage defaultMessage={'Travel'} />,
            key: "journey",
            className: '__width_200',
            render: (value, record) => {
                return (
                    <span>{record.name === "Zone de Régie" ? `${record.name} (km)` : record.name}</span>
                );
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Passenger'} />,
            key: "passenger_vehicle",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.passengerVehicle}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Personnal vehicle'} />,
            key: "own_vehicle",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.ownVehicle}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Work vehicle'} />,
            key: "working_vehicle",
            className: '__centered-text',
            render: (value, record) => {
                return (
                    <span>{record.workVehicle}</span>
                );
            }
        }
    ];

    //? Render
    render() {
        // const { starrDayDataCells, stEmployee } = this.state;
        let tableHeight = this.props.height - 570;
        if (tableHeight < 250) tableHeight = 250;
        const tableHeight2 = 190;

        // const monthIndex = cctMonthReport - 1;
        const monthIndex = 0;
        const selectedMonth = this.state.cctSecurityEventsByMonth?.months?.at(monthIndex);
        const { isLoading } = this.state;

        return (
            <div id="cctBody">
                <Spin spinning={isLoading}>
                    <Row gutter={[20, 20]}>
                        <Col key="col-report-recap" xs={24}>
                            <Table
                                style={{ borderRadius: 'var(--border-radius)' }}
                                className='__min-height-100-percent'
                                bordered
                                columns={this.columnsEventsByMonthRecap()}
                                dataSource={selectedMonth?.recap}
                                pagination={false}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No event'} />} /> }}
                                scroll={{ x: true, y: tableHeight }} />
                        </Col>
                        <Col key="col-report-summary" md={{ span: 8 }} xs={{ span: 24 }}>
                            <CardA bodyStyle={{ padding: "1px 0 10px 0" }} className='__min-height-100-percent' title={<FormattedMessage defaultMessage={'Monthly overview'} />}>
                                <Table
                                    columns={this.columnsEventsByMonthSummary()}
                                    dataSource={selectedMonth?.summary}
                                    pagination={false}
                                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No overview'} />} /> }}
                                    scroll={{ x: true, y: tableHeight2 }} />
                            </CardA>
                        </Col>
                        <Col key="col-report-overview" md={{ span: 8 }} xs={{ span: 24 }}>
                            <CardA bodyStyle={{ padding: "1px 0 10px 0" }} className='__min-height-100-percent' title={<FormattedMessage defaultMessage={'Hours and vacations overview'} />}>
                                <Table
                                    columns={this.columnsEventsByMonthOverview()}
                                    dataSource={selectedMonth?.overview}
                                    pagination={false}
                                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No overview'} />} /> }}
                                    scroll={{ x: true, y: tableHeight2 }} />
                            </CardA>
                        </Col>
                        <Col key="col-report-travel" md={{ span: 8 }} xs={{ span: 24 }}>
                            <CardA bodyStyle={{ padding: "1px 0 10px 0" }} title={<FormattedMessage defaultMessage={'Travel summary'} />}>
                                <Table
                                    columns={this.columnsEventsByMonthTravel()}
                                    dataSource={selectedMonth?.travel}
                                    pagination={false}
                                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No overview'} />} /> }}
                                    scroll={{ x: true, y: tableHeight2 }} />
                            </CardA>
                        </Col>
                    </Row>
                </Spin>
            </div>
        );

    }

}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfDayOff: state.configurations.typesOfDayOff,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportContent));